import React from "react";
import PropTypes from "prop-types";
import GeneralTableDropdown from "@layout/tables/GeneralTableDropdown"
import { isExecutiveRelationsLead, isJaguar, canEditUsers,isSolutionsLead, isMarketingManager, isAccountManager, addMy} from "@utility/PermissionUtility"
class MobileHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      userMenuOpen: false,
      activeLinkId: props.sidebarId
    }
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    const { open } = this.state;
    this.setState({
      open: !open,
      userMenuOpen: false
    })
  }

  getSidebarLinks() {
    const { user } = this.props;
    const isUserMarketingManager = isMarketingManager(user);
    const isUserAccountManager = isAccountManager(user);
    const links = [
      {name: "New Nomination",tutorialId:'new_nom', classNames: "cnt-default-layout-new-nomination-button", url:"/nominations/new", id: "nominations", visible: true},
      {name: "My Dashboard",tutorialId:'dashboard', url:"/", id: "dashboard", visible: true},

      {name: `${addMy(user)}Contacts`,tutorialId:'contacts', url:"/contacts", id: "contacts",  visible: !isUserAccountManager },
      {name: `Events`, url:"/events",tutorialId:'events', id: "events", visible: true},
      {name: `${addMy(user)}Companies`,tutorialId:'companies', url:"/companies", id: "companies", visible: !isUserAccountManager},
      {name: `Updates`, url:"/updates",tutorialId:'updates', id: "updates", visible: !isUserAccountManager},
      {name:"Reports", url:"/reports",tutorialId:'reports',id: "reports",  visible: !isUserAccountManager},
      {name: "Users", url:"/admin/users",tutorialId:'users', id: "users", visible: !isUserAccountManager},
      {name: "Tool Guide", url:"/tool_guide",tutorialId:'toolguide', id: "tool-guide", visible: true}
    ].filter(x=> x.visible);
    return links;
  }

  renderOpenMenu() {
    const { open } = this.state;
    if(open) {
      return (
        <div className="cnt-default-layout-mobile-header-menu-open">
          {this.renderLinks()}
        </div>
      )
    }
  }

  getUserOptions() {
    const  {user} = this.props;
    let options =[
      {name:"My Profile", onClick: ()=>{window.location = '/profile'}, visible: true},
      {name:"Sign Out", onClick: ()=>{window.location = '/users/sign_out'}, visible: true}
    ].filter(x=> x.visible);
    return options;
  }

  renderUserDropdown() {
    const {user} = this.props;
    return (
      <GeneralTableDropdown
        options={this.getUserOptions()}
        outerClassNames={"cnt-default-layout-mobile-header-user-menu-button-menu"}
      >
        <div className="cnt-default-layout-mobile-header-user-menu-button">
          {user.first_name} {user.last_name}
          <img className="ctn-default-layout-sidebar-user-name-arrow" src="/layout/sidebar-arrow.svg" />
        </div>
      </GeneralTableDropdown>
    )
  }


  renderLinks() {
    const { user } = this.props;
    const {activeLinkId} = this.state;
    return this.getSidebarLinks().map(link=>{
      return (
        <li key={`cnt-mobile-header-link-${link.id}`}>
          <a className={`cnt-default-layout-mobile-header-link ${activeLinkId == link.id ? "active" : ""} ${link.classNames ? link.classNames : ""}`} href={link.url}>
            <img className={`cnt-default-layout-mobile-header-link-icon`} src={`/layout/sidebar/${link.id}.svg`} />
            {link.name}
          </a>
        </li>
      )
    })
  }

  render() {
    const { user } = this.props;
    return (
      <div className="cnt-default-layout-mobile-header-outer">
        <div className="cnt-default-layout-mobile-header">
          <img src="/layout/mobile-header-menu-button.png" className="cnt-default-layout-mobile-header-menu-button " onClick={this.toggleMenu} />
          {this.renderUserDropdown()}
        </div>
        {this.renderOpenMenu()}
      </div>
    )
  }
}

export default MobileHeader;
