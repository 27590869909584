import React from "react";
import PropTypes from "prop-types";

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
  }

  getCheckboxImage() {
    const {value, disabled} = this.props;
    const images = this.getCheckboxImages();
    if(disabled) {
      return  value ? images.disabled.checked : images.disabled.unchecked;
    }
    return value ? images.standard.checked : images.standard.unchecked;
  }

  getCheckboxImages() {
    let defaultCheckboxImages = {
      standard: {
        checked: "/images/checkbox-checked.svg",
        unchecked: "/images/checkbox-unchecked.svg"
      },
      disabled: {
        checked: "/images/checkbox-checked.svg",
        unchecked:"/images/checkbox-unchecked.svg"
      }
    }

    return {...defaultCheckboxImages, ...this.props.images};
  }

  render() {
    const { value, label, disabled, inline } = this.props;
    return (
      <div
        className={`cnt-default-layout-form-checkbox ${inline ? 'cnt-default-layout-form-checkbox-inline':''}`}
      >
        <img
          src={this.getCheckboxImage()}
          style={{cursor:"pointer", margin: "0 10px 0 0 "}}
          onClick={disabled ? ()=>{} : this.props.onClick}
        />
        <label className="checkbox-label" style={{marginRight:"10px"}} htmlFor="topic_ids">{label}</label>
      </div>
    );
  }
}

export default Checkbox;
