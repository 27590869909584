
export function isJaguar(user) {
  return user.role == "jaguar";
}

export function canEditContact(user) {
  return !!user;
}

export function canEditCompany(user) {
  return !!user
}

export function canBulkNominate(user) {
  return isExecutiveRelationsLead(user) || isJaguar(user) || isSolutionsLead(user) || isMarketingManager(user)
}

export function canEditUsers(user) {
  return isExecutiveRelationsLead(user) || isJaguar(user) || isSolutionsLead(user) || isMarketingManager(user);
}

export function canEditSubmittedNomination(user) {
  return isExecutiveRelationsLead(user) || isSolutionsLead(user) || isJaguar(user)
}

export function canEditComplianceIssue(user) {
  return isJaguar(user) || isExecutiveRelationsLead(user) || isSolutionsLead(user)
}

export function canEditNomination(user) {
  return true;
}

export function canViewEventReport(user) {
  return isExecutiveRelationsLead(user) || isJaguar(user) || isSolutionsLead(user) || isMarketingManager(user);
}

export function canApproveNomination(user) {
  return !(isAccountManager(user) || isMarketingManager(user));
}


export function canEditEvents(user) {
  return isSolutionsLead(user) || isExecutiveRelationsLead(user) || isJaguar(user);
}

export function canSeeDrafts(user) {
  return isJaguar(user) || isSolutionsLead(user) || isExecutiveRelationsLead(user);
}


export function canUserValidateContact(user) {
  return isJaguar(user) || isSolutionsLead(user) || isExecutiveRelationsLead(user);
}

export function canDeleteEvents(user) {
  return isJaguar(user);
}

export function isAccountManager(user) {
  return user.role == "account_manager";
}

export function isAdmin(user) {
  return user.role == "admin" || user.role == "jaguar";
}

export function isExecutiveRelationsLead(user) {
  return user.role == "executive_relations_lead";
}

export function isSolutionsLead(user) {
  return user.role == "solutions_lead";
}

export function isMarketingManager(user) {
  return user.role == "marketing_manager";
}

export function addMy(user) {
  if(isAccountManager(user)){
    return "My "
  }
  return ""
}

export function canViewLog(user) {
  return !isAccountManager(user)
}

export function canEditCompanyAccountManagers(user) {
  return !isAccountManager(user)
}

export function canEditUserRole(user) {
  return isJaguar(user) || isSolutionsLead(user) || isExecutiveRelationsLead(user)
}

export function canDeleteContact(user) {
  return !isAccountManager(user)
}

export function canDeleteCompany(user) {
  return !isAccountManager(user)
}

export function canSeeNotes(user) {
  return !(isAccountManager(user) || isMarketingManager(user))
}

export function canSeeContactNotes(user) {
  return canSeeNotes(user)
}

export function canSeeCompanyNotes(user) {
  return canSeeNotes(user)
}

export function canDeleteNomination(user) {
  return !isAccountManager(user)
}

export function canDeleteUser(user) {
  return !isAccountManager(user)
}

export function canUserShift(user) {
  return isJaguar(user) || isSolutionsLead(user)
}

export function isEventNominationOpen(event){
  return event && event.archived_at == null;
}

export function isEventCriteriaLocked(event){
  return (event && event.award_criteria && event.award_criteria.locked);
}

export function isNominationEditable(nomination) {
  return nomination.status == 'draft'
}
export function getRoleName(role) {
  switch(role) {
    case "marketing_manager":
      return "Marketing Manager"
    case "solutions_lead":
      return "Solutions Lead"
    case "account_manager":
      return "Account Manager"
    case "executive_relations_lead":
      return "Audience Engagement"
    case "jaguar":
      return "Jaguar"
    case "admin":
      return "Admin"
  }
  return role
}
