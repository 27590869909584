import React from "react";
import PropTypes from "prop-types";
import GeneralChit from "@layout/tables/GeneralChit"
import UniversalImageCircle from "@images/UniversalImageCircle"
import ContactProfileModal from "@contacts/modals/ContactProfileModal"
import { canUserValidateContact } from "@utility/PermissionUtility";
class ContactTableChit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  renderValidationIcon() {
    const { includeValidationIcon, nomination, contact } = this.props;
    if(includeValidationIcon && nomination) {
      return (
        <a className="cnt-default-layout-contact-chit-validation-icon" href={this.isValidatable() ? `/contacts/${contact.id}/validate?nomination_id=${nomination.id}` : "#"}>
          <img  src={nomination && nomination.validated_at ? "/images/nominations/review-valid.svg" : "/images/nominations/review-invalid.svg"} />
        </a>
      )
    }
  }

  isValidatable() {
    const { includeValidationIcon, nomination, user } = this.props;
    return includeValidationIcon && user && canUserValidateContact(user) && nomination;
  }

  render() {
    const { contact, user, nomination } = this.props;
    if(contact) {
      return (
        <ContactProfileModal disabled={this.isValidatable()} user={user} contact={contact}>
          <GeneralChit
            circle={
              <UniversalImageCircle size={'tiny'} image={null} name={contact.first_name} id={contact.id}/>
            }
            forceCircle={this.props.forceCircle}
            name={`${contact.first_name} ${contact.last_name}`}
            link={this.isValidatable() ? `/contacts/${contact.id}/validate?nomination_id=${nomination.id}` : "#"}
            outerClassNames={'cnt-default-layout-contact-chit'}
          />
          {this.renderValidationIcon()}
        </ContactProfileModal>
      );
    }
    return "";
  }
}

export default ContactTableChit;
