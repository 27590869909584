import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import {isJaguar, addMy} from "@utility/PermissionUtility"
import ContactsTablePaginated from "./ContactsTablePaginated"
class ContactsIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  renderCreateContactsButton() {
    const { user } = this.props;

    return (
      <a href="/contacts/new">
        <button className="cnt-page-header-button">
         Add Contact
        </button>
      </a>
    )
  }

  render() {
    const { user } = this.props;
    return (
      <DefaultLayout
        {...this.props}
        sidebarId="contacts"
      >
        <h1 className="cnt-page-header">
          {addMy(user)}Contacts
          {this.renderCreateContactsButton()}
        </h1>
        <ContactsTablePaginated {...this.props} />
      </DefaultLayout>
    );
  }
}

export default ContactsIndex;
