import React from "react";
import PropTypes from "prop-types";
import {List} from 'react-virtualized';

class MultiselectActionBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      actionPerformed: false,
      optionOpen: null,
      values: props.defaultValues ? props.defaultValues : {},
      searchingBar: null,
      searchingBarValue: null,
    };
    this.performActionCallback = this.performActionCallback.bind(this);
    this.setOptionValue = this.setOptionValue.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.mouseEnter = this.mouseEnter.bind(this);
    this.mouseLeave = this.mouseLeave.bind(this);
    this.optionRowRenderer = this.optionRowRenderer.bind(this);
  }

  getStatus() {
    const { actionPerformed, loading } = this.state;
    const { selectedItems } = this.props;
    if(actionPerformed) {
      return loading ? "loading" : "completed";
    }
    if(selectedItems && selectedItems.length > 0) {
      return 'show'
    }
    return 'hidden';
  }

  renderInner() {
    const status = this.getStatus();
    switch(status) {
      case 'loading':
        return (
          <div className="cnt-default-layout-general-table-multi-select-action-bar-inner " style={{paddingLeft: "10px"}}>
            Your changes are being saved. This may take a minute.
          </div>
        )
      case 'completed':
        return (
          <div className="cnt-default-layout-general-table-multi-select-action-bar-inner" style={{paddingLeft: "10px"}}>
            Your change has been applied.
          </div>
        )
      default:
        return this.renderOptionsBar();
    }
  }

  renderOptionsBar() {
    const { selectedItems, options, closeCallback} = this.props;

    const renderedOptions = options.map(x=>
      this.renderOption(x)
    )
    return (
      <div className="cnt-default-layout-general-table-multi-select-action-bar-inner">
        <div className="cnt-default-layout-general-table-multi-select-action-bar-option-container cnt-default-layout-general-table-multi-select-action-bar-selections">
          <img
            src="/images/dashboard/multiselect-close.svg"
            onClick={closeCallback}
            className="cnt-default-layout-general-table-multi-select-action-bar-close"
          />
          {selectedItems.length} Selections
        </div>
        {renderedOptions}
        <div className="cnt-default-layout-general-table-multi-select-action-bar-submit-container">
          <button
            onClick={this.saveChanges}
            className="cnt-default-layout-general-table-multi-select-action-bar-submit"
          >
            Apply Change
          </button>
        </div>
      </div>
    )
  }

  mouseEnter(option){
    this.setState({ optionOpen: option.id});
  }

  mouseLeave(option){
    const { optionOpen } = this.state;
    if(optionOpen == option.id) {
      this.setState({ optionOpen: null });
    }
  }

  renderOption(option) {
    const { optionOpen, values, searchingBar, searchingBarValue} = this.state;
    const value = values[option.id];
    const valueObject = option.options.find(x=>x.value == value);
    const isOpen = optionOpen == option.id || searchingBar == option.id;
    const isSearching = searchingBar == option.id;


    const  allAvailableOptions = this.getFilteredOptions(option)
    return (
      <div onMouseEnter={()=>this.mouseEnter(option)} onMouseLeave={()=>this.mouseLeave(option)}className="cnt-default-layout-general-table-multi-select-action-bar-option-dropdown-container">
        <div onClick={()=>this.toggleOptionMenu(option)} className="cnt-default-layout-general-table-multi-select-action-bar-option-dropdown-button">
          <input
            placeholder={(valueObject||{}).label || option.name}
            value={isSearching ? searchingBarValue : ""}
            onFocus={()=>
              this.setState({
                searchingBar: option.id,
                searchingBarValue: ""
              })
            }
            style={{...(option.inputStyle||{})}}
            onChange={(e)=>
              this.setState({
                searchingBarValue: e.target.value
              })
            }
          />
          <img src="/images/dashboard/multicheck-arrow.svg"/>
        </div>
        {isOpen ? (
          <div className="cnt-default-layout-general-table-multi-select-action-bar-option-dropdown-menu">
            <List
              width={300}
              height={Math.min(allAvailableOptions.length*40, 300)}
              rowCount={allAvailableOptions.length}
              rowHeight={40}
              rowRenderer={(e)=>this.optionRowRenderer(e, option, allAvailableOptions)}
            />
            <div
              onClick={()=>this.setOptionValue(option.id, null)}
              className="cnt-default-layout-general-table-multi-select-action-bar-option-dropdown-menu-option"
            >
              Deselect
            </div>
          </div>
        ):""}
      </div>
    )
  }

  optionRowRenderer({key, index, isScrolling, isVisible, style}, option, allSelectableOptions) {
    return this.renderMenuOption(option,allSelectableOptions[index], style)
  }

  getFilteredOptions(option) {
    const { optionOpen, values, searchingBar, searchingBarValue} = this.state;
    const value = values[option.id];
    const valueObject = option.options.find(x=>x.value == value);
    const isOpen = optionOpen == option.id || searchingBar == option.id;
    const isSearching = searchingBar == option.id;

    return option.options.filter(x=>
      isSearching ? x.label.toLowerCase().indexOf((searchingBarValue||"").toLowerCase()) > -1 : true
    )
  }


  renderMenuOption(option, optionOption, style) {
    return (
      <div
        style={{...style}}
        onClick={optionOption.onClick ? optionOption.onClick : ()=>this.setOptionValue(option.id, optionOption.value)}
        className="cnt-default-layout-general-table-multi-select-action-bar-option-dropdown-menu-option"
      >
        {optionOption.label}
      </div>
    )
  }

  toggleOptionMenu(option) {
    const {optionOpen} = this.state;
    if(optionOpen == option.id) {
      this.setState({
        optionOpen: null
      })
      return;
    }
    this.setState({
      optionOpen: option.id
    })
  }

  saveChanges() {
    const {submitCallback, selectedItems} = this.props;
    const { values } = this.state;
    this.setState({loading: true, actionPerformed: true})
    if(submitCallback) {
      submitCallback(
        selectedItems.map(x=> x.id),
        this.performActionCallback,
        values
      )
    }
  }

  setOptionValue(optionID, optionValue) {
    let { values } = this.state;
    values[optionID] = optionValue
    this.setState({
      values,
      optionOpen: null,
      searchingBar: null,
      searchingBarValue: null,
    })
  }

  performActionCallback(json) {
    this.setState({
      loading: false,
    })
    setTimeout(()=>
      this.setState({actionPerformed: false}),
    3000)
  }

  render() {
    const status = this.getStatus();
    if(status=='hidden'){
      return (<></>)
    }
    return (
      <div
        className={`
          cnt-default-layout-general-table-multi-select-action-bar
          cnt-default-layout-general-table-multi-select-action-bar-${status}
        `}>
        {this.renderInner()}
      </div>
    );
  }
}

export default MultiselectActionBar;
