import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import Checkbox from "@layout/forms/Checkbox";
import Cookies from "universal-cookie";
import {getCountrySelectOptions, getStateSelectOptions}  from "@utility/CountryCodes"
import {isJaguar, canSeeContactNotes, isSolutionsLead, isExecutiveRelationsLead, canEditContact, isAccountManager, canDeleteContact} from "@utility/PermissionUtility"
import Select from 'react-select-virtualized'
import { jobLevelOptions, cabMemberOptions, inviteeTypeOptions, relationshipWithCiscoOptions} from "@utility/OptionsUtils"
import DropzoneComponent from "@images/DropzoneComponent"
import UniversalImageCircle from "@images/UniversalImageCircle"
import {prefixOptions} from "./ContactUtils"
import { fetchAPI } from "@utility/NetworkUtils"
import { Countries, Provinces } from "country-and-province"
import { radioImages, checkboxImages } from "@utility/checkboxUtility"
import {tierOptions, abxOptions, companyTypeOptions, salesSubCoverageOptions, salesCoverageOptions, areaOptions, theaterOptions, geoOptions, operationsOptions, verticalOptions, regionOptions, segmentOptions, subSegmentOptions} from "@companies/forms/CompanyUtils"
class ContactAccountManagerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      saving: false,
    };
    this.destroyContact = this.destroyContact.bind(this);
    this.checkUniqueValidation = this.checkUniqueValidation.bind(this);
  }

  isEditing() {
    const { contact } = this.props;
    return !!contact.id;
  }

  renderField(name,formikProps, required=false,customValidation = false, properties = {}) {
    const { user } = this.props;
    const { checkError } = this.state;
    const erroring = required && checkError && (!formikProps.values.contact[name] || formikProps.values.contact[name].length <= 0 || customValidation);

    return (
      <Field
        onChange={(e) => {
            const { contact } = formikProps.values;
            contact[name] = e.target.value
            if(properties.stripValue) {
              contact[name] = (contact[name]||"").trim()
            }
            formikProps.setFieldValue('contact', contact);
            
            if(properties.onChange) {
              properties.onChange(formikProps)
            }
            this.setState({
              dirty: true,
            });
          }
        }
        style={{...properties['style']||{}}}
        disabled={!canEditContact(user)}
        component={properties['component']}
        className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`contact[${name}]`}
      />
    )
  }

  renderDateField(name,formikProps, required = false) {
    const { checkError } = this.state;
    const erroring = required && checkError && (!formikProps.values.contact[name] || formikProps.values.contact[name].length <= 0 || customValidation);

    return (
      <Field
        onChange={(e) => {
            const { contact } = formikProps.values;
            contact[name] = e.target.value
            formikProps.setFieldValue('contact', contact);
            this.setState({
              dirty: true,
            });
          }
        }
        component={properties['component']}
        className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`contact[${name}]`}
      />
    )
  }


  padWithZeroes(number, length) {
    var my_string = '' + number;
    while (my_string.length < length) {
        my_string = '0' + my_string;
    }
    return my_string;
  }

  checkUniqueValidation(formikProps) {
    const { contact } = formikProps.values;
    const contact_id = this.props.contact.id
    if(contact && contact.email) {
      const url = `/api/v1/contacts/validate_contact_new?email=${contact.email}${contact_id ? "&id="+contact_id : ""}`
      fetchAPI(
        url,
        (json) => {
          if(json.contact_exists) {
            this.setState({
              contactExists: true
            })
          }else {
            this.setState({
              contactExists: false
            })
          }
        }
      )
    }
  }

  renderContactExistsMessage() {
    const { user } =this.props;
    const { contactExists } = this.state;
    if(contactExists) {
      return (
        <div className="row">
          <div className="col-xs-12">
            <br/>
            Looks like a contact with this email already exists.  {isAccountManager(user) ? "Please find the existing record to nominate them." : "Please go to the contacts page to find out more."} 
          </div>
        </div>
      )
    }
  }

  renderForm(formikProps) {
    const { error, saved } = this.state;
    const { user } = this.props;
    const { values } = formikProps;
    const { contact } = values;
    return (
      <Form className="cnt-default-layout-contacts-form" >
        <div className="row">
          <div className="col-xs-12 ">
            <div className="cnt-form-subheader">
              Contact Information
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 cnt-default-layout-event-form-error text-right">
            {error}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <label className="required">Mandatory Fields are marked with a </label>
          </div>

          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                First Name
              </label>
              {this.renderField('first_name', formikProps, true, false)}
            </div>
          </div>

          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Middle
              </label>
              {this.renderField('middle_name', formikProps, false)}
            </div>
          </div>

          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Last Name
              </label>
              {this.renderField('last_name', formikProps, true,false)}
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Email
              </label>
              {this.renderField('email', formikProps, true, false, {
                onChange: this.checkUniqueValidation,
                stripValue: true
              })}
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Country
              </label>
              {this.renderSelectField("address_country", formikProps, getCountrySelectOptions(), false, {required: true})}
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Official Job Title
              </label>
              {this.renderField('job_title', formikProps, true)}
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Job Level
              </label>
              {this.renderSelectField('job_level', formikProps, jobLevelOptions(), false, {required: true})}
            </div>
          </div>
        </div>
        {this.renderContactExistsMessage()}
        <div className="row">
          <div className="col-xs-12 ">
            <div className="cnt-form-subheader">
              Company
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Company
              </label>
              {this.renderField('am_provided_company_name', formikProps, true)}
            </div>
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Company Area
              </label>
              {this.renderSelectField('am_provided_area', formikProps, areaOptions(), false, {required: false})}
            </div>
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Theater
              </label>
              {this.renderSelectField('am_provided_theater', formikProps, theaterOptions(), false, {required: true})}
            </div>
          </div>


          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Tier/Segment
              </label>
              {this.renderSelectField('am_provided_segment', formikProps, tierOptions(), false, {grouped: true, required: true})}
            </div>
          </div>
        </div>


        <div className="row">
          <div className="col-xs-12 ">
            <div className="cnt-form-subheader">
              Assistant
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Assistant Name
              </label>
              {this.renderField('assistant_name', formikProps, false)}
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Assistant Email
              </label>
              {this.renderField('assistant_email', formikProps, false)}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 ">
            <div className="cnt-form-subheader">
              Preferred AM
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Preferred AM First Name
              </label>
              {this.renderField('preferred_am_first_name', formikProps, true)}
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Preferred AM Last Name
              </label>
              {this.renderField('preferred_am_last_name', formikProps, true)}
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
              Preferred AM Email
              </label>
              {this.renderField('preferred_am_email', formikProps, true)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 ">
            <div className="cnt-form-subheader">
              Executive Sponsor
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
              Executive Sponsor
              </label>
              {this.renderField('executive_sponsor', formikProps, false)}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 cnt-default-layout-event-form-error text-right">
            {error}
          </div>
          {saved ? (
            <div className="col-xs-12">
              Successfully Saved Contact
            </div>
          ):""}
          {this.renderButtons(formikProps)}
        </div>
      </Form>
    )
  }



  isDirty() {
    const { dirty } = this.state;
    return dirty
  }

  getAccountManagerOptions() {
    const { users } = this.props;
    return users.map(x=> {return {
      value: x.id,
      label: `${x.first_name} ${x.last_name}`
    }})
  }

  getStates(country_name) {
    try {
      const country = Countries.byName(country_name||"");
      if(country && country.provinces && country.provinces.data) {
        return country.provinces.data.map(x=>{
          return {
            label: x.name,
            value: x.name
          }
        })
      }
      return null;
    }catch(e){
      return null;
    }
  }

  submitForm(formikProps) {
    this.setState({
      error: null,
      checkError: true,
    })
    if(this.isFormValid(formikProps)) {
      formikProps.handleSubmit()
    }else {
      this.setState({
        error: "Please fill in all required fields"
      })
    }
  }

  requiredFields() {
    return [
      "first_name",
      "last_name",
      "email",
      "address_country",
      "job_title",
      "job_level",
      "am_provided_company_name",
      "am_provided_theater",
      "am_provided_segment",
      "preferred_am_first_name",
      "preferred_am_last_name",
      "preferred_am_email",
    ]
  }

  isFormValid(formikProps) {
    const { values } = formikProps;
    const fieldList = this.requiredFields()
    const validFields = fieldList.filter(field => values["contact"][field] && values["contact"][field].length > 0);

    if (!(validFields.length == fieldList.length)) {
      return false;
    }
    return true
  }


  renderButtons(formikProps) {
    const { dirty, saving } = this.state;
    const { user, cancelCallback, forceDirty } = this.props;
    const isSubmitButtonDisabled = !(formikProps.dirty || dirty || forceDirty)
    if(canEditContact(user)) {
      return (
        <div className="col-xs-12" style={{paddingTop: "20px"}}>
          <button type="button"
            onClick={() => this.submitForm(formikProps)}
            disabled={isSubmitButtonDisabled}
            className={!isSubmitButtonDisabled ? "cnt-submit-button" : "cnt-disabled-button "}
          > {saving ? "Saving..." : "Save"} </button>
          <button onClick={cancelCallback ? cancelCallback : ()=>{window.location="/contacts"}} type="button" className="cnt-cancel-button "> Cancel </button>
        </div>
      )
    }
  }

  destroyContact() {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    if(!confirm("Are you sure you want to delete this contact?")) {
      return;
    }
    fetch(this.formURL(), {
      method: "DELETE",
      redirect: "manual",
      body: null,
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          if(json.redirect_url) {
            window.location = json.redirect_url
          }else {
            console.log("Successful no redirect")
          }
        }
        else {
          this.setState({
            error: json.error
          })
        }
      });
  }
  renderSelectField(name, formikProps, options, customValidation = false, renderOptions = {}) {
    const { checkError } = this.state;
    const erroring = checkError && renderOptions['required'] && ((formikProps.values.contact[name] == null || formikProps.values.contact[name].length <= 0) || customValidation);
    let value = null;
    if(renderOptions['grouped']){
      value = formikProps.values['contact'][name] ? {
        label: formikProps.values['contact'][name],
        value: formikProps.values['contact'][name]
      } : null
    }
    else {
      try {
        value = options.filter(option => option.value === formikProps.values['contact'][name])[0]
      }catch (e) {
  
      }
    }

      return (
        <Select
          className={erroring ? "cnt-form-select-erroring" : ""}
          options={options}
          grouped={renderOptions["grouped"]}
          classNamePrefix='cnt-form-select'
          isClearable={false}
          value={options ? value : ""}
          onChange={(option) => {
            const { contact } = formikProps.values;
            contact[name] = option.value
            this.setState({
              dirty: true
            })
            formikProps.setFieldValue('contact', contact)
          }}
          isDisabled={renderOptions['disabled']}
          filterOption={this.customFilter}
        />
      )
    }
    
  formMethod() {
    return this.isEditing() ? "PATCH" : "POST"
  }

  formURL() {
    const { contact } = this.props;
    return this.isEditing() ? `/contacts/${contact.id}` : `/contacts`;
  }

  generateDefaultAccountManager() {
    const { user } = this.props
    if(isAccountManager(user)) {
      return user.id
    }
    return null
  }

  saveFormToCookies(formikProps) {
    const values = formikProps.values;
    const temp_id = Math.floor(Math.random()*1000)+1
    values.temp_id = temp_id
    localStorage.setItem("ContactFormTemp", JSON.stringify(values));
    return temp_id
  }

  createNewCompany(formikProps) {
    const { from_nomination } = this.props;
    const id = this.saveFormToCookies(formikProps);
    window.location=`/companies/new?temporary_contact_id=${id}${from_nomination? "&from_nomination=true" : ""}`
  }

  createNewUser(formikProps) {
    const id = this.saveFormToCookies(formikProps);
    window.location=`/admin/users/new?temporary_contact_id=${id}`
  }
  getAmProvidedInformation(name) {
    const { contact } = this.props;
    const company = contact.company || {};
    const am_name = `am_provided_${name}`;
    if(contact[am_name]) {
      return contact[am_name]
    }
    return company[name.replace("company_","")];
  }

  render() {
    const { user, from_nomination, contact, return_nomination, return_nomination_event } = this.props;
    return (
      <>
      <Formik
        initialValues={{
          contact: {
            first_name: contact.first_name || "",
            last_name: contact.last_name || "",
            email: contact.email || "",
            job_title: contact.job_title || "",
            job_level: contact.job_level || "",
            am_provided_company_name: this.getAmProvidedInformation('company_name'),
            am_provided_area: this.getAmProvidedInformation('area'),
            am_provided_theater: this.getAmProvidedInformation('theater'),
            am_provided_segment: this.getAmProvidedInformation('segment'),
            address_country: contact.address_country || "",

            assistant_name: contact.assistant_name || "",
            assistant_email: contact.assistant_email || "",
            
            preferred_am_first_name: contact.preferred_am_first_name || "",
            preferred_am_last_name: contact.preferred_am_last_name || "",
            preferred_am_email: contact.preferred_am_email,

            executive_sponsor: contact.executive_sponsor || "",
          }
        }}
        onSubmit={(values, actions) => {
          const { onFormSubmitCallback } = this.props;
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          var form_data = new FormData();

          this.setState({
            checkError: false,
            saved: false,
            saving: true,
          })
          for ( var key in values['contact'] ) {
            form_data.append(`contact[${key}]`, values['contact'][key]);
          }
          fetch(this.formURL(), {
            method: this.formMethod(),
            redirect: "manual",
            body: form_data,
            headers: {
              "X-CSRF-Token": token
            }
          }).then(response => {
            this.setState({
              saving: false
            })
            return response.json();
          })
          .then(json => {
            if (json.error == null) {
                this.setState({
                  saved: true,
                  error: null,
                })
                if(onFormSubmitCallback) {
                  onFormSubmitCallback(json.contact)
                }
            }else {
              this.setState({
                error: json.error,
                checkError: true,
              })
            }
          });
        }}
        render={(formikProps) => (
          <>
            {this.renderForm(formikProps)}
          </>
        )}
      />
      </>
    );
  }
}

export default ContactAccountManagerForm;
