import React from "react";
import PropTypes from "prop-types";
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-timezone';
class CompanyCondensedView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderField = this.renderField.bind(this);
    this.renderSections = this.renderSections.bind(this);
  }

  getFieldSections() {
    return [
      {
        name: "Company Information",
        fields: [
          {id: "name", name: "Company Name"},
          {id: "common_company_name", name: "Common Company Name"},
          {id: "country", name: "Country"},
          {id: "geo", name: "GEO (Sales Level 1)"},
          {id: "theater", name: "Theater"},
          {id: "area", name: "Area"},
          {id: "abx_tier", name: "ABX Tier"},
        ]
      },
      {
        name: "Company Account Information",
        fields: [
          {id: "competitor", name: "Competitor", render: (row)=>row.competitor ? "True" : "False"},
          {id: "transformation_account", name: "Transformational Account", render: (row)=>(row||{}).transformation_account ? "True" : "False"},
          {id: "competitor_reason", name: "competitor_reason"},
          {id: "org_party_id", name: "Org Party ID"},
          {id: "org_party_id_source", name: "SAVM Source"},
          {id: "savmid", name: "SAVM ID"},
          {id: "savm_version", name: "SAVM Version"},
          {id: "account_id_group", name: "Account ID Group"},
          {id: "savm_version", name: "SAVM Source"},
          {id: "sales_coverage", name: "Sales Coverage Code"},
          {id: "sales_sub_coverage", name: "Sales Sub Coverage Code"},
          {id: "segment", name: "Sales Level 2"},
          {id: "sub_segment", name: "Sales Level 3"},
          {id: "operations", name: "Sales Level 4"},
          {id: "sales_level_5", name: "Sales Level 5"},
          {id: "vertical", name: "Account Vertical Market"},

          {id: "company_type", name: "Company Type"},
          {id: "wallet_opportunity", name: "Wallet Opportunity ($Million) #"},
          {id: "employee_count", name: "Employee Count"},
          {id: "compliance_issue", name: "Compliance Issue", render: (row)=>{return (row||{}).compliance_issue ? "True" : "False"}},
          {id: "compliance_issue_reasoning", name: "Compliance Issue"},
          {id: 'notes', name: 'Notes'},
        ]
      },
      {
        name: "Company Account Information",
        fields: [
          {id: 'account_manager_id', name: 'Account Manager'},
          {id: 'created_at', name: 'Account Created At', render:(row)=>formatToTimeZone(row.created_at, "MMM DD, YYYY HH:mm aa", {timeZone: "America/Los_Angeles"})},
          {id: 'updated_at', name: 'Account Updated At', render: (row)=>formatToTimeZone(row.updated_at, "MMM DD, YYYY HH:mm aa", {timeZone: "America/Los_Angeles"})},
          {id: 'am_updated_at', name: 'Am Validation Date', render: (row)=>formatToTimeZone(row.am_updated_at, "MMM DD, YYYY HH:mm aa", {timeZone: "America/Los_Angeles"})},
          {id: 'am_updated_by_id', name: 'Am Validation By', render: (row)=>{return row.am_updated_by ? `${row.am_updated_by.first_name} ${row.am_updated_by.last_name}` : row.am_updated_by_id}},
        ]
      }
    ]
  }

  getValidFieldSections() {
    const { company } = this.props;
    let fieldSections = this.getFieldSections();
    fieldSections = fieldSections.map(section => {
      section.fields = section.fields.filter(field=>
        company[field.id]
      )
      return section;
    }).filter(section  => section.fields.length > 0);
    return fieldSections;
  }


  renderSections() {
    const sections = this.getValidFieldSections();
    return sections.map(section=> (
      <div className="cnt-condensed-model-section">
        <div className="cnt-condensed-model-section-header">
          {section.name}
        </div>
        <div className="cnt-condensed-model-section-fields">
          {section.fields.map((field)=>this.renderField(field))}
        </div>
      </div>
    ))
  }

  renderField(field) {
    const { company } = this.props;
    return (
      <div className="cnt-condensed-modal-section-field">
        <div className="cnt-condensed-modal-section-field-name">
          {field.name}:{" "}
        </div>
        <div className="cnt-condensed-modal-section-field-value">
          {field.render ? (field.render(company)) : company[field.id]}
        </div>
      </div>
    )
  }

  render() {
    const { company } = this.props;

    return (
      <div className="cnt-condensed-model-container">
        {company ? this.renderSections() : ""}
      </div>
    );
  }
}

export default CompanyCondensedView;
