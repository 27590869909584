import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import ContactMergeForm from "@contacts/forms/ContactMergeForm"
class ContactMergeModal extends React.Component {
  constructor(props) {
    super(props);
    this.onMerge = this.onMerge.bind(this);
  }

  onMerge(selectedId, rejectedIds) {
    console.log("Modal On Merge")
    console.log({selectedId, rejectedIds})
    const { onMerge } = this.props;
    if(onMerge) {
      onMerge(selectedId, rejectedIds)
    }
  }

  render() {
    const {contacts, user, isOpen, closeModal } = this.props;
    return (
      <>
        <Modal
          className="white-background cnt-user-admin-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={isOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          <ContactMergeForm
            onMerge={this.onMerge}
            onCancel={closeModal}
            contacts={contacts}
          />
        </Modal>
      </>
    );
  }
}

export default ContactMergeModal;
