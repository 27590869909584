import React from "react";
import PropTypes from "prop-types";
// import Select from "react-select";
import Select from 'react-select-virtualized';

class ReportTableFilters extends React.Component {
  constructor(props) {
    super(props);
    const hash = window.location.hash.substr(1);
    this.state = {
      defaultFilterBy: '',
      searchTerm: ''
    }
    this.setFilterBy = this.setFilterBy.bind(this);
  }

  setFilteredReports() {
    const { reports, starredReports, updateFilteredReports } = this.props;
    const { defaultFilterBy, searchTerm } = this.state;

    let filteredReports = reports;
    switch (defaultFilterBy) {
      case 'starred':
        filteredReports = reports.filter(report => starredReports.find(s => s.report_id === report.id));
        break;
      default:
        console.log('default');
    }
    let searchedReports = filteredReports.filter(report => this.compareValues(searchTerm, report));
    updateFilteredReports(searchedReports);
  }

  setFilterBy(value) {
    const { reports } = this.props;
    let filteredReports = reports;

    this.setState({
      defaultFilterBy: value
    }, () => {
      this.setFilteredReports();
    });
  }

  safeToString(x) {
    switch (typeof x) {
      case 'object':
        return 'object';
      case 'function':
        return 'function';
      default:
        return x + '';
    }
  }

  compareValues(value, report) {
    let match = false;
    Object.entries(report).forEach(key => {
      if ( this.safeToString(key[1]).includes(this.safeToString(value)) ) match = true;
    });
    return match
  }

  setSearch(e) {
    const { updateFilteredReports } = this.props;
    this.setState({
      searchTerm: e.target.value
    }, () => {
      this.setFilteredReports();
    });
  }

  render() {
    const { defaultFilterBy } = this.state;
    const filterObject = [
      {id:'starred', label: 'Starred'}
    ]

    return (
      <div className="report-table-header-options">
        <div className="report-table-filter">
          <div className="cnt-default-layout-super-filter" style={{margin: "0px"}}>
            <div className="cnt-super-filter-inactive">
              <Select
                clearable={false}
                options={
                  filterObject.map(x=> {
                    return {
                      value: x.id,
                      label: x.label
                    }
                  })
                }
                onChange={(e) => this.setFilterBy(e.value)}
                classNamePrefix='cnt-default-layout-super-filter-select'
                placeholder={'Filter by'}
                value={defaultFilterBy.label}
              />
            </div>
          </div>
        </div>
        <div className="report-table-search">
          <div className="MuiInputBase-root" style={{width: "100%"}}>
            <div className="MuiInputAdornment-root MuiInputAdornment-positionStart">
              <span className="material-icons MuiIcon-root MuiIcon-fontSizeSmall" aria-hidden="true" title="Search">search</span>
            </div>
            <input type="text" onChange={(e) => this.setSearch(e)} placeholder="Search" />
            <div className="MuiInputAdornment-root MuiInputAdornment-positionEnd">
              <button className="MuiButtonBase-root MuiIconButton-root" type="button" aria-label="Clear Search">
                <span className="material-icons MuiIcon-root MuiIcon-fontSizeSmall" aria-hidden="true" aria-label="clear">clear</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportTableFilters;
