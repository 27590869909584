import React from "react";
import PropTypes from "prop-types";
import { isAccountManager, canApproveNomination } from "@utility/PermissionUtility";
import { getAllInviteStatuses } from "@utility/NominationUtility";
import Select from 'react-select-virtualized';
import Cookies from "universal-cookie";

class EventOptionStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.changeValue = this.changeValue.bind(this);
  }

  renderSelectField() {
    const { nomination } = this.props;
    return (
      <div className="cnt-default-attendee-status-ticker-select-container">
        <Select
          options={this.getSelectOptions()}
          classNamePrefix='cnt-status-select'
          isClearable={false}
          components={{
            SingleValue: (children, props)=> this.renderTicker(children.children),
            Option: (children, props)=> this.renderTicker(children.children)
          }}
          value={{value: this.getValue(), label: this.getValue()}}
          onChange={this.changeValue}
        />
      </div>
    )
  }

  changeValue(value) {

    const { updateNomination, nomination, name } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    if(updateNomination){
      nomination.options[name] = value.value || "";
      updateNomination(nomination);
    }

    let newOptions = nomination.options;
    newOptions[name] = value.value || "";
    fetch(`/nominations/${nomination.id}/update_status`, {
      method: 'PATCH',
      redirect: "manual",
      body: JSON.stringify(
        {
          nomination: {
            options: newOptions
          }
        }
      ),
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.nomination != null) {
        if(updateNomination){
          updateNomination(json.nomination);
        }
      } else {

      }
    });
  }

  getSelectOptions() {
    const { options } = this.props;
    return [
      {value:null, label:""}
    ].concat((options||[]).map(x=>{
      return {
        value: x,
        label:x
      }
    }))
  }

  renderTicker(status) {
    if(status) {
      return (
        <div className={`cnt-default-attendee-status-ticker`}>
          {status}
        </div>
      );
    }
    return "--"
  }

  getValue() {
    const { name, nomination } = this.props;
    return nomination.options[name];
  }

  render() {
    const { nomination, user } = this.props;
    if(user && canApproveNomination(user)) {
      return this.renderSelectField();
    }
    return this.renderTicker(this.getValue())
  }
}

export default EventOptionStatus;
