import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import Cookies from "universal-cookie";
import DefaultLayout from "@layout/DefaultLayout";

class AdminReportToolTable extends React.Component {
  constructor(props) {
    super(props);
    const hash = window.location.hash.substr(1);
    this.state = {
      reportTabs: [
        {id: "field_select", name: "Select fields", content: this.fieldSelect()},
        {id: "field_rename", name: "Rename fields", content: this.fieldRename()},
      ],
      current_tab_id: hash ? hash : props.default_tab_id || 'field_select',
    }
  }

  fieldSelect() {
    return (
      'In progress..'
    );
  }

  fieldRename() {
    return (
      'In progress..'
    );
  }

  changeTab(tab) {
    this.setState({
      current_tab_id: tab.id
    })
  }

  isTabActive(tab) {
  const { current_tab_id } = this.state;
    if(tab && tab['id'] && tab['id'] == current_tab_id) {
      return true;
    }
    return false;
  }

  renderTableTabs() {
    const { reportTabs } = this.state;
    const renderedTabs = reportTabs.map(tab =>
      <div key={`report-${tab.name}`} className={`report-table-tab ${this.isTabActive(tab) ? "active" : ""}`} onClick={() => this.changeTab(tab)}>
        {tab.name}
      </div>
    );
    return (
      <div className="report-table-tabs-wrapper" style={{marginBottom: "20px"}}>
        {renderedTabs}
      </div>
    );
  }

  getActiveTab() {
    const { reportTabs } = this.state;
    const current_tab = reportTabs.find(tab => this.isTabActive(tab));
    return current_tab;
  }

  renderContent() {
    const activeTab = this.getActiveTab();
    return (
      <div id="cnt-default-layout-tabbed-page-body">
        <div className="cnt-default-layout-general-table-container">
          {activeTab.content || ""}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="report-table-wrapper">
        {this.renderTableTabs()}
        {this.renderContent()}
      </div>
    );
  }
}

export default AdminReportToolTable;
