import React from "react";
import PropTypes from "prop-types";
import GeneralTable from "@layout/tables/GeneralTable"
import DefaultTooltip from "@layout/tooltips/DefaultTooltip";
import CompanyAccountManagerAddModal from "./CompanyAccountManagerAddModal"
class CompanyAccountManagerDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  renderUsersTable() {
    return (
        <GeneralTable
          columns={[
            { title: 'Name', field: 'full_name_normal', hidden: true, searchable: true},
            { title: 'Name', field: 'full_name', render: (row) => `${row.first_name} ${row.last_name}` },
            { title: 'Email', field: 'email'},
            { title: 'Sales Role', field: 'sales_role' },
            { title: 'Sales Role - Subategory', field: 'sub_sales_role' },
            { export: false, title: 'Actions', field: 'id', render: (row)  => this.renderActions(row)}
          ]}
          data={this.getFilteredUsers()}
        />
    )
  }

  renderActions(user) {
    const { removeUser } = this.props;
    return (
      <>
        {removeUser ? (
          <>
            <DefaultTooltip
              tooltipId={`sg-cnt-default-layout-contact-table-contact-delete-${user.id}`}
            >
              Remove User from Company
            </DefaultTooltip>
            <a href="#" onClick={()=>removeUser(user)}>
              <img
                className="sg-cnt-default-layout-table-delete-button"
                data-for={`sg-cnt-default-layout-contact-table-contact-delete-${user.id}`}
                data-tip
                 src="/layout/trash-icon.svg"
              />
            </a>
          </>
        ):""}

      </>
    )
  }

  getFilteredUsers() {
    let  {users} = this.props;
    users = users.map(x=>{
      x.full_name = `${x.last_name} ${x.first_name}`.toLowerCase()
      x.full_name_normal = `${x.first_name} ${x.last_name}`.toLowerCase()
      return x;
    }).sort((a,b) => b.full_name < a.full_name ? 1 : -1)

    return users;
  }


  render() {
    return (
      <div>
        <br/>
        <CompanyAccountManagerAddModal
          {...this.props}
        >
          <button class="cnt-page-header-button">Add Account Manager</button>
        </CompanyAccountManagerAddModal>
        <br/>
        <div>
          {this.renderUsersTable()}
        </div>
      </div>
    );
  }
}

export default CompanyAccountManagerDashboard;
