import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import ReportCustomize from "./form/ReportCustomize";
import ReportLoading from "./form/ReportLoading";
import ReportSave from "./form/ReportSave";

class ReportEdit extends React.Component {
  constructor(props) {
    super(props);

    let reportName = props.title ? props.title : "";
    let reportEditable = false;
    let baseObj = {};
    let selectedKeys = [];
    let filterChains = props.filterChains ? props.filterChains : [];
    let sortingCriteria = props.sorting_criteria ? props.sorting_criteria : [];
    let reportLocation = { value: "", label: "" };

    if (props.report) {
      if (props.report.private) {
        reportLocation = { value: true, label: "My Reports (private)" };
      } else {
        reportLocation = { value: false, label: "Reports (shared)" };
      }
      reportEditable = props.report.editable;
    }

    this.state = {
      baseObj: baseObj,
      filterChains: filterChains,
      keysReady: false,
      objectKeys: {},
      phase: 1,
      reportEditable: reportEditable,
      reportLocation: reportLocation,
      reportName: reportName,
      selectedKeys: selectedKeys,
      sortingCriteria: sortingCriteria
    };

    this.setFilterChains = this.setFilterChains.bind(this);
    this.setReportLocation = this.setReportLocation.bind(this);
    this.setReportEditable = this.setReportEditable.bind(this);
    this.setReportName = this.setReportName.bind(this);
    this.setSelectedKeys = this.setSelectedKeys.bind(this);
    this.setSortingCriteria = this.setSortingCriteria.bind(this);
    this.submitPhase1 = this.submitPhase1.bind(this);
    this.submitPhase2 = this.submitPhase2.bind(this);
  }

  // need to parse keys after baseObj load. Use keysReady boolean to delay
  // rendering keys section until after this completes
  componentDidMount() {
    const { report } = this.props;
    if (report) {
      this.setBaseObj(
        { value: report.object_type, label: report.object_type },
        false
      ).then(() => {
        const { objectKeys } = this.state;
        let selectedKeys = report.keys.map(key => {
          const keyDetails = objectKeys[key];
          return { value: key, label: keyDetails.label, datatype: keyDetails.datatype };
        });
        this.setState({
          keysReady: true,
          selectedKeys: selectedKeys
        });
      });
    }
  }

  setFilterChains(filterChains) {
    this.setState({ filterChains: filterChains });
  }

  setReportEditable(e) {
    this.setState({ reportEditable: e.target.checked });
  }

  setReportLocation(e) {
    const editable = e.value === "shared" ? true : false;
    this.setState({
      reportLocation: e,
      reportEditable: editable
    });
  }

  setReportName(e) {
    this.setState({ reportName: e.target.value });
  }

  setSelectedKeys(keys) {
    this.setState({ selectedKeys: keys });
  }

  setSortingCriteria(sortingCriteria) {
    this.setState({ sortingCriteria: sortingCriteria });
  }

  submitPhase1() {
    console.log("phase 1 submitted");
    this.setState({ phase: 2 });
  }

  submitPhase2() {
    console.log("phase 2 submitted");
    this.saveReport();
  }

  async setBaseObj(e, resetValues = true) {
    this.setState({ reportType: e.value });
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    let objList = [];
    let objKeys = [];
    await fetch("/reports/object_skeleton_all", {
      method: "POST",
      redirect: "manual",
      body: JSON.stringify({
        object_type: e.value
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          objList = json.object_list;
          objKeys = objList[0];
          if (resetValues) {
            this.setState(
              {
                baseObj: e,
                objectKeys: objKeys
              },
              this.setDefaultFields
            );
          } else {
            this.setState({
              baseObj: e,
              objectKeys: objKeys
            });
          }
        } else {
          console.log(json.error);
        }
      });
  }

  saveReport() {
    const {
      baseObj,
      reportName,
      reportLocation,
      reportEditable,
      selectedKeys,
      filterChains,
      sortingCriteria
    } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const keys = selectedKeys.map(key => key.value);
    fetch(this.renderSaveUrl(), {
      method: this.renderSaveType(),
      redirect: "manual",
      body: JSON.stringify({
        report_name: reportName,
        report_location: reportLocation.value,
        report_editable: reportEditable,
        keys: keys,
        object_type: baseObj.value,
        filterChains: filterChains,
        sortingCriteria: sortingCriteria
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          console.log("success!");
          if (reportLocation.value) {
            window.location.href = "/reports#my_reports";
          } else {
            window.location.href = "/reports";
          }
        } else {
          console.log(json.error);
        }
      });
  }

  renderSaveUrl() {
    const { report } = this.props;
    if (report) {
      return `/reports/${report.id}`;
    }
    return "/reports";
  }
  renderSaveType() {
    const { report } = this.props;
    if (report) {
      return "PATCH";
    }
    return "POST";
  }

  render() {
    const {
      baseObj,
      filterChains,
      keysReady,
      objectKeys,
      phase,
      reportEditable,
      reportLocation,
      reportName,
      reportType,
      selectedKeys,
      sortingCriteria
    } = this.state;
    const { baseObjects, events, report, user } = this.props;
    switch (phase) {
      case 1:
        if (!keysReady) {
          return (
            <ReportLoading
              events={events}
              user={user}
            />
          );
        }
        return (
          <ReportCustomize
            baseObj={baseObj}
            events={events}
            filterChains={filterChains}
            objectKeys={objectKeys}
            report={report}
            selectedKeys={selectedKeys}
            setFilterChains={this.setFilterChains}
            setSelectedKeys={this.setSelectedKeys}
            setSortingCriteria={this.setSortingCriteria}
            sortingCriteria={sortingCriteria}
            submit={this.submitPhase1}
            user={user}
          />
        );
      case 2:
        return (
          <ReportSave
            events={events}
            report={report}
            reportEditable={reportEditable}
            reportLocation={reportLocation}
            reportName={reportName}
            setReportEditable={this.setReportEditable}
            setReportLocation={this.setReportLocation}
            setReportName={this.setReportName}
            submit={this.submitPhase2}
            user={user}
          />
        );
      default:
        throw new Error("Invalid phase");
    }
  }
}

ReportEdit.propTypes = {
  baseObjects: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  report: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default ReportEdit;
