import React from "react";
import PropTypes from "prop-types";
import titleize from "titleize";
import Checkbox from "@layout/forms/Checkbox";
import { getCategoryOrder } from "./ReportToolDefaultFields";

class ReportFieldSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: { value: "", label: "", datatype: "" },
      optionArray: [],
      openOptions: []
    };

    this.buildOptionArray = this.buildOptionArray.bind(this);
    this.objectExpand = this.objectExpand.bind(this);
    this.optionClicked = this.optionClicked.bind(this);
    this.selectAll = this.selectAll.bind(this);
  }

  componentDidMount() {
    this.buildOptionArray();
  }

  componentWillUpdate(newProps) {
    const { baseObj } = this.props;
    if (newProps.baseObj.value !== baseObj.value) {
      this.buildOptionArray();
    }
  }

  buildOptionArray() {
    const { baseObj, options } = this.props;
    const baseOptions = [];
    const relationalOptions = [];

    options.forEach(option => {
      // values on the base object (eg. field_name only)
      if (!option.value.includes(".")) {
        baseOptions.push(option);
      } else {
        // values on associated models (eg. model_name.field_name)
        const [model, field] = option.value.split(".");
        const modelObject = relationalOptions.find(e => e.object === model);

        if (modelObject) {
          modelObject["options"].push(option);
        } else {
          relationalOptions.push({
            object: model,
            options: [option]
          });
        }
      }
    });

    // Add base options to relational ones, and save in state
    // relationalOptions.unshift({
    //   object: baseObj.value.toLowerCase(),
    //   options: baseOptions
    // });

    let order = getCategoryOrder();
    relationalOptions.sort((a, b) => {
      return order.indexOf(a.object) - order.indexOf(b.object);
    });

    this.setState({
      optionArray: relationalOptions
    });
  }

  optionClicked(e, option) {
    const { onChange } = this.props;
    e.preventDefault();
    onChange({
      value: option.value,
      label: option.label,
      datatype: option.datatype
    });
  }

  objectExpand(e, object) {
    const { openOptions } = this.state;
    e.stopPropagation();
    let newOptions = openOptions;
    if (newOptions.includes(object)) {
      newOptions = newOptions.filter(item => {
        return item !== object;
      });
    } else {
      newOptions.push(object);
    }
    this.setState({ openOptions: newOptions });
  }

  selectAll(e, category, allSelectAvailable) {
    const { massKeySelection } = this.props;
    e.stopPropagation();
    massKeySelection(category.options, allSelectAvailable);
  }

  renderSelectAll(category) {
    const { items } = this.props;
    let allSelectAvailable = false;
    category.options.forEach(option => {
      if (!items.some(e => e.value === option.value)) {
        allSelectAvailable = true;
      }
    });
    if (allSelectAvailable) {
      return (
        <div className="report-field-select-category-select-all" onClick={(e) => this.selectAll(e, category, allSelectAvailable)}>
          Select all
        </div>
      );
    }
    return (
      <div className="report-field-select-category-select-all" onClick={(e) => this.selectAll(e, category, allSelectAvailable)}>
        Deselect all
      </div>
    );
  }

  renderOptions() {
    const { items } = this.props;
    const { optionArray, openOptions } = this.state;

    const renderArrow = (category) => {
      if (openOptions.includes(category.object)) {
        return (
          <div className="report-field-select-category-title-arrow">
            <i className="fas fa-angle-down"></i>
          </div>
        );
      }
      return (
        <div className="report-field-select-category-title-arrow">
          <i className="fas fa-angle-right"></i>
        </div>
      );
    }

    const renderCategoryName = (name) => {
      return titleize(name.replaceAll("_", " "));
    }

    const renderCategoryOptions = (category) => {
      const selected = items.map(ele => ele.value);
      if (openOptions.includes(category.object)) {
        return (
          <>
            {category.options.map(option => (
              <div
                key={option.value}
                className="report-field-select-option"
                onClick={e => this.optionClicked(e, option)}
              >
                <Checkbox
                  disabled={false}
                  inline={true}
                  value={selected.includes(option.value)}
                />
                {option.label}
              </div>
            ))}
          </>
        );
      }
      return <></>;
    }

    const renderCategory = (category) => {
      return (
        <div key={category.object} className="report-field-select-category">
          <div
            className="report-field-select-category-title"
            onClick={e => this.objectExpand(e, category.object)}
          >
            {renderCategoryName(category.object)}
            {renderArrow(category)}
            {this.renderSelectAll(category)}
          </div>
          <div className="report-field-select-category-options">
            {renderCategoryOptions(category)}
          </div>
        </div>
      );
    }

    const renderedCategories = optionArray.map(category => {
      return renderCategory(category);
    });

    return (
      <div>
        {renderedCategories}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderOptions()}
      </div>
    );
  }
}

export default ReportFieldSelect;
