import React from "react";
import PropTypes from "prop-types";
import GeneralTable from "@layout/tables/GeneralTable"
import { isAccountManager } from "@utility/PermissionUtility"
import NominationStatus from "@nominations/tables/NominationStatus"
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime, listTimeZones } from 'date-fns-timezone';
import ContactTableChit from "@contacts/tables/ContactTableChit"
import EventTableChit from "@events/tables/EventTableChit"
import CompanyTableChit from "@companies/tables/CompanyTableChit"
import Cookies from "universal-cookie";
import AttendeeStatus from "@nominations/tables/AttendeeStatus"
import AttendenceStatus from "@nominations/tables/AttendenceStatus"
import NominationProfileModal from "../modals/NominationProfileModal"
import DefaultTooltip from "@layout/tooltips/DefaultTooltip";

class NominationsHistoryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nominations: props.nominations
    };
  }

  componentDidMount() {
    if(this.state.nominations == null) {
      this.getNominations();
    }
  }

  getNominations() {
    const { contact } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    if(contact) {
      fetch(`/contacts/${contact.id}/nomination_history`, {
        method: "get",
        redirect: "manual",
        body: null,
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            nominations: json.nominations,
            events: json.events,
          });
        }
        else {
          this.setState({
            error: json.error
          })
        }
      });
    }
  }

  getFilteredNominations() {
    const { user } = this.props;
    const { nominations } = this.state;
    if(nominations) {
      let filteredNominations = nominations.filter(x=> x.status != "draft" || x.user_id == user.id)
      if(isAccountManager(user)) {
        filteredNominations = filteredNominations.filter(x=> x.user_id == user.id);
      }
      filteredNominations.map(x=>{
        x.company_name = x.contact && x.contact.company ? x.contact.company.name :"";
        x.event_name = x.event ? x.event.name :"";
        x.date = new Date(x.created_at)
        return x;
      })
      return filteredNominations;
    }
    return [

    ];
  }

  renderViewAll(row) {
    const { user } = this.props;
    return (
      <div className="cnt-default-layout-nomination-table-icons">
        <NominationProfileModal
          user={user}
          nomination={row}
        >
          <a href="#">
            <DefaultTooltip
              tooltipId={`sg-cnt-default-layout-table-view-tooltip-${row.id}`}
            >
              Quick View
            </DefaultTooltip>
            <img
              data-for={`sg-cnt-default-layout-table-view-tooltip-${row.id}`}
              data-tip
              className="sg-cnt-default-layout-table-review-button"
              src="/layout/view-icon.svg"
             />
          </a>
        </NominationProfileModal>
      </div>
    )
  }

  renderEvent(row) {
    const { user } = this.props;
    const {events} = this.state;
    const event = (events||[]).find(x=> x.id == row.event_id);
    if(event) {
      return (
        <EventTableChit user={user} event={event} />
      );
    }
    return ""
  }



  render() {
    return (
      <GeneralTable
        disableSearch={true}
        columns={[
          { title: 'Event', field: 'event_id  ', render: (row)=>(this.renderEvent(row))},
          { title: 'Status', field: 'status', render: (row) => (<NominationStatus nomination={row} />)},
          { title: 'Invite Status', field: 'invite_status', render:(row)=>(<AttendeeStatus nomination={row}/>)},
          { title: 'Attendance Status', field: 'attendence_status', render:(row)=>(<AttendenceStatus nomination={row}/>)},
          { title: 'Date', field: 'date', render: (row) => (formatToTimeZone(row.date,"MMM DD YYYY", { timeZone: 'America/Los_Angeles'}))},
          { title: 'Actions', field: 'id', render: (row)  => this.renderViewAll(row)}
        ]}
        actions={[]}
        data={this.getFilteredNominations()}
      />
    );
  }
}
export default NominationsHistoryTable;
