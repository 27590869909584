import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Select from 'react-select-virtualized'
import Cookies from "universal-cookie";
class NominationNotifyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: props.forceOpen || false,
      selectedUser: null,
      statusChangeReason: null,
      submitting: false,
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.notifyNomination = this.notifyNomination.bind(this);
  }

  renderModal() {
    const { contacts, nomination, events, statusChangeReason } = this.props;
    const { submitting } = this.state;
    if(nomination) {
      return (
        <div className="row">
          <div className="col-xs-12 ">
            <div className="cnt-default-layout-nominations-modal-description">
              What note would you like to add a note for the account manager?
            </div>
            <textarea
              style={{
                height: "100px"
              }}
              className="form-control"
              placeholder="Type here..."
              value={statusChangeReason}
              onChange={(e)=>
                this.setState({
                  statusChangeReason: e.target.value
                })
              }
            />
            <br/><br/>
            <div className="">
              <button className="cnt-approve-button" onClick={() => this.notifyNomination()}> Notify </button>
              <button className="cnt-cancel-button"onClick={this.toggleModal}> Cancel</button>
            </div>
          </div>
        </div>
      );
    }
  }

  renderLoadingCircleButton() {
    return (<div class="lds-button-ellipsis"><div></div><div></div><div></div><div></div></div>)
  }

  notifyNomination() {
    const { nomination, notifyCallback  } = this.props;
    const { currentComment, statusChangeReason } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    const body = {
      status_changed_reason: statusChangeReason
    }
    this.setState({
      submitting: true
    })

    fetch(`/nominations/${nomination.id}/notify`, {
      method: "post",
      redirect: "manual",
      body: JSON.stringify(body),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            submitting: false
          })
          if(json.redirect_url) {
            if(notifyCallback) {
              notifyCallback(nomination)
            }else {
              window.location = json.redirect_url
            }
          }else {
            console.log("Successful no redirect")
          }
        }
        else {
          this.setState({
            error: json.error,
            submitting: false,
          })
        }
      });
  }

  toggleModal() {
    const { modalOpen } = this.state;
    const { openModalCallback, closeModalCallback } = this.props;
    if(!modalOpen == false && closeModalCallback) {
      closeModalCallback()
    }
    if(!modalOpen == true && openModalCallback) {
      openModalCallback()
    }
    this.setState({
      modalOpen: !modalOpen
    });
  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, sessions } = this.props;
    return (
      <>
        <div className={outerClassNames} style={{cursor:"pointer", display: "inline-block"}} onClick={() => this.toggleModal()} >
          {children}
        </div>
        <Modal
          className="white-background cnt-user-admin-modal cnt-status-change-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default NominationNotifyModal;
