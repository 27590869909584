import React from "react";
import PropTypes from "prop-types";
import GeneralTable from "@layout/tables/GeneralTable"
import {canSeeDrafts,canUserValidateContact, isJaguar, isSolutionsLead, canUserShift, canDeleteNomination,  isAccountManager, canApproveNomination } from "@utility/PermissionUtility"
import NominationStatus from "@nominations/tables/NominationStatus"
import AttendeeStatus from "@nominations/tables/AttendeeStatus"
import AttendenceStatus from "@nominations/tables/AttendenceStatus"
import SourceStatus from "@nominations/tables/SourceStatus"
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime, listTimeZones } from 'date-fns-timezone';
import {getAllNominationStatuses, getReadableNominationStatus, getNominationTableMultiSelectOptions} from "@utility/NominationUtility"
import ContactTableChit from "@contacts/tables/ContactTableChit"
import EventTableChit from "@events/tables/EventTableChit"
import CompanyTableChit from "@companies/tables/CompanyTableChit"
import GeneralTableDropdown from "@layout/tables/GeneralTableDropdown"
import NominationApproveModal from "@nominations/forms/NominationApproveModal"
import NominationNotifyModal from "@nominations/forms/NominationNotifyModal"
import NominationRejectModal from "@nominations/forms/NominationRejectModal"
import UserProfileModal from "../../users/modals/UserProfileModal"
import NominationProfileModal from "../modals/NominationProfileModal"
import DefaultTooltip from "@layout/tooltips/DefaultTooltip";
import Select from 'react-select-virtualized'
import ToggleOnOff from "@layout/superfilter/ToggleOnOff"
import SuperFilter from "@layout/superfilter/SuperFilter"
import NominationDeleteModal from "../modals/NominationDeleteModal"
import EventVariableStatus from "./EventVariableStatus"
import NominationReviewModal from "@nominations/forms/NominationReviewModal"
import EventPriorityStatus from "./EventPriorityStatus";
import EventOptionStatus from "./EventOptionStatus"
class UngroupedNominationsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      approveNomination: null,
      rejectNomination: null,
      nominations: props.nominations,
      hideArchivedEvents: false,
      superFilters: null,
      currentComment: "",
      notifyNomination: null,
    };
    this.rejectionCallback = this.rejectionCallback.bind(this);
    this.reviewCallback = this.reviewCallback.bind(this);
    this.approvalCallback = this.approvalCallback.bind(this);
    this.closeModalCallback = this.closeModalCallback.bind(this);
    this.openApproveNomination = this.openApproveNomination.bind(this);
    this.openReviewNomination = this.openReviewNomination.bind(this);
    this.openRejectNomination = this.openRejectNomination.bind(this);

    this.updateNomination = this.updateNomination.bind(this);
    this.setSuperFilters = this.setSuperFilters.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.renderUserProfileModal = this.renderUserProfileModal.bind(this);
    this.openNotifyNomination = this.openNotifyNomination.bind(this);
  }

  openNotifyNomination(row, currentComment) {
    this.setState({notifyNomination: row, currentComment: currentComment || ""});
  }

  setSuperFilters(filters) {
    this.setState({
      superFilters: filters
    })
  }

  getUnfilteredNominations() {
    const { nominations, } = this.state;
    const { user, show_drafts } = this.props;
    if(nominations) {
      let filteredNominations = nominations.sort((a,b) => a.id < b.id ? -1 : 1)
      
      if(show_drafts && (canSeeDrafts(user)))  {

      }else {
        filteredNominations = nominations.filter(x =>
          (x.status != "draft" || (
            isAccountManager(user) ? ((x.contact && x.contact.users && x.contact.users.map(x=>x.id).indexOf(user.id) != -1)|| x.user_id == user.id) : x.user_id == user.id))
        )
      }

      if(isAccountManager(user)) {
        filteredNominations = filteredNominations.filter(x=> x.user_id == user.id);
      }

      return filteredNominations
    }
  }

  getFilteredNominations() {
    const { events } = this.props;
    const { nominations, hideArchivedEvents, superFilters } = this.state;

    if(nominations) {
      let filteredNominations = this.getUnfilteredNominations().sort((a,b) => a.id < b.id ? -1 : 1)

      if(superFilters) {
        superFilters.map(filterObject =>{
          filteredNominations = filteredNominations.filter((x,i,t)=>filterObject.filterFunction(x,i,t))
        })
      }

      if(hideArchivedEvents) {
        filteredNominations = filteredNominations.filter(x=> !x.event.deleted_at);
      }
      filteredNominations.map(x=>{
        x.segment_p24 = (x.options||{}).segment
        x.lower_last_name = (x.contact.last_name||"").toLowerCase()
        x.lower_first_name = (x.contact.first_name||"").toLowerCase()
        x.email = x.contact.email;
        x.contact_name = x.contact ? `${x.contact.first_name} ${x.contact.last_name}` :"";
        x.event = (events||[]).find(e=> e.id == x.event_id)
        x.company_name = x.contact && x.contact.company ? x.contact.company.name :"";
        x.event_name = x.event ? x.event.name :"";
        x.user_name = x.contact && x.contact.users ? x.contact.users.map(y=>`${y.first_name} ${y.last_name}`).join("|") : ""
        x.user_email = x.contact && x.contact.users ? x.contact.users.map(y=>`${y.email}`).join("|") : ""
        x.submitter_name = x.user ? `${x.user.first_name} ${x.user.last_name}` : "";
        x.contact_id = x.contact ? x.contact.id : "";
        x.date = new Date(x.created_at)
        x.opt_out = x.contact ? x.contact.opt_out : false
        x.vip = x.contact ? x.contact.vip : false
        return x;
      })
      return filteredNominations;
    }
    return [

    ];
  }

  updateNomination(newNomination) {
    let { nominations } = this.state;
    const index = nominations.findIndex(x=> x.id == newNomination.id);
    if(index > -1) {
        nominations[index] = newNomination;
    }else {
      nominations = nominations.concat([newNomination]);
    }
    this.setState({
      nominations
    })
  }

  renderViewAll(row) {
    const { user } = this.props;

    return (
      <div className="cnt-default-layout-nomination-table-icons">
        <NominationProfileModal
          user={user}
          openRejectNomination={this.openRejectNomination}
          openApproveNomination={this.openApproveNomination}
          openReviewNomination={this.openReviewNomination}
          openNotifyNomination={this.openNotifyNomination}
          nomination={row}
        >
          <a href="#">
            <DefaultTooltip
              tooltipId={`sg-cnt-default-layout-table-view-tooltip-${row.id}`}
            >
              Quick View
            </DefaultTooltip>
            <img
              data-for={`sg-cnt-default-layout-table-view-tooltip-${row.id}`}
              data-tip
              className="sg-cnt-default-layout-table-review-button"
              src="/layout/view-icon.svg"
             />
          </a>
        </NominationProfileModal>
        {this.renderEditIcon(row)}
        {canDeleteNomination(user) ? (
          <>
          <DefaultTooltip
            tooltipId={`sg-cnt-default-layout-contact-table-nomination-delete-${row.id}`}
          >
            Delete Nomination
          </DefaultTooltip>
          <NominationDeleteModal nomination={row} onDelete={this.onDelete}>
            <img
              className="sg-cnt-default-layout-table-delete-button"
              data-for={`sg-cnt-default-layout-contact-table-nomination-delete-${row.id}`}
              data-tip
              src="/layout/trash-icon.svg"
            />
        </NominationDeleteModal>
          </>
        ):""}

      </div>
    );
  }

  onDelete(id) {
    let { nominations } = this.state;
    nominations = nominations.filter(x=> x.id != id)
    this.setState({
      nominations
    })
  }


  renderEditIcon(row) {
    const { user } = this.props;
    if(isAccountManager(user)) {
      return (
        <>
        <DefaultTooltip
          tooltipId={`sg-cnt-default-layout-edit-tooltip-${row.id}`}
        >
          Full View
        </DefaultTooltip>
        <a href={`/nominations/${row.id}`}>
          <img
            style={{width:"17px"}}
            data-for={`sg-cnt-default-layout-edit-tooltip-${row.id}`}
            data-tip
            src="/layout/review-icon.png"
          />
        </a>
        </>
      )
    } else {
      return (
        <>
        <DefaultTooltip
          tooltipId={`sg-cnt-default-layout-edit-tooltip-${row.id}`}
        >
          Full View
        </DefaultTooltip>
        <a href={`/nominations/${row.id}`}>
          <img
            style={{width: "17px"}}
            data-for={`sg-cnt-default-layout-edit-tooltip-${row.id}`}
            data-tip
            src="/layout/review-icon.png"
          />
        </a>
        </>
      )
    }

  }

  openApproveNomination(row, currentComment) {
    this.setState({approveNomination: row, currentComment: currentComment || ""});
  }

  openReviewNomination(row, currentComment) {
    this.setState({reviewNomination: row, currentComment: currentComment || ""});
  }

  openRejectNomination(row, currentComment) {
    this.setState({rejectNomination: row, currentComment: currentComment || ""});
  }

  renderNominationRow(column) {
    column.render = (row) => {return (
        <div className="cnt-nomination-table-multiple-render-container">
          {row.nominations.map(x=> (
            <div className="cnt-nomination-table-multiple-render-inner-container">
              {column.renderSingular(x)}
            </div>
          ))}
        </div>
    )}
    return column
  }

  renderUserProfileModal(row) {
    if(row && row.contact && row.contact.users) {
      return (row.contact.users||[]).map(x=>
        <UserProfileModal user={x}>
          <a href="#">{x.first_name} {x.last_name}</a>
        </UserProfileModal>
      )
    }
  }

  renderSubmitter(row) {
    if(row && row.user) {
      return (
        <UserProfileModal user={row.user}>
          <a href="#">{row.user.first_name} {row.user.last_name}</a>
        </UserProfileModal>
      )
    }
  }

  renderOptOut(row) {
    if(row.opt_out) {
      return (
        <div>
        <DefaultTooltip
          tooltipId={`sg-cnt-default-layout-table-optout-tooltip-${row.id}`}
        >
          This contact is opted out of transactional communications
        </DefaultTooltip>

        <img
        style={{marginLeft:"10px"}}
          src="/images/nominations/optout_icon.png"
          data-for={`sg-cnt-default-layout-table-optout-tooltip-${row.id}`}
          data-tip
        />
        </div>
      )
    }
  }

  renderVip(row) {
    if(row.vip) {
      return (
        <div>
        <DefaultTooltip
          tooltipId={`sg-cnt-default-layout-table-vip-tooltip-${row.id}`}
        >
          This contact is a VIP
        </DefaultTooltip>

        <img
        style={{marginLeft:"10px"}}
          src="/images/nominations/vip_icon.png"
          data-for={`sg-cnt-default-layout-table-vip-tooltip-${row.id}`}
          data-tip
        />
        </div>
      )
    }
  }

  getColumns() {
      const {user,show_submitter, filteredColumns, event, show_source,show_segment_p24, show_priority, show_vip, show_opt_out} = this.props;
      const canUserValidateContact_cached = canUserValidateContact(user)
      let columns = [
        { title: 'Name', field: 'contact_name', render: (row) => (row.contact ? (<ContactTableChit user={user} forceCircle={false} contact={row.contact} nomination={row} includeValidationIcon={canUserValidateContact_cached} />) : "") },
        {hidden: true, field: 'lower_first_name'},
        {hidden: true, field: 'lower_last_name' },
        {hidden: true,searchable: true, export: true, title:"Email", field: 'email' },


        { title: 'Company', field: 'company_name', render: (row)=>(row.contact && row.contact.company ? (<CompanyTableChit user={user} contact={row.contact} company={row.contact.company} />):((row.contact||{}).am_provided_company_name||""))},
        { sorting: false, title: 'Event', field: 'event_name', render: (row)=>(row.event ? (<EventTableChit user={user} event={row.event} />):"")},
        { sorting: false, title: 'Submitter', field: 'submitter_name', renderSingular: this.renderSubmitter},
        { sorting: false, title: 'Account Manager(s)', field: 'user_name', renderSingular: this.renderUserProfileModal},
        { hidden: true, export: true, sorting: false, title: 'Account Manager Email', field: 'user_email', renderSingular: (row)=>{return ""}},
        { title: 'Source', field: 'source', render: (row)=>(<SourceStatus user={user} updateNomination={this.updateNomination} nomination={row} />)},
        { title: 'Segment', field: 'segment_p24', render: (row)=>(
          <EventOptionStatus name="segment"
            user={user} updateNomination={this.updateNomination} nomination={row}
            options={[
              "CGEM",
              "Channel",
              "Commercial",
              "Enterprise",
              "Marketing",
              "Public sector",
              "Service Provider",
              "SMB",
            ]}
          />
         )
        },
        { title: 'Priority', field: 'priority', render: (row)=>(<EventPriorityStatus user={user} updateNomination={this.updateNomination} nomination={row} />)},

        { sorting: false, title: 'Nom Status', field: 'status', render: (row) => (<NominationStatus user={user} updateNomination={this.updateNomination} nomination={row} />)},
        { sorting: false, title: 'Variable', field: 'event_variable', render: (row) => (<EventVariableStatus event={event} user={user} updateNomination={this.updateNomination} nomination={row} />)},
        { sorting: false, title: 'Invite Status', field: 'invite_status', render: (row) => (<AttendeeStatus user={user} updateNomination={this.updateNomination} nomination={row} />)},
        { sorting: false,  title: 'Attendance Status', field: 'attendence_status', render: (row) => (<AttendenceStatus user={user} updateNomination={this.updateNomination} nomination={row} />)},
        { title: 'Opt Out', field: 'opt_out', render: this.renderOptOut},
        { title: 'VIP', field: 'vip', render: this.renderVip},
        { export: false, sorting: false,  title: 'Actions', field: 'id', render: (row)  => this.renderViewAll(row)}
      ]
      if(!show_submitter) {
        columns = columns.filter(x=> x.field != 'submitter_name');
      }

      if(!show_vip) {
        columns = columns.filter(x=> x.field != 'vip');
      }
      if(!show_opt_out) {
        columns = columns.filter(x=> x.field != 'opt_out');
      }
      if(!show_source) {
        columns = columns.filter(x=> x.field != 'source');
      }
      if(!show_segment_p24) {
        columns = columns.filter(x=> x.field != 'segment_p24');
      }
      if(!show_priority) {
        columns = columns.filter(x=> x.field != 'priority');
      }
      if(filteredColumns) {
        columns = columns.filter(x=> filteredColumns.indexOf(x.field) == -1);
      }
      if(!(event && event.event_variable_data && event.event_variable_data.options)) {
        columns = columns.filter(x=> x.field != 'event_variable');
      }
      if(isAccountManager(user)) {
        columns= columns.filter(x=> x.field!="user_name")
      }else {
        columns= columns.filter(x=> x.field!="date")
      }
      return columns;
  }

  rejectionCallback(nomination) {
    let { nominations } = this.state;
    const index = nominations.findIndex(x=> x.id == nomination.id);
    nominations[index].status= "rejected";
    this.setState({
      nominations: nominations,
      approveNomination: null,
      rejectNomination: null,
      reviewNomination: null,
      currentComment: null
    })
  }

  approvalCallback(nomination) {
    let { nominations } = this.state;
    const index = nominations.findIndex(x=> x.id == nomination.id);
    nominations[index].status= "approved";
    this.setState({
      nominations: nominations,
      approveNomination: null,
      rejectNomination: null,
      reviewNomination: null,
      currentComment: null,
    })
  }

  reviewCallback(nomination) {
    let { nominations } = this.state;
    const index = nominations.findIndex(x=> x.id == nomination.id);
    nominations[index].status= "in_review";
    this.setState({
      nominations: nominations,
      approveNomination: null,
      rejectNomination: null,
      reviewNomination: null,
      currentComment: null,
    })
  }

  closeModalCallback() {
    this.setState({
      approveNomination: null,
      rejectNomination: null,
      reviewNomination: null,
      notifyNomination: null,
      currentComment: null,
    })
  }

  renderModals() {
    const {notifyNomination, reviewNomination,  approveNomination,rejectNomination,currentComment } = this.state;
    if(rejectNomination) {
      return (
        <NominationRejectModal
          nomination={rejectNomination}
          closeModalCallback={this.closeModalCallback}
          rejectionCallback={this.rejectionCallback}
          forceOpen={true}
          currentComment={currentComment}
        />
      )
    }
    if(reviewNomination) {
      return (
        <NominationReviewModal
          nomination={reviewNomination}
          closeModalCallback={this.closeModalCallback}
          reviewCallback={this.reviewCallback}
          forceOpen={true}
          currentComment={currentComment}
        />
      )
    }
    if(notifyNomination) {
      return (
        <NominationNotifyModal
          nomination={notifyNomination}
          closeModalCallback={this.closeModalCallback}
          forceOpen={true}
          currentComment={currentComment}
        />
      )
    }
    if(approveNomination) {
      return (
        <NominationApproveModal
          nomination={approveNomination}
          closeModalCallback={this.closeModalCallback}
          approvalCallback={this.approvalCallback}
          forceOpen={true}
          currentComment={currentComment}
        />
      )
    }
  }

  getFilters() {
    return [
      {id: null, name: "All", filterFunction:()=>{return true}},
      {id: 'draft', name: "Draft", filterFunction:(x,i,t)=>{ return x.status=="draft"}},
      {id: 'approved', name: "Approved", filterFunction:(x,i,t)=>{ return x.status=="approved"}},
      {id: 'submit', name: "Submitted", filterFunction:(x,i,t)=>{ return x.status=="submit"}},
      {id: 'rejected', name: "Denied", filterFunction:(x,i,t)=>{ return x.status=="rejected"}}
    ]
  }

  renderSuperFilter() {
    const { includeSuperFilter,event } = this.props;

    const nom_status_options = [
      {label: 'All', id: null, filterFunction: (x,i,t)=>{return true}},
    ].concat(getAllNominationStatuses().map(x=> {
      return {label: x.name, id: x.id, filterFunction: (y,i,t)=>{ return y.status==x.id}}
    }))

    const invite_status_options = [
      {label: 'All', id: null, filterFunction: (x,i,t)=>{return true}},
      {label: 'Waitlisted', id: 'waitlisted', filterFunction: (x,i,t)=>{return x.invite_status == 'waitlisted'}},
      {label: 'Pending Invite', id: 'pending_invite', filterFunction: (x,i,t)=>{return x.invite_status == 'pending_invite'}},
      {label: 'Invited', id: 'invited', filterFunction: (x,i,t)=>{return x.invite_status == 'invited'}},
      {label: 'Registered', id: 'registered', filterFunction: (x,i,t)=>{return x.invite_status == 'registered'}},
      {label: 'Declined', id: 'declined', filterFunction: (x,i,t)=>{return x.invite_status == 'declined'}},
      {label: 'Deadline Passed', id: 'deadline_passed', filterFunction: (x,i,t)=>{return x.invite_status == 'deadline_passed'}},
      {label: 'Opt Out', id: 'opt_out', filterFunction: (x,i,t)=>{return x.invite_status == 'opt_out'}},
      {label: 'Bounced', id: 'bounced', filterFunction: (x,i,t)=>{return x.invite_status == 'bounced'}},
      {label: 'VIP', id: 'vip', filterFunction: (x,i,t)=>{return x.invite_status == 'vip'}},
      {label: 'Unmarked', id: 'unmarked', filterFunction: (x,i,t)=>{return !x.invite_status}},
    ]
    const attendence_status_options = [
      {label: 'All', id: null, filterFunction: (x,i,t)=>{return true}},
      {label: 'Not attended', id: 'did-not-attend', filterFunction: (x,i,t)=>{return x.attendence_status == 'did_not_attend'}},
      {label: 'Attended', id: 'attended', filterFunction: (x,i,t)=>{return x.attendence_status == 'attended'}},
      {label: 'No Show', id: 'no-show', filterFunction: (x,i,t)=>{return x.attendence_status == 'no_show'}},
      {label: 'Cancellation', id: 'cancellation', filterFunction: (x,i,t)=>{return x.attendence_status == 'cancellation'}},
      {label: 'Unmarked', id: 'unmarked', filterFunction: (x,i,t)=>{return !x.attendence_status}},
    ]
    let variable_field_options = []
    if(event && event.event_variable_data && event.event_variable_data.options) {
      variable_field_options = [
        {label: 'All', id: null, filterFunction: (x,i,t)=>{return true}},
      ].concat(event.event_variable_data.options.map(z=>{
        return {label: z, id: z, filterFunction: (x,i,t)=>{return x.event_variable == z}}
      }))
    }

    if(event && event.options.show_pr && event.event_variable_data.options) {
      variable_field_options = [
        {label: 'All', id: null, filterFunction: (x,i,t)=>{return true}},
      ].concat(event.event_variable_data.options.map(z=>{
        return {label: z, id: z, filterFunction: (x,i,t)=>{return x.event_variable == z}}
      }))
    }

    let filters = [
      {
        id: "nom_status",
        type:"select",
        options: nom_status_options,
        outerClassName: "col-xs-12  col-md-6 col-lg-3",
        placeholder: "Filter by nom status"
      },
      {
        id: "invite_status",
        type:"select",
        options: invite_status_options,
        outerClassName: "col-xs-12  col-md-6 col-lg-3",
        placeholder: "Filter by invite status"
      },
      {
        id: "attendece_status",
        type:"select",
        options: attendence_status_options,
        outerClassName: "col-xs-12 col-md-6 col-lg-3",
        placeholder: "Filter by attendance status"
      },
    ]
    if(event && event.event_variable_data && event.event_variable_data.options) {
      filters = filters.concat([
        {
          id: "variable",
          type:"select",
          options: variable_field_options,
          outerClassName: "col-xs-12 col-md-6 col-lg-3",
          placeholder: "Filter by variable"
        }
      ])
    }

    if(includeSuperFilter) {
      return(
        <>
        <SuperFilter
          defaultValues={{
            events:["active_events"]
          }}
          onChange={this.setSuperFilters}
          filters={filters}
        />
        </>
      )
    }
  }

  render() {
    const filteredNominations = this.getFilteredNominations();
    return (
      <>
        {this.renderModals()}
        <div className="">
          {this.renderSuperFilter()}
          <GeneralTable
            columns={this.getColumns()}
            actions={[]}
            data={filteredNominations}
            showTotals={true}
            unfilteredTotal={this.getUnfilteredNominations().length}
            filteredTotal={filteredNominations.length}
          />
        </div>
      </>
    );
  }
}
export default UngroupedNominationsTable;
