import React from "react";
import PropTypes from "prop-types";
import { format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import ReactTooltip from "react-tooltip";
import Cookies from "universal-cookie";
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-timezone';
import { canSessionBeAdded, isInTrack } from "@utility/SessionUtils"

class AdminAchievementsDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: ""
    }
  }

  renderUsersTable() {
    const { searchQuery } = this.state;
    return (
      <div className="sg-admin-achievements-table-container row">
        <div className="col-xs-12">
          Search:
          <br />
          <input className="form-control" value={searchQuery} onChange={(e)=>this.setState({searchQuery: e.target.value})}/>
          <br />
        </div>
        <div className="col-xs-12">
          <table className="sg-admin-achievements-table">
            <tr>
              <th>
                Email
              </th>
              <th>
                First Name
              </th>
              <th>
                Last Name
              </th>
              <th>
                Actions
              </th>
            </tr>
            {this.renderUsers()}
          </table>
        </div>
      </div>
    );
  }


  renderUsers() {
    const { users, attendees } = this.props;
    return this.getUsersFiltered().map(user => {
      const attendee = user.attendee
      return (
        <tr>
          <td>
            {user.email}
          </td>
          <td>
            {attendee ? attendee.name_first : ""}
          </td>
          <td>
            {attendee ? attendee.name_last : ""}
          </td>
          <td>
            <a href={`/admin/achievements/new?id=${user.id}`}>
              <button type="button" className="btn btn-primary">
                Give Awards
              </button>
            </a>
          </td>
        </tr>
      );
    });
  }

  getUsersFiltered() {
    let { users, attendees } = this.props;
    const { searchQuery } = this.state;
    users = users.map(user => {
      const attendee = attendees.find(x=> x.email.toLowerCase() == user.email.toLowerCase());
      user.attendee = attendee;
      return user;
    });

    users = users.filter(user => {
      const attendee = user.attendee;
      if(user.email.toLowerCase().includes(searchQuery.toLowerCase())){
        return true;
      }
      if(attendee) {
        if(attendee.name_last.toLowerCase().includes(searchQuery.toLowerCase()) || attendee.name_first.toLowerCase().includes(searchQuery.toLowerCase()))
        {
          return true;
        }
      }
      return false
    })
    return users
  }


  render() {
    return (
      <>
        {this.renderUsersTable()}
      </>
    );
  }
}

export default AdminAchievementsDashboard;
