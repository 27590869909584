import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import ContactForm from "./forms/ContactForm"
import {canEditContact, canViewLog, canDeleteContact, isAccountManager} from "@utility/PermissionUtility"
import Cookies from "universal-cookie";
class ContactsValidate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactImage: props.contact ? props.contact.contact_image : null,
      forceDirty: false,
    };
    this.setImage = this.setImage.bind(this);
    this.cancelCallback = this.cancelCallback.bind(this);
    this.onRedirect = this.onRedirect.bind(this);
  }

  setImage(name, file, preview, tagList) {
    const { contact } = this.props;
    var form_data = new FormData();
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    form_data.append('contact[contact_image]', file);

    this.setState({
      contactImage: preview,
      forceDirty: true,
    });

    fetch(`/contacts/${contact.id}/update_image`, {
      method: 'POST',
      redirect: "manual",
      body: form_data,
      headers: {
        "X-CSRF-Token": token,
      }
    })
  }


  renderHeader() {
    const { contact, user } = this.props;
    const { contactImage } = this.state;
    return (
      <div className="row cnt-event-show-header-container">
        <div className="cnt-event-show-header-container-description col-xs-12 col-sm-12">
          <div className="cnt-event-show-header-container-name">
            {contact.first_name} {contact.last_name}
          </div>
          <div className="cnt-event-show-header-container-date">

          </div>
        </div>
      </div>
    )
  }

  renderReturnLink() {
    const { nomination } =this.props;
    if(nomination) {
      return (
        <div class="cnt-page-subheader-link">
          <a href={`/events/${nomination.event_id}`}>
            {"< Return to nominations"}
          </a>
        </div>
      )
    }
    return (
      <div class="cnt-page-subheader-link">
        <a href="/contacts">
          {"< Return to my contacts"}
        </a>
      </div>
    )
  }

  cancelCallback() {
    const { nomination } =this.props;
    if(nomination) {
      window.location = `/events/${nomination.event_id}`
    }else {
      window.location = "/contacts"
    }
  }

  onRedirect(json) {
    const { nomination } =this.props;
    window.location = `/events/${nomination.event_id}#nominations`
  }

  render() {
    const { user } = this.props;
    const { forceDirty } = this.state;
    return (
      <DefaultLayout
        {...this.props}
        sidebarId="contacts"
      >
        {this.renderReturnLink()}
        {this.renderHeader()}
        <ContactForm onRedirect={this.onRedirect} {...this.props} cancelCallback={this.cancelCallback} isValidating={true} disableDelete={true} forceDirty={forceDirty}/>
      </DefaultLayout>
    );
  }
}

export default ContactsValidate;
