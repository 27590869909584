import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import Cookies from "universal-cookie";
import {getCountrySelectOptions, getStateSelectOptions}  from "@utility/CountryCodes"
import {canSeeCompanyNotes, canEditComplianceIssue, canEditCompany, isAccountManager, canDeleteCompany, isExecutiveRelationsLead, isAdmin, isJaguar,isSolutionsLead} from "@utility/PermissionUtility"
import Select from 'react-select-virtualized'
import CompanyDeleteModal from "@companies/modals/CompanyDeleteModal"
import {abxOptions, companyTypeOptions, salesSubCoverageOptions, salesCoverageOptions, areaOptions, theaterOptions, geoOptions, operationsOptions, verticalOptions, regionOptions, segmentOptions, subSegmentOptions} from "./CompanyUtils"
import Checkbox from "@layout/forms/Checkbox";
import { Countries, Provinces } from "country-and-province"
import { radioImages } from "@utility/checkboxUtility"
class CompanyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      saving: false,
    };

    this.onDelete = this.onDelete.bind(this);
    this.isDirty = this.isDirty.bind(this);
  }

  isEditing() {
    const { company } = this.props;
    return !!company.id;
  }

  renderField(name,formikProps, required=false,customValidation = false, properties = {}) {
    const { user } = this.props;
    const { checkError } = this.state;
    const erroring = required && checkError && (!formikProps.values.company[name] || formikProps.values.company[name].length <= 0 || customValidation);

    return (
      <Field
        onChange={(e) => {
            const { company } = formikProps.values;
            company[name] = e.target.value
            formikProps.setFieldValue('company', company);
            this.setState({
              dirty: true,
            });
          }
        }
        placeholder={properties['placeholder']}
        disabled={!this.areFieldsEnabled() || properties['disabled']}
        component={properties['component']}
        className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`company[${name}]`}
      />
    )
  }

  renderDateField(name,formikProps, required = false) {
    const { checkError } = this.state;
    const erroring = required && checkError && (!formikProps.values.company[name] || formikProps.values.company[name].length <= 0 || customValidation);

    return (
      <Field
        onChange={(e) => {
            const { company } = formikProps.values;
            company[name] = e.target.value
            formikProps.setFieldValue('company', company);
            this.setState({
              dirty: true,
            });
          }
        }
        component={properties['component']}
        className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`company[${name}]`}
      />
    )
  }

  getStates(formikProps) {
    try {
      const country = Countries.byName(formikProps.values.company.country||"");
      if(country && country.provinces && country.provinces.data) {
        return country.provinces.data.map(x=>{
          return {
            label: x.name,
            value: x.name
          }
        })
      }
      return null;
    }catch(e){
      return null;
    }
  }

  renderForm(formikProps) {
    const { error } = this.state;
    const { user } = this.props;
    const { values } = formikProps;
    const { company } = values;
    const states = this.getStates(formikProps)
    this.getStates(formikProps);
    return (
      <Form className="cnt-default-layout-contacts-form" >
        <div className="row">
          <div className="col-xs-12">
            <label className="required">Mandatory Fields are marked with a </label>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 ">
            <div className="cnt-form-subheader">
              Company Information
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Company Name
              </label>
              {this.renderField('name', formikProps, true)}
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Common Company Name
              </label>
              {this.renderField('common_company_name', formikProps, false)}
            </div>
          </div>
        </div>
        <div className="row" >
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Org Party ID
              </label>
              {this.renderField('org_party_id', formikProps, false)}
              Not sure what this is? <a href="http://crtmetrics-prd-03:8000/single_match" target="_blank"> Check here.</a>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Org Party ID Source
              </label>
              {this.renderField('org_party_id_source', formikProps, false)}
            </div>
          </div>
        </div>
        
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                SAVM ID
              </label>
              {this.renderField('savmid', formikProps, false)}
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                SAVM Version
              </label>
              {this.renderField('savm_version', formikProps, false)}
            </div>
          </div>
        </div>

        <div className="row">


          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
              Theater
              </label>
              {this.renderSelectField('theater', formikProps, theaterOptions())}
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Country
              </label>
              {this.renderSelectField('country', formikProps, getCountrySelectOptions())}
            </div>
          </div>

          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Area
              </label>
              {this.renderSelectField('area', formikProps, areaOptions())}
            </div>
          </div>
        </div>
        <div className="row" style={{display:'flex'}}>
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                ABX Tier
              </label>
              {this.renderSelectField('abx_tier', formikProps, abxOptions(), false, {required: false})}
              Not sure what this is? <a href="https://cisco.sharepoint.com/sites/InsightsAnalytics/SitePages/ABX.aspx" target="_blank"> Check here.</a>
            </div>
          </div>
          {this.renderRadioFields(formikProps)}
        </div>
        <div className="row">
          <div className="col-xs-12 ">
            <div className="cnt-form-subheader">
              Company Account Information
            </div>
          </div>
        </div>


        <div className="row">
          <div className="col-xs-12 col-md-3">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Sales Coverage Code
              </label>
              {this.renderSelectField('sales_coverage', formikProps, salesCoverageOptions(), false, {required: false})}
            </div>
          </div>
          <div className="col-xs-12 col-md-3">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Sales Sub Coverage Code
              </label>
              {this.renderSelectField('sales_sub_coverage', formikProps, salesSubCoverageOptions(),  false, {required: false})}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-3">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Sales Level 1
              </label>
              {this.renderSelectField('geo', formikProps, geoOptions(),false, {required: false})}
            </div>
          </div>
          <div className="col-xs-12 col-md-3">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Sales Level 2
              </label>
              {this.renderSelectField('segment', formikProps, segmentOptions(),  false, {required: false})}
            </div>
          </div>
          <div className="col-xs-12 col-md-3">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Sales Level 3
              </label>
              {this.renderSelectField('sub_segment', formikProps, subSegmentOptions(),  false, {required: false})}
            </div>
          </div>
          {formikProps.values.company.sales_sub_coverage == "OTHER" ? (
            <div className="col-xs-12 col-md-3">
              <div className="cnt-default-layout-event-form-input-container">
                <label className="">
                  If "Other", please write here.
                </label>
                {this.renderField('sales_sub_coverage_temp', formikProps, false,false, {placeholder:""})}
              </div>
            </div>
          ):""}
          <div className="col-xs-12 col-md-3">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Sales Level 4
              </label>
              {this.renderSelectField('operations', formikProps, operationsOptions())}
            </div>
          </div>
          <div className="col-xs-12 col-md-3">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Sales Level 5
              </label>
              {this.renderField('sales_level_5', formikProps, false)}
            </div>
          </div>
        </div>


        <div className="row">
          <div className="col-xs-12 col-md-3">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Wallet Opportunity ($Million) #
              </label>
              <div class="input-group input-group-md">
                <span class="input-group-addon" id="sizing-addon1">$</span>
                {this.renderField('wallet_opportunity', formikProps, false)}
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-3">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Employee Count
              </label>
              <div class="input-group input-group-md">
                {this.renderField('employee_count', formikProps, false, false, {type: 'number', disabled: !(isJaguar(user)|| isSolutionsLead(user) || isAdmin(user) || isExecutiveRelationsLead(user))})}
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-3">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Company Type
              </label>
              {this.renderSelectField('company_type', formikProps, companyTypeOptions())}
            </div>
          </div>
          <div className="col-xs-12 col-md-3">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Vertical Market
              </label>
              {this.renderSelectField('vertical', formikProps, verticalOptions(), false, {required: false})}
            </div>
          </div>
          {this.renderCompanyTypeChildFields(formikProps)}
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-3">
            <br/>
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Compliance Issue
              </label>
            </div>
            <Checkbox
              label="Yes"
              disabled={(!this.areFieldsEnabled())||!canEditComplianceIssue(user)}
              images={radioImages()}
              inline={true}
              value={formikProps.values.company.compliance_issue}
              onClick={(e) => {
                  const { company } = formikProps.values;
                  company['compliance_issue'] = true
                  formikProps.setFieldValue('company', company);
                  this.setState({
                    dirty: true,
                  });
                }
              }
            />
            <Checkbox
              label="No"
              disabled={(!this.areFieldsEnabled())||!canEditComplianceIssue(user)}
              images={radioImages()}
              inline={true}
              value={!formikProps.values.company.compliance_issue}
              onClick={(e) => {
                  const { company } = formikProps.values;
                  company['compliance_issue'] = false
                  formikProps.setFieldValue('company', company);
                  this.setState({
                    dirty: true,
                  });
                }
              }
            />
          </div>
          <div className="col-xs-12">
            <label className="">
              Compliance Issue Reasoning
            </label>
            {this.renderField('compliance_issue_reasoning', formikProps, false, false, {component:"textarea", disabled: !canEditComplianceIssue(user)})}
          </div>
        </div>
        <div className="row">
          {canSeeCompanyNotes(user) ? (
            <div className="col-xs-12 col-md-8">
              <label className="">
                Notes
              </label>
              {this.renderField('notes', formikProps, false,false, {component: 'textarea', style: {minHeight: '200px'}})}
            </div>
          ):""}
        </div>

        <div className="row">
          <div className="col-xs-12 cnt-default-layout-event-form-error">
            {error}
          </div>
          {this.renderButtons(formikProps)}
        </div>
      </Form>
    )
  }

  renderCompanyTypeChildFields(formikProps) {
    const { values } = formikProps;
    const { company } = values;
    const { company_type } = company;
    switch(company_type) {
      case 'Corporation (not subsidiary)':
        return ("");
        break;
      case 'Subsidiary':
        return ("");
        break;
      case 'Government Agency':
        return ("");
        break;
      case 'School':
        return ("");
        break;
    }
  }

  renderRadioFields(formikProps) {
    const { values } = formikProps;
    const { company } = values;
    return (
      <>
      <div className="col-xs-12 col-md-3">
        <div className="cnt-default-layout-event-form-input-container">
          <label className="">
            Competitor
          </label>
        </div>
        <Checkbox
          label="Yes"
          disabled={!this.areFieldsEnabled()}
          images={radioImages()}
          inline={true}
          value={formikProps.values.company.competitor == true}
          onClick={(e) => {
              const { company } = formikProps.values;
              company['competitor'] = true
              formikProps.setFieldValue('company', company);
              this.setState({
                dirty: true,
              });
            }
          }
        />
        <Checkbox
          label="No"
          disabled={!this.areFieldsEnabled()}
          images={radioImages()}
          inline={true}
          value={formikProps.values.company.competitor != true}
          onClick={(e) => {
              const { company } = formikProps.values;
              company['competitor'] = false
              formikProps.setFieldValue('company', company);
              this.setState({
                dirty: true,
              });
            }
          }
        />
      </div>
      {company && company.competitor ? (
        <div className="col-xs-12 col-md-3">
          <div className="cnt-default-layout-event-form-input-container">
            <label className="">
              If "Yes", please write here.
            </label>
            {this.renderField('competitor_reason', formikProps, false,false, {placeholder:""})}
          </div>
        </div>
      ):""}
      <div className="col-xs-12 col-md-3">
        <div className="cnt-default-layout-event-form-input-container">
          <label className="">
            Transformational Account
          </label>
        </div>
        <Checkbox
          label="Yes"
          disabled={!this.areFieldsEnabled()}
          images={radioImages()}
          inline={true}
          value={formikProps.values.company.transformation_account == true}
          onClick={(e) => {
              const { company } = formikProps.values;
              company['transformation_account'] = true
              formikProps.setFieldValue('company', company);
              this.setState({
                dirty: true,
              });
            }
          }
        />
        <Checkbox
          label="No"
          disabled={!this.areFieldsEnabled()}
          images={radioImages()}
          inline={true}
          value={formikProps.values.company.transformation_account != true}
          onClick={(e) => {
              const { company } = formikProps.values;
              company['transformation_account'] = false
              formikProps.setFieldValue('company', company);
              this.setState({
                dirty: true,
              });
            }
          }
        />
      </div>
      </>
    )

  }

  renderButtons(formikProps) {
    const { dirty, saving } = this.state;
    const { user, company, companies,cancelCallback, disableDelete } = this.props;
    const isSubmitButtonDisabled = !(formikProps.dirty || dirty || saving)
    if(this.areFieldsEnabled()) {
      return (
        <div className="col-xs-12" style={{paddingTop: "20px"}}>
          <button
            type="button"
            onClick={() => formikProps.handleSubmit()}
            disabled={isSubmitButtonDisabled}
            style={{marginLeft:"0"}}
            className={!isSubmitButtonDisabled ? "cnt-submit-button" : "cnt-disabled-button "}
          > {saving ? "Saving..." : "Save Company"} </button>
          <button
            type="button" className=" cnt-cancel-button "
            onClick={cancelCallback ? cancelCallback : ()=>{window.location="/companies"}}
          > Cancel </button>
          {this.isEditing() && canDeleteCompany(user) && !disableDelete? (
            <CompanyDeleteModal
              companies={companies}
              company={company}
              onDelete={this.onDelete}
            >
              <button type="button" className=" cnt-delete-button"> Delete </button>
            </CompanyDeleteModal>

          ) : ""}
        </div>
      )
    }
  }

  onDelete(json, opts) {
    window.location = '/companies'
  }

  renderSelectField(name, formikProps, options, customValidation = false, renderOptions = {}) {
    const { checkError } = this.state;
    const erroring = renderOptions['required'] && checkError && ((formikProps.values.company[name] == null || formikProps.values.company[name].length <= 0) || customValidation);
    let value = null;
    try {
       value = options.filter(option => option.value === formikProps.values['company'][name])[0]
       if(value == null && formikProps.values['company'][name]){
         value = {
           label: formikProps.values['company'][name],
           value: formikProps.values['company'][name]
         }
       }
    }catch (e) {
      console.log("error found");
      console.log(e);
    }
      return (
        <Select
          className={erroring ? "cnt-form-select-erroring" : ""}
          options={options}
          classNamePrefix='cnt-form-select'
          value={options ? value : ""}
          onChange={(option) => {
            const { company } = formikProps.values;
            company[name] = option.value
            this.setState({
              dirty: true
            })
            formikProps.setFieldValue('company', company)
          }}
          isClearable={false}
          disabled={renderOptions['disabled']}
          filterOption={this.customFilter}
        />
      )
    }

  areFieldsEnabled() {
    const { user, editingDisabled } = this.props;
    if(editingDisabled) {
      return false;
    }
    return canEditCompany(user);
  }

  formMethod() {
    return this.isEditing() ? "PATCH" : "POST"
  }

  formURL() {
    const { company } = this.props;
    return this.isEditing() ? `/companies/${company.id}` : `/companies`;
  }

  generateDefaultAccountManager() {
    const { user } = this.props
    if(isAccountManager) {
      return user.id
    }
    return null
  }

  isDirty() {
    const { dirty } = this.state;
    return dirty
  }


  render() {
    const { user, company, from_nomination } = this.props;
    return (
      <>
      <Formik
        initialValues={{
          company: {
            name: company ? company.name : "",
            sales_coverage: company ? company.sales_coverage : "",
            sales_sub_coverage: company ? company.sales_sub_coverage : "",
            segment: company ? company.segment : "",
            sub_segment: company ? company.sub_segment : "",
            region: company ? company.region : "",
            vertical: company ? company.vertical : "",
            operations: company ? company.operations : "",
            savmid: company ? company.savmid : "",
            org_party_id: company ? company.org_party_id : "",
            company_type: company ? company.company_type : "",
            wallet_opportunity: company ? company.wallet_opportunity : "",
            competitor: company && company.competitor ? true : false,
            transformation_account: company && company.transformation_account ? true : false,
            savm_version: company ? company.savm_version : "",
            country: company ? company.country : "",
            geo: company ? company.geo : "",
            theater: company ? company.theater : "",
            area: company ? company.area : "",
            employee_count: company ? company.employee_count : null,
            competitor_reason:company ? company.competitor_reason : "",
            org_party_id_source: company ? company.org_party_id_source : "",
            account_id_group: company ? company.account_id_group : "",
            primary_account_lead_role: company ? company.primary_account_lead_role : "",
            primary_accout_lead_first_name: company ? company.primary_accout_lead_first_name : "",
            primary_accout_lead_last_name: company ? company.primary_accout_lead_last_name : "",
            primary_accout_lead_email: company ? company.wallet_opportunity : "",
            primary_accout_lead_phone: company ? company.wallet_opportunity : "",
            source: company ? company.source : "",
            common_company_name: company ? company.common_company_name : "",
            abx_tier: company ? company.abx_tier : "",
            compliance_issue: company ? company.compliance_issue : "",
            compliance_issue_reasoning: company ? company.compliance_issue_reasoning : "",
            sales_level_5: company ? company.sales_level_5 : "",
            notes: company ? company.notes : "",
          }
        }}
        onSubmit={(values, actions) => {
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          if(values.company.sales_sub_coverage == "OTHER") {
            values.company.sales_sub_coverage = values.company.sales_sub_coverage_temp;
            delete values.company.sales_sub_coverage_temp
          }
          this.setState({
            error: null,
            saving: true
          })
          fetch(this.formURL(), {
            method: this.formMethod(),
            redirect: "manual",
            body: JSON.stringify(values),
            headers: {
              "X-CSRF-Token": token,
              "Content-Type": 'application/json'
            }
          })
            .then(response => {
              this.setState({
                saving: false
              })
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                if(this.props.onRedirect) {
                  this.props.onRedirect(json)
                  return;
                }
                if(this.props.temporary_contact_id) {
                  window.location=`/contacts/new?temporary_contact_id=${this.props.temporary_contact_id}&company_id=${json.company_id}${from_nomination? "&from_nomination=true" : ""}`
                  return;
                }
                if(json.redirect_url) {
                  window.location = json.redirect_url
                }else {
                  console.log("Successful no redirect")
                }
              }
              else {
                this.setState({
                  error: json.error,
                  checkError: true,
                })
              }
            });
        }}
        render={(formikProps) => (
          <>
          {this.renderForm(formikProps)}
          </>
        )}
      />
      </>
    );
  }
}

export default CompanyForm;
