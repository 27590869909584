import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import {DatePickerField} from './DatePickerField'
import Cookies from "universal-cookie";
import {canEditEvents, isJaguar} from "@utility/PermissionUtility"
import NominationPreviewModal from "@nominations/modals/NominationPreviewModal"
import Select from 'react-select-virtualized'
import DefaultTooltip from "@layout/tooltips/DefaultTooltip";

class EventNominationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      locked: props.event && props.event.award_criteria && props.event.award_criteria.locked ? true : false,
      copyEventId: null,
      collapsedQuestions: []
    };
    this.lockEvent = this.lockEvent.bind(this);
    this.unlockEvent = this.unlockEvent.bind(this);
    this.copyEvent = this.copyEvent.bind(this);
    this.openAll = this.openAll.bind(this);
    this.collapseAll = this.collapseAll.bind(this);
    this.toggleCollapsedQuestion = this.toggleCollapsedQuestion.bind(this);
    this.unpublishEvent = this.unpublishEvent.bind(this);
  }
  componentDidMount(){
    document.addEventListener("keydown", this.unlockEvent, false);
  }

  unlockEvent(e) {
    const { user } = this.props;
    if(event.keyCode === 121 && isJaguar(user)) {
      this.setState({
        locked: false
      })
    }
  }
  
  unpublishEvent(formikProps) {
    this.state.locked = false;
    this.setState({
      locked: false
    })
    formikProps.handleSubmit();
  }

  addNewQuestion(formikProps) {
    const { values } = formikProps;
    let { questions } = values;
    questions = questions.concat([this.getDefaultQuestion()])
    formikProps.setFieldValue('questions', questions);
    window.scrollTo(0, document.body.scrollHeight);
  }

  getDefaultQuestion() {
    var array = new Uint32Array(10);
    window.crypto.getRandomValues(array);
    return {
      question: "",
      type:"text",
      answers:"",
      id: array[0]
    }
  }

  isEditing() {
    const { event } = this.props;
    return !!event.id;
  }

  isLocked() {
    const { locked } = this.state;
    return locked;
  }

  isFormEditable() {
    const { user } = this.props;
    return (
      canEditEvents(user) && !this.isLocked()
    )
  }

  renderField(name, questionIndex, formikProps, required=false,customValidation = false, properties = {}) {
    const { user } = this.props;
    const { checkError } = this.state;
    const erroring = required && checkError && (!formikProps.values.questions[questionIndex][name] || formikProps.values.questions[questionIndex][name].length <= 0 || customValidation);

    return (
      <Field
        onChange={(e) => {
            const { questions } = formikProps.values;
            questions[questionIndex][name] = e.target.value
            formikProps.setFieldValue('questions', questions);

            this.setState({
              dirty: true,
            });
          }
        }
        disabled={!canEditEvents(user) || this.isLocked() }
        component={properties['component']}
        className={`form-control ${erroring ? 'field-erroring': "" }`}
        type={properties['type']}
        name={`questions[${questionIndex}][${name}]`}
      />
    )
  }

  renderCheckboxField(name, questionIndex,formikProps,properties={}) {
    const { user } = this.props;
    const { checkError } = this.state;
    const { questions } = formikProps.values;
    const erroring = properties['required'] && checkError && (!formikProps.values.questions[questionIndex][name] || formikProps.values.questions[questionIndex][name].length <= 0 || customValidation);
    const value = questions[questionIndex][name];
    const setValue = properties.setValue;
    return (
      <>
        <img
          src={ value == (setValue ? setValue : true) ? "/images/checkbox-checked.svg" : "/images/checkbox-unchecked.svg" }
          style={{cursor:"pointer", margin: "0 10px"}}
          onClick={(e) => {
            if(this.isFormEditable()){
              if(setValue) {
                questions[questionIndex][name] = setValue
              }else {
                questions[questionIndex][name] = !questions[questionIndex][name]
              }
              formikProps.setFieldValue('questions', questions)
            }
          }}
        />
        {properties.label ? (
          <label className="checkbox-label" for="topic_ids">{properties['label']}</label>
        ):""}
      </>
    )
  }



  renderQuestions(formikProps) {
    const { values } = formikProps;
    const { questions } = values;
    return (questions||[]).map((question, index)=>(
      <div className="col-xs-12">
        {this.renderQuestion(question,index, formikProps)}
      </div>

    ));
  }

  removeQuestion(index, formikProps) {
    const { values } = formikProps;
    let { questions } = values;
    questions = questions.filter((x,i) => i != index);
    formikProps.setFieldValue('questions', questions);
  }

  toggleCollapsedQuestion(id) {
    const { collapsedQuestions } = this.state;
    if(this.isQuestionCollapsed(id)) {
      this.setState({
        collapsedQuestions: collapsedQuestions.filter(x=> x!= id)
      })
    }else {
      this.setState({
        collapsedQuestions: collapsedQuestions.concat([id])
      })
    }
  }

  isQuestionCollapsed(id) {
    const { collapsedQuestions } = this.state;
    return collapsedQuestions.indexOf(id) != -1
  }

  renderQuestion(question,index,formikProps) {
    const isOpen = !this.isQuestionCollapsed(question.id)
    let type = ""
    try {
      type = formikProps.values['questions'][index]['type'];
    }catch(e){

    }
    return (
      <div className={` cnt-event-nomination-collapsable-container ${isOpen ? "open" : "closed"}`}>
        <div className="cnt-event-nomination-collapsable-container-header">
          <div className="cnt-nomination-collapsable-container-signifier" onClick={()=>this.toggleCollapsedQuestion(question.id)}/>
          <label className="cnt-nomination-form-question-counter" onClick={()=>this.toggleCollapsedQuestion(question.id)}> Question #{index+1}</label>
          {this.renderQuestionButtons(question,index,formikProps)}
        </div>
        <div className="cnt-event-nomination-collapsable-container-body">
          {this.renderField('question', index, formikProps, false,false, {})}
          <div className="cnt-event-nomination-collapsable-container-subbody">
            <div className="">
              <label className=""> Answer Type: </label>
              {this.renderQuestionType(index, formikProps)}
            </div>
            {type == 'dropdown' || type == 'radiobutton' || type == 'checkbox' || type=="multiselect"? (
              <div className="">
                <label className=""> Drop down for question #{index+1} (one item per line)</label>
                {this.renderField('answers', index, formikProps, false,false, {component: 'textarea'})}
              </div>
            ):""}
            <div className="">
              {this.renderCheckboxField('optional', index, formikProps,{label: 'Optional'})}
              {this.renderCheckboxField('hidden', index, formikProps,{label: 'Hidden'})}
              {this.renderCheckboxField('custom_modal', index, formikProps,{label: 'Include Modal'})}
              {this.renderCheckboxField('use_custom_styles', index, formikProps,{label: 'Use Custom Styles'})}
              {question.custom_modal == true ? (
                <div className="">
                  <label className=""> Modal Content (HTML)</label>
                  {this.renderField('custom_modal_content', index, formikProps, false,false, {component: 'textarea'})}
                </div>
              ):""}
              {question.use_custom_styles == true ? (
                <div className="">
                  <label className=""> Custom Styles Content (CSS)</label>
                  {this.renderField('custom_styles_css', index, formikProps, false,false, {component: 'textarea'})}
                </div>
              ):""}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderTooltips() {
    return (
      <>
        <DefaultTooltip
          tooltipId={`sg-cnt-event-form-nominate-up`}
        >
          Move up
        </DefaultTooltip>
        <DefaultTooltip
          tooltipId={`sg-cnt-event-form-nominate-down`}
        >
          Move down
        </DefaultTooltip>
        <DefaultTooltip
          tooltipId={`sg-cnt-event-form-nominate-trash`}
        >
          Delete
        </DefaultTooltip>
      </>
    )
  }

  renderQuestionButtons(question,index,formikProps) {
    return (
      <div className="cnt-event-nomination-question-buttons">
        <img
          data-for={`sg-cnt-event-form-nominate-up`}
          data-tip
          onClick={()=>this.rearrangeItem(index, index-1, formikProps)}
          src="/images/events/up.svg" className="cnt-event-nomination-question-button"
        />
        <img
          data-for={`sg-cnt-event-form-nominate-down`}
          data-tip
          onClick={()=>this.rearrangeItem(index, index+1, formikProps)}
          src="/images/events/down.svg" className="cnt-event-nomination-question-button"
        />
        <img
          data-for={`sg-cnt-event-form-nominate-trash`}
          data-tip
          onClick={() => this.removeQuestion(index, formikProps)}
          src="/images/events/trash.svg" className="cnt-event-nomination-question-button"
        />
      </div>
    )
  }

  rearrangeItem(old_index, new_index, formikProps) {
      let questions = formikProps.values.questions;
      console.log({old_index, new_index})
      if (new_index >= questions.length || new_index < 0) {
          //Do nothing
      }else {
        questions.splice(new_index, 0, questions.splice(old_index, 1)[0]);
        formikProps.setFieldValue('questions', questions);
      }
  };

  renderCopyEvent(formikProps) {
    const { copyEventId } = this.state;
    const options = this.props.events.map(x=> {return {
      value: x.id,
      label: x.name
    }})

    const value = options.find(x=> x.value == copyEventId);
    return (
      <>
      <div className="col-xs-12">
          <label className=""> Copy criteria from another event (this will replace all existing criteria)</label>
      </div>
      <div className="col-xs-8 col-md-6">
        <Select
          classNamePrefix="cnt-default-select"
          value={value}
          options={options}
          onChange={(e)=>{this.setState({
            copyEventId: e.value
          })}}
          disabled={this.isLocked()}
        />
      </div>
      <div className="col-xs-4 col-md-3">
        <button
          disabled={this.isLocked() || value == null}
          type="button"
          class="cnt-submit-button"
          onClick={(e)=>this.copyEvent(formikProps)}
        > Copy Event </button>
      </div>
      </>
    )
  }

  copyEvent(formikProps) {
    const { copyEventId } = this.state;
    const { events } = this.props;

    const value = events.find(x=> x.id == copyEventId);
    console.log(value);
    if(value) {
      const questions = value['award_criteria']['questions']
      formikProps.setFieldValue('questions', questions);

      this.setState({
        dirty: true,
      });
    }
  }

  collapseAll(formikProps) {
    this.setState({
      collapsedQuestions: formikProps.values.questions.map(x=>x.id)
    })
  }

  openAll(formikProps) {
    this.setState({
      collapsedQuestions: []
    })
  }

  renderForm(formikProps) {
    const { error } = this.state;
    const { user } = this.props;
    return (
      <Form className="cnt-default-layout-event-form" >
        <div className="row">
          
          {this.renderCopyEvent(formikProps)}
          <div className="col-xs-12">
            <label className=""> Qualifying questions to appear on nomination form.</label>
            <br/>
            <div className="cnt-event-nomination-form-collapse-options">
              <a onClick={(e)=>this.collapseAll(formikProps)}href="#">
                Collapse All
              </a>
              <div className="cnt-event-nomination-link-divider">
                |
              </div>
              <a href="#" onClick={this.openAll}>
                Open All
              </a>
            </div>

          </div>
          {this.renderButtons(formikProps)}
          {this.renderQuestions(formikProps)}
          {this.isFormEditable() ? (
            <div className="col-xs-12">
              <br/>
              <a id="add-new-items" href="#add-new-items" onClick={()=>this.addNewQuestion(formikProps)}>+ Add another question</a>
            </div>
          ) : ""}
          <div className="col-xs-12 cnt-default-layout-event-form-error">
            {error}
          </div>

          {this.renderButtons(formikProps)}
        </div>
      </Form>
    )
  }

  renderPreviewModal(formikProps) {
    let { event, user } = this.props;
    if(!event.data) {
      event.data={}
    }
    if(typeof event.award_criteria == 'string'){
      event.award_criteria = {}
    }
    event.award_criteria.questions = formikProps.values.questions || []
    return (
      <NominationPreviewModal
        user={user}
        contacts={[]}
        events={[
          event
        ]}
        preview={true}
        nomination={{id: null, contact_id: null, event_id: event.id, award_criteria_answers: {}, status: 'draft'}}
      >
        <button type="button" style={{background: "#0e274c", color: "white"}} className="cnt-submit-button"> Preview  </button>
      </NominationPreviewModal>
    )
  }

  renderButtons(formikProps) {
    const { user } = this.props;
    if(canEditEvents(user) && !this.isLocked()) {
      return (
        <>
        <div className="col-xs-12" style={{paddingTop: "20px"}}>
          <button type="button" style={{marginLeft:0}} onClick={() => formikProps.handleSubmit()} className="cnt-submit-button"> Save </button>
          {this.renderPreviewModal(formikProps)}
          <button type="button" onClick={() => this.lockEvent(formikProps)} style={{background: "#68BF4B"}} className="cnt-submit-button"> Publish  </button>
          <a href="/events"><button type="button" className="cnt-cancel-button "> Cancel </button></a>
        </div>
        <div className="col-xs-12">
          <br/>
          <span style={{color: 'red'}}>Note:</span> Nomination form needs to be published before it can be used. Nomination questions cannot be changed after they are published.
        </div>
        </>
      )
    }else if(canEditEvents(user)) {
      return (
        <div className="col-xs-12" style={{paddingTop: "20px"}}>
          <button type="button" onClick={()=>this.unpublishEvent(formikProps)} style={{marginRight:"15px", background: "red", color: "white"}} className="cnt-submit-button" > Close Event Nominations </button>
          {this.renderPreviewModal(formikProps)}
          <a href="/events"><button type="button" className="cnt-cancel-button "> Cancel </button></a>
        </div>
      )
    }
  }

  renderQuestionType(questionIndex, formikProps, customValidation= false, renderOptions={}) {
    const { checkError } = this.state;
    let value = ""
    try {
      value = formikProps.values['questions'][questionIndex]['type'];
    }catch(e){

    }
    return (
      <>
      <img
        src={ value == 'dropdown'  ? "/images/checkbox-checked.svg" : "/images/checkbox-unchecked.svg" }
        style={{cursor:"pointer", margin: "0 10px"}}
        onClick={(e) => {
          if(this.isFormEditable()){
            const { questions } = formikProps.values;
            questions[questionIndex]['type'] = 'dropdown'
            formikProps.setFieldValue('questions', questions)
          }
        }}
      /><label className="checkbox-label" for="topic_ids">Dropdown</label>
      <img
        src={ value == "text"  ? "/images/checkbox-checked.svg" : "/images/checkbox-unchecked.svg" }
        style={{cursor:"pointer", margin: "0 10px"}}
        onClick={(e) => {
          if(this.isFormEditable()){
            const { questions } = formikProps.values;
            questions[questionIndex]['type'] = 'text'
            formikProps.setFieldValue('questions', questions)
          }
        }}
      /><label className="checkbox-label" for="topic_ids">Text</label>
      <img
        src={ value == "money"  ? "/images/checkbox-checked.svg" : "/images/checkbox-unchecked.svg" }
        style={{cursor:"pointer", margin: "0 10px"}}
        onClick={(e) => {
          if(this.isFormEditable()){
            const { questions } = formikProps.values;
            questions[questionIndex]['type'] = 'money'
            formikProps.setFieldValue('questions', questions)
          }
        }}
      /><label className="checkbox-label" for="topic_ids">Money</label>
              <img
          src={ value == "radiobutton"  ? "/images/checkbox-checked.svg" : "/images/checkbox-unchecked.svg" }
          style={{cursor:"pointer", margin: "0 10px"}}
          onClick={(e) => {
            if(this.isFormEditable()){
              const { questions } = formikProps.values;
              questions[questionIndex]['type'] = 'radiobutton'
              formikProps.setFieldValue('questions', questions)
            }
          }}
        /><label className="checkbox-label" for="topic_ids">Radio Buttons</label>
        <img
          src={ value == "checkbox"  ? "/images/checkbox-checked.svg" : "/images/checkbox-unchecked.svg" }
          style={{cursor:"pointer", margin: "0 10px"}}
          onClick={(e) => {
            if(this.isFormEditable()){
              const { questions } = formikProps.values;
              questions[questionIndex]['type'] = 'checkbox'
              formikProps.setFieldValue('questions', questions)
            }
          }}
        /><label className="checkbox-label" for="topic_ids">Checkboxes</label>
        <img
          src={ value == "multiselect"  ? "/images/checkbox-checked.svg" : "/images/checkbox-unchecked.svg" }
          style={{cursor:"pointer", margin: "0 10px"}}
          onClick={(e) => {
            if(this.isFormEditable()){
              const { questions } = formikProps.values;
              questions[questionIndex]['type'] = 'multiselect'
              formikProps.setFieldValue('questions', questions)
            }
          }}
        /><label className="checkbox-label" for="topic_ids">Multiselect</label>
        <img
          src={ value == "label"  ? "/images/checkbox-checked.svg" : "/images/checkbox-unchecked.svg" }
          style={{cursor:"pointer", margin: "0 10px"}}
          onClick={(e) => {
            if(this.isFormEditable()){
              const { questions } = formikProps.values;
              questions[questionIndex]['type'] = 'label'
              questions[questionIndex]['optional'] = true
              formikProps.setFieldValue('questions', questions)
            }
          }}
        /><label className="checkbox-label" for="topic_ids">Label</label>
        <br/>
      {checkError && !value ? (
        <div className="erroring-text" style={{color: "red"}}>
          <br/>
          Please select an option
        </div>
      ):""}
      </>
    )
  }

  lockEvent(formikProps) {
    this.state.locked = true;
    this.setState({
      locked: true
    })
    formikProps.handleSubmit();
  }

  formMethod() {
    return this.isEditing() ? "PATCH" : "POST"
  }

  formURL() {
    const { event } = this.props;
    return this.isEditing() ? `/events/${event.id}` : `/events`;
  }

  render() {
    const { user, event } = this.props;
    return (
      <>
      <Formik
        initialValues={{
          questions: event ? event.award_criteria.questions || [] : []
        }}
        onSubmit={(values, actions) => {
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          let award_criteria = event.award_criteria || {};
          if(typeof award_criteria == 'string') {
            award_criteria = {}
          }
          award_criteria.questions = values.questions || []
          award_criteria.locked = this.state.locked;
          fetch(this.formURL(), {
            method: this.formMethod(),
            redirect: "manual",
            body: JSON.stringify({
              event: {
                award_criteria
              }
            }),
            headers: {
              "X-CSRF-Token": token,
              "Content-Type": 'application/json'
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                if(json.redirect_url) {
                  window.location = (""+window.location).replace(/#[A-Za-z0-9_]*$/,'')+"#nomform"
                  location.reload();
                }else {
                  console.log("Successful no redirect")
                }
              }
              else {
                this.setState({
                  error: json.error
                })
              }
            });
        }}
        render={(formikProps) => (
          <>
          {this.renderTooltips()}
          {this.renderForm(formikProps)}
          </>
        )}
      />
      </>
    );
  }
}

export default EventNominationForm;
