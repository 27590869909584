import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import Cookies from "universal-cookie";
import Modal from "react-modal";
import { flattenObj } from "@/utility/ObjectManipulation";
import ReportPreviewValues from "@/report-tool/ReportPreviewValues";

import MaterialTable from 'material-table';
import GeneralTablePagination from "../layout/tables/TableOverwrites/GeneralTablePagination"
import TablePagination from "@material-ui/core/TablePagination";

class ReportPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetched: false,
      modalOpen: false,
      previewList: [],
      pageNumber: 0,
      itemsPerPage: 5,
      count: 0
    }
    this.fetchPreview = this.fetchPreview.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.storeRowsPerPage = this.storeRowsPerPage.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement("body");
    this.fetchPreview();
  }

  fetchPreview() {
    const { baseObj, filterChains, sortingCriteria, objectKeys} = this.props;
    const { pageNumber, itemsPerPage } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch('/reports/get_preview', {
      method: 'POST',
      redirect: "manual",
      body: JSON.stringify(
        {
          object_type: baseObj.value,
          filterChains: filterChains,
          sortingCriteria: sortingCriteria,
          keys: objectKeys.map(e => e.value),
          page_number: pageNumber,
          items_per_page: itemsPerPage
        }
      ),
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        let newList = json.list[1].map(object => flattenObj(object))
        this.setState({
          fetched: true,
          previewList: newList,
          count: json.list[0]
        });
      } else {
        console.log(json.error);
      }
    });
  }

  toggleModal() {
    const { modalOpen } = this.state;
    if (!modalOpen) {
      this.fetchPreview();
    }
    this.setState({ modalOpen: !modalOpen });
  }

  onChangePage(event, page) {
    this.setState(
      {pageNumber: page},
      // () => this.fetchPreview()
    );
  }
  storeRowsPerPage(event) {
    this.setState(
      {itemsPerPage: event},
      // () => this.fetchPreview()
    );
  }

  renderTable() {
    const { objectKeys, keysWithDataType } = this.props;
    const { count, fetched, previewList, itemsPerPage, pageNumber } = this.state;
    let keys = objectKeys.map(key => { return {title: key.label, field: key.value, render: (row) => (<ReportPreviewValues row={row} value={key.value} keysWithDataType={keysWithDataType} />)} });

    const options = {
      serverSide: true,
      pageSize: itemsPerPage,
      pageSizeOptions: [
        5,10,20,50,100
      ],
      search: false
    };
    if (fetched) {
      return (
        <div className="report-tool-preview-wrapper" style={{overflow: "hidden"}}>
          <MaterialTable
            key={`material-table-selected-table-preview`}
            localization={{
              body: {
                emptyDataSourceMessage: "No records to display"
              }
            }}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  ActionsComponent={(subprops) =>
                    <GeneralTablePagination
                      {...subprops}
                      icons={props.icons}
                      showFirstLastPageButtons={
                        true
                      }
                      page={pageNumber}
                      count={count}
                      onChangePage={this.onChangePage}
                    />
                  }
                />
              )
            }}
            columns={keys}
            data={previewList}
            autoHeight={true}
            title="Report Preview"
            options={options}
            onChangeRowsPerPage={this.storeRowsPerPage}
          />
        </div>
      );
    }
    return (
      <div className="report-preview-placeholder">
        Generating Preview
      </div>
    );
  }

  render() {
    const { modalOpen } = this.state;
    return (
      <>
        {this.renderTable()}
      </>
    );
  }
}

export default ReportPreview;
