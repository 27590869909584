import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import {isJaguar, isAccountManager} from "@utility/PermissionUtility"
import { formatId } from "@utility/ProgramUtils"
import GeneralTable from "@layout/tables/GeneralTable"
import EventTableChit from "@events/tables/EventTableChit"
class ReportEventTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.getColumns = this.getColumns.bind(this);
  }

  getFilteredEvents() {
    const  {events} = this.props;
    const filteredEvents = events.map(x=> {
      return x;
    })
    return filteredEvents;
  }

  renderEmail(row) {
    return (
      <a href={`mailto:${row.email}`} target="_blank">
        {row.email}
      </a>
    )
  }

  renderActions(event) {
    return (
      <>
        <a href={`/reports/events/${event.id}/drafts`}>
          Draft Report
        </a>
        {" "}
        <a href={`/events/${event.id}/full_report`}>
          Full Report
        </a>
      </>
    )
  }

  renderChit(event) {
    const { user } = this.props;
    return (
      <EventTableChit user={user} event={event}/>
    )
  }

  renderId(event) {
    return <a href={`/events/${event.id}`}>{formatId(event.id)}</a>
  }

  getColumns() {
    const { user } = this.props;
    let allColumns = [
      { title: 'ID', field: 'id', render: (row) => this.renderId(row)},
      { title: 'Event', field: 'name', render: (row) => this.renderChit(row) },
      { title: 'Contacts', field: 'contact_count'},
      { title: 'Actions', field: 'id', render: (row) => this.renderActions(row) },
    ]
    // if(isAccountManager(user)) {
    //   allColumns = allColumns.filter(x=>x.title != "Account Manager");
    // }
    return allColumns;
  }

  render() {
    return (
        <GeneralTable
          columns={this.getColumns()}
          data={this.getFilteredEvents()}
        />
    )
  }
}

export default ReportEventTable;
