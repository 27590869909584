import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import GeneralTable from "@layout/tables/GeneralTable"
import {isMarketingManager, canViewEventReport, isEventNominationOpen,isEventCriteriaLocked,  isJaguar, isAccountManager, isExecutiveRelationsLead, canEditNomination,canEditEvents, canDeleteEvents } from "@utility/PermissionUtility"
class EventShowReports extends React.Component {

  getReports() {
    const { user, event, token } = this.props;
    return [
      {name:"Nom Report", url:`/events/${event.id}/full_report`, type:"XLSX", isVisible: canViewEventReport(user)},
      {name:"To Send Report", url:`/events/${event.id}/invite_report`, type:"XLSX", isVisible: canViewEventReport(user)},

      {name:"Reg Report", url:`/events/${event.id}/core_participant_report`, type:"XLSX", isVisible: canViewEventReport(user) && token },
      {name:"Snowflake Report", url:`/events/${event.id}/snowflake_report`, type:"XLSX", isVisible: canViewEventReport(user)},
      {name:"SB Custom Report", url:`/events/${event.id}/superbowl_report`, type:"XLSX", isVisible: canViewEventReport(user)},
      
      {name:"CXO Attendence Report (Individual Event)", url:`/events/${event.id}/custom_reports/cxo_attended_report?events_ids=${event.id}`, type:"XLSX", isVisible: canViewEventReport(user), isVisible: event.tag_list.indexOf("CXO") >= -1},
      {name:"CXO Attendence Report (All CXO Events)", url:`/events/${event.id}/custom_reports/cxo_attended_report`, type:"XLSX", isVisible: event.tag_list.indexOf("CXO") >= -1},
      {name:"ATT Special Reg Report", url:`/events/${event.id}/att_special_report`, type:"XLSX", isVisible: ((isJaguar(user) || user.email == "juharvey@cisco.com") && event.name == "AT&T ProAm 2022")},
      {name:"ATT Special Reg Report", url:`/events/${event.id}/us_golf_special_report`, type:"XLSX", isVisible: ((isJaguar(user) || user.email == "juharvey@cisco.com") && (event.name == "U.S. Women's Open 2022 - Day Program" || event.name == "U.S. Open 2022 - Day Program" || event.name == "U.S. Open 2022 - Overnight Program"))},
      {name:"Reduced Nom Report", url:`/events/${event.id}/full_report`, type:"XLSX", isVisible: isMarketingManager(user)},
      {name: "Registered Region Report", url: `/events/${event.id}/custom_reports/registered_ticketing_report`, type:"XLSX", isVisible: canViewEventReport(user) && token},
      {name:"Preferred AM Report", url:`/events/${event.id}/preferred_am_report`, type:"XLSX", isVisible: canViewEventReport(user)},
      {name:"Special: Criteria Report 2023", url:`/events/${event.id}/custom_reports/julie_criteria_report_23`, isVisible: ((isJaguar(user) || user.email == "juharvey@cisco.com"))},
    ].filter(report=>report.isVisible)
  }

  getColumns() {
    const { user } = this.props;
    let allColumns = [
      { title: "Reports", field: 'name', render: this.renderTitle},
    ]
    return allColumns;
  }

  renderTitle(report) {
    return (
      <div>
        <a href={report.url}>
          {report.name}
        </a>
      </div>
    )
  }

  renderDownload(report) {
    return (
      <div>
        <a href={report.url}>
          Download {report.type || "XLSX"}
        </a>
      </div>
    )
  }


  render() {
    return (
      <div className="cnt-default-layout-updates-table">
        <GeneralTable
          tableRef={this.tableRef}
          columns={this.getColumns()}
          data={this.getReports()}
        />
      </div>
    )
  }
}

export default EventShowReports;
