import ContactCondensedView from "@contacts/modals/ContactCondensedView"
import CompanyCondensedView from "@companies/modals/CompanyCondensedView"
import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils";
import ContactFormSlowLoad from "@contacts/forms/ContactFormSlowLoad"
import CompanyFormSlowLoad from "@companies/forms/CompanyFormSlowLoad"
import Modal from "react-modal";
import ContactAccountManagerForm from "@contacts/forms/ContactAccountManagerForm"
class NominationVerifyPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      contact: null,
      company: null,
      contactModalOpen: false,
      companyModalOpen: false,
    };

    this.loadContactCompanies = this.loadContactCompanies.bind(this);
    this.loadContactsIfEmpty = this.loadContactsIfEmpty.bind(this);
    this.closeModals = this.closeModals.bind(this);
    this.contactFormRef = React.createRef();
    this.companyFormRef = React.createRef();
    this.onSaveContact = this.onSaveContact.bind(this);
  }

  componentDidUpdate() {
    this.loadContactsIfEmpty();
  }
  componentDidMount() {
    this.loadContactsIfEmpty();
  }

  loadContactsIfEmpty() {
    const { contactId } = this.props;
    let { contact, loading } = this.state;
    if(!loading) {
      if((!contact && contactId != null) || (contact && contact.id != contactId)) {
        this.loadContactCompanies();
      }
    }
  }

  loadContactCompanies() {
    const { contactId } = this.props;
    this.setState({
      contact: null,
      company: null,
      loading: true,
      contactModalOpen: false,
      companyModalOpen: false,
    })
    fetchAPI(
      `/api/v1/contacts/${contactId}/verification_info`,
      (json) => {
        if(!json.error) {
          this.setState({
            contact: json.contact,
            company: json.company,
            loading: false
          })
        }
      }
    )
  }

  renderModals() {
    const { contactModalOpen, companyModalOpen, contact, company} = this.state;
    const { user } = this.props;
    return (
      <>
        {contact ? (
          <Modal
            className="white-background cnt-user-admin-modal disable-scrollbars cnt-form-modal"
            overlayClassName="cnt-default-layout-modal-overlay"
            isOpen={contactModalOpen}
            onRequestClose={this.closeModals}
            contentLabel=""
          >
            <ContactAccountManagerForm
              contactRef={this.contactFormRef}
              user={user}
              contactId={contact.id}
              contact={contact}
              cancelCallback={this.closeModals}
              onFormSubmitCallback={this.onSaveContact}
            />
          </Modal>
        ) : ""}
      </>
    )
  }

  closeModals() {
    const { closeModalCallback } = this.props;
    const { contactModalOpen, companyModalOpen } = this.state;
    try{
      if(contactModalOpen && this.contactFormRef.current && this.contactFormRef.current.isDirty()) {
        if(!confirm("You have unsaved changes. Are you sure you would like to close this form?")) {
          return;
        }
      }
      if(companyModalOpen && this.companyFormRef.current && this.companyFormRef.current.isDirty()) {
        if(!confirm("You have unsaved changes. Are you sure you would like to close this form?")) {
          return;
        }
      }
    }catch(e) {

    }
    if(closeModalCallback) {
      closeModalCallback()
    }

    this.setState({
      contactModalOpen: false,
      companyModalOpen: false
    })
  }

  onSaveContact(contact) {
    console.log("TEST")
    const { closeModalCallback } = this.props;
    if(closeModalCallback) {
      closeModalCallback()
    }
    this.setState({
      contactModalOpen: false,
      companyModalOpen: false
    })
    this.loadContactCompanies();
  }

  render() {
    const { user } = this.props;
    const { contact, company} = this.state;
    if(contact) {
      return (
        <>
          <div className="col-xs-12">
            <br/>
            <br/>
          </div>
          <div className="col-xs-12">
            <a className="cnt-nomination-form-edit-information" href="#" onClick={()=>this.setState({contactModalOpen: true})}>
              Edit contact information
            </a>
            <ContactCondensedView
              contact={contact}
              company={company}
              amView={true}
              user={user}
            />
            <br/>
          </div>
          {this.renderModals()}
        </>
      )
    } else {
      return (
        <div className="col-xs-12">
          <div className="ctn-default-layout-log-table-loading">
            <br/>
            <br/>
            <h1> Loading</h1>
            <br />
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          </div>
        </div>

      )
    }
  }
}

export default NominationVerifyPanel;
