import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Cookies from "universal-cookie";

class ResendInvitationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      loading: true,
    }
    this.toggleModal = this.toggleModal.bind(this)
    this.resendConfirmation = this.resendConfirmation.bind(this);
  }

  renderLoadingCircle() {
    return (<div class="lds-button-ellipsis"><div></div><div></div><div></div><div></div></div>)
  }

  renderModal() {
    const { user, loading } = this.props;
    return (
      <div className="row">
        <div className="col-xs-12 ">
          <div className="cnt-admin-user-management-delete-modal-title">
            Resend User Notification
          </div>
          <div className="cnt-admin-user-management-delete-modal-description">
            An email will be sent to {user.email} re-inviting them to the tool.
          </div>
          <div className="">
            <button disabled={loading} className="cnt-submit-button" onClick={this.resendConfirmation}>{loading? this.renderLoadingCircle() : "Resend"}</button>
            <button className="cnt-cancel-button" onClick={this.toggleModal}> Cancel</button>
          </div>
        </div>
      </div>
    );
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen,
      loading: false,
    })
  }

  resendConfirmation() {
    const { user } = this.props;
    const { loading } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    if(loading) {
      return;
    }else {
      this.setState({loading: true});
    }
    fetch(`/admin/users/${user.id}/resend_invite`, {
      method: "POST",
      redirect: "manual",
      body: null,
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        this.toggleModal();
      }
      else {
        this.setState({
          loading: false
        })
      }
    }).catch((error)=>{
      this.setState({
        loading: false
      })
    });
  }

  render() {
    const { outerClassNames, children, user } = this.props;
    const { modalOpen } = this.state;
    return (
      <>
        <div className={outerClassNames} style={{cursor:"pointer", display: "inline-block"}} onClick={() => this.toggleModal()} >
          {children}
        </div>
        <Modal
          className="white-background cnt-user-admin-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default ResendInvitationModal;
