import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Dropzone from "react-dropzone";
import { Formik, Form } from "formik";

class ImportsUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formInitialValues: {
        sheet: "",
        csv_data: "",
      },
      sheet: {id: null},
      sheetDropped: false,
      isSubmitting: false,
    };
    this.toggleCheckBox = this.toggleCheckBox.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(acceptedFiles) {
    const reader = new FileReader();
    console.log(acceptedFiles);
    if (typeof window.FileReader !== "undefined") {
      reader.onload = _e => {
        this.setState({
          sheet: acceptedFiles[0],
          sheetDropped: true
        })
      };
      reader.readAsDataURL(acceptedFiles[0]);
    } else {
      this.setState({
        sheet: acceptedFiles[0],
        sheetDropped: true
      })
    }
  }

  renderSheet() {
    const { sheetDropped, formInitialValues, sheet } = this.state;
    if (sheetDropped) {
      return (
        <div className="sg-mgmt-form-input-import-tile-prompt">
          <img src="/images/imports/import_upload.svg" style={{width: "30px", margin: "auto", marginBottom: "10px", marginTop: "10px"}} />
          Sheet Attached
          <br />
          {sheet.name}
        </div>
      );
    }
    return (
      <div className="sg-mgmt-form-input-import-tile-prompt">
        <img src="/images/imports/import_upload.svg" style={{width: "30px", margin: "auto", marginBottom: "10px", marginTop: "10px"}} />
        Drag and Drop Spreadsheet
        <br />
        or Click to Choose File
      </div>
    );
  };

  renderSheetField() {
    const dropzoneAccept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, text/plain";
    return (
      <div>
        <Dropzone accept={dropzoneAccept} onDrop={this.onDrop} name="import[sheet]">
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <>
                <div
                  {...getRootProps()}
                  className="sg-mgmt-form-input-import-tile"
                >
                  <input {...getInputProps()} />
                  {this.renderSheet()}
                </div>
              </>
            );
          }}
        </Dropzone>
      </div>
    );
  }

  renderSubmitButton(label, disabled, isSubmitting) {
    return (
      <button
        className={`sg-mgmt-modal-btn ${disabled ? '' : 'sg-mgmt-modal-btn-enabled'}`}
        type="submit"
        disabled={disabled}
      >
        {
          isSubmitting ?
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          :
          <>{label}</>
        }
      </button>
    );
  }

  toggleCheckBox(e, label) {
    const { dataTypeCheckBox } = this.props;
    dataTypeCheckBox(e, label);
  }

  render() {
    const { user, callbackSuccess, selectedDataTypes } = this.props;
    const { formInitialValues, sheetDropped, isSubmitting, sheet } = this.state;
    const formConfig = {
      alert: "added",
      formId: "sg-mgmt-form-list-import",
      formUrl: `/imports/import_spreadsheet`,
      method: "POST",
      title: "Upload"
    };
    return (
      <Formik
        initialValues={{
          import: formInitialValues
        }}
        onSubmit={({ setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);
          const token = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
          this.setState({ isSubmitting: true });
          if (sheet) {
            formData.set("import[sheet_data]", sheet);
            formData.set("import[data_type]", "mixed");
          }

          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          })
            .then(response => {
              if (response.data.error == null) {
                callbackSuccess(response.data.sheet, response.data.fields);
              } else {
                // callbackFailure(response);
                setSubmitting(false);
                alert(response.data.error);
                if(!alert(response.data.error)){window.location.reload();}
              }
            })
            .catch(error => {
              if (error.response) {
                if(!alert(error.response.data.error)){window.location.reload();}
              } else {
                if(!alert(error)){window.location.reload();}
              }
            });
        }}
        render={(formikProps) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            <div className="sg-mgmt-form-container">
              <div className="sg-mgmt-form-section">
                <div className="sg-mgmt-form-row">
                  <div className="sg-mgmt-form-row-column">
                    {this.renderSheetField()}
                  </div>
                </div>
              </div>
            </div>
            <div className="sg-mgmt-form-actions import-action-button">
              {this.renderSubmitButton(
                formConfig.title,
                isSubmitting || !(sheetDropped),
                isSubmitting
              )}
            </div>
          </Form>
        )}
      />
    );
  }
}

export default ImportsUpload;
