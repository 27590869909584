export function getImportantDataFields(allFields) {
  let allImportantFields = allFields.map(fields => {
    let allModelFields = getImportantDataFieldsByModel(fields.label);
    // console.log(fields.label);
    // console.log(allModelFields);
    if (allModelFields.values.length === 0) {
      return fields;
    }
    let selectFields = fields.options.filter(option => !allModelFields.values.includes(option.value))
    return {label: fields.label, options: selectFields};
  });
  // console.log(allImportantFields);
  return allImportantFields;
}

// Add field values to use in the data mapping
// Empty values will include every field in the model
export function getImportantDataFieldsByModel(modelName) {
  const importantFields = [
    {
      label: "Company",
      values: [
        "Company.created_at",
        "Company.updated_at",
        "Company.deleted_at",
        "Company.am_updated_by_id",
        "Company.am_updated_at",

      ]
    },
    {
      label: "Contact",
      values: [
        "Contact.created_at",
        "Contact.updated_at",
        "Contact.deleted_at",
        "Contact.data",
        "Contact.created_by_id",
        "Contact.updated_by_id",
        "Contact.am_updated_by_id",
        "Contact.am_updated_at"
      ]
    },
    {
      label: "Event",
      values: [
        "Event.deleted_at",
        "Event.created_at",
        "Event.updated_at",
        "Event.circle_image_url",
        "Event.icon_file_name",
        "Event.icon_content_type",
        "Event.icon_file_size",
        "Event.icon_updated_at",
        "Event.archived_at",
        "Event.event_variable_data"
      ]
    },
    {
      label: "Nomination",
      values: [
        "Nomination.created_at",
        "Nomination.updated_at",
        "Nomination.deleted_at",
        "Nomination.status_changed_when",
        "Nomination.status_changed_by_id",

      ]
    },
    {
      label: "Account Manager",
      values: [
        "User.encrypted_password",
        "User.reset_password_token",
        "User.reset_password_sent_at",
        "User.remember_created_at",
        "User.sign_in_count",
        "User.current_sign_in_at",
        "User.last_sign_in_at",
        "User.current_sign_in_ip",
        "User.last_sign_in_ip",
        "User.confirmation_token",
        "User.confirmed_at",
        "User.confirmation_sent_at",
        "User.unconfirmed_email",
        "User.failed_attempts",
        "User.unlock_token",
        "User.locked_at",
        "User.created_at",
        "User.updated_at",
        "User.deleted_at",
        "User.profile_image_file_name",
        "User.profile_image_content_type",
        "User.profile_image_file_size",
        "User.profile_image_updated_at",
        "User.tutorial_completed_at"
      ]
    }
  ];
  return importantFields.filter(fields => fields.label === modelName)[0];
}

export function getRequiredDataFieldsByModel(modelName) {
  const requiredFields = [
    {
      label: "Company",
      values: [
        "Company Name",
        "Company ABX Tier",
        "Company Sales Coverage",
        "Company Sales Sub Coverage",
        "Company Sales Level 1"
      ]
    },
    {
      label: "Contact",
      values: [
        "Contact First Name",
        "Contact Last Name",
        "Contact Email",
        "Company Name"
      ]
    },
    {
      label: "Event",
      values: [
        "Event Name"
      ]
    },
    {
      label: "Nomination",
      values: [
        "Nomination Status",
        "Contact Email",
        "Event Name"
      ]
    },
    {
      label: "User",
      values: [
        "User Email",
        "User First Name",
        "User Last Name"
      ]
    }
  ];
  return requiredFields.filter(fields => fields.label === modelName)[0];
}

export function getStaticRequiredFields() {
  return ['Event.name', 'Nomination.status', 'Contact.email', 'Contact.first_name','Contact.last_name', 'Company.name']
}