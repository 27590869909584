import React from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import ReportFilterChain from "./ReportFilterChain";

class ReportFilters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chains: [],
      chainCount: 0,
      initialChainsImported: false
    }

    this.addChain = this.addChain.bind(this);
    this.updateChain = this.updateChain.bind(this);
    this.importChains = this.importChains.bind(this);
    this.refreshChains = this.refreshChains.bind(this);
    this.deleteChainAtIndex = this.deleteChainAtIndex.bind(this);
  }

  componentDidMount() {
    this.importChains();
  }

  componentDidUpdate(prevProps) {
    const { options, initialFilterChains } = this.props;
    if (options !== prevProps.options && Array.isArray(options) && options.length > 0 && initialFilterChains) {
      // Broken
      // this.refreshChains();
    }
  }

  // This doesn't work because a filter's selectedOption becomes undefined within this method for god knows what reason,
  // because Javascript is a trash language.
  // cloneDeep doesn't seem to help
  refreshChains() {
    const chains = cloneDeep(this.state.chains);
    const { options } = this.props;
    const selectedOptions = options.map(opt => opt.value)
    const newChains = chains;
    newChains.forEach(chain => {
      const newFilters = chain.filters.filter(f => selectedOptions.includes(f.selectedOption.value));
      chain.filters = newFilters;
    });
    newChains = newChains.filter(chain => chain.filters.length > 0);  // probably incorrect, rest of function broken so can't test
    this.setState({ chains: newChains });
  }

  importChains() {
    const { initialFilterChains } = this.props;
    const { initialChainsImported } = this.state;
    if (!initialChainsImported) {
      initialFilterChains.forEach((chain, index) => {
        this.addChain({count: index + 1, filters: chain.filters, id: chain.id})
      });
      this.setState({
        chainCount: initialFilterChains.length,
        initialChainsImported: true
      });
    }
  }

  updateChain(chain) {
    const { updateFilterChains } = this.props
    const { chains } = this.state;
    const newChains = chains;
    newChains.forEach(item => {
      if (item.id === chain.id) {
        item.filters = chain.filters;
      }
    });
    updateFilterChains(newChains);
    this.setState({ chains: newChains })
  }

  deleteChainAtIndex(index) {
    const { updateFilters } = this.props
    const { chains } = this.state;
    let newChains = [];
    chains.forEach(filter => {
      if (chain.count !== index) {
        newChains.push(chain);
      }
    });
    this.setState({ chains: newChains });
  }

  addChain({count = null, filters = []}) {
    const { chains, chainCount } = this.state;
    const newChains = chains;
    const newId = count || chainCount + 1;
    newChains.push({count: newId, id: newId, filters: filters});
    this.setState({
      chains: newChains,
      chainCount: chainCount + 1
    });
  }

  renderOr() {
    return <div className="report-tool-filter-chain-or">OR</div>
  }

  renderFilterChains() {
    const { options } = this.props;
    const { chains } = this.state;
    const operations = [
      {value: 'equals', label: 'Includes (text)'},
      {value: 'less', label: 'Less than (integer)'},
      {value: 'greater', label: 'Greater than (integer)'}
    ]
    return chains.map((chain, index) => {
      return (
        <div key={`filter-chain-${chain.id}`}>
          <div
            key={`filter-chain-item-${chain.id}`}
            style={{display: "flex", alignItems: "baseline"}}
            className="report-tool-filter-chain-item"
          >
            <ReportFilterChain
              count={chain.count}
              id={chain.id}
              key={`filter-chain-${chain.id}`}
              options={options}
              operationOptions={operations}
              index={chain.id}
              chain={chain}
              updateChain={this.updateChain}
              deleteIndex={this.deleteChainAtIndex}
            />
          </div>
          {this.renderOr()}
        </div>
      );
    });
  }

  render() {
    return (
      <div className="report-tool-filter-wrapper">
        {this.renderFilterChains()}
        <div className="report-tool-modifier-link" onClick={this.addChain}>Add filter chain</div>
      </div>
    );
  }
}

export default ReportFilters;
