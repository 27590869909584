import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

class ReportFilterField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
    this.setFilterOption = this.setFilterOption.bind(this);
  }

  setFilterOption(key) {
    const { filter, updateAllCriteria, index } = this.props;
    updateAllCriteria({ selectedOption: key, operation: filter.operation, operationValue: filter.operationValue, count: index })
  }

  setFilterOperation(key) {
    const { filter, updateAllCriteria, index } = this.props;
    updateAllCriteria({ selectedOption: filter.selectedOption, operation: key, operationValue: filter.operationValue, count: index })
  }

  setFilterValue(key) {
    const { filter, updateAllCriteria, index } = this.props;
    updateAllCriteria({ selectedOption: filter.selectedOption, operation: filter.operation, operationValue: key.target.value, count: index })
  }

  render() {
    const { options, operationOptions, index, deleteIndex, filter } = this.props;
    const { ascending } = this.state;
    return (
      <>
        <Select options={options} value={filter.selectedOption} onChange={(key) => {this.setFilterOption(key)}} />
        <Select options={operationOptions} value={filter.operation} onChange={(key) => {this.setFilterOperation(key)}} />
        <input type="text" className="report-tool-input" style={{marginBottom: "0px"}} placeholder="..value.." value={filter.operationValue} onChange={(key) => {this.setFilterValue(key)}}/>
        <div className="report-tool-delete-modifier" onClick={() => {deleteIndex(index)}}>
          <img src="/images/report-tool/form-icons/icon-close-grey.svg" />
        </div>
      </>
    );
  }
}

export default ReportFilterField;
