import React from "react";
import PropTypes from "prop-types";
import { isFunction } from "@utility/ProgramUtils.jsx"

class UniversalOpenableContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };

    this.toggleOpen = this.toggleOpen.bind(this);
  }

  renderBody() {
    const { content } = this.props;
    const { open } = this.state;
    if(open) {
      if(isFunction(content)) {
        return (
          <div className="cnt-default-layout-universal-openable-body">
            {content()}
          </div>
        )
      }
      return (
        <div className="cnt-default-layout-universal-openable-body">
          {content}
        </div>
      )
    }
  }

  renderArrow() {
    const { open } = this.state;
    return (
      <div className={`cnt-default-layout-universal-openable-header-arrow ${open ? "open" : "closed"}`}>
        <img src="/layout/openable-arrow.svg" />
      </div>
    )
  }

  toggleOpen() {
    const { open } = this.state;
    this.setState({
      open: !open
    })
  }

  render() {
    const { header } = this.props;
    return (
      <div className="cnt-default-layout-universal-openable-container">
        <div onClick={this.toggleOpen} className="cnt-default-layout-universal-openable-header">
          {header}
          {this.renderArrow()}
        </div>
        {this.renderBody()}
      </div>
    );
  }
}

export default UniversalOpenableContainer;
