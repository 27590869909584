import React from "react";
import PropTypes from "prop-types";
import NominationComment from "@nominations/comments/NominationComment"

class ToggleableCommentContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open != null ? props.open : true,
    };

    this.toggleOpen = this.toggleOpen.bind(this);
  }

  renderComments() {
    const { comments } = this.props;
    const { open } = this.state;
    if(open) {
      return (
        <>
          {comments.sort((a,b) => new Date(a) > new Date(b) ? 1 : -1).map(x=>
            <NominationComment comment={x}/>
          )}
          {!comments || comments.length == 0 ? (
            <div className="cnt-default-layout-nomination-comment">
              <div className="cnt-default-layout-nomination-comment-info">
                <i>There are no comments yet.</i>
              </div>
            </div>
          ):""}
        </>
      )
    }
    return "";
  }

  toggleOpen() {
    const { open } = this.state;
    this.setState({
      open: !open
    })
  }

  render() {
    const { open } = this.state;
    return (
      <div className="cnt-default-layout-toggleable-comment-container">
        <div onClick={this.toggleOpen} className="cnt-default-layout-nominations-toggleable-comments-header">
          Comments
          <div className={`cnt-default-layout-nominations-form-comments-header-icon ${open ? "open" : "closed"}`}/>
        </div>
        {this.renderComments()}
      </div>
    );
  }
}

export default ToggleableCommentContainer;
