import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";

class LogItemLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      finished: false,
    };
  }

  componentDidMount() {
    this.getLogs();
  }

  getLogs() {
    const { finished } = this.state;
    if(!finished) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch(this.getLogEndpoint(), {
        method: "GET",
        redirect: "manual",
        body: null,
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            name: json.name,
            finished: true
          })
        }
        else {
          this.setState({
            finished: true
          })
        }
      });
    }
  }


  getLogEndpoint() {
    const { id, fieldName } = this.props;
    return `/api/v1/updates/get_association?id=${id}&field_name=${fieldName}`;
  }

  getItemName() {
    const { name, finished } = this.state;
    const { id } = this.props;
    if(name){return name}
    else if(finished) { return `Could not find (ID#${id})`}
    else {return `Loading (ID# ${id})`}
  }

  render() {
    const { user } = this.props;
    return (
      <span className="cnt-default-layout-log-table-name">
        {this.getItemName()}
      </span>
    );
  }
}

export default LogItemLoader;
