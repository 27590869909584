import React from "react";
import PropTypes from "prop-types";
import {getRoleName} from "@utility/PermissionUtility"
class UserCondensedView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderField = this.renderField.bind(this);
    this.renderSections = this.renderSections.bind(this);
  }

  getFieldSections() {
    return [
      {
        name: "User Information",
        fields: [
          {id: 'first_name', name: 'First Name'},
          {id: 'middle_name', name: 'Middle'},
          {id: 'last_name', name: 'Last Name'},
          {id: 'email', name: 'Email', render: (x)=>(
            <a href={`mailto: ${x.email}`}>
              {x.email}
            </a>
          )},
          {id: 'role', name: 'Nomination Role', render: (x)=> {return getRoleName(x.role)}},
          {id: 'sales_role', name: 'Sales Role'},
          {id: 'phone_number', name: 'Phone Number'},
          {id: 'job_title', name: "Job Title"}
        ]
      }
    ]
  }

  getValidFieldSections() {
    const { user } = this.props;
    let fieldSections = this.getFieldSections();
    fieldSections = fieldSections.map(section => {
      section.fields = section.fields.filter(field=>
        user[field.id]
      )
      return section;
    }).filter(section  => section.fields.length > 0);
    return fieldSections;
  }


  renderSections() {
    const sections = this.getValidFieldSections();
    return sections.map(section=> (
      <div className="cnt-condensed-model-section">
        <div className="cnt-condensed-model-section-header">
          {section.name}
        </div>
        <div className="cnt-condensed-model-section-fields">
          {section.fields.map((field)=>this.renderField(field))}
        </div>
      </div>
    ))
  }

  renderField(field) {
    const { user } = this.props;
    return (
      <div className="cnt-condensed-modal-section-field">
        <div className="cnt-condensed-modal-section-field-name">
          {field.name}:{" "}
        </div>
        <div className="cnt-condensed-modal-section-field-value">
          {field.render ? field.render(user) : user[field.id]}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="cnt-condensed-model-container">
        {this.renderSections()}
      </div>
    );
  }
}

export default UserCondensedView;
