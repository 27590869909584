import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

class UserDeleteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    }
    this.toggleModal = this.toggleModal.bind(this);
  }

  getQualifyingQuestion() {
    const { event } = this.props;
    if(event && event.award_criteria && event.award_criteria.questions) {
      return event.award_criteria.questions[0];
    }
    return "";
  }

  renderModal() {

    return (
      <div className="row">
        <div className="col-xs-12 ">
          <div className="cnt-default-layout-event-criteria-modal-header">
            Nomination Criteria
          </div>
          <div className="cnt-default-layout-event-criteria-modal-criteria">
            - Must be a CEO
            <br/>
            - Must have attended at least 1 CIO event
            <br/>
            - Company wallet share of $3B
            <br/>
            - Company revenue over $5B
            <br/>
            <br/>
            <b> Qualifying Question: </b>
            <br/>
            {this.getQualifyingQuestion()}
          </div>
          <div className="">
            <button className="cnt-cancel-button"onClick={this.toggleModal}> Close</button>
          </div>
        </div>
      </div>
    )

  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen
    });
  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, sessions } = this.props;
    return (
      <>
        <div className={outerClassNames} style={{cursor:"pointer", display: "inline-block"}} onClick={() => this.toggleModal()} >
          {children}
        </div>
        <Modal
          className="white-background cnt-user-admin-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default UserDeleteModal;
