import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import ImportsUpload from "./ImportsUpload";
import ImportsMapping from "./ImportsMapping";
import ImportsConfirmation from "./ImportsConfirmation";

class ImportsIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 0,
      sheet: null,
      fields: [],
      importMessage: "",
      selectedDataTypes: [],
      eventId: ""
    };
    this.successStage1 = this.successStage1.bind(this);
    this.successStage2 = this.successStage2.bind(this);
    this.successStage3 = this.successStage3.bind(this);
    this.setStage = this.setStage.bind(this);
    this.alertBeforeRedirect = this.alertBeforeRedirect.bind(this);
    this.goBack = this.goBack.bind(this);
    this.setEvent = this.setEvent.bind(this);
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.alertBeforeRedirect);
  }
  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.alertBeforeRedirect);
  }

  goBack() {
    const { stage } = this.state;
    this.setState({ stage: stage - 1 });
  }

  setStage(page) {
    this.setState({ stage: page });
  }

  setEvent(id) {
    this.setState({ eventId: id });
  }

  alertBeforeRedirect(e) {
    const { stage } = this.state;
    if (stage === 2) {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.returnValue = "Are you sure you want to leave. There may be unsaved data";
    }
  }

  successStage1() {
    this.setState({
      stage: 1
    });
  }

  successStage2(sh, fld) {
    this.setState({
      sheet: sh,
      fields: fld,
      stage: 2
    });
  }

  successStage3() {
    this.setState({
      stage: 3,
      importMessage: "Import was successful"
    });
  }

  renderImportStage() {
    const { event } = this.props;
    const { stage, sheet, importMessage, fields, selectedDataTypes, eventId } = this.state;
    switch (stage) {
      case 0:
        return (
          <ImportsConfirmation {...this.props} callbackSuccess={this.successStage1} />
        );
      case 1:
        return (
          <ImportsUpload {...this.props} callbackSuccess={this.successStage2} />
        );
      case 2:
        return (
          <ImportsMapping {...this.props} callbackSuccess={this.successStage3} sheet={sheet} fields={fields} setStage={this.setStage} setEvent={this.setEvent} />
        );
      case 3:
        return (
          <>
            <b>Your nomination data has been imported successfully!</b>
            <br />
            {
              event ?
              <>Please go to the <a href={`/events/${event.id}`}>event</a> page to review.</>
              :
              <>Please go to the <a href={`/events/${eventId}`}>event</a> page to review.</>
            }
          </>
        );
      default:
        console.log("Invalid stage");
        return <></>;
    }
  }

  renderImportSubheader() {
    const { event } = this.props;
    const { stage } = this.state;
    switch (stage) {
      case 0:
        return (
          <div style={{fontSize: "14px"}}>
            <b>Before importing your data, please make sure you are using the spreadsheet template provided.</b>
            <br />
            {
              event ?
              <>You can download the <a href={`/imports/download_example_sheet?event_id=${event.id}`} target="_blank">AM Upload Template</a> or the <a href={`/imports/download_example_sheet?complete=true&event_id=${event.id}`} target="_blank">Verified Upload Template</a>.</>
              :
              <>You can download the template <a href={`/spreadsheet_examples/nomination_template_sheet_test_f1.xlsx`} target="_blank">here</a>.</>
            }
          </div>
        );
      case 1:
        return (
          <div style={{fontSize: "14px"}}>
            <div className="importer-back" onClick={this.goBack}>{"< Go back"}</div>
            Please upload your spreadsheet.
          </div>
        )
      case 2:
        return (
          <div style={{fontSize: "14px"}}>
            <div className="importer-back" onClick={this.goBack}>{"< Go back"}</div>
          </div>
        );
      default:
        console.log("Invalid stage");
        return <></>;
    }
  }

  render() {
    return (
      <DefaultLayout
        {...this.props}
        sidebarId="reports"
      >
        <div style={{maxWidth: "100%"}}>
          <h1 className="cnt-page-header">
            Importer
          </h1>
          {this.renderImportSubheader()}
          {this.renderImportStage()}
        </div>
      </DefaultLayout>
    );
  }
}

export default ImportsIndex;
