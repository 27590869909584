
export function getPaths() {
  return {
    "dashboard":{url:"/", name:"My Dashboard"},
    "events":{url:"/events", name:"Events"},
    "contacts":{url:"/contacts", name:"Contacts"},
    "companies":{url:"/companies", name:"Companies"},
    "users":{url:"/admin/users", name:"Users"},
  }
}

export function getPathById(id) {
  return getPaths()[id]
}

export function getPath(path) {
  if(typeof path == "string") {
    return getPathById(path)
  }
  if(typeof path == "object") {
    //TODO
  }
}


export function generateBreadCrumbObjectById(id) {
  return getPathById(id) || {};
}
