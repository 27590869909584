import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import {isJaguar} from "@utility/PermissionUtility"
import Select from 'react-select-virtualized'
import { fetchAPI } from "@utility/NetworkUtils";
import { Formik, Field, Form } from "formik";
import Checkbox from "@layout/forms/Checkbox"
class EventTokenForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: props.token,
      save_message: null,
    };
    this.createToken = this.createToken.bind(this);
  }

  renderCreateEventTokenButton() {
    return (
      <div className="col-xs-12">
        <button onClick={this.createToken} style={{marginLeft:"0"}}type="button" class="cnt-submit-button"> Create New Token </button>
      </div>
    )
  }

  createToken() {
    const { event } = this.props;
    fetchAPI(`/events/${event.id}/core_tokens/`, (json) => {
      if(json.token) {
        this.updateTokenState(json.token);
      }
    }, {
      method:"POST",
      body:{}
    });
  }

  renderField(name,formikProps, properties = {}) {
    const { user } = this.props;
    const { checkError } = this.state;
    const required = false
    const erroring = false

    return (
      <Field
        onChange={(e) => {
            const { token } = formikProps.values;
            token[name] = e.target.value
            formikProps.setFieldValue('token', token);
            this.setState({
              dirty: true,
            });
          }
        }
        component={properties['component']}
        className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`token[${name}]`}
      />
    )
  }

  renderEventTokenForm() {
    const { token, save_message} = this.state;
    return (
      <Formik
        initialValues={{
          token: {
            token: token.token,
            invite_gids: token.invite_gids || "",
            active: token.active,
            feature_preregister_users: token.feature_preregister_users,
            default_ticket_type: token.default_ticket_type,
            override_api_host: token.override_api_host
          },
        }}
        onSubmit={(values, actions) => {
          const { event } = this.props;
          this.setState({
            save_message: null,
          })
          fetchAPI(`/events/${event.id}/core_tokens/`, (json) => {
            if(json.token) {
              this.updateTokenState(json.token);
            }
            this.setState({
              save_message: json.error ? json.error : "Saved Successfully",
            })
          }, {
            method:"PATCH",
            body:{token: values.token}
          });
        }}
        render={(formikProps) => (
          <>
            <div className="col-xs-12">
              <div className="cnt-default-layout-event-form-input-container">
                <label className="required">
                  Token
                </label>
                {this.renderField('token', formikProps)}
              </div>
            </div>
            <div className="col-xs-12">
              <div className="cnt-default-layout-event-form-input-container">
                <label className="required">
                  Invite GIDs (Comma Seperated)
                </label>
                {this.renderField('invite_gids', formikProps)}
              </div>
            </div>
            <div className="col-xs-12">
              <div className="cnt-default-layout-event-form-input-container">
                <label className="required">
                  Default Ticket Type
                </label>
                {this.renderField('default_ticket_type', formikProps)}
              </div>
            </div>

            <div className="col-xs-12">
              <div className="cnt-default-layout-event-form-input-container">
                <label className="required">
                  Override API Host (e.g https://{"<core>"}.silentgrowl.com)
                </label>
                {this.renderField('override_api_host', formikProps)}
              </div>
            </div>

            <div className="col-xs-12">
              <br/>
              <Checkbox
                value={!!formikProps.values['token']['active']}
                onClick={()=>{
                  let nO = formikProps.values['token'];
                  let value = nO['active']
                  nO['active']= !value;
                  formikProps.setFieldTouched('token', nO);
                  this.setState({
                    dirty: true,
                  });
                }}
                label={"Active"}
              />
            </div>


            <div className="col-xs-12">
              <br/>
              <Checkbox
                value={!!formikProps.values['token']['feature_preregister_users']}
                onClick={()=>{
                  let nO = formikProps.values['token'];
                  let value = nO['feature_preregister_users']
                  nO['feature_preregister_users']= !value;
                  formikProps.setFieldTouched('feature_preregister_users', nO);
                  this.setState({
                    dirty: true,
                  });
                }}
                label={"Feature: Pre-register Users"}
              />
            </div>
            <div className="col-xs-12">
              <button
                type="button"
                onClick={() => formikProps.handleSubmit()}
                className="cnt-submit-button"
              > Save </button>
              <br/>
              {save_message ? save_message : ""}
            </div>
          </>
        )}
      />
    )

  }

  updateTokenState(token) {
    const { onChangeToken } = this.props;
    if(onChangeToken) {
      onChangeToken(token);
    }
    this.setState({
      token
    })
  }

  render() {
    const { user } = this.props;
    const { token } = this.state;
    if(!isJaguar(user)){
      return ""
    }
    return (
      <div className="cnt-event-token-form row">
        <div className="cnt-event-token-form-header col-xs-12">
          Core Token
          <span> (Jaguars Only) </span>
        </div>
        <div className="cnt-event-token-form-body">
          {token ? this.renderEventTokenForm() : this.renderCreateEventTokenButton()}
        </div>
      </div>
    )
  }
}

export default EventTokenForm;
