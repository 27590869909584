import { canApproveNomination } from "@utility/PermissionUtility";
import Cookies from "universal-cookie";

export function getNominationQuestionsAndAnswers(nomination, event){
  const questions = event && event.award_criteria && event.award_criteria.questions ? event.award_criteria.questions : [];
  const answers = nomination.award_criteria_answers && nomination.award_criteria_answers.answers ? nomination.award_criteria_answers.answers : []
  let returnableObject = questions.map(question => {
    const answer = answers.find(x=> x.id == question.id) || {}
    return {
      question: question || {},
      answer: answer || {}
    }
  }).filter(qanda =>{
    if(qanda.question.hidden && !qanda.answer.answer){
      return false;
    }
    return true;
  })
  return returnableObject
}

export function getAllNominationStatuses() {
  return [
    {id: 'draft', name:'Draft'},
    {id: 'submit', name:'Submitted', actionName: 'Submit'},
    {id: 'approved', name:'Approved', actionName: 'Approve'},
    {id: 'rejected', name:'Denied', actionName: 'Deny'},
    {id: 'in_review', name:'In Review', actionName: 'Reviewed'}
  ]
}

export function setNominationReview(nomination,inReviewCallback = null) {
  const cookies = new Cookies();
  const token = cookies.get("X-CSRF-Token");
  fetch(`/nominations/${nomination.id}/review`, {
    method: "post",
    redirect: "manual",
    body: JSON.stringify({}),
    headers: {
      "X-CSRF-Token": token,
      "Content-Type": 'application/json'
    }
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        if(json.redirect_url) {
          if(inReviewCallback) {
            inReviewCallback(nomination)
          }else {
            window.location = json.redirect_url
          }
        }else {
          console.log("Successful no redirect")
        }
      }
    });
}

export function getAllInviteStatuses() {
  return [
    {id: 'waitlisted', name: "Waitlisted"},
    {id: "invited", name: "Invited"},
    {id: "registered", name: "Registered"},
    {id: "declined", name: "Declined"},
    {id: "deadline_passed", name: "Deadline Passed"},
    {id: "opt_out", name: "Opt Out"},
    {id: "bounced", name: "Bounced"},
    {id: "pending_invite", name: "Pending Invite"},
    {id: "vip", name: "VIP"},
  ]
}

export function getAllAttendenceStatuses() {
  return [
    {id: "attended", name: "Attended"},
    {id: "did-not-attend", name: "Not attended"},
    {id: "no-show", name: "No Show"},
    {id: "cancellation", name: "Cancellation"},
  ]
}


export function getReadableNominationStatus(status) {
  const statuses = getAllNominationStatuses()
  const statusObjectIndex = statuses.findIndex(x=>x.id == status)
  if(statusObjectIndex > -1) {
    return statuses[statusObjectIndex].name
  }
  return status.replaceAll("_"," ");
}


export function getNominationTableMultiSelectOptions(user) {
  return [
    {
      name: "Change nom status",
      id: "status",
      options: getAllNominationStatuses().map(x=>{
        return {value: x.id,label: x.name}
      }),
      hidden: !canApproveNomination(user)
    },
    {
      name: "Change invite status",
      id: "invite_status",
      options: getAllInviteStatuses().map(x=>{
        return {value: x.id,label: x.name}
      })
    },
    {
      name: "Change attendance status",
      id: "attendence_status",
      inputStyle: {minWidth: "13em"},
      options: getAllAttendenceStatuses().map(x=>{
        return {value: x.id,label: x.name}
      })
    },
    {
      name: "More options",
      id: "other",
      options: [{label: "Delete", value: 'delete'}]
    }
  ].filter(x=> !x.hidden)
}
