import React from "react";
import ReportSortingCriteriaField from "./ReportSortingCriteriaField";

class ReportSortingCriteria extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortingCriteria: [],
      criteriaCount: 0
    }
    this.addSortingCriteria = this.addSortingCriteria.bind(this);
    this.updateAllCriteria = this.updateAllCriteria.bind(this);
    this.deleteCriteriaAtIndex = this.deleteCriteriaAtIndex.bind(this);
  }

  componentDidMount() {
    const { initialCriteria, report } = this.props;
    if (report) {
      initialCriteria.forEach((s, index) => { this.addSortingCriteria(index); });
      initialCriteria.forEach((s, index) => { this.updateAllCriteria({ ascending: s.ascending, count: index, selectedOption: { value: s.selected_option, label: s.selected_option }})});
    }
  }

  // options not available during componentDidMount, so update criteria with labels from options as soon as the options prop arrives
  componentDidUpdate(prevProps) {
    const { initialCriteria, options } = this.props;
    if ( prevProps.options !== options && Array.isArray(options) && options.length !== 0 && initialCriteria) {
      initialCriteria.forEach((s, index) => {
        const option = options.find(ele => ele.value === s.selected_option);
        this.updateAllCriteria({ ascending: s.ascending, count: index, selectedOption: { value: option.value, label: option.label }});
      });
    }
  }

  updateAllCriteria(criteria) {
    const { updateSorting } = this.props;
    const { sortingCriteria } = this.state;
    const newSortingCriteria = sortingCriteria;
    newSortingCriteria.forEach(item => {
      if (item.count === criteria.count) {
        item.selectedOption = criteria.selectedOption;
        item.ascending = criteria.ascending;
      }
    });
    updateSorting(newSortingCriteria);
    this.setState({ sortingCriteria: newSortingCriteria })
  }

  deleteCriteriaAtIndex(index) {
    const { updateSorting } = this.props;
    const { sortingCriteria } = this.state;
    let newCriteria = [];
    sortingCriteria.forEach(criteria => {
      if (criteria.count !== index) {
        newCriteria.push(criteria);
      }
    });
    updateSorting(newCriteria);
    this.setState({ sortingCriteria: newCriteria });
  }

  addSortingCriteria({count = -1}) {
    const { sortingCriteria, criteriaCount } = this.state;
    const newCriteria = sortingCriteria;
    newCriteria.push({ascending: true, selectedOption: {label: 'Select a column', value: 'select a field'}, count: count === -1 ? criteriaCount : count});
    this.setState({
      sortingCriteria: newCriteria,
      criteriaCount: criteriaCount + 1
    });
  }

  renderSortingCriteria() {
    const { options } = this.props;
    const { sortingCriteria } = this.state;
    const renderedCriteria = sortingCriteria.map( (criteria, index) =>
      <div className="report-tool-sorting-criteria-item" key={`sort-criteria-item-${criteria.count}`} style={{display: "flex", alignItems: "baseline"}}>
        <ReportSortingCriteriaField key={`sort-criteria-${criteria.count}`} options={options} index={criteria.count} criteria={criteria} updateAllCriteria={this.updateAllCriteria} deleteIndex={this.deleteCriteriaAtIndex}/>
      </div>
    );
    return renderedCriteria;
  }

  render() {
    return (
      <div className="report-tool-sorting-criteria-wrapper">
        {this.renderSortingCriteria()}
        <div className="report-tool-modifier-link" onClick={this.addSortingCriteria}>Add sorting criteria</div>
      </div>
    );
  }
}

export default ReportSortingCriteria;
