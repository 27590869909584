import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import   { formatToTimeZone } from 'date-fns-timezone';
import { getReadableNominationStatus } from "@utility/NominationUtility"
class NominationStatusBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  renderStatusChangedBy() {
    const { nomination } = this.props;
    if( nomination.status == 'approved' || nomination.status == 'rejected' || nomination.status =="in_review") {
      return (
        <>
          <div className="cnt-nomination-status-block-field">
            <b>
              {nomination.status == "approved" ? "Approved by: ":""}
              {nomination.status == "rejected" ? "Denied by: ":""}
              {nomination.status == "in_review" ? "Being reviewed by: ":""}
            </b>
            {nomination.status_changed_by ? (
              <a href={`mailto: ${nomination.status_changed_by.email}`}>{nomination.status_changed_by.first_name} {nomination.status_changed_by.last_name}</a>
            ) : (<a href="#">Import</a>)}
          </div>
          {nomination.status_changed_when ? (
            <div className="cnt-nomination-status-block-field ">
              {
                formatToTimeZone(new Date(nomination.status_changed_when),'MM/DD/YYYY, hh:MM A', {timeZone: 'America/Los_Angeles'})
              }
            </div>
          ) : ""}

          <div className="cnt-nomination-status-block-field">
            {
              nomination.status_changed_reason
            }
          </div>

        </>
      )
    }
  }

  renderSubmittedBy() {
    const { nomination, showSubmission } = this.props;
    if(showSubmission && nomination.user) {
      return (
        <>
        <div className="cnt-nomination-status-block-field cnt-nomination-status-block-field-submitted-by">
          <b>
            Submitted by:{" "}
          </b>
          {nomination.user ? (
            <a href={`mailto: ${nomination.user.email}`}>{nomination.user.first_name} {nomination.user.last_name}</a>
          ) : (<a href="#">Import</a>)}
        </div>
        {nomination.created_at ? (
          <div className="cnt-nomination-status-block-field" style={{paddingBottom: "7px"}}>
            {
              formatToTimeZone(new Date(nomination.created_at),'MM/DD/YYYY, hh:MM A', {timeZone: 'America/Los_Angeles'})
            }
          </div>
        ) : ""}
        </>
      )
    }
  }

  renderChanges() {
    let { versions } = this.props;
    if(versions) {
      console.log(versions);
      //Filter only changes where the status changes
      let new_versions = versions.filter((x,i,t)=> i != 0 && i != t.length -1).filter(x=>
        x.object_changes && x.object_changes.status &&
        (
          x.object_changes.status[1] == 'declined' ||
          x.object_changes.status[1] == 'approved' ||
          x.object_changes.status[1] == 'in_review'
        )
        &&
        x.object_changes.status[1] != x.object_changes.status[2]
      );

      new_versions = new_versions.map(x=>{
        x.version_metadata = versions.find(y => y.id == x.id + 1)
        if(x.version_metadata) {
          if(!x.object_changes.status_changed_by_id) {
            x.object_changes.status_changed_by_id = x.version_metadata.object_changes.status_changed_by_id
          }
          if(!x.object_changes.status_changed_reason) {
            x.object_changes.status_changed_reason = x.version_metadata.object_changes.status_changed_reason
          }
        }
        return x
      }).reverse();

      if(versions  && versions.length > 0) {
        return (
          <>
            <b>Previous Actions:</b>
            {new_versions.map(x=> this.renderChange(x))}
          </>
        );
      }
    }
  }

  renderChange(version) {
    console.log(version);
    return (
      <>
        <div className="cnt-nomination-status-block-field cnt-nomination-status-block-field-submitted-by">
          <b>
            Status Changed to: {getReadableNominationStatus(version.object_changes.status[1])}
          </b>
        </div>
        <div className="cnt-nomination-status-block-field" style={{paddingBottom: "7px"}}>
          {
            formatToTimeZone(new Date(version.created_at),'MM/DD/YYYY, hh:MM A', {timeZone: 'America/Los_Angeles'})
          }
        </div>
        <div className="cnt-nomination-status-block-field">
          {
            version.object_changes.status_changed_reason
          }
        </div>

      </>
    )
  }

  render() {
    const { nomination, showSubmission } = this.props;
    if(nomination.status == 'in_review' || nomination.status == 'approved' || nomination.status == 'rejected' || (showSubmission && nomination.user)) {
      return (
        <div className="cnt-nomination-status-block">
          <div className="cnt-nomination-status-block-header">
            Status Info
          </div>
          {this.renderStatusChangedBy()}
          {this.renderSubmittedBy()}
          {this.renderChanges()}
        </div>

      );
    }
    return (
      <></>
    )
  }
}

export default NominationStatusBlock;
