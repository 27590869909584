import React from "react";
import PropTypes from "prop-types";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import Modal from "react-modal";
import ReportToolSelect from './ReportToolSelect';
import ReportFieldSelect from './ReportFieldSelect';

const SortableItem = SortableElement(({item, keySelected}) => {
  return (
    <div className="report-key-wrapper">
      {item.label}
      <div onClick={() => keySelected(item)}>
        <img src="/images/report-tool/form-icons/icon-close.svg" />
      </div>
    </div>
  );
});

const SortableList = SortableContainer(({items, keySelected, testValue}) => {
  return (
    <div className="sortable-list-elements-wrapper">
      {items.map((item, index) => (
        <SortableItem key={`sortable-${item.value}`} index={index} item={item} keySelected={keySelected} />
      ))}
    </div>
  );
});

class ReportFieldsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement("body");
  }

  toggleModal() {
    const { modalOpen } = this.state;
    if (this.typeSelected()) {
      this.setState({ modalOpen: !modalOpen });
    }
  }

  typeSelected() {
    const { baseObj } = this.props;
    return baseObj && Object.keys(baseObj).length > 0;
  }

  renderModal() {
    const { baseObj, keys, selectedKeys, keySelected, massKeySelection, onSortEnd } = this.props;

    return (
      <>
        <div className="report-columns-modal-container">
          <div className="report-columns-modal-key-select">
            <div className="report-columns-modal-header">
              <h3>Add Columns</h3>
            </div>
            {/* <ReportToolSelect baseObj={baseObj} items={selectedKeys} options={keys} onChange={(key) => {keySelected(key)} } /> */}
            <ReportFieldSelect baseObj={baseObj} items={selectedKeys} options={keys} onChange={(key) => {keySelected(key)} } massKeySelection={massKeySelection} />
          </div>
          <div className="report-columns-modal-keys">
            <div className="report-columns-modal-header">
              <h3>Selected Columns</h3>
              <p>You can drag the fields to sort them in the order you want the columns to appear.</p>
            </div>
            <SortableList
              items={selectedKeys}
              keySelected={(e) => keySelected(e)}
              testValue={'testValue'}
              onSortEnd={(e) => onSortEnd(e)}
              axis="xy"
              distance={1}
            />
            <div className="report-tool-button report-tool-button-blue report-modal-button-continue" onClick={this.toggleModal}>
              Continue
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { modalOpen } = this.state;
    const btnClass = this.typeSelected() ? "report-tool-button-green" : "report-tool-button-disabled";
    return (
      <>
        <div className="report-tool-modifier-link report-tool-edit-link" onClick={this.toggleModal} disabled={this.typeSelected()}>
          Add Columns
        </div>
        <Modal
          className="white-background report-modal report-columns-modal disable-scrollbars"
          overlayClassName="report-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default ReportFieldsModal;
