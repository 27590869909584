export function canSessionBeAdded(session) {
  const { enrollment, attendee_count } = session;
  if(session.enrollment == 'closed_enroll') {
    return false;
  }
  if(attendee_count != 0 && session.capacity != 0 && session.attendee_count >= session.capacity){
    return false;
  }
  const maxCapacities = [
    {label: "Workshop", capacity: 100},
    {label: "Demo", capacity: 500},
    {label: "Meet the expert", capacity: 75},
    {label: "Collaboration topic", capacity: 75},
    {label: "Women of Sumo", capacity: 500}
  ]
  const currentCapacityObject = maxCapacities.find(x=> x.label == session.session_type);
  if (isSIEMSession(session)) {
    if (attendee_count > 32) {
      return false;
    }
    return true;
  }
  if(currentCapacityObject && attendee_count >= currentCapacityObject.capacity) {
    return false;
  }
  return true;
}

export function isInTrack(session, trackName) {
  return session.session_tracks.filter(track => track == trackName).length > 0;
}

export function isSIEMSession(session) {
  if (
    session.code === "ST-ME 02" ||
    session.code === "ST-ME 04" ||
    session.code === "ST-ME 07" ||
    session.code === "ST-ME 09" ||
    session.code === "ST-ME 02 PR" ||
    session.code === "ST-ME 04 PR" ||
    session.code === "ST-ME 07 PR" ||
    session.code === "ST-ME 09 PR"
  ) {
    return true;
  }
  return false;
}
