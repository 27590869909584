import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import NominationForm from "./forms/NominationForm"
import UniversalImageCircle from "@images/UniversalImageCircle"
import  TabbedPageContainer from "@layout/tabs/TabbedPageContainer"
import  LogTable from "@logs/LogTable"
import { isAccountManager } from "@utility/PermissionUtility"
import NominationsHistoryTable from "@nominations/tables/NominationsHistoryTable"
import ContactFormSlowLoad from "@contacts/forms/ContactFormSlowLoad";
import CompanyFormSlowLoad from "@companies/forms/CompanyFormSlowLoad";

class NominationsShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.renderLogs = this.renderLogs.bind(this);
  }

  renderLogs() {
    const { nomination } = this.props;
    return (
      <LogTable
        logType="nominations"
        logObject={nomination}
       />
    )
  }

  renderHeader() {
    const { nomination } = this.props;
    if(nomination.status == "draft") {
      return (
        <h1 className="cnt-page-header">
          Nomination Form
        </h1>
      )
    }
    return (
      <h1 className="cnt-page-header">
        Nomination Summary
      </h1>
    )
  }

  getPages() {
    const { user, nomination, contacts} = this.props;
    let pages = [
      {id: "nomination", name: nomination.status == "draft" ? "Nomination" : "Summary", content:(<NominationForm {...this.props} />)},
      {id: "contact",name: "Contact Detail  ", content:() => (
        <ContactFormSlowLoad contactId={nomination.contact_id} user={this.props.user} />
      )},
      {id: "company",name: "Company", content:() => (
        <CompanyFormSlowLoad companyId={contacts[0].company_id} user={this.props.user} />
      )}, 
      {id: "nomination-history",name: "Nomination History", content:() => (<NominationsHistoryTable contact={this.props.contacts ? this.props.contacts[0]:null} user={this.props.user} nominations={null} />)},


      {id: "log", name: "Log", content: () => this.renderLogs()}
    ]
    if(isAccountManager(user)) {
      pages = pages.filter(x=>x.id != "log");
    }
    if(nomination.status == "draft") {
      pages = pages.filter(x=>x.id != "nomination-history");
    }
    return pages
  }

  render() {
    const { user, nomination } = this.props;
    return (
      <DefaultLayout
        {...this.props}
        breadcrumbs={
          ['dashboard']
        }
        sidebarId="nominations"
      >
        {this.renderHeader()}
        <TabbedPageContainer
          pages={this.getPages()}
          default_tab_id="nomination"
        />
      </DefaultLayout>
    );
  }
}

export default NominationsShow;
