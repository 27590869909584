import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"

import NominationForm from "./forms/NominationForm"
class NominationsNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const { user } = this.props;
    return (
      <DefaultLayout
        {...this.props}
        sidebarId="nominations"
      >
        <div className="row">
          <div className="col-xs-12 col-md-8">
            <h1 className="cnt-page-header cnt-nomination-header">
              Nomination Form
            </h1>
            <NominationForm {...this.props} />
          </div>
        </div>

      </DefaultLayout>
    );
  }
}

export default NominationsNew;
