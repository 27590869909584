import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Select from 'react-select-virtualized'
import Cookies from "universal-cookie";

class NominationQuestionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    }
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen
    })
  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, question } = this.props;
    return (
      <>
        <div className={outerClassNames} style={{cursor:"pointer", display: "inline-block"}} onClick={() => this.toggleModal()} >
          {children ? (
            children
          ): (
            <img src="/images/nominations/info-icon.svg" style={{marginLeft: "5px", marginBottom: "5px"}}/>
          )
          }
        </div>
        <Modal
          className="white-background cnt-user-admin-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          <div className="cnt-default-layout-nomination-modal">
            <div dangerouslySetInnerHTML={{__html: question.custom_modal_content||""}}/>
          </div>
        </Modal>
      </>
    );
  }
}

export default NominationQuestionModal;
