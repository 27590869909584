import React from "react";
import PropTypes from "prop-types";
import {isJaguar, addMy, isAccountManager} from "@utility/PermissionUtility"
import UniversalImageCircle from "@images/UniversalImageCircle"
import GeneralTable from "@layout/tables/GeneralTable"
import CompanyProfileModal from "@companies/modals/CompanyProfileModal"
import CompanyDeleteModal from "@companies/modals/CompanyDeleteModal"
import DefaultTooltip from "@layout/tooltips/DefaultTooltip";
import SuperFilter from "@layout/superfilter/SuperFilter"
import {fetchAPI} from "@utility/NetworkUtils"
import { multiSelectCheckboxImages } from "@utility/checkboxUtility"
import Checkbox from "@layout/forms/Checkbox"
import MultiselectActionBar from "@layout/tables/MultiselectActionBar"
import { numberWithSuffix } from "@utility/NumberUtility"
import CompanyMergeModal from "@companies/modals/CompanyMergeModal"
class CompaniesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: props.companies,
      superFilters: null,
      contactCounts:null,
      selectedCompanies: [],
      accountManagers: null,
      mergingCompanyIds:[],
    };
    this.onDelete = this.onDelete.bind(this);
    this.setSuperFilters = this.setSuperFilters.bind(this);
    this.renderAccountManagers = this.renderAccountManagers.bind(this);
    this.renderWalletOppurtunity = this.renderWalletOppurtunity.bind(this);
    this.renderViewAll = this.renderViewAll.bind(this);

    this.applyMultiSelectAttributes = this.applyMultiSelectAttributes.bind(this);
    this.renderCompanyCheckbox = this.renderCompanyCheckbox.bind(this);
    this.onCheckboxClick = this.onCheckboxClick.bind(this);
    this.getSelectedItems = this.getSelectedItems.bind(this);
    this.unselectAll = this.unselectAll.bind(this);
    this.closeCompaniesMergeModal = this.closeCompaniesMergeModal.bind(this);
    this.openCompaniesMergeModal =   this.openCompaniesMergeModal.bind(this);
    this.onMergeCallback = this.onMergeCallback.bind(this);
  }

  getSelectedItems() {
    const { selectedCompanies } = this.state;
    return selectedCompanies;
  }

  setSuperFilters(filters) {
    this.setState({
      superFilters: filters
    })
  }

  unselectAll() {
    this.setState({
      selectedCompanies: []
    })
  }

  applyMultiSelectAttributes(ids, performActionCallback, values) {
    let body = {
      ids: ids,
      company: values
    }
    this.setState({
      selectedCompanies: []
    })
    fetchAPI('/api/v1/companies/update_multiple', (json) => {
      if(json.companies) {
        let {companies} = this.state;
        companies = companies.filter(x=>
          ids.indexOf(x.id) == -1
        );
        this.setState({
          companies: companies.concat(json.companies),
          error: json.error,
        })
      }
      if(performActionCallback) {
        performActionCallback(json)
      }
    }, {
      body: body,
      method: 'PATCH'
    });
  }

  getCompanyTableMultiSelectOptions() {
    const { companies, accountManagers } = this.state;
    let otherOptions = {
      name: "More options",
      id: "other",
      options: [
        {label: "Delete", value: 'delete'},
        {label: "Merge", value: 'merge', onClick: this.openCompaniesMergeModal}
      ]
    };
    let accountManagerOptions =  null;
    if(accountManagers) {
      accountManagerOptions= {
        name: 'Assign Account Manager',
        id: "account_manager_id",
        options: accountManagers.map(x=> {return{
          value: x.id,
          label:`${x.first_name} ${x.last_name}`
        }})
      }
    }
    return [accountManagerOptions, otherOptions].filter(x=>x);
  }




  renderCompanyCheckbox(row) {
    const { selectedCompanies } = this.state;
    const isChecked = selectedCompanies.map(x=>x.id).indexOf(row.id) != -1;
    return (
      <div className="cnt-nomination-table-checkbox">
        <Checkbox
          disabled={false}
          value={isChecked}
          onClick={()=>this.onCheckboxClick(row)}
          images={multiSelectCheckboxImages()}
        />
      </div>
    )
  }

  onCheckboxClick(row) {
    let { selectedCompanies } = this.state;
    const isChecked = selectedCompanies.indexOf(row.id) != -1;
    if(isChecked) {
      selectedCompanies = selectedCompanies.filter(x=> x != row.id)
    }else {
      selectedCompanies = selectedCompanies.concat([
        row
      ])
    }
    this.setState({
      selectedCompanies
    })
  }

  componentDidMount() {
    const { forceLocal } = this.props;
    if(!forceLocal) {
      fetchAPI('/api/v1/companies/contacts_count', (json) => {
        if(json.error){

        } else {
          console.log("setting counts")
          let { companies } = this.state;
          companies = companies.map(x=> {
            const compcount = json.companies.find(y=> y.id == x.id);
            if(compcount){
              x.contact_count = compcount.contact_count;
              x.users = compcount.users.filter((x,i,t)=>t.indexOf(x) == i) || [];
            }
            return x;
          })
          console.log("mapped counts")
          this.setState({
            companies: companies,
            contactCounts: true,
          });
          console.log("state set counts")
        }
      })
    }
    fetchAPI('/api/v1/users/account_manager_dropdown', (json) => {
      if(json.users) {
        this.setState({
          accountManagers: json.users.sort((a,b)=>{return `${a.first_name} ${a.last_name}`< `${b.first_name} ${b.last_name}` ? -1 : 1})
        })
      }
    })
  }


  onDelete(id, opts) {
    let { companies } = this.state;
    const originalCompany = companies.find(x=> x.id != id)
    companies = companies.filter(x=> x.id != id)

    const selectedCompanyID = opts && opts.selectedCompanyID ? opts.selectedCompanyID : null;
    if(selectedCompanyID) {
      const newCompanyIndex = companies.findIndex(x=> x.id == selectedCompanyID)
      if(newCompanyIndex > -1 && originalCompany.contact_count){
        const newCount = companies[newCompanyIndex].contact_count + originalCompany.contact_count
        let newCompany = companies[newCompanyIndex]
        newCompany.contact_count = newCount;
        companies = companies.filter(x=> x.id != selectedCompanyID).concat([
          newCompany
        ]);
      }
    }
    this.setState({
      companies
    })
  }

  getFilteredCompanies() {
    let  {companies, superFilters, contactCounts} = this.state;
    companies = companies.map(x=> {
      x.lower_name = x && x.name ? x.name.toLowerCase() : "";
      x.select = true;
      return x;
    })
    if(superFilters) {
      superFilters.map(filterObject =>{
        companies = companies.filter((x,i,t)=>filterObject.filterFunction(x,i,t))
      })
    }

    return companies;
  }


  getTableActions() {
    // return [
    //   {
    //     icon: 'save',
    //     tooltip: 'Save User',
    //     onClick: (event, rowData) => {
    //       // Do save operation
    //     }
    //   }
    // ]
    return [];
  }

  renderViewAll(row) {
    const { user } = this.props;
    const { companies } = this.state;
    return (
      <div className="cnt-default-layout-nomination-table-icons">
        <DefaultTooltip
          tooltipId={`sg-cnt-default-layout-company-table-contact-quickview-${row.id}`}
        >
          Quick View
        </DefaultTooltip>
        <CompanyProfileModal
          user={user}
          company={row}
        >
          <a
            href="#"
            data-for={`sg-cnt-default-layout-company-table-contact-quickview-${row.id}`}
            data-tip
          >
            <img src="/layout/view-icon.svg" />
          </a>
        </CompanyProfileModal>
        <DefaultTooltip
          tooltipId={`sg-cnt-default-layout-company-table-contact-edit-${row.id}`}
        >
          Full View
        </DefaultTooltip>
        <a
          data-for={`sg-cnt-default-layout-company-table-contact-edit-${row.id}`}
          data-tip
          href={`/companies/${row.id}`}
        >
          <img style={{width: "17px"}} src="/layout/review-icon.png" />
        </a>
        <DefaultTooltip
          tooltipId={`sg-cnt-default-layout-company-table-contact-delete-${row.id}`}
        >
          Delete Company
        </DefaultTooltip>
        <CompanyDeleteModal companies={companies} company={row} onDelete={this.onDelete}>
          <img
            className="sg-cnt-default-layout-table-delete-button"
            data-for={`sg-cnt-default-layout-company-table-contact-delete-${row.id}`}
            data-tip
             src="/layout/trash-icon.svg"
          />
      </CompanyDeleteModal>
      </div>
    );
  }

  renderWalletOppurtunity(row) {
    return (
      <>
        ${row.wallet_opportunity ? numberWithSuffix(row.wallet_opportunity*1000000) : ""}
      </>
    )
  }

  getFilters() {
    return [
      {id: null, name: "All", filterFunction:()=>{return true}},
      {id: 'incomplete', name: "Incomplete", filterFunction:(x,i,t)=>{ return (
        !x.name
      )}},
    ]
  }

  renderAccountManagers(row) {
    if(row.users) {
      return (<>
        {row.users.map(x=> (
          <div>
            {x.first_name} {x.last_name}
          </div>
        ))}
      </>)
    }
  }

  getColumns() {
    const { user } = this.props;
    const { contactCounts } = this.state;
    let columns = [
      { maxWidth: '35px',sorting: false, title: "", field: 'select', render: this.renderCompanyCheckbox},
      { title: 'Name', defaultSort: 'asc', field: 'lower_name',render: (row) => row.name },
      { title: 'Contact #',  field: 'contact_count'},
      { title: 'SAVM ID',  field: 'savmid'},
      { title: 'Account Manager(s)',sortable: false,  field: 'users', render: this.renderAccountManagers},
      { title: 'Wallet Opportunity', field: 'wallet_opportunity', render: this.renderWalletOppurtunity, customSort: (a,b) => {return (a.wallet_opportunity||0)-(b.wallet_opportunity||0)}},
      { export: false, title: 'Actions', field: 'id', render: this.renderViewAll}
    ]
    if(!contactCounts) {
      columns = columns.filter(x=> x.field != 'contact_count');
      columns = columns.filter(x=> x.field != 'users');
    }
    return columns;
  }

  renderSuperFilter() {
    const walletOptions = [
      {label: 'All', id: 'all', filterFunction: (x,i,t)=>{return true}},
      {label: '< 1 Million', id: 'did-not-attend', filterFunction: (x,i,t)=>{return x.wallet_opportunity < 1}},
      {label: '1 - 10 Million', id: 'attended', filterFunction: (x,i,t)=>{return x.wallet_opportunity >= 1 && x.wallet_opportunity < 10 }},
      {label: '10 - 50 Million', id: 'no-show', filterFunction: (x,i,t)=>{return x.wallet_opportunity >= 10 && x.wallet_opportunity < 50 }},
      {label: '50 - 100 Million', id: 'cancellation', filterFunction: (x,i,t)=>{return x.wallet_opportunity >= 50 && x.wallet_opportunity < 100 }},
      {label: '> 100 Million', id: 'unmarked', filterFunction: (x,i,t)=>{return x.wallet_opportunity >= 100 }},
    ];

    return(
      <>
        <SuperFilter
          onChange={this.setSuperFilters}
          filters={[
            {
              id: "wallet_opportunity",
              type:"select",
              options: walletOptions,
              outerClassName: "col-xs-12 col-md-6 col-lg-3",
              placeholder: "Wallet Opportunity"
            },
          ]}
        />
      </>
    )
  }


    openCompaniesMergeModal() {
      const { selectedCompanies } = this.state;
      this.setState({
        isMergingCompanyOpen: true,
        mergingCompanyIds:selectedCompanies
      })
    }

    closeCompaniesMergeModal() {
      this.setState({
        isMergingCompanyOpen: false,
        mergingCompanyIds: [],
      })
    }

    onMergeCallback(selectedId, rejectedIds) {
      let { companies } = this.state;
      console.log("Table On Merge")
      console.log({selectedId, rejectedIds})
      //Remove all ids returned
      companies = companies.filter(x=> rejectedIds.indexOf(x.id) == -1)
      this.setState({
        companies,
        selectedCompanies: [],
        isMergingCompanyOpen: false,
        mergingCompanyIds: [],
      })
    }

  render() {
    const { selectedCompanies, mergingCompanyIds, isMergingCompanyOpen } = this.state;
    const { user } = this.props;
    return (
      <div className="cnt-default-layout-company-table">
        <MultiselectActionBar
          selectedItems={this.getSelectedItems()}
          options={this.getCompanyTableMultiSelectOptions()}
          submitCallback={this.applyMultiSelectAttributes}
          closeCallback={this.unselectAll}
        />
        <GeneralTable
          dataUrl='/api/v1/companies/table'
          columns={this.getColumns()}
          actions ={this.getTableActions()}
          showTotals={true}
        />
        <CompanyMergeModal
          companies={selectedCompanies.filter(x=> mergingCompanyIds.indexOf(x.id) != -1)}
          openModal={this.openCompaniesMergeModal}
          closeModal={this.closeCompaniesMergeModal}
          isOpen={isMergingCompanyOpen}
          onMerge={this.onMergeCallback}
        />
      </div>
    );
  }
}

export default CompaniesTable;
