import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

class ReportPreviewValues extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  getDate(date) {
    let datetime = DateTime.fromISO(date, { zone: "utc"}).toLocal();
    let time = datetime.toFormat('hh:mm');
    let ampm = datetime.toFormat('a') === 'AM' ? 'a.m.' : 'p.m.';
    let month = datetime.toFormat('LLL').toUpperCase();
    let dayyear = datetime.toFormat('dd/yyyy');
    return (
      <>{time + ' ' + ampm}<br/>{month + '/' + dayyear}</>
    );
  }

  // removed previous date rendering code from this function, which date-ized
  // any value that DateTime.fromISO could parse. It was parsing any 5-digit
  // number as dates. TODO: pass datatypes along and use for type-specific parsing
  // here and in the report service
  readable(value, datatype) {
    if (datatype === 'datetime') {
      return this.getDate(value);
    }
    return value;
  }

  render() {
    const { row, value, keysWithDataType } = this.props;
    if (keysWithDataType[value] === undefined) {
      return (
        <>{row[value]}</>
      );
    }
    let cellValue = this.readable(row[value], keysWithDataType[value].datatype);
    return (
      <>{cellValue}</>
    );
  }
}

export default ReportPreviewValues;
