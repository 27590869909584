import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import {isJaguar, canEditEvents } from "@utility/PermissionUtility"
import Select from 'react-select-virtualized'
import { fetchAPI } from "@utility/NetworkUtils";
import { Formik, Field, Form } from "formik";
import Checkbox from "@layout/forms/Checkbox"
const EventManagerForm = ({user, event}) => {
  const [availableUsers, setAvailableUsers] = useState([]);
  const [managers, setManagers] = useState(event.managers||[]);
  const [ selectedManager, setSelectedManager] = useState(null);
  const fetchAvilableUsers = () => {
    fetchAPI(`/events/${event.id}/available_managers/`, (json) => {
      setAvailableUsers(json.managers)
    })
  }

  useEffect(() => {
    fetchAvilableUsers()
  },[])

  const renderAddManagersForm = () => {
    return (
      <div className="row">
        <div className="col-xs-6">
          <Select
            value={selectedManager}
            onChange={setSelectedManager}
            options={availableUsers.filter(x=>
              managers.map(y=>y.id).indexOf(x.id) == -1
            ).map(user => ({value:user.id, label:user.first_name+" "+user.last_name}))}
          />
        </div>
        <div className="col-xs-6">
          <button onClick={() => addManager(selectedManager.value)}>
            Add Manager
          </button>
        </div>
      </div>
    )
  }

  const renderManagers = () => {
    return managers.map(manager => (
      <div style={{marginRight:"10px"}} className="mr-1 badge badge-pill badge-primary">
        {manager.first_name} {manager.last_name}
        <button onClick={()=>removeManager(manager.id)} type="button">
          x
        </button>
      </div>
    ));
  }

  const removeManager = (id) => {
    fetchAPI(`/events/${event.id}/remove_manager/`, (json) => {
      setManagers(managers.filter(manager => manager.id != id))
    }, {
      method:"POST",
      body:{
        user_id: id
      }
    })
  }

  const addManager = (id) => {
    setSelectedManager(null)
    fetchAPI(`/events/${event.id}/add_manager/`, (json) => {
      setManagers(managers.concat([json.manager]))
    }, 
    {
      method:"POST",
      body:{
        user_id: id
      }
    })
  }

  if(canEditEvents(user)) {
    return (
      <div className="cnt-event-token-form row">
        <div className="cnt-event-token-form-header col-xs-12">
          Event Managers
        </div>
        <div className="col-xs-12">
          These users will recieve an email whenever a new nomination is submitted to this event.
          <br/>
        </div>
        <div className="col-xs-12 cnt-event-token-form-body">
          {renderAddManagersForm()}
          <div>
            <br/>
            {renderManagers()}
          </div>
        </div>
      </div>
    )
  }
}

export default EventManagerForm