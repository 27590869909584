import React from "react";

class UniversalImageCircle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.icon_colors = [
      "#EDFBFF"
    ]
  }

  renderInnerIcon() {
    const { user, fontSize, image, id, name} = this.props;
    if(image){
      return (
        <div
          className="sg-user-icon-inner"
          style={{backgroundImage: `url(${image})`, backgroundColor: "white"}}
        />
      )
    }
    else {
      return (
        <div
          className="sg-user-icon-inner"
          style={{backgroundColor: this.icon_colors[id % this.icon_colors.length], fontSize: this.getFontSize()}}
        >
          {name ? name[0] : ""}
        </div>
      )
    }
  }

  getFontSize() {
    const { fontSize, size } = this.props;
    if(fontSize) {
      return fontSize;
    }
    if(size){
      switch(size) {
        case "tiny":
          return "10px";
        case "small":
          return "22px";
        case "medium":
          return "30px";
        case "large":
          return "50px";
      }
    }
    return "16px";
  }

  render() {
    return (
      <div className="sg-user-icon">
        {this.renderInnerIcon()}
      </div>
    );
  }
}

export default UniversalImageCircle;
