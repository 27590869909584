import React from "react";
import PropTypes from "prop-types";
import { isAccountManager, canApproveNomination } from "@utility/PermissionUtility";
import Select from 'react-select-virtualized';
import Cookies from "universal-cookie";

class AttendenceStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.changeValue = this.changeValue.bind(this)
  }

  getStatus(status) {
    if(status) {
      return status;
    }
  }

  getReadableStatus(status) {
    switch(status) {
      case 'did_not_attend':
        return 'not_attended'
      case 'did-not-attend':
        return 'not_attended'
      default:
        return status
    }
  }

  changeValue(value) {
    const { updateNomination, nomination } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    if(updateNomination){
      nomination.attendence_status = value.value
      updateNomination(nomination);
    }
    fetch(`/nominations/${nomination.id}/update_status`, {
      method: 'PATCH',
      redirect: "manual",
      body: JSON.stringify(
        {
          nomination: {
            attendence_status: value.value
          }
        }
      ),
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.nomination != null) {
        if(updateNomination){
          updateNomination(json.nomination);
        }
      } else {

      }
    });
  }

  renderSelectField() {
    const { nomination } = this.props;
    return (
      <div className="cnt-default-attendee-status-ticker-select-container">
        <Select
          options={this.getSelectOptions()}
          classNamePrefix='cnt-status-select'
          isClearable={false}
          components={{
            SingleValue: (children, props)=> this.renderTicker(children.children),
            Option: (children, props)=> this.renderTicker(children.children)
          }}
          value={{value: nomination.attendence_status, label: this.capatalizeString(this.getStatus(nomination.attendence_status))}}
          onChange={this.changeValue}
        />
      </div>
    )
  }

  capatalizeString(updatable_string) {
    if(updatable_string) {
      return updatable_string.charAt(0).toUpperCase() + updatable_string.slice(1).replaceAll("_"," ").replaceAll("-"," ");
    }
    return null
  }

  renderTicker(status) {
    status = (status||"").toLowerCase().replaceAll(" ", "_")
    if(status) {
      return (
        <div className={`cnt-default-attendence-status-ticker cnt-default-attendence-status-ticker-${this.getStatus(status)}`}>
          {this.capatalizeString(this.getReadableStatus(status))}
        </div>
      );
    }
    return "--"
  }

  getSelectOptions() {
    return [
      null,
      "attended",
      "did-not-attend",
      "no-show",
      "cancellation"
    ].map(x=> {return {
      value: x,
      label: this.capatalizeString(this.getReadableStatus(x ||"")) || ""
    }})
  }


  render() {
    const { nomination, user } = this.props;
    if(user && canApproveNomination(user)) {
      return this.renderSelectField();
    }
    return this.renderTicker(nomination.attendence_status)
  }
}

export default AttendenceStatus;
