import React from "react";
import PropTypes from "prop-types";
import { isExecutiveRelationsLead, isJaguar, canEditUsers,isSolutionsLead, isMarketingManager, isAccountManager, addMy} from "@utility/PermissionUtility"
import UniversalImageCircle from "@images/UniversalImageCircle"
import GeneralTableDropdown from "@layout/tables/GeneralTableDropdown"
import eventBus from "@tutorial/EventBus"
import TutorialBox from "@tutorial/TutorialBox"
class DefaultLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLinkId: props.sidebarId
    };
  }


  renderUserManagement(){
    const { user } = this.props;
    if(canEditUsers(user)) {
      return (
        <>
        <li>
            <a href="/admin/users">User Management</a>
        </li>
        </>
      )
    }
  }

  renderSidebarOptions() {
    const { user } = this.props;
    const {activeLinkId} = this.state;
    return this.getSidebarLinks().map(link=>{
      return (
        <li key={`cnt-sidebar-link-${link.id}`}>
          <a className={`cnt-default-layout-sidebar-link ${activeLinkId == link.id ? "active" : ""} ${link.classNames ? link.classNames : ""}`} href={link.url}>
            <img className={`cnt-default-layout-sidebar-link-icon`} src={`/layout/sidebar/${link.id}.svg`} />
            {link.name}
          </a>
          {link.tutorialId ? (
            <TutorialBox stateID={link.tutorialId}/>
          ):""}
        </li>
      )
    })
  }

  getSidebarLinks() {
    const { user } = this.props;
    const isUserMarketingManager = isMarketingManager(user);
    const isUserAccountManager = isAccountManager(user);
    const isUserExecutiveRelationsLead = isExecutiveRelationsLead(user)
    const links = [
      {name: "New Nomination",tutorialId:'new_nom', classNames: "cnt-default-layout-new-nomination-button", url:"/nominations/new", id: "nominations", visible: true},
      {name: "My Dashboard",tutorialId:'dashboard', url:"/", id: "dashboard", visible: true},

      {name: `${addMy(user)}Contacts`,tutorialId:'contacts', url:"/contacts", id: "contacts",  visible: !isUserAccountManager },
      {name: `Events`, url:"/events",tutorialId:'events', id: "events", visible: true},
      {name: `${addMy(user)}Companies`,tutorialId:'companies', url:"/companies", id: "companies", visible: !isUserAccountManager},
      {name: `Updates`, url:"/updates",tutorialId:'updates', id: "updates", visible: !isUserAccountManager},
      {name: "Users", url:"/admin/users",tutorialId:'users', id: "users", visible: !isUserAccountManager},
      {name: "Tool Guide", url:"/tool_guide",tutorialId:'toolguide', id: "tool-guide", visible: true}
    ].filter(x=> x.visible);
    return links;
  }

  getUserOptions() {
    const  {user} = this.props;
    let options =[
      {name:"My Profile", onClick: ()=>{window.location = '/profile'}, visible: true},
      {name:"Sign Out", onClick: ()=>{window.location = '/users/sign_out'}, visible: true}
    ].filter(x=> x.visible);
    return options;
  }

  renderUserDropdown() {
    const {user} = this.props;
    return (
      <GeneralTableDropdown
        options={this.getUserOptions()}
      >
        <div className="ctn-default-layout-sidebar-user-name">
          {user.first_name} {user.last_name}
          <img className="ctn-default-layout-sidebar-user-name-arrow" src="/layout/sidebar-arrow.svg" />
        </div>
      </GeneralTableDropdown>
    )
  }

  render() {
    const { user } = this.props;
    const { activeLinkId } = this.state;
    return (
      <div id="cnt-default-layout-sidebar-container">
          <ul className="cnt-default-layout-sidebar">
            <div className="ctn-default-layout-sidebar-user">
              <div className="ctn-default-layout-sidebar-user-image-container">
                <UniversalImageCircle image={user.profile_image} size="large" name={user.first_name} id={user.id}/>
              </div>
              <div className="ctn-default-layout-sidebar-user-name-container">
                {this.renderUserDropdown()}
              </div>
            </div>
            {this.renderSidebarOptions()}
            <li className="cnt-default-layout-sidebar-extra-spacing"></li>
            <div className="cnt-default-layout-sidebar-help-box">
              <div className="cnt-default-layout-sidebar-help-box-header">
                Need Help?
              </div>

                <div style={{position: 'relative'}}>
                  <TutorialBox stateID={'questions'}/>
                    <a href="mailto:nominationtoolsupport@cisco.com">
                    • Please contact us
                    </a>
                </div>
              {activeLinkId == 'dashboard' ? (
                <a href="#" onClick={()=>eventBus.dispatch("tutorialStart", {})}>• Start tutorial</a>
              ):""}
              <div>
                <a href={
                    isAccountManager(user) ?
                    "https://app.smartsheet.com/sheets/7w984Q8GPJwhF4v9gWJV6pR4cpVrmX7rwjRPx7Q1"
                    :
                    "https://app.smartsheet.com/sheets/8P9Q4XvCv8ppjXfJQWC9pPGR37Grw3256w2Q3Pg1"
                  } target="_blank">
                  • Leave feedback
                </a>
              </div>
            </div>
            <div className="cnt-default-layout-sidebar-sg-logo">
              <img src="/layout/jaguar-design-blue.png"/>
            </div>
          </ul>


      </div>
    );
  }
}

export default DefaultLayout;
