import React from "react";
import PropTypes from "prop-types";

class GeneralTableDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ open: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu() {
    this.setState({ open: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  renderMenu() {
    const { open } = this.state;
    const {options} = this.props
    if(open) {
      const renderedOptions = options.map(option=>
        <div className="cnt-default-layout-general-table-dropdown-menu-option" onClick={option.onClick}>
          {option.name}
        </div>
      );

      return (
        <div className="cnt-default-layout-general-table-dropdown-menu">
          {renderedOptions}
        </div>
      )
    }
  }

  renderClickable() {
    const { outerClassNames, outerStyle} = this.props;
    if(this.props.children) {
      return (
        <div
          className={outerClassNames}
          style={outerStyle}
          onClick={this.showMenu}
        >
          {this.props.children}
        </div>
      )
    }
    return (
      <img onClick={this.showMenu} src="/layout/options-ellipsis.svg" className="cnt-default-layout-general-table-dropdown-icon" />
    )
  }

  render() {
    const { outerClassNames } = this.props;
    return (
      <div className={`cnt-default-layout-general-table-dropdown ${outerClassNames}`}>
        {this.renderClickable()}
        {this.renderMenu()}
      </div>
    );
  }
}

export default GeneralTableDropdown;
