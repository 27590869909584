export function urlParamsToObject() {
  try{
    var search = location.search.substring(1);
    let searchObject = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
    return searchObject;
  }catch(e){
    return {}
  }

}

export function getUrlPage() {
  console.log(  parseInt((urlParamsToObject()['page']||""))||1);
  return (parseInt((urlParamsToObject()['page']||""))||1)-1;
}

export function updateUrlParam(key, value) {
  let urlObject = urlParamsToObject()
  urlObject[key] = value
  if(!value) {
    delete urlObject[key]
  }
    const querystring = encodeQueryData(urlObject);
  var url = new URL(location.protocol + '//' + location.host + location.pathname)
  history.pushState({}, null, url+"?"+querystring);
}

export function updateUrlParams(newObject) {
  let urlObject = urlParamsToObject()
  console.log({newObject});
  Object.keys(newObject).map(key=>{
    urlObject[key] = newObject[key]
    if(!urlObject[key]) {
      delete urlObject[key]
    }
  })

  const querystring = encodeQueryData(urlObject);
  var url = new URL(location.protocol + '//' + location.host + location.pathname)
  history.pushState({}, null, url+"?"+querystring);
}

export function removeUrlParam(key) {
  let urlObject = urlParamsToObject()
  delete urlObject[key]

  const querystring = encodeQueryData(urlObject);
  var url = new URL(location.protocol + '//' + location.host + location.pathname)
  history.pushState({}, null, url+"?"+querystring);
}

export function encodeQueryData(data) {
   const ret = [];
   for (let d in data)
     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
   return ret.join('&');
}
