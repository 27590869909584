import React from "react";
import PropTypes from "prop-types";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import ReportFieldsModal from "./ReportFieldsModal";
// import ReportToolSelect from './ReportToolSelect';

const SortableItem = SortableElement(({item, keySelected}) => {
  return (
    <div className="report-key-wrapper">
      {item.label}
      <div onClick={() => keySelected(item)}>
        <img src="/images/report-tool/form-icons/icon-close.svg" />
      </div>
    </div>
  );
});

const SortableList = SortableContainer(({items, keySelected, testValue}) => {
  return (
    <div className="sortable-list-elements-wrapper">
      {items.map((item, index) => (
        <SortableItem key={`sortable-${item.value}`} index={index} item={item} keySelected={keySelected} />
      ))}
    </div>
  );
});

class ReportKeys extends React.Component {
  constructor(props) {
    super(props);

    this.keySelected = this.keySelected.bind(this);
    this.massKeySelection = this.massKeySelection.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  // componentDidUpdate(prevProps) {
  //   const { keys, selectedKeys } = this.props;
  //   if (prevProps.keys !== keys) {
  //     console.log("new keys!");
  //     const correctedSelectedKeys = selectedKeys.map(k => {
  //       const newKey = keys.find(y => y.value === k.value);
  //       return newKey;
  //     });
  //     this.setState({ selectedKeys: correctedSelectedKeys });
  //     console.log(correctedSelectedKeys);
  //   } else {
  //     console.log("same keys");
  //   }
  // }

  onSortEnd(indices) {
    const { updateKeys, selectedKeys } = this.props;
    var itemArray = selectedKeys;
    const itemArrayNew = [];

    itemArray.forEach(item => {
      itemArrayNew.push(item);
    });

    itemArrayNew.splice(indices.newIndex, 0, itemArrayNew.splice(indices.oldIndex, 1)[0]);
    updateKeys(itemArrayNew);
  };

  massKeySelection(keys, selectAll) {
    const { updateKeys, selectedKeys } = this.props;
    let newSelectedKeys = selectedKeys;
    if (selectAll) {
      keys.forEach(key => {
        if (!selectedKeys.some(e => e.value === key.value)) {
          newSelectedKeys.push(key);
        }
      });
    } else {
      keys.forEach(key => {
        if (selectedKeys.some(e => e.value === key.value)) {
          newSelectedKeys = newSelectedKeys.filter(function(e) { return e.value !== key.value })
        }
      });
    }
    updateKeys(newSelectedKeys);
  }

  keySelected(key) {
    const { updateKeys, selectedKeys } = this.props;
    let newSelectedKeys = selectedKeys;
    if (selectedKeys.some(e => e.value === key.value)) {
      newSelectedKeys = newSelectedKeys.filter(function(e) { return e.value !== key.value })
    } else {
      newSelectedKeys.push(key);
    }
    updateKeys(newSelectedKeys);
  }

  render() {
    const { baseObj, keys, selectedKeys } = this.props;
    // <Select options={keys} onChange={(key) => {this.keySelected(key)} } />

    return (
      <>
        <label>Columns:</label>
        <div>
          {/* <ReportToolSelect options={keys} onChange={(key) => {this.keySelected(key)} } /> */}
          <p className="report-keys-note">Drag and drop tags to change column order</p>
          <SortableList
            items={selectedKeys}
            keySelected={(e) => this.keySelected(e)}
            testValue={'testValue'}
            onSortEnd={(e) => this.onSortEnd(e)}
            axis="xy"
            distance={1}
          />
          <ReportFieldsModal baseObj={baseObj} keys={keys} selectedKeys={selectedKeys} keySelected={this.keySelected} massKeySelection={this.massKeySelection} onSortEnd={this.onSortEnd} />
        </div>
      </>
    );
  }
}

export default ReportKeys;
