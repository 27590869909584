import React from "react";
import PropTypes from "prop-types";
import {isFunction } from "@utility/ProgramUtils.jsx"

class TabbedPageContainer extends React.Component {
  constructor(props) {
    super(props);
    const hash = window.location.hash.substr(1);
    this.state = {
      current_tab_id: hash ? hash : props.default_tab_id || null,
    };
  }

  renderHeader() {
    const { pages } = this.props;
    const tabs = pages.map(page=> this.renderTab(page));

    return (
      <div className="cnt-default-layout-tabbed-page-tabs">
        {tabs}
      </div>
    )
  }

  renderTab(tab) {
    const active = this.isTabActive(tab)
    return (
      <div onClick={() => this.changeTab(tab)} className={`cnt-default-layout-tabbed-page-tab ${active ? "active" : ""}`}>
        {tab.name || ""}
      </div>
    )
  }

  changeTab(tab) {
    this.setState({
      current_tab_id: tab.id
    })
  }

  renderContent() {
    const activeTab = this.getActiveTab();
    if(isFunction(activeTab.content)) {
      console.log("is function");
      return (
        <div id="cnt-default-layout-tabbed-page-body">
          {activeTab.content()}
        </div>
      )
    }
    return (
      <div id="cnt-default-layout-tabbed-page-body">
        {activeTab.content || ""}
      </div>
    )
  }

  getActiveTab() {
    const { pages } = this.props;
    const current_tab = pages.find(tab => this.isTabActive(tab));
    if(current_tab) {
      return current_tab;
    }else if(pages.length > 0) {
      this.setState({
        current_tab_id: pages[0].id
      })
    }
    return {}
  }

  isTabActive(tab) {
    const { current_tab_id } = this.state;
    if(tab && tab['id'] && tab['id'] == current_tab_id) {
      return true;
    }
    return false;
  }

  render() {
    const { user } = this.props;
    return (
      <div id="cnt-default-layout-tabbed-page-container">
        {this.renderHeader()}
        {this.renderContent()}
      </div>
    );
  }
}

export default TabbedPageContainer;
