import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"

class ErrorIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    const { user } = this.props;
    return (
      <DefaultLayout {...this.props}>
        <div className="cnt-error-index-container">
          <div className="cnt-error-index-inner">
            <div className="cnt-error-index-number">
              404
            </div>
            <div className="cnt-error-index-description">
              Page not found
            </div>
            <a href="/" className="cnt-error-index-return-to-dashboard">
              <button> Return to My Dashboard </button>
            </a>
          </div>
        </div>
      </DefaultLayout>
    );
  }
}

export default ErrorIndex;
