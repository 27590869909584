import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import GeneralTable from "@layout/tables/GeneralTable"
import  TabbedPageContainer from "@layout/tabs/TabbedPageContainer"
import  NominationsTable from "@nominations/tables/NominationsTable"
import { isAccountManager, canEditNomination } from "@utility/PermissionUtility"
import Cookies from "universal-cookie";
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  }


  renderMyNominations() {
    return (
      <>
        <NominationsTable
          {...this.props}
          includeEventFilter={true}
        />
      </>
    );
  }


  render() {
    const { user } = this.props;
    return (
      <DefaultLayout {...this.props} sidebarId="dashboard">
        <h1 className="cnt-page-header">
          My Dashboard
        </h1>
        <br/>
        <br/>
        {this.renderMyNominations()}
      </DefaultLayout>
    );
  }
}

export default Dashboard;
