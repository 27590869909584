import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Select from 'react-select-virtualized'
import Cookies from "universal-cookie";
import {getRoleName} from "@utility/PermissionUtility"
class NominationSubmitModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  renderModal() {
      return (
        <div className="row">
          <div className="col-xs-12 ">
            <div className="cnt-default-layout-nominations-modal-title">
              Thank you for your submission.
            </div>
            <div className="cnt-default-layout-nominations-modal-description">
              A Cisco {getRoleName('executive_relations_lead')} Lead will review your nomination.
            </div>
            <div className="">
              <button className="cnt-submit-button" onClick={() => {window.location="/"}}> My Dashboard </button>
              <button className="cnt-approve-button" onClick={() => {window.location="/nominations/new"}}> New Nomination</button>
            </div>
          </div>
        </div>
      );
  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, sessions } = this.props;
    return (
      <>
        <Modal
          className="white-background cnt-user-admin-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={true}
          onRequestClose={()=>{}}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default NominationSubmitModal;
