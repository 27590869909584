import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import LogItemLoader from "./LogItemLoader"

class LogItemChange extends React.Component {
  constructor(props) {
    super(props);
  }

  getFieldNamePrintable() {
    let { fieldName } = this.props;
    if(fieldName.endsWith("_id")) {
      fieldName = fieldName.substring(0, fieldName.length - 3)
    }
    return fieldName.replaceAll("_"," ");
  }

  getStartingValue() {
    const { fieldName, startingState } = this.props;
    if(typeof startingState == 'object'){
      return JSON.stringify(startingState)
    }
    if(fieldName.endsWith("_id")) {
      return (
        <LogItemLoader id={startingState} fieldName={fieldName} />
      )
    }
    return startingState;
  }


  getEndingValue() {
    const { fieldName, endingState } = this.props;
    if(typeof endingState == 'object'){
      return JSON.stringify(endingState)
    }
    if(fieldName.endsWith("_id")) {
      return (
        <LogItemLoader id={endingState} fieldName={fieldName} />
      )
    }
    return endingState;
  }


  render() {
    const {fieldName, startingState, endingState} = this.props;
    if(fieldName.endsWith('image_updated_at')) {
      return (
        <div className="cnt-default-layout-log-table-change">
          Photo Updated
        </div>
      )
    }
    return (
      <div className="cnt-default-layout-log-table-change">
        {this.getFieldNamePrintable()} changed from "{this.getStartingValue()}" to "{this.getEndingValue()}"
      </div>
    )
  }
}

export default LogItemChange;
