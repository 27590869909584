export function salesSubCoverageOptions() {
  return [
    'COM-MIDSIZE',
    'COM-OTHER',
    'COM-SELECT',
    'COM-SMALL',
    'ENT-KEY',
    'ENT-MAJOR',
    'ENT-PREMIER',
    'OTH-OTHER',
    'PS-KEY',
    'PS-MAJOR',
    'PS-MIDSIZE',
    'PS-OTHER',
    'PS-PREMIER',
    'PS-SELECT',
    'PS-SMALL',
    'SP-KEY',
    'SP-MAJOR',
    'SP-MIDSIZE',
    'SP-OTHER',
    'SP-PREMIER',
    'SP-SELECT',
    'SP-SMALL',
  ].map(x=>{return {value: x, label: x}})
}

export function tierOptions() {
  return [
    {label: "COMMERCIAL", options: [
      "COM-MIDSIZE",
      "COM-OTHER",
      "COM-SELECT",
      "COM-SMALL",
    ]
    },
    {label: "ENTERPRISE", options: [
      "ENT-KEY",
      "ENT-MAJOR",
      "ENT-OTHER",
      "ENT-PREMIER",
    ]
    },
    {label: "OTHER", options: [
      "OTH-OTHER"
    ]
    },
    {label: "PUBLIC SECTOR", options: [
      'PS-KEY',
      'PS-MAJOR',
      'PS-MIDSIZE',
      'PS-OTHER',
      'PS-PREMIER',
      'PS-SELECT',
      'PS-SMALL',
    ]
    },
    {label: "SERVICE PROVIDER", options: [
      'SP-KEY',
      'SP-MAJOR',
      'SP-MIDSIZE',
      'SP-OTHER',
      'SP-PREMIER',
      'SP-SELECT',
      'SP-SMALL',
    ]
    },
    {label: "SMB", options: [
      'SMALL BUSINESS - NON NAMED'
    ]
    }
  ].map(x=> {
    x.options = x.options.map(x=>{return {value: x, label: x}})
    return x
  })
}

export function abxOptions() {
  return [
    'Tier 1', 'Tier 2', 'Tier 3', 'Unsure', 'Not an ABX account'
  ].map(x=>{return {value: x, label: x}})
}

export function salesCoverageOptions() {
  return [
    'COMMERCIAL',
    'ENTERPRISE',
    'OTHER',
    'PUBLIC SECTOR',
    'SERVICE PROVIDER',
    'SMALL'
  ].map(x=>{return {value: x, label: x}})
}

export function areaOptions() {
  return [
    'FED Civilian',
    'FED Defense',
    'FED Intel',
    'SLED Central-East',
    'SLED South-West',
    'Canada FED',
    'Canada East',
    'Canada West',
    'Commercial',
    'Commercial Central',
    'South',
    'West'
  ].map(x=>{return {value: x, label: x}})
}

export function companyTypeOptions() {
  return [
    'Corporation (not subsidiary)',
    'Subsidiary',
    'Government Agency',
    'School'
  ].map(x=>{return {value: x, label: x}})
}

export function theaterOptions() {
  return [
    'US',
    'LATAM',
    'Canada',
    'ANZ',
    'Greater China',
    'Central',
    'Emerging',
    'Asia',
    'UK & Ireland',
    'India',
  ].map(x=>{return {value: x, label: x}})
}

export function geoOptions() {
  return [
    'AMERICAS',
    'APJC',
    'EMEAR-REGION'
  ].map(x=>{return {value: x, label: x}})
}

export function operationsOptions() {
  return [
    'AFRICA_SPAAC_SMB1_L4',
    'ÅLAND ISLANDS UNNAMED',
    'ALTICE',
    'AMERICAN SAMOA UNNAMED',
    'ANDORRA UNNAMED',
    'ANGUILLA UNNAMED',
    'ANTARCTICA UNNAMED',
    'ANTIGUA AND BARBUDA UNNAMED',
    'ANZ_SP_NBN',
    'ANZ_SP_OPTUS',
    'ANZ_SP_SC',
    'ANZ_SP_TELSTRA_GSP',
    'ANZ_SP_TPG_TELECOM',
    'ARGENTINA_P_U',
    'ARUBA UNNAMED',
    'ASEAN_COM_IDN',
    'ASEAN_COM_MYS',
    'ASEAN_COM_PHL',
    'ASEAN_COM_SGP',
    'ASEAN_COM_THA',
    'ASEAN_COM_VNM',
    'ASEAN_ENT_IDN',
    'ASEAN_ENT_MYS',
    'ASEAN_ENT_PHL',
    'ASEAN_ENT_SGP',
    'ASEAN_ENT_THA',
    'ASEAN_ENT_VNM',
    'ASEAN_PS_IDN',
    'ASEAN_PS_MYS',
    'ASEAN_PS_PHL',
    'ASEAN_PS_SGP',
    'ASEAN_PS_THA',
    'ASEAN_PS_VNM',
    'ASEAN_SMALL_IDN',
    'ASEAN_SMALL_MYS',
    'ASEAN_SMALL_PHL',
    'ASEAN_SMALL_SGP',
    'ASEAN_SMALL_THA',
    'ASEAN_SMALL_VNM',
    'ASEAN_SP_IDN',
    'ASEAN_SP_MLY',
    'ASEAN_SP_PHL',
    'ASEAN_SP_SGP',
    'ASEAN_SP_THA',
    'ASEAN_SP_VNM',
    'ASP AMAZON',
    'ASP AMX_TELMEX',
    'ASP APPLE',
    'ASP ATT',
    'ASP CANADA SEGMENTS',
    'ASP CHARTER',
    'ASP COMCAST',
    'ASP COX',
    'ASP DIGITAL MEDIA PROVIDERS',
    'ASP DISH AND ECHOSTAR',
    'ASP FACEBOOK',
    'ASP GOOGLE',
    'ASP LATAM LOCAL SP',
    'ASP LUMEN',
    'ASP MEXICO',
    'ASP MICROSOFT',
    'ASP MISC L4',
    'ASP ORACLE',
    'ASP SALESFORCE',
    'ASP US SEGMENTS OP',
    'ASP VERIZON',
    'ASP WEB PLATFORMS',
    'ASP_TMO',
    'ATLANTIC ENTERPRISE OPERATION',
    'AU_COM_NSW',
    'AU_COM_QLD',
    'AU_COM_SA',
    'AU_COM_VIC',
    'AU_COM_WA',
    'AU_DEFENCE REGION',
    'AU_ENT_KEY',
    'AU_ENT_MAJOR',
    'AU_PS_FEDERAL_REG',
    'AU_PS_NSW_REG',
    'AU_PS_QLD_REG',
    'AU_PS_SA_REG',
    'AU_PS_VIC_REG',
    'AU_PS_WA_REG',
    'AU_SMALL',
    'BAHAMAS UNNAMED',
    'BANGLADESH UNNAMED',
    'BARBADOS UNNAMED',
    'BELIZE UNNAMED',
    'BENIN UNNAMED',
    'BERMUDA UNNAMED',
    'BHUTAN UNNAMED',
    'BONAIRE, SINT EUSTATIUS AND SABA UNNAMED',
    'BOUVET ISLAND UNNAMED',
    'BOUYGUES_TELECOM_L4',
    'BRASIL COMMERCIAL',
    'BRASIL ENTERPRISE',
    'BRASIL PUBLIC SECTOR',
    'BRASIL SMALL',
    'BRITISH INDIAN OCEAN TERRITORY UNNAMED',
    'BRUNEI DARUSSALAM UNNAMED',
    'BT',
    'BURKINA FASO UNNAMED',
    'BURUNDI UNNAMED',
    'CAMBODIA UNNAMED',
    'CAMEROON UNNAMED',
    'CAN MISC AREA',
    'CANADA UNNAMED',
    'CAPE VERDE UNNAMED',
    'CARIBBEAN_REGION',
    'CAYMAN ISLANDS UNNAMED',
    'CENTRAL AFRICAN REPUBLIC UNNAMED',
    'CENTRAL EAST COMMERCIAL OPERATION',
    'CENTRAL MIDSIZE COMMERCIAL OPERATION',
    'CENTRAL NORTH COMMERCIAL OPERATION',
    'CENTRAL_AMERICA',
    'CGEM OPERATION',
    'CGEM_FRA',
    'CHAD UNNAMED',
    'CHILE1',
    'CHINA UNNAMED',
    'CHRISTMAS ISLAND UNNAMED',
    'CLUSTER_ALB_BIH',
    'CLUSTER_ALGTUN',
    'CLUSTER_ARM_GEO',
    'CLUSTER_CENTRAL_ASIA',
    'CLUSTER_EAST AFRICA',
    'CLUSTER_FYROM_KOS',
    'CLUSTER_LEVANT',
    'CLUSTER_LYB_SDN',
    'CLUSTER_NAL_UN',
    'CLUSTER_REST_OF_GULF',
    'CLUSTER_SRB_MNE',
    'CLUSTER_SSA_UN',
    'CLUSTER_VELOCITY',
    'CLUSTER_WEST_AFRICA',
    'CN_CABLE_NORTH',
    'CN_COM EAST_MD',
    'CN_COM EAST_SL',
    'CN_COM NORTH',
    'CN_COM_SOUTH_MD',
    'CN_COM_SOUTH_SL_GD_FJ',
    'CN_COM_SOUTH_SL_R',
    'CN_DN_NORTH',
    'CN_ENT NORTH',
    'CN_ENT_EAST',
    'CN_ENT_SOUTH',
    'CN_ENT_SOUTH_PINGAN',
    'CN_MNC_COM_MD',
    'CN_MNC_COM_SL',
    'CN_MNC_ENT_MJ',
    'CN_PS_EAST_MD',
    'CN_PS_EAST_SL',
    'CN_PS_NORTH',
    'CN_PS_SOUTH_MD',
    'CN_PS_SOUTH_SL_GD_FJ',
    'CN_PS_SOUTH_SL_R',
    'CN_SMALL_EAST',
    'CN_SMALL_MNC',
    'CN_SMALL_NORTH',
    'CN_SMALL_SOUTH',
    'CN_SP_HQ_NORTH',
    'CN_SP_OTH_EAST',
    'CN_SP_SOUTH_MJ_SL_R',
    'COCOS (KEELING) ISLANDS UNNAMED',
    'COLOMBIA_',
    'COM CENTRAL AREA',
    'COM EAST AREA',
    'COM WEST AREA',
    'COM_DEU_CENTRAL',
    'COM_DEU_MIDSIZE',
    'COM_DEU_NORTHEAST',
    'COM_DEU_SOUTH',
    'COM_DEU_SOUTHWEST',
    'COM_DEU_WEST',
    'COM_EAW_MID_LONDON',
    'COM_EAW_MID_NORTH',
    'COM_EAW_MID_SOUTH',
    'COM_EAW_SEL_LONDON',
    'COM_EAW_SEL_NORTH',
    'COM_EAW_SEL_SOUTH',
    'COM_EAW_SP_NORTH',
    'COMMERCIAL_AUT',
    'COMMERCIAL_BEL',
    'COMMERCIAL_CHE',
    'COMMERCIAL_DNK',
    'COMMERCIAL_ESP',
    'COMMERCIAL_FIN',
    'COMMERCIAL_FRA',
    'COMMERCIAL_IRL',
    'COMMERCIAL_ISR',
    'COMMERCIAL_ITA',
    'COMMERCIAL_KSA',
    'COMMERCIAL_NLD',
    'COMMERCIAL_NOR',
    'COMMERCIAL_POL',
    'COMMERCIAL_ROU',
    'COMMERCIAL_RUS',
    'COMMERCIAL_SCT',
    'COMMERCIAL_SWE',
    'COMMERCIAL_TUR',
    'COMOROS UNNAMED',
    'CONGO UNNAMED',
    'CONGO, THE DEMOCRATIC REPUBLIC OF THE UNNAMED',
    'COOK ISLANDS UNNAMED',
    'COUNTRY_ARE',
    'COUNTRY_AZERBAIJAN',
    'COUNTRY_BELARUS',
    'COUNTRY_BULGARIA',
    'COUNTRY_CROATIA',
    'COUNTRY_EGYPT',
    'COUNTRY_ESTONIA',
    'COUNTRY_GREECE',
    'COUNTRY_ICELAND',
    'COUNTRY_KAZAKHSTAN',
    'COUNTRY_LATVIA',
    'COUNTRY_LITHUANIA',
    'COUNTRY_LUXEMBOURG',
    'COUNTRY_MAR',
    'COUNTRY_MOLDOVA_L4',
    'COUNTRY_PORTUGAL',
    'COUNTRY_QAT',
    'COUNTRY_SLOVENIA',
    'COUNTRY_UKRAINE',
    'COUNTRY_ZAF',
    'CUBA UNNAMED',
    'CURAÇAO UNNAMED',
    'DJIBOUTI UNNAMED',
    'DOMINICA UNNAMED',
    'DT_GROUP_L4',
    'EAST MIDSIZE COMMERCIAL OPERATION',
    'EL SALVADOR UNNAMED',
    'ENT CENTRAL AREA',
    'ENT EAST AREA',
    'ENT WEST AREA',
    'ENT_DEU_DEUTSCHE_BAHN_L4',
    'ENT_DEU_FSI',
    'ENT_DEU_NORTH',
    'ENT_DEU_SOUTH',
    'ENT_EAW_FINANCIAL_SERVICES',
    'ENT_EAW_G_T_INDUSTRIAL',
    'ENT_EAW_NATIONALS_RSM1',
    'ENT_EAW_NATIONALS_RSM2',
    'ENT_EAW_RAIL_L4',
    'ENTERPRISE_AUT',
    'ENTERPRISE_BEL',
    'ENTERPRISE_CHE',
    'ENTERPRISE_DNK',
    'ENTERPRISE_ESP',
    'ENTERPRISE_FIN',
    'ENTERPRISE_FRA',
    'ENTERPRISE_IRL',
    'ENTERPRISE_ISR_1',
    'ENTERPRISE_ITA',
    'ENTERPRISE_KSA',
    'ENTERPRISE_NLD',
    'ENTERPRISE_NLD_GLOBAL',
    'ENTERPRISE_NOR',
    'ENTERPRISE_POL',
    'ENTERPRISE_ROU',
    'ENTERPRISE_RUS',
    'ENTERPRISE_SCT',
    'ENTERPRISE_SWE',
    'ENTERPRISE_TUR',
    'EQUATORIAL GUINEA UNNAMED',
    'ERITREA UNNAMED',
    'ETHIOPIA UNNAMED',
    'ETISALAT_GROUP_L4',
    'FALKLAND ISLANDS (MALVINAS) UNNAMED',
    'FAROE ISLANDS UNNAMED',
    'FED-CIVILIAN OPERATION',
    'FED-DOD ENTERPRISE OPERATION',
    'FED-DOD TACTICAL AGENCIES OPERATION',
    'FED-NATIONAL SECURITY OPERATION',
    'FIJI UNNAMED',
    'FRENCH GUIANA UNNAMED',
    'FRENCH POLYNESIA UNNAMED',
    'FRENCH SOUTHERN TERRITORIES UNNAMED',
    'G_K_CARGILL',
    'G_K_EXELON',
    'GABON UNNAMED',
    'GAMBIA UNNAMED',
    'GC_GSP_ALIBABA_DN',
    'GC_GSP_BYTEDANCE_DN',
    'GC_GSP_TENCENT_DN',
    'GC_ICN',
    'GERMANY UNNAMED',
    'GERMANY_SPAAC_SMB_L4',
    'GHANA UNNAMED',
    'GIBRALTAR UNNAMED',
    'GLL_ALLIANZ',
    'GLL_ANZ_BANK',
    'GLL_ASML',
    'GLL_BASF',
    'GLL_BAYER',
    'GLL_BHP',
    'GLL_BMW',
    'GLL_BOSCH',
    'GLL_CN_RAILWAY',
    'GLL_COGNIZANT',
    'GLL_DAIMLER',
    'GLL_DEUTSCHE_BANK',
    'GLL_DP_DHL',
    'GLL_ERICSSON',
    'GLL_FOXCONN',
    'GLL_G_RBC',
    'GLL_G_TD BANK GROUP',
    'GLL_HCL',
    'GLL_HENNES_MAURITZ',
    'GLL_HITACHI',
    'GLL_HONDA',
    'GLL_HYUNDAI',
    'GLL_IKEA',
    'GLL_INFOSYS',
    'GLL_ING',
    'GLL_K_3M',
    'GLL_K_ACCENTURE',
    'GLL_K_ADOBE',
    'GLL_K_ADP',
    'GLL_K_AIG',
    'GLL_K_BAKER HUGHES',
    'GLL_K_BLOOMBERG',
    'GLL_K_BOEING',
    'GLL_K_CARNIVAL',
    'GLL_K_CATERPILLAR',
    'GLL_K_CHEVRON',
    'GLL_K_COCA COLA',
    'GLL_K_CUMMINS',
    'GLL_K_DELL',
    'GLL_K_DELOITTE',
    'GLL_K_DOW CHEMICAL',
    'GLL_K_E AND Y',
    'GLL_K_EXPEDIA',
    'GLL_K_EXXON',
    'GLL_K_FISERV',
    'GLL_K_GOLDMAN SACHS',
    'GLL_K_HILTON',
    'GLL_K_HONEYWELL',
    'GLL_K_JABIL',
    'GLL_K_JOHNSON CONTROLS',
    'GLL_K_KPMG',
    'GLL_K_MARSH AND MCLENNAN',
    'GLL_K_MASTERCARD',
    'GLL_K_MEDTRONIC',
    'GLL_K_MERCK',
    'GLL_K_METLIFE',
    'GLL_K_MICRON',
    'GLL_K_NETAPP',
    'GLL_K_PEPSI',
    'GLL_K_PFIZER',
    'GLL_K_PROCTER AND GAMBLE',
    'GLL_K_PRUDENTIAL',
    'GLL_K_PWC',
    'GLL_K_QUALCOMM',
    'GLL_K_SCHLUMBERGER',
    'GLL_K_STARBUCKS',
    'GLL_K_STATE STREET',
    'GLL_K_VISA',
    'GLL_LG',
    'GLL_MAERSK',
    'GLL_MUFG',
    'GLL_NOMURA',
    'GLL_P_A_AMEX',
    'GLL_P_A_BNYM',
    'GLL_P_A_CITI',
    'GLL_P_A_GE',
    'GLL_P_A_HOME_DEPOT',
    'GLL_P_A_MORGAN STANLEY',
    'GLL_P_A_RAYTHEON TECHNOLOGIES',
    'GLL_P_B_FEDEX',
    'GLL_P_B_FORD',
    'GLL_P_B_GM',
    'GLL_P_B_INTEL',
    'GLL_P_B_J AND J',
    'GLL_P_B_UNITED HEALTH GROUP',
    'GLL_P_C_BOA',
    'GLL_P_C_IBM',
    'GLL_P_C_JPMC',
    'GLL_P_C_WALMART',
    'GLL_P_C_WELLS_FARGO',
    'GLL_PHILIPS',
    'GLL_RIO',
    'GLL_SAMSUNG',
    'GLL_SAP',
    'GLL_SCHWARZ',
    'GLL_SHELL',
    'GLL_SIEMENS',
    'GLL_SK',
    'GLL_SONY',
    'GLL_TCS',
    'GLL_TECHM',
    'GLL_TOYOTA',
    'GLL_TSMC',
    'GLL_VW_GROUP',
    'GLL_WIPRO',
    'GOLDEN WEST COMMERCIAL OPERATION',
    'GREAT_NORTH_OPERATION',
    'GREENLAND UNNAMED',
    'GRENADA UNNAMED',
    'GUADELOUPE UNNAMED',
    'GUAM UNNAMED',
    'GUERNSEY UNNAMED',
    'GUINEA UNNAMED',
    'GUINEA-BISSAU UNNAMED',
    'GUYANA UNNAMED',
    'HAITI UNNAMED',
    'HEARD ISLAND AND MCDONALD ISLANDS UNNAMED',
    'HEARTLAND_OPERATION',
    'HEARTLAND-GULF COMMERCIAL OPERATION',
    'HK COM',
    'HK ENT',
    'HK PS',
    'HK SP',
    'HK_ENT_FSI',
    'HK_MACAU',
    'HK_SMALL',
    'HOLY SEE (VATICAN CITY STATE) UNNAMED',
    'HONDURAS UNNAMED',
    'ILIAD_FREE_NJJ',
    'IND_BD',
    'IND_DNE_LARGE',
    'IND_DNE_MEDIUM',
    'IND_EAST',
    'IND_ENT_BFSI_PUB',
    'IND_ENT_BFSI_PVT',
    'IND_ENT_STRATEGIC',
    'IND_NORTH',
    'IND_PS_CENTRE',
    'IND_PS_DEFENCE',
    'IND_PS_STATES',
    'IND_SMALL_BD',
    'IND_SMALL_NTH_EST',
    'IND_SMALL_STH',
    'IND_SMALL_WST',
    'IND_SOUTH',
    'IND_SRLK',
    'IND_WEST',
    'INDIA UNNAMED',
    'INDIA_ENT_TATA_GROUP',
    'INDIA_SP RELIANCE',
    'INDIA_SP ROI_SC',
    'INDIA_SP_BHARTI_GSP',
    'INDIA_SP_SIFY',
    'INDIA_SP_TATA_GSP',
    'IRAN UNNAMED',
    'ISLE OF MAN UNNAMED',
    'IVORY COAST UNNAMED',
    'JAMAICA UNNAMED',
    'JAPAN UNNAMED',
    'JAPAN_SP_KDDI_GSP',
    'JAPAN_SP_NTT_GROUP_GSP',
    'JAPAN_SP_OSP_SC',
    'JAPAN_SP_RAKUTEN_GSP',
    'JAPAN_SP_SOFTBANK_GSP',
    'JERSEY UNNAMED',
    'JP_COM_DS',
    'JP_COM_MD_VSAM',
    'JP_COM_MFG_FSI',
    'JP_COM_WEST',
    'JP_ENT_DS',
    'JP_ENT_FSI',
    'JP_ENT_MFG_1',
    'JP_ENT_MFG_2',
    'JP_PS_CENTRAL_1_CG',
    'JP_PS_CENTRAL_2_SI',
    'JP_PS_EAST_1_NORTH',
    'JP_PS_EAST_2_KANTO',
    'JP_PS_WEST_1',
    'JP_PS_WEST_2',
    'JP_SMALL_MSP',
    'JP_SMALL_SBU',
    'KIRIBATI UNNAMED',
    'KOREA, DEMOCRATIC PEOPLE\'S REPUBLIC OF UNNAMED',
    'KOREA_SP_KT CORP',
    'KOREA_SP_LGU',
    'KOREA_SP_NAVER',
    'KOREA_SP_SC',
    'KOREA_SP_SK TELECOM',
    'KR_COM_DN',
    'KR_COM_FSI',
    'KR_COM_IND',
    'KR_ENT_FSI',
    'KR_ENT_IND',
    'KR_PS_BR',
    'KR_PS_CENTRAL',
    'KR_SMALL_SBU',
    'LAOS UNNAMED',
    'LESOTHO UNNAMED',
    'LGC_ARMENIA',
    'LGC_AUSTRIA',
    'LGC_AZERBAIJAN',
    'LGC_BRITISHTELECOM',
    'LGC_CROATIA',
    'LGC_CYPRUS',
    'LGC_CZECH REPUBLIC',
    'LGC_DENMARK',
    'LGC_EAW',
    'LGC_FINLAND',
    'LGC_FRANCE',
    'LGC_GC',
    'LGC_GEORGIA',
    'LGC_GREECE',
    'LGC_HUNGARY',
    'LGC_IND',
    'LGC_IRELAND',
    'LGC_ITALY',
    'LGC_JPN',
    'LGC_KAZAKHSTAN',
    'LGC_KR',
    'LGC_KYRGYZSTAN',
    'LGC_LATVIA',
    'LGC_LITHUANIA',
    'LGC_LUXEMBOURG',
    'LGC_NETHERLANDS',
    'LGC_NORWAY',
    'LGC_NZ',
    'LGC_ORANGE',
    'LGC_POLAND',
    'LGC_PORTUGAL',
    'LGC_ROMANIA',
    'LGC_SERBIA',
    'LGC_SGP',
    'LGC_SITA',
    'LGC_SPAIN',
    'LGC_SWITZERLAND',
    'LGC_TELE2',
    'LGC_TELIA',
    'LGC_UKRAINE',
    'LGC_UZBEKISTAN',
    'LGC_VNM',
    'LGC_VODAFONE',
    'LIBERIA UNNAMED',
    'LIBERTY_GLOBAL',
    'LIECHTENSTEIN UNNAMED',
    'MACAU UNNAMED',
    'MADAGASCAR UNNAMED',
    'MALAWI UNNAMED',
    'MALDIVES UNNAMED',
    'MALI UNNAMED',
    'MARSHALL ISLANDS UNNAMED',
    'MARTINIQUE UNNAMED',
    'MAURITANIA UNNAMED',
    'MAURITIUS UNNAMED',
    'MAYOTTE UNNAMED',
    'MEDIA_GROUP',
    'MEXICO COMMERCIAL MIDSIZE',
    'MEXICO COMMERCIAL SELECT',
    'MEXICO ENTERPRISE',
    'MEXICO PUBLIC SECTOR',
    'MEXICO SMALL',
    'MICRONESIA, FEDERATED STATES OF UNNAMED',
    'MID-ATLANTIC COMMERCIAL OPERATION',
    'MIDWEST ENTERPRISE OPERATION',
    'MONACO UNNAMED',
    'MONTSERRAT UNNAMED',
    'MYANMAR UNNAMED',
    'NAMIBIA UNNAMED',
    'NAURU UNNAMED',
    'NEPAL UNNAMED',
    'NETHERLANDS ANTILLES UNNAMED',
    'NEW CALEDONIA UNNAMED',
    'NEW YORK ENTERPRISE OPERATION',
    'NICARAGUA UNNAMED',
    'NIGER UNNAMED',
    'NIUE UNNAMED',
    'NORFOLK ISLAND UNNAMED',
    'NORTH EAST COMMERCIAL OPERATION',
    'NORTHEAST ENTERPRISE OPERATION',
    'NORTHERN MARIANA ISLANDS UNNAMED',
    'NZ_COMMERCIAL_SEG',
    'NZ_ENTERPRISE_SEG',
    'NZ_PUBLIC_SECTOR_SEG',
    'NZ_SMALL',
    'OCEANSIDE OPERATION',
    'ORANGE_GROUP',
    'P_A_DUKE',
    'P_A_TRUIST',
    'P_B_CVS HEALTH',
    'P_B_HCA',
    'P_B_KAISER',
    'PACIFIC WEST COMMERCIAL OPERATION',
    'PALAU UNNAMED',
    'PAPUA NEW GUINEA UNNAMED',
    'PERU_ANDEAN',
    'PITCAIRN UNNAMED',
    'PRIVATE_SECTOR_CZE_1',
    'PRIVATE_SECTOR_CZE_2',
    'PRIVATE_SECTOR_HUN',
    'PRIVATE_SECTOR_SVK',
    'PS CENTRAL AREA',
    'PS EAST AREA',
    'PS FED AREA',
    'PS FEDERAL AREA MISC',
    'PS WEST AREA',
    'PS_DEU_FEDERAL',
    'PS_DEU_SHEL',
    'PS_EAW_CGOV_JUSTICE',
    'PS_EAW_DEFENCE',
    'PS_EAW_DGOV',
    'PS_EAW_UKIC',
    'PUBLIC_SEC_REGIONAL_KSA',
    'PUBLIC_SECTOR_AUT',
    'PUBLIC_SECTOR_CHE',
    'PUBLIC_SECTOR_CZE',
    'PUBLIC_SECTOR_ESP',
    'PUBLIC_SECTOR_FRA',
    'PUBLIC_SECTOR_HUN',
    'PUBLIC_SECTOR_IRL',
    'PUBLIC_SECTOR_ISR',
    'PUBLIC_SECTOR_ISR_MOD',
    'PUBLIC_SECTOR_ITA',
    'PUBLIC_SECTOR_KSA',
    'PUBLIC_SECTOR_POL',
    'PUBLIC_SECTOR_ROU',
    'PUBLIC_SECTOR_RUS',
    'PUBLIC_SECTOR_SCT',
    'PUBLIC_SECTOR_SVK',
    'PUBLIC_SECTOR_TUR',
    'PUBLICSECTOR_BEL',
    'PUBLICSECTOR_DNK',
    'PUBLICSECTOR_FIN',
    'PUBLICSECTOR_NLD',
    'PUBLICSECTOR_NOR',
    'PUBLICSECTOR_SWE_NATIONAL',
    'PUBLICSECTOR_SWE_REGIONAL',
    'RED RIVER OPERATION',
    'RÉUNION UNNAMED',
    'RWANDA UNNAMED',
    'SAINT BARTHÉLEMY UNNAMED',
    'SAINT HELENA UNNAMED',
    'SAINT KITTS AND NEVIS UNNAMED',
    'SAINT LUCIA UNNAMED',
    'SAINT PIERRE AND MIQUELON UNNAMED',
    'SAINT VINCENT AND THE GRENADINES UNNAMED',
    'SAMOA UNNAMED',
    'SAN MARINO UNNAMED',
    'SAO TOME AND PRINCIPE UNNAMED',
    'SBU MISC',
    'SBU_EAW_L4',
    'SBU_EAW_LONDON_L4',
    'SBU_EAW_NORTH_L4',
    'SBU_EAW_SOUTH_L4',
    'SBU_USC CENTRAL SMALL BUSINESS',
    'SBU_USC EAST SMALL BUSINESS',
    'SBU_USC SOUTH SMALL BUSINESS',
    'SBU_USC WEST SMALL BUSINESS',
    'SDN_LIST_RUS',
    'SENEGAL UNNAMED',
    'SERVICE_PROVIDER_ISR',
    'SERVICE_PROVIDER_KSA',
    'SERVICEPROVIDER_NOR',
    'SEYCHELLES UNNAMED',
    'SIERRA LEONE UNNAMED',
    'SINT MAARTEN (DUTCH PART) UNNAMED',
    'SINT MAARTEN (FRENCH PART) UNNAMED',
    'SLED-CENTRAL OPERATION',
    'SLED-CENTRALEAST OPERATION',
    'SLED-EAST TERRITORY OPERATION',
    'SLED-GOLD COAST OPERATION',
    'SLED-NORTHEAST OPERATION',
    'SLED-SOUTHEAST OPERATION',
    'SLED-WEST BY MIDWEST OPERATION',
    'SLED-WEST TERRITORY OPERATION',
    'SMALL CAN AREA',
    'SMALL CAN SPAACH',
    'SMALL CENTRAL AREA',
    'SMALL EAST AREA',
    'SMALL WEST AREA',
    'SMALL_AUT',
    'SMALL_BELGIUM',
    'SMALL_CHE',
    'SMALL_CZE',
    'SMALL_DENMARK',
    'SMALL_DEU_CENTRAL',
    'SMALL_DEU_EAST',
    'SMALL_DEU_NORTH',
    'SMALL_DEU_SOUTH',
    'SMALL_DEU_SOUTHWEST',
    'SMALL_DEU_WEST',
    'SMALL_ESP',
    'SMALL_FINLAND',
    'SMALL_FRA',
    'SMALL_GULF',
    'SMALL_HUN',
    'SMALL_IRL',
    'SMALL_ISR',
    'SMALL_ITA',
    'SMALL_KSA',
    'SMALL_NAL',
    'SMALL_NETHERLANDS',
    'SMALL_NORWAY',
    'SMALL_POLAND_L4',
    'SMALL_ROU',
    'SMALL_RUS',
    'SMALL_SCT',
    'SMALL_SSA',
    'SMALL_SVK',
    'SMALL_SWEDEN',
    'SMALL_TURKEY',
    'SOLOMON ISLANDS UNNAMED',
    'SOMALIA UNNAMED',
    'SOUTH EAST COMMERCIAL OPERATION',
    'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS UNNAMED',
    'SOUTH SUDAN UNNAMED',
    'SOUTHEAST OP',
    'SOUTHERN STATES COMMERCIAL OPERATION',
    'SOUTHWEST OPERATION',
    'SP_AFRICA_NORTH_L4',
    'SP_AFRICA_SOUTH_L4',
    'SP_BENELUX',
    'SP_DEU_CHE_L4',
    'SP_EE_CIS',
    'SP_FRANCE_HOSTERS',
    'SP_IBERIA_CYPRUS_MALTA',
    'SP_ITALY_GROUP',
    'SP_MEA_REST_L4',
    'SP_MTS',
    'SP_NORDICS',
    'SP_RUSSIA',
    'SP_SWISSCOM_L4',
    'SP_UK',
    'SRI LANKA UNNAMED',
    'STC_GROUP_L4',
    'STOCK UNALLOCATED OPS',
    'SURINAME UNNAMED',
    'SVALBARD AND JAN MAYEN UNNAMED',
    'SWAZILAND UNNAMED',
    'SWITZERLAND_CGEM',
    'TAG OPERATION',
    'TANZANIA, UNITED REPUBLIC OF UNNAMED',
    'TEF_GROUP',
    'TELIA_L4',
    'TI_GROUP',
    'TIMOR-LESTE UNNAMED',
    'TOGO UNNAMED',
    'TOKELAU UNNAMED',
    'TONGA UNNAMED',
    'TRINIDAD AND TOBAGO UNNAMED',
    'TRI-STATE COMMERCIAL OPERATION',
    'TRISTATE ENTERPRISE OPERATION',
    'TURKS AND CAICOS ISLANDS UNNAMED',
    'TUVALU UNNAMED',
    'TW SMALL',
    'TW_COM',
    'TW_COM_FSI',
    'TW_COM_MFG',
    'TW_ENT',
    'TW_PS',
    'TW_SP',
    'UGANDA UNNAMED',
    'UNITED KINGDOM UNNAMED',
    'UNITED STATES MINOR OUTLYING ISLANDS UNNAMED',
    'UNITED STATES UNNAMED',
    'US COMM MISC L4',
    'US COMMERCIAL MISC L4',
    'US MISC - NODE 4',
    'VANUATU UNNAMED',
    'VIRGIN ISLANDS, BRITISH UNNAMED',
    'VIRGIN ISLANDS, U.S. UNNAMED',
    'VODAFONE_',
    'WALLIS AND FUTUNA UNNAMED',
    'WEST MIDSIZE COMMERCIAL OPERATION',
    'WESTERN SAHARA UNNAMED',
    'YUGOSLAVIA UNNAMED',
    'ZAMBIA UNNAMED',
    'ZIMBABWE UNNAMED',
  ].map(x=>{return {value: x, label: x}})
}

export function verticalOptions() {
  return [
    "Education",
    "Energy/Utilities",
    "FinServ",
    "Government",
    "HLTHCR",
    "Hospitality/Hotels and Leisure",
    "Retail",
    "Professional Services",
    "Manufacturing",
    "Media/Entertainment",
    "Service Provider",
    "Technical Services",
    "Transportation",
    "Wholesale/Distribution",
    "Other"
  ].map(x=>{return {value: x, label: x}})
}

export function regionOptions() {
  return [
    "Americas",
    "APJC",
    "EMEAR-REGION",
  ].map(x=>{return {value: x, label: x}})
}

export function segmentOptions() {
  return [
    'AMERICAS_MISC',
    'AMERICAS_SP',
    'ANZ AREA',
    'APJ_SP',
    'ASEAN_AREA',
    'CANADA',
    'EMEAR_GERMANY',
    'EMEAR_MEA',
    'EMEAR_OTHER',
    'EMEAR_SP',
    'EMEAR_UNALLOCATED',
    'EMEAR-CENTRAL',
    'EMEAR-NORTH',
    'EMEAR-SOUTH',
    'EMEAR-UKI',
    'GLOBAL ENTERPRISE SEGMENT',
    'GREATER_CHINA',
    'INDIA_AREA',
    'JAPAN__',
    'LATIN AMERICA',
    'ROK_AREA',
    'US COMMERCIAL',
    'US PS MARKET SEGMENT',
  ].map(x=>{return {value: x, label: x}})
}

export function subSegmentOptions() {
  return [
    'ANZ_ MISC',
    'ANZ_NEW_ZEALAND',
    'ANZ_SMALL',
    'APJ_SP_ANZ',
    'APJ_SP_ASEAN',
    'APJ_SP_INDIA',
    'APJ_SP_JAPAN',
    'APJ_SP_KOREA',
    'APJ_SP_MISC',
    'ASEAN_ID',
    'ASEAN_MISC',
    'ASEAN_MY',
    'ASEAN_PH',
    'ASEAN_SG',
    'ASEAN_SMALL',
    'ASEAN_TH',
    'ASEAN_VN',
    'ASP CSP',
    'ASP WEB',
    'ASP_CANADA',
    'ASP_HQ',
    'ASP_LATAM',
    'ASP_US SEGMENTS',
    'AU_COMMERCIAL',
    'AU_ENTERPRISE',
    'AU_PUBLIC SECTOR',
    'BRASIL',
    'CLUSTER_BALKANS',
    'CLUSTER_BELUX',
    'CLUSTER_CIS',
    'CLUSTER_FINLANDBALTICS',
    'CLUSTER_GREECE_PORTUGAL',
    'CLUSTER_GULF',
    'CLUSTER_HRV_SVN',
    'CLUSTER_IRELAND_SCOTLAND',
    'CLUSTER_NAL',
    'CLUSTER_NORWAYICELAND',
    'CLUSTER_SSA',
    'CN EAST',
    'CN MNC',
    'CN SOUTH',
    'CN_NORTH',
    'COMMERCIAL CAN',
    'COMMERCIAL CENTRAL AREA',
    'COMMERCIAL EAST AREA',
    'COMMERCIAL WEST AREA',
    'COMMERCIAL_DEU',
    'COMMERCIAL_EAW',
    'COUNTRY_AUSTRIA',
    'COUNTRY_BELARUS_L3',
    'COUNTRY_CZECH REPUBLIC',
    'COUNTRY_DENMARK',
    'COUNTRY_FRANCE',
    'COUNTRY_HUNGARY',
    'COUNTRY_ISRAEL',
    'COUNTRY_ITALY',
    'COUNTRY_KSA',
    'COUNTRY_NETHERLANDS',
    'COUNTRY_POLAND',
    'COUNTRY_ROMANIA',
    'COUNTRY_RUSSIA',
    'COUNTRY_SLOVAKIA',
    'COUNTRY_SPAIN',
    'COUNTRY_SWEDEN',
    'COUNTRY_SWITZERLAND',
    'COUNTRY_TUR',
    'COUNTRY_UKRAINE_L3',
    'EMEAR_GERMANY_MISC_L3',
    'EMEAR_MEA_MISC_L3',
    'EMEA-UNALL-STOCK',
    'ENTERPRISE CA',
    'ENTERPRISE_EAW',
    'ENTERPRISE_I_DEU',
    'ENTERPRISE_II_DEU',
    'GC_NON_DISTRIBUTED_MISC',
    'GC_SMALL',
    'GES CENTRAL',
    'GES EAST',
    'GES PREMIER',
    'GES SOUTH',
    'GES WEST',
    'HONG_KONG',
    'IND_COMMERCIAL_OTH',
    'IND_DNE',
    'IND_ENTERPRISE_ITES',
    'IND_ENTERPRISE_MAJOR',
    'IND_PUBLIC_SECTOR',
    'IND_SMALL',
    'INDIA_MISC',
    'JP_COMMERCIAL_OTH',
    'JP_ENTERPRISE',
    'JP_PUBLIC_SECTOR_OTH',
    'JP_SMALL',
    'KOR_COMMERCIAL',
    'KOR_ENTERPRISE_CGEM',
    'KOR_ENTERPRISE_MAJOR',
    'KOR_PUBLIC SECTOR',
    'KOR_SMALL',
    'KOREA_MISC',
    'LGC_CENTRAL',
    'LGC_NORTH',
    'LGC_SOUTH',
    'LGC_SP',
    'LGC_UKI',
    'M_C_R',
    'MEXICO-',
    'MISC CAN',
    'PS FEDERAL AREA',
    'PUBLIC SECTOR CAN',
    'PUBLIC_SECTOR_DEU',
    'PUBLIC_SECTOR_EAW',
    'SBU_US COMMERCIAL SMALL BUSINESS',
    'SLED-EAST AREA',
    'SLED-TERRITORY AREA',
    'SLED-WEST AREA',
    'SMALL CAN',
    'SMALL_DEU',
    'SMALL_EAW',
    'SMALL_NORTH',
    'SMALL_POLAND',
    'SMALL_SOUTH',
    'SP_ALTICE',
    'SP_BT',
    'SP_CENTRAL',
    'SP_DT_GROUP',
    'SP_MEA',
    'SP_NORTH',
    'SP_ORANGE_GROUP',
    'SP_RUSSIA_BELARUS',
    'SP_SOUTH',
    'SP_VDF',
    'TAIWAN',
    'US COMM MISC L3',
    'US COMMERCIAL MISC',
    'US MISC'
  ].map(x=>{return {value: x, label: x}})
}
