import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Select from 'react-select-virtualized'
import Cookies from "universal-cookie";

class NominationRejectModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: props.forceOpen || false,
      selectedUser: null,
      statusChangeReason: null,
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.rejectNomination = this.rejectNomination.bind(this);
  }

  renderModal() {
    const { contacts, nomination, events } = this.props;
    const { rejectionReasoning, rejectionReasoningText, error, statusChangeReason,submitting} = this.state;
    if(nomination) {
      let contact = {}
      if(nomination.contact) {
        contact = nomination.contact
      }else if(nomination.contact_id) {
        contact = contacts.find(x=> x.id == nomination.contact_id);
      }
      let event = {}
      if(nomination.event) {
        event = nomination.event
      }else if(events) {
        event = (events||[]).find(x=> x.id == nomination.event_id);
      }

      return (
        <div className="row">
          <div className="col-xs-12 ">
            <div className="cnt-default-layout-nominations-modal-description">
              Would you like to add a note for the account manager? <span className="optional">(Optional)</span>
            </div>
            <textarea
              style={{
                height: "100px"
              }}
              className="form-control"
              placeholder="Type here..."
              value={statusChangeReason}
              onChange={(e)=>
                this.setState({
                  statusChangeReason: e.target.value
                })
              }
            />
            <br/>
            <br/>

            {error ? (
              <div className="cnt-default-layout-nominations-modal-error">
                {error}
                <br/>
              </div>
            ):""}
            <div className="">
              <button className="cnt-reject-button" onClick={() => this.rejectNomination()}> {submitting ? (this.renderLoadingCircleButton()): "Deny"} </button>
              <button className="cnt-cancel-button"onClick={this.toggleModal}> Cancel</button>
            </div>
          </div>
        </div>
      );
    }
  }

  rejectionReasonOptions() {
    return [
      "Event is already full.",
      "Nominee does not meet criteria",
      "Form filled out incorrectly",
      "Other"
    ].map(x=>{return {value: x, label: x}})
  }

  rejectNomination() {
    const { rejectionReasoning, rejectionReasoningText, statusChangeReason} = this.state;
    const { rejectionCallback, currentComment } = this.props;
    const { nomination } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    const body = {
      status_changed_reason: statusChangeReason
    }

    this.setState({
      submitting: true
    })


    fetch(`/nominations/${nomination.id}/reject`, {
      method: "post",
      redirect: "manual",
      body: JSON.stringify(body),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            submitting: false
          })
          if(json.redirect_url) {
            if(rejectionCallback) {
              rejectionCallback(nomination)
            }else {
              window.location = json.redirect_url
            }
          }else {
            console.log("Successful no redirect")
          }
        }
        else {
          this.setState({
            error: json.error,
            submitting: false
          })
        }
      });
  }

  renderLoadingCircleButton() {
    return (<div class="lds-button-ellipsis"><div></div><div></div><div></div><div></div></div>)
  }



  toggleModal() {
    const { modalOpen } = this.state;
    const { openModalCallback, closeModalCallback } = this.props;
    if(!modalOpen == false && closeModalCallback) {
      closeModalCallback()
    }
    if(!modalOpen == true && openModalCallback) {
      openModalCallback()
    }
    this.setState({
      modalOpen: !modalOpen
    });
  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, sessions } = this.props;
    return (
      <>
        <div className={outerClassNames} style={{cursor:"pointer", display: "inline-block"}} onClick={() => this.toggleModal()} >
          {children}
        </div>
        <Modal
          className="white-background cnt-user-admin-modal cnt-status-change-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default NominationRejectModal;
