import { getTimezoneInfo } from "./CountryCodes"
import { format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime, listTimeZones } from 'date-fns-timezone';
import moment from 'moment-timezone';

export function getCurrentUTC(attendee) {
  if(attendee && attendee.registration_answers) {
    const timezones = getTimezoneInfo()
    const timezone = timezones.find(x=> x.value == attendee.registration_answers["21"])
    if(timezone) {
      return timezone.utc
    }
  }
  return 'America/Los_Angeles'
}

export function renderFullDate(date) {
  return formatToTimeZone(new Date(date), 'MM/DD/YYYY HH:MM A', {timeZone: 'America/Los_Angeles'});
}

export function getTimeZoneAbbreviation(attendee) {
  const timezone = getCurrentUTC(attendee);
  const abbr = formatToTimeZone(new Date(), 'z', {timeZone: timezone});
  switch (abbr) {
    case "PST":
      return "PT";
    case "PDT":
      return "PT";
    case "EST":
      return "ET";
    case "EDT":
      return "ET";
    default:
      return abbr;
  }
}

export function areChatroomsOpen(times) {
 const overrideDate = localStorage.getItem('dayNumber');
 if(overrideDate >= 2 || new Date() > new Date(times['day_two_start'])) {
   return true;
 }
 return false;
}

export function isWelcomeOpen(times) {
 const overrideDate = localStorage.getItem('dayNumber');
 if(overrideDate >= 2 || new Date() > new Date(times['welcome_center_open'])) {
   return true;
 }
 return false;
}

export function isPostShowOpen(times) {
 const overrideDate = localStorage.getItem('dayNumber');
 if(overrideDate >= 5 || new Date() > new Date(times['day_five_start'])) {
   return true;
 }
 return false;
}

export function showPostShowNotification(times) {
  const overrideDate = localStorage.getItem('dayNumber');
  if(overrideDate >= 7 || new Date() > new Date(times['day_seven_start'])) {
    return true;
  }
  return false;
}

export function isConnectOpen(times) {
  const overrideDate = localStorage.getItem('dayNumber');
  if(overrideDate >= 3 || new Date() > new Date(times['day_three_start'])) {
    return true;
  }
  return false;
}

export function getCurrentTime(current_user_data) {
  let now = moment().tz(getCurrentUTC(current_user_data));
  const dayNumber = localStorage.getItem('dayNumber');
  const time = localStorage.getItem('time');
  if (dayNumber !== null) {
    let daySplit = dayNumber.split('-');
    now = now.year(daySplit[0]);
    now = now.month(daySplit[1] - 1);
    now = now.date(daySplit[2]);
  }
  if (time) {
    let timeSplit = time.split(":");
    now.hours(timeSplit[0]);
    now.minutes(timeSplit[1]);
  }
  return now;
}

export function datesAreOnSameDay(current_user_data, first, second) {
  const utc = getCurrentUTC(current_user_data)

  let momentObject1 = moment(first).tz(utc);
  let momentObject2 = moment.tz(second.split('+')[0], utc);

  return (
    momentObject1.isSame(momentObject2, 'day')
  );
}

//Imported from jagtrax. Please fix.
export function dateText(event) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  if(!event.start_date) {
    return "";
  }
  const startDate = new Date(event.start_date);
  if (event.end_date) {
    const endDate = new Date(event.end_date);
    return `${
      formatToTimeZone(startDate, "MMMM D",  {timeZone: "America/Los_Angeles"})
    } - ${
      formatToTimeZone(endDate, "MMMM D YYYY",  {timeZone: "America/Los_Angeles"})
    }`
  }
  return formatToTimeZone(startDate, "MMMM D YYYY",  {timeZone: "America/Los_Angeles"})
}
