import React from "react";
import PropTypes from "prop-types";
import GeneralChit from "@layout/tables/GeneralChit"
import UniversalImageCircle from "@images/UniversalImageCircle"
import EventModal from "@events/modals/EventModal"
import { isAccountManager } from "@utility/PermissionUtility"
class EventTableChit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.props.event||{}).id == (nextProps.event||{}).id
  }

  render() {
    let { event, user } = this.props;
    event = event || {}
    return isAccountManager(user) ? (
      <EventModal
        user={user}
        event={event}
      >
        <GeneralChit
          circle={
            <UniversalImageCircle size={'tiny'} image={null} name={event.name} id={event.id}/>
          }
          name={event.name}
        />
      </EventModal>

    ) : (
      <GeneralChit
        circle={
          <UniversalImageCircle size={'tiny'} image={null} name={event.name} id={event.id}/>
        }
        name={event.name}
        link={`/events/${event.id}`}
      />
    )

  }
}

export default EventTableChit;
