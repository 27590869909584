import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

class ReportSortingCriteriaField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.toggleAscending = this.toggleAscending.bind(this);
    this.setSortingOption = this.setSortingOption.bind(this);
  }

  toggleAscending(key) {
    const { criteria, updateAllCriteria, index } = this.props;
    updateAllCriteria({ ascending: !criteria.ascending, selectedOption: criteria.selectedOption, count: index });
  }

  setSortingOption(key) {
    const { criteria, updateAllCriteria, index } = this.props;
    updateAllCriteria({ ascending: criteria.ascending, selectedOption: key, count: index })
  }

  render() {
    const { options, index, deleteIndex, criteria } = this.props;
    const { ascending } = this.state;
    return (
      <>
        <Select options={options} value={criteria.selectedOption} onChange={(key) => {this.setSortingOption(key)}} />
        <div className={`report-tool-ascending-toggle ${criteria.ascending ? 'sort-criteria-selected' : ''}`} onClick={this.toggleAscending}>
          {
            criteria.ascending ?
            <img src="/images/report-tool/form-icons/ascending-black.svg" />
            :
            <img src="/images/report-tool/form-icons/ascending-grey.svg" />
          }
        </div>
        <div className={`report-tool-ascending-toggle ${criteria.ascending ? '' : 'sort-criteria-selected'}`} onClick={this.toggleAscending}>
          {
            criteria.ascending ?
            <img src="/images/report-tool/form-icons/descending-grey.svg" />
            :
            <img src="/images/report-tool/form-icons/descending-black.svg" />
          }
        </div>
        <div className="report-tool-delete-modifier" onClick={() => {deleteIndex(index)}}>
          <img src="/images/report-tool/form-icons/icon-close-grey.svg" />
        </div>
      </>
    );
  }
}

export default ReportSortingCriteriaField;
