import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Select from 'react-select-virtualized'

class UserDeleteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      selectedUser: null,
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.destroyUserModal = this.destroyUserModal.bind(this);
  }

  getUserOptions() {
    const { users, editing_user } = this.props;
    if(users) {
      return users.filter(x=> x.id != editing_user.id).map(x=> {return {value: x.id, label: `${x.first_name} ${x.last_name}`}})
    }
    return [];
  }

  renderModal() {
    const {editing_user, user, contacts } = this.props;
    const { selectedUser, selectErroring } = this.state;
    return (
      <div className="row">
        <div className="col-xs-12 ">
          <div className="cnt-admin-user-management-delete-modal-title">
              Are you sure you want to delete this user?
          </div>
          <div className="">
            <button className="cnt-submit-button" onClick={() => this.props.destroyUser(null)}> Confirm and Delete</button>
            <button className="cnt-cancel-button"onClick={this.toggleModal}> Cancel</button>
          </div>
        </div>
      </div>
    )

  }

  destroyUserModal() {
    const { destroyUser } = this.props;
    const { selectedUser}= this.state;
    destroyUser(selectedUser.value)
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen
    });
  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, sessions } = this.props;
    return (
      <>
        <div className={outerClassNames} style={{cursor:"pointer", display: "inline-block", overflow: "visible"}} onClick={() => this.toggleModal()} >
          {children}
        </div>
        <Modal
          className="white-background cnt-user-admin-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default UserDeleteModal;
