import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import DefaultLayout from "@layout/DefaultLayout";
import { getTemplates, getReportTypeImage } from "@utility/ReportUtils";

class ReportType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templateSelected: false
    }
    this.templateSelect = this.templateSelect.bind(this);
  }

  renderReportType() {
    const { baseObj, reportTypes, setBaseObj } = this.props;
    const renderedTypes = reportTypes.map(type =>
      <div
        className={`report-select-key-type ${type === baseObj.value ? 'report-select-key-type-selected' : ''}`}
        onClick={() => setBaseObj({value: type})}
      >
        <div className="report-select-key-type-title">
          {type}
        </div>
        <img src={getReportTypeImage(type)} />
      </div>
    );
    return (
      <div className="report-select-key-type-wrapper">
        {renderedTypes}
      </div>
    );
  }

  renderSelectReportType() {
    const { baseObj, reportTypes, setBaseObj } = this.props;
    const objects = reportTypes.map(object => {
      const obj = { value: object, label: object };
      return obj;
    });
    return (
      <div>
        <div className="report-select-keys">
          <label>Select a report type</label>
        </div>
        {this.renderReportType(objects)}
      </div>
    );
  }

  renderSelectedKeys() {
    const { selectedKeys } = this.props;
    if (selectedKeys.length === 0) return '';
    const renderedKeys = selectedKeys.map(key =>
      <div className="report-tool-select-template-key">
        {key.label}
      </div>
    );
    return (
      <div className="report-tool-select-template-preview">
        <div className="report-tool-select-template-preview-title">Template presets:</div>
        <div className="report-tool-select-template-keys">{renderedKeys}</div>
        <div className="report-tool-select-template-preview-disclaimer">Presets can be edited in next step.</div>
      </div>
    );
  }

  templateSelect(template) {
    const { setSelectedKeys } = this.props;
    setSelectedKeys(template.keys);
    this.setState({templateSelected: true});
  }

  renderTemplates() {
    const { baseObj } = this.props;
    const renderedTemplates = getTemplates(baseObj).map(template =>
      <div>
        <input
          type="radio"
          name={`${baseObj.value}-template`}
          value={template.name}
          onChange={() => this.templateSelect(template)}
        />
        {template.name}
      </div>
    );
    return (
      <div className="report-tool-select-template-wrapper">
        <div>
          <input
            type="radio"
            name={`${baseObj.value}-template`}
            value={'No template'}
            onChange={() => this.templateSelect({keys: []})}
          />
          No template
        </div>
        {renderedTemplates}
      </div>
    );
  }

  renderSelectReportTemplate() {
    return (
      <div className="report-tool-select-template">
        <div className="report-tool-select-template-left">
          <div>
            <label>Select a report template</label>
          </div>
          {this.renderTemplates()}
        </div>
        <div className="report-tool-select-template-right">
          {this.renderSelectedKeys()}
        </div>
        <div style={{ clear: "both" }} />
      </div>
    );
  }

  renderSubmit() {
    const { submit, baseObj } = this.props;
    const { templateSelected } = this.state;
    if (Object.keys(baseObj).length === 0 || !templateSelected) {
      return (
        <div
          className="report-tool-button report-tool-button-disabled"
          style={{ marginLeft: "10px" }}
        >
          Continue
        </div>
      )
    }
    return (
      <div
        className="report-tool-button report-tool-button-blue"
        style={{ marginLeft: "10px" }}
        onClick={submit}
      >
        Continue
      </div>
    );
  }

  renderButtons() {
    return (
      <div className="report-select-keys report-select-keys-divider">
        {this.renderSubmit()}
        <div
          className="report-tool-button report-tool-button-white"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            javascript: history.back();
          }}
        >
          Cancel
        </div>
      </div>
    );
  }

  render() {
    const { events, user } = this.props;
    return (
      <DefaultLayout
        events={events}
        sidebarId="reports"
        user={user}
      >
        <div className="report-tool-form-wrapper" style={{ margin: "0px" }}>
          <div className="report-tool-page-header">
            <h1 className="cnt-page-header">
              New Report
            </h1>
          </div>
          <div style={{ clear: "both" }} />
          {this.renderSelectReportType()}
          {this.renderSelectReportTemplate()}
          {this.renderButtons()}
        </div>
      </DefaultLayout>
    );
  }
}

ReportType.propTypes = {
  baseObj: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired,
  reportTypes: PropTypes.array.isRequired,
  setBaseObj: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default ReportType;
