import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import { isJaguar } from "@utility/PermissionUtility"
import EventForm from "./forms/EventForm"
class EventsNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { user } = this.props;
    return (
      <DefaultLayout
        {...this.props}
        breadcrumbs={
          ['dashboard', 'events']
        }
        sidebarId="events"
      >
        <h1 className="cnt-page-header">
          New Event
        </h1>
        <EventForm {...this.props} />
      </DefaultLayout>
    );
  }
}

export default EventsNew;
