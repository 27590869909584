import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import {isJaguar, addMy} from "@utility/PermissionUtility"
import UpdatesTable from "@updates/tables/UpdatesTable"

class UpdatesIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const { user } = this.props;
    return (
      <DefaultLayout
        {...this.props}
        sidebarId="updates"
      >
        <h1 className="cnt-page-header">
          Updates
        </h1>
        <br/>
        <br/>
        <UpdatesTable {...this.props} />
      </DefaultLayout>
    );
  }
}

export default UpdatesIndex;
