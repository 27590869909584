import React from "react";
import PropTypes from "prop-types";
import ReportFilterField from "./ReportFilterField";

class ReportFilterChain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: [],
      filterCount: 0
    }
    this.addFilter = this.addFilter.bind(this);
    this.update = this.update.bind(this);
    this.deleteFilterAtIndex = this.deleteFilterAtIndex.bind(this);
  }

  componentDidMount() {
    const { chain, options } = this.props;
    if (chain && Array.isArray(options) && options.length > 0) {
      const newFilters = chain.filters;
      newFilters.forEach((filter, index) => {
        const matchingOption = options.find(ele => ele.value === filter.selectedOption.value);
        filter.count = index;
        filter.selectedOption = matchingOption;
      });
      this.setState({
        filters: newFilters,
        filterCount: newFilters.length
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { options, chain } = this.props;
    if (options !== prevProps.options && Array.isArray(options) && options.length > 0 && chain) {
      const newFilters = chain.filters;
      newFilters.forEach((filter, index) => {
        const matchingOption = options.find(ele => ele.value === filter.selectedOption.value);
        filter.count = index;
        filter.selectedOption = matchingOption;
      });
      this.setState({
        filters: newFilters,
        filterCount: newFilters.length
      });

      // newFilters.forEach((filter, index) => {
      //   this.addFilter({count: index, filter: filter})
      // });
    }
  }

  update(filter) {
    const { id, options, updateChain } = this.props
    const { filters } = this.state;
    const newFilters = filters;
    newFilters.forEach(item => {
      if (item.count === filter.count) {
        const matchingOption = options.find(ele => ele.value === filter.selectedOption.value);
        item.operation = filter.operation;
        item.operationValue = filter.operationValue;
        item.selectedOption = matchingOption;
      }
    });
    updateChain({ id: id, filters: newFilters });
    this.setState({ filters: newFilters })
  }

  deleteFilterAtIndex(index) {
    const { id, updateChain } = this.props
    const { filters } = this.state;
    let newFilters = [];
    filters.forEach(filter => {
      if (filter.count !== index) {
        newFilters.push(filter);
      }
    });
    updateChain({ id: id, filters: newFilters });
    this.setState({ filters: newFilters });
  }

  addFilter({count = 1, filter = null}) {
    const { filters, filterCount } = this.state;
    const newFilters = filters;
    if (!filter) {
      newFilters.push({ selectedOption: {label: 'Select a column', value: 'select a field'},
                        selectedOperation: {label: 'operation', value: 'operation'},
                        operationValue: '',
                        count: count === -1 ? filterCount : count});
    } else {
      newFilters.push(filter);
    }
    this.setState({
      filters: newFilters,
      filterCount: filterCount + 1
    });
  }

  renderFilters() {
    const { options } = this.props;
    const { filters } = this.state;
    const operations = [
      {value: 'equals', label: 'Includes (text)'},
      {value: 'less', label: 'Less than (integer)'},
      {value: 'greater', label: 'Greater than (integer)'}
    ]
    return filters.map((filter, index) => {
      return (
        <div key={`filter-item-wrapper-${filter.count}`}>
          <div
            key={`filter-item-${filter.count}`}
            style={{display: "flex", alignItems: "baseline"}}
            className="report-tool-filter-item"
          >
            <ReportFilterField
              key={`filter-${filter.count}`}
              options={options}
              operationOptions={operations}
              index={filter.count}
              filter={filter}
              updateAllCriteria={this.update}
              deleteIndex={this.deleteFilterAtIndex}
            />
          </div>
          {this.renderAnd()}
        </div>
      );
    });
  }

  renderAnd() {
    const { filters } = this.state;
    if (filters.length > 0) {
      return (
        <div className="report-tool-filter-chain-and">AND</div>
      );
    }
    return <></>;
  }

  renderFilterChainLabel() {
    const { count } = this.props;
    return (
      <div className="report-tool-filter-chain-title">{`Chain ${count}`}</div>
    );
  }

  render() {
    const { filterCount } = this.state;
    return (
      <div className="report-tool-filter-chain-wrapper">
        {this.renderFilterChainLabel()}
        {this.renderFilters()}
        <div className="report-tool-modifier-link" onClick={() => { this.addFilter({count: filterCount + 1}) }}>Add filter to chain</div>
      </div>
    );
  }
}

export default ReportFilterChain;
