import React from "react";
import ReactTooltip from "react-tooltip";

class DefaultTooltip extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ReactTooltip
        id={this.props.tooltipId}
        place="top"
        type="dark"
        backgroundColor="#0D274D"
        effect="solid"
      >
        <div className="cnt-default-layout-tooltip-content">
          {this.props.children}
        </div>

      </ReactTooltip>
    );
  }
}

export default DefaultTooltip;
