export function flattenObj(ob) {
  let result = {};
  for (const i in ob) {
    if ((typeof ob[i]) === 'object') {
      const temp = flattenObj(ob[i]);
      for (const j in temp) {
        if (temp[j] === "{}") {
          result[i + '.' + j] = "";
        } else {
          result[i + '.' + j] = temp[j];
        }
      }
    }
    else {
      if (ob[i] === "{}") {
        result[i] = "";
      } else {
        result[i] = ob[i];
      }
    }
  }
  return result;
}
