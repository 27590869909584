import React from "react";
import PropTypes from "prop-types";

class ToggleOnOff extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { value } = this.props;
    return (
      <div onClick={this.props.onClick} className={`cnt-default-on-off-toggle ${value ? "toggled-on" : "toggled-off"}`}>
        <div className={`cnt-default-on-off-toggle-side cnt-default-on-off-toggle-on ${value ? "active": ""}`}>
          ON
        </div>
        <div className={`cnt-default-on-off-toggle-side cnt-default-on-off-toggle-off ${value ? "": "active"}`}>
          OFF
        </div>
      </div>
    );
  }
}

export default ToggleOnOff;
