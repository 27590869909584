import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";

class NominationStarRanking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ranking: props.nomination.ranking,
      dirty: false,
    }
    this.setRanking = this.setRanking.bind(this);
    this.saveRanking = this.saveRanking.bind(this);
  }

  isStarActive(score) {
    const { ranking }= this.state;
    if(ranking) {
      return score <= ranking;
    }
    return false;
  }

  renderStars() {
    return [1,2,3,4,5].map(score =>(
      <img className="cnt-default-layout-nomination-star" onClick={() => this.setRanking(score)} src={this.isStarActive(score) ? "/layout/gold-star.svg" : "/layout/gray-star.svg"} />
    ))
  }

  setRanking(rank) {
    this.setState({ranking: rank, dirty: true})
  }

  saveRanking(rank) {
    const { nomination } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch(`/nominations/${nomination.id}/set_ranking`, {
      method: "POST",
      redirect: "manual",
      body: JSON.stringify({ranking: rank}),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({ranking: rank, dirty: false})
        }
        else {
          this.setState({
            error: json.error
          })
        }
      });
  }

  renderSaveButton() {
    const { dirty, ranking } = this.state;
    return (
      <div style={{display:"block", textAlign: "center", paddingTop:"20px", height: "35px"}}>
        { dirty && ranking ? (
          <a href="#" onClick={()=>this.saveRanking(ranking)}>
            Save Ranking
          </a>
        ):""}
      </div>
    )
  }

  render() {
    return (
      <>
      <div className="cnt-default-layout-nomination-stars-container">
        {this.renderStars()}
      </div>
      {this.renderSaveButton()}
      </>
    );
  }
}

export default NominationStarRanking;
