import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import Cookies from "universal-cookie";
import ReportTableItems from "./ReportTableItems";
import ReportDeleteModal from "./ReportDeleteModal";
import ReportTableFilters from "./ReportTableFilters";
import ReportScheduler from "./ReportScheduler";
import DefaultTooltip from "@layout/tooltips/DefaultTooltip";
import Modal from "react-modal";
import { DateTime } from "luxon";

class ReportTable extends React.Component {
  constructor(props) {
    super(props);
    const hash = window.location.hash.substr(1);
    this.state = {
      filteredReports: props.reports,
      reportTabs: [
        {id: "reports", name: "Reports", content: this.renderReportTable()},
        {id: "my_reports", name: "My Reports", content: this.renderMyReportTable()},
        {id: "archived", name: "Archived", content: this.renderArchivedReportTable()}
      ],
      downloadModalOpen: false,
      downloadId: '',
      scheduleModalOpen: false,
      scheduleId: '',
      downloadReportFileId: null,
      downloadReportGenerated: false,
      downloadReportUrl: null,
      current_tab_id: hash ? hash : props.default_tab_id || 'reports',
      starredReports: props.starred_reports
    }
    this.toggleDownloadModalAndId = this.toggleDownloadModalAndId.bind(this);
    this.toggleScheduleModalAndId = this.toggleScheduleModalAndId.bind(this);
    this.toggleDownloadModal = this.toggleDownloadModal.bind(this);
    this.toggleScheduleModal = this.toggleScheduleModal.bind(this);
    this.generateReportCsv = this.generateReportCsv.bind(this);
    this.generateReportXlsx = this.generateReportXlsx.bind(this);
    this.clearReportDownload = this.clearReportDownload.bind(this);
    this.fetchReportFile = this.fetchReportFile.bind(this);
    this.toggleStarred = this.toggleStarred.bind(this);
    this.updateFilteredReports = this.updateFilteredReports.bind(this);
  }

  updateFilteredReports(reports) {
    const { reportTabs } = this.state;
    this.setState({
      filteredReports: reports
    },
      () => {
        this.setState({
          reportTabs: [
            {id: "reports", name: "Reports", content: this.renderReportTable()},
            {id: "my_reports", name: "My Reports", content: this.renderMyReportTable()},
            {id: "archived", name: "Archived", content: this.renderArchivedReportTable()}
          ]
        });
      }
    );
  }

  toggleDownloadModal() {
    const { downloadModalOpen } = this.state;
    this.setState({ downloadModalOpen: !downloadModalOpen });
  }

  toggleScheduleModal() {
    const { scheduleModalOpen } = this.state;
    this.setState({ scheduleModalOpen: !scheduleModalOpen });
  }

  clearReportDownload() {
    this.setState({
      downloadModalOpen: false,
      downloadReportFileId: null,
      downloadReportGenerated: false,
      downloadReportUrl: false,
      downloadId: ''
    });
  }

  renderReportName(row) {
    return row.name;
  }

  renderReportType(row) {
    return row.object_type;
  }

  renderReportModifiedTime(row) {
    let datetime = DateTime.fromISO(row.updated_at, { zone: "utc"}).toLocal();
    let time = datetime.toFormat('hh:mm');
    let ampm = datetime.toFormat('a') === 'AM' ? 'a.m.' : 'p.m.';
    let month = datetime.toFormat('LLL').toUpperCase();
    let dayyear = datetime.toFormat('dd/yyyy');
    return (
      <>{time + ' ' + ampm}<br/>{month + '/' + dayyear}</>
    );
  }

  renderReportModifiedBy(row) {
    const { users } = this.props;
    let modifiedEmail = "";
    users.forEach(user => {
      if (user.id === row.modified_by_id) modifiedEmail = user.email;
    });
    return modifiedEmail;
  }

  isReportStarred(row) {
    const { starredReports } = this.state;
    if (starredReports.length > 0 && starredReports.find(s => s.report_id === row.id)) {
      return true;
    }
    return false;
  }

  renderReportStarred(row) {
    const isStarred = this.isReportStarred(row);
    return (
      <>
        <DefaultTooltip
          tooltipId={`sg-report-table-star-tooltip-${row.id}`}
        >
          { isStarred ? "Un-favorite" : "Favorite" }
        </DefaultTooltip>
        <a onClick={() => {this.toggleStarred(row.id, isStarred)}} data-for={`sg-report-table-star-tooltip-${row.id}`} data-tip>
          {
            isStarred ?
            <img src="/images/report-tool/table-icons/starred.svg"/>
            :
            <img src="/images/report-tool/table-icons/unstarred.svg"/>
          }
        </a>
      </>
    );
  }

  renderReportLocation(row) {
    if (row.private) {
      return "Private";
    }
    return "Shared";
  }

  toggleStarred(id, isStarred) {
    const { current_user } = this.props;
    const { starredReports, filteredReports } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    let endpoint = 'set_starred';
    if (isStarred) endpoint = 'set_unstarred';
    fetch(`/reports/${id}/${endpoint}`, {
      method: "POST",
      redirect: "manual",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          console.log('success');
          if (isStarred) {
            this.setState({starredReports: starredReports.filter(s => s.report_id !== id )});
          } else {
            starredReports.push({user_id: current_user.id, report_id: id})
            this.setState({starredReports: starredReports});
          }
          this.updateFilteredReports(filteredReports);
        } else {
          console.log(json.error);
        }
      });
  }

  generateReportXlsx() {
    const { downloadId } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch(`/reports/${downloadId}/generate_report`, {
      method: "GET",
      redirect: "manual",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          const reportFileId = json.report_file_id;
          this.setState({
            downloadReportFileId: reportFileId
          }, () => {
            // start polling in setState callback to ensure downloadReportFileId is set
            setTimeout(this.fetchReportFile, 3000)
          });
        } else {
          console.log(json.error);
        }
      });
  }

  generateReportCsv() {
    const { downloadId } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch(`/reports/${downloadId}/generate_report_csv`, {
      method: "GET",
      redirect: "manual",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          const reportFileId = json.report_file_id;
          this.setState({
            downloadReportFileId: reportFileId
          }, () => {
            // start polling in setState callback to ensure downloadReportFileId is set
            setTimeout(this.fetchReportFile, 3000)
          });
        } else {
          console.log(json.error);
        }
      });
  }

  fetchReportFile() {
    const { downloadReportFileId } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch(`/reports/fetch_report`, {
      method: "POST",
      redirect: "manual",
      body: JSON.stringify({
        report_file_id: downloadReportFileId
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error != null) {
          console.log(json.error);
        } else {
          switch(json.status) {
            case "completed":
              this.setState({
                downloadReportGenerated: true,
                downloadReportUrl: json.report_url
              });
              break;
            case "failed":
              console.log(json.error);
              alert("Report generation failed, try again.");
              break;
            default:
              // still generating, continue fetching
              setTimeout(this.fetchReportFile, 3000);
          }
        }
      });
  }

  toggleDownloadModalAndId(id) {
    const { downloadModalOpen } = this.state;
    this.setState({
      downloadModalOpen: !downloadModalOpen,
      downloadId: id
    });
  }

  toggleScheduleModalAndId(id) {
    const { scheduleModalOpen } = this.state;
    this.setState({
      scheduleModalOpen: !scheduleModalOpen,
      scheduleId: id
    });
  }

  renderReportActions(row, type) {
    const { current_user, report_emails } = this.props;
    if (type === "archive") {
      return (
        <div className="report-table-actions">
          <DefaultTooltip
            tooltipId={`sg-report-table-download-tooltip-${row.id}`}
          >
            Download
          </DefaultTooltip>
          <a onClick={() => this.toggleDownloadModalAndId(row.id)} target="__blank" data-for={`sg-report-table-download-tooltip-${row.id}`} data-tip>
            <img src="/images/report-tool/table-icons/download.svg"/>
          </a>
          <DefaultTooltip
            tooltipId={`sg-report-table-unarchive-tooltip-${row.id}`}
          >
            Unarchive
          </DefaultTooltip>
          <a onClick={() => this.unArchiveReport(row)} data-for={`sg-report-table-unarchive-tooltip-${row.id}`} data-tip>
            <img src="/images/report-tool/table-icons/unarchive.svg" />
          </a>
          <DefaultTooltip
            tooltipId={`sg-report-table-delete-tooltip-${row.id}`}
          >
            Delete
          </DefaultTooltip>
          <ReportDeleteModal deleteReport={this.deleteReport} report={row}>
            <a data-for={`sg-report-table-delete-tooltip-${row.id}`} data-tip>
              <img src="/images/report-tool/table-icons/delete.svg" />
            </a>
          </ReportDeleteModal>
        </div>
      );
    }
    return (
      <div className="report-table-actions">
        <DefaultTooltip
          tooltipId={`sg-report-table-view-tooltip-${row.id}`}
        >
          View
        </DefaultTooltip>
        {
          row.created_by_id === current_user.id ?
          <a href={`/reports/${row.id}`} data-for={`sg-report-table-view-tooltip-${row.id}`} data-tip>
            <img src="/images/report-tool/table-icons/view.svg"/>
          </a>
          :
          <></>
        }
        <DefaultTooltip
          tooltipId={`sg-report-table-download-tooltip-${row.id}`}
        >
          Download
        </DefaultTooltip>
        <a onClick={() => this.toggleDownloadModalAndId(row.id)}  target="__blank" data-for={`sg-report-table-download-tooltip-${row.id}`} data-tip>
          <img src="/images/report-tool/table-icons/download.svg" />
        </a>
        <DefaultTooltip
          tooltipId={`sg-report-table-schedule-tooltip-${row.id}`}
        >
          Schedule
        </DefaultTooltip>
        <a onClick={() => this.toggleScheduleModalAndId(row.id)}  target="__blank" data-for={`sg-report-table-schedule-tooltip-${row.id}`} data-tip>
          {
            report_emails.find(report_email => report_email.report_id === row.id) !== undefined ?
            <img src="/images/report-tool/table-icons/scheduled.svg" />
            :
            <img src="/images/report-tool/table-icons/schedule.svg" />
          }
        </a>
        <DefaultTooltip
          tooltipId={`sg-report-table-archive-tooltip-${row.id}`}
        >
          Archive
        </DefaultTooltip>
        <a onClick={() => this.archiveReport(row)} data-for={`sg-report-table-archive-tooltip-${row.id}`} data-tip>
          <img src="/images/report-tool/table-icons/archive.svg" />
          </a>
      </div>
    );
  }

  deleteReport(report) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/reports/${report.id}`, {
      method: 'DELETE',
      redirect: "manual",
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        console.log('success');
        location.reload();
      } else {
        console.log(json.error);
      }
    });
  }

  archiveReport(report) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/reports/${report.id}/archive`, {
      method: 'POST',
      redirect: "manual",
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        console.log('success');
        location.reload();
      } else {
        console.log(json.error);
      }
    });
  }

  unArchiveReport(report) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/reports/${report.id}/unarchive`, {
      method: 'POST',
      redirect: "manual",
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        console.log('success');
        location.reload();
      } else {
        console.log(json.error);
      }
    });
  }

  getColumns(type) {
    let allColumns = [
      { title: 'Report Name', field: 'id', render: (row) => this.renderReportName(row)},
      { title: 'Report Type', field: 'name', render: (row) => this.renderReportType(row)},
      { title: 'Last Modified', field: 'contact_count', render: (row) => this.renderReportModifiedTime(row)},
      { title: 'Modified By', field: 'id', render: (row) => this.renderReportModifiedBy(row)},
      { title: 'Starred', field: 'id', render: (row) => this.renderReportStarred(row)},
      { title: 'Actions', field: 'id', render: (row) => this.renderReportActions(row, type)}
    ];
    return allColumns;
  }

  getColumnsArchived(type) {
    let allColumns = [
      { title: 'Report Name', field: 'id', render: (row) => this.renderReportName(row)},
      { title: 'Report Type', field: 'name', render: (row) => this.renderReportType(row)},
      { title: 'Location', field: 'contact_count', render: (row) => this.renderReportLocation(row)},
      { title: 'Actions', field: 'id', render: (row) => this.renderReportActions(row, type)}
    ];
    return allColumns;
  }

  renderReportTable() {
    let filteredReports = []
    if (this.state === undefined) {
      filteredReports = this.props.reports;
    } else {
      filteredReports = this.state.filteredReports;
    }

    let publicReports = filteredReports.filter(report => report.private !== true && report.archived === false);
    return (
      <ReportTableItems
        name={'reports'}
        reports={publicReports}
        columns={this.getColumns('public')}
        data={publicReports}
        type={'public'}
      />
    );
  }

  renderMyReportTable() {
    const { current_user } = this.props;
    let filteredReports = []
    if (this.state === undefined) {
      filteredReports = this.props.reports;
    } else {
      filteredReports = this.state.filteredReports;
    }
    let privateReports = []
    if (current_user !== null) {
      privateReports = filteredReports.filter(report => report.archived === false && current_user.id === report.created_by_id);
    }
    return (
      <ReportTableItems
        name={'reports'}
        reports={privateReports}
        columns={this.getColumns('private')}
        data={privateReports}
      />
    );
  }

  renderArchivedReportTable() {
    const { current_user } = this.props;
    let filteredReports = []
    if (this.state === undefined) {
      filteredReports = this.props.reports;
    } else {
      filteredReports = this.state.filteredReports;
    }
    let archivedReports = filteredReports.filter(report => {
      return (
        (report.archived === true && report.private === false) ||
        (report.archived === true && (report.private === true && report.created_by_id === current_user.id))
      )
    });
    return (
      <ReportTableItems
        name={'reports'}
        reports={archivedReports}
        columns={this.getColumnsArchived('archive')}
        data={archivedReports}
      />
    );
  }

  changeTab(tab) {
    this.setState({
      current_tab_id: tab.id
    })
  }

  isTabActive(tab) {
  const { current_tab_id } = this.state;
    if(tab && tab['id'] && tab['id'] == current_tab_id) {
      return true;
    }
    return false;
  }

  renderTableTabs() {
    const { reportTabs } = this.state;
    const renderedTabs = reportTabs.map(tab =>
      <div key={`report-${tab.name}`} className={`report-table-tab ${this.isTabActive(tab) ? "active" : ""}`} onClick={() => this.changeTab(tab)}>
        {tab.name}
      </div>
    );
    return (
      <div className="report-table-tabs-wrapper" style={{marginBottom: "20px"}}>
        {renderedTabs}
      </div>
    );
  }

  getActiveTab() {
    const { reportTabs } = this.state;
    const current_tab = reportTabs.find(tab => this.isTabActive(tab));
    return current_tab;
  }

  renderContent() {
    const activeTab = this.getActiveTab();
    return (
      <div id="cnt-default-layout-tabbed-page-body">
        <div className="cnt-default-layout-general-table-container">
          {activeTab.content || ""}
        </div>
      </div>
    )
  }

  renderModalDownload() {
    const { downloadReportUrl } = this.state;
    return (
      <>
        <div className="report-modal-download-text">
          <a href={downloadReportUrl} download>Click here to download report</a>
        </div>
        <div className="report-modal-download-options">
          <a className="report-modal-download-option" onClick={this.clearReportDownload}>
            Close
          </a>
        </div>
      </>
    );
  }

  renderModalPolling() {
    return (
      <>
        <div className="report-modal-download-text">
          <p>Your report is generating. It will be ready to download in a few moments.</p>
          <p>If you are generating a very large report, it may take a few minutes.</p>
        </div>
      </>
    );
  }

  renderModalGenerateButtons(reportId) {
    return (
      <>
        <div className="report-modal-download-text">
          Export Report as
        </div>
        <div className="report-modal-download-options">
          <a className="report-modal-download-option" onClick={() => { this.generateReportXlsx(reportId) }}>
            XLSX
          </a>
          <a className="report-modal-download-option" onClick={() => { this.generateReportCsv(reportId) }}>
            CSV
          </a>
        </div>
      </>
    );
  }

  renderModalBody() {
    const { downloadReportFileId, downloadReportGenerated } = this.state;

    // no report job running, present buttons for starting job
    if (!downloadReportFileId) {
      return this.renderModalGenerateButtons();
    }

    // report generating job in progress, continue to poll for job completion
    if (downloadReportFileId && !downloadReportGenerated) {
      return this.renderModalPolling();
    }

    // report generated and ready to download
    if (downloadReportGenerated) {
      return this.renderModalDownload();
    }
  }

  renderModal() {
    return (
      <div className="report-modal-download-wrapper">
        {this.renderModalBody()}
      </div>
    );
  }

  renderModalOld() {
    const { downloadId } = this.state;
    return (
      <div className="report-modal-download-wrapper">
        <div className="report-modal-download-text">
          Export Report as
        </div>
        <div className="report-modal-download-options">
          <a className="report-modal-download-option" href={`/reports/${downloadId}/download`} onClick={this.toggleDownloadModal}>
            XLSX
          </a>
          <a className="report-modal-download-option" href={`/reports/${downloadId}/download_csv`} onClick={this.toggleDownloadModal}>
            CSV
          </a>
        </div>
      </div>
    );
  }

  renderScheduleModal() {
    const { current_user, report_emails } = this.props;
    const { scheduleId } = this.state;
    return (
      <div className="report-modal-download-wrapper" style={{padding: "35px"}}>
        <div className="report-modal-download-text">
          Schedule Report
        </div>
        <div className="report-modal-download-options">
          <ReportScheduler report_id={scheduleId} current_user={current_user} report_email={report_emails.find(report_email => report_email.report_id === scheduleId)} />
        </div>
      </div>
    );
  }

  render() {
    const { reports } = this.props;
    const { downloadModalOpen, scheduleModalOpen, starredReports } = this.state;
    return (
      <>
        <div className="report-table-wrapper">
          <ReportTableFilters reports={reports} updateFilteredReports={this.updateFilteredReports} starredReports={starredReports}/>
          {this.renderTableTabs()}
          {this.renderContent()}
        </div>
        <Modal
          className="white-background report-modal-download disable-scrollbars"
          overlayClassName="report-modal-overlay"
          isOpen={downloadModalOpen}
          onRequestClose={this.toggleDownloadModal}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
        <Modal
          className="white-background report-modal-schedule disable-scrollbars"
          overlayClassName="report-modal-overlay"
          isOpen={scheduleModalOpen}
          onRequestClose={this.toggleScheduleModal}
          contentLabel=""
        >
          {this.renderScheduleModal()}
        </Modal>
      </>
    );
  }
}

export default ReportTable;
