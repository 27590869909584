import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import { canEditNomination, isAccountManager } from "@utility/PermissionUtility"
import ContactCondensedView from "./ContactCondensedView"
import UniversalImageCircle from "@images/UniversalImageCircle"
import CompanyCondensedView from "@companies/modals/CompanyCondensedView"
class ContactProfileModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      contact: null,
      company: null,
    }
    this.toggleModal = this.toggleModal.bind(this);
  }

  getContact() {
    const { contact } = this.props;
    if(contact != null) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch(`/contacts/${contact.id}/get_contact_modal_info`, {
        method: "GET",
        redirect: "manual",
        body: null,
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            contact: json.contact,
            company: json.company
          })
        }
        else {
          this.setState({
            error: json.error
          })
        }
      }).catch((error)=>{
        this.setState({
        error: "Could not load contacts at this time. Try again later."
      })});
    }
  }

  renderModal() {
    const { contact,company } = this.state;
    const { user } = this.props;
    if(contact) {
      return (
        <div className="row">
          <div className="col-xs-12 cnt-default-layout-nomination-contact-area">
            <div className="cnt-default-layout-nomination-contact-description-container">
              <div className="cnt-default-layout-nomination-contact-picture">
                <UniversalImageCircle image={contact.contact_image} name={contact.first_name} id={contact.id}/>
              </div>
              <div className="cnt-default-layout-nomination-contact-description">
                <span className="cnt-default-layout-nomination-contact-description-name">
                  {contact.first_name} {contact.last_name}
                </span>
              </div>
            </div>
          </div>
          <div className="col-xs-12">
            <ContactCondensedView user={user} contact={contact}/>
          </div>
          {company && !isAccountManager(user)? (
            <div className="col-xs-12">
              <CompanyCondensedView company={company} />
            </div>
          ):""}
          <div className="col-xs-12">
            <br/>
            <br/>
          </div>
          <div className="col-xs-12">
            {canEditNomination(user) ? (
              <a href={`/nominations/new?contact_id=${contact.id}`}>
                <button className="cnt-approve-button" type="button">Nominate Contact</button>
              </a>
            ):""}
            {!isAccountManager(user) ? (
              <button className="cnt-submit-button" onClick={() => {window.location = `/contacts/${contact.id}`}}>Edit Contact</button>
            ):""}
            <button className="cnt-cancel-button" onClick={this.toggleModal}> Cancel</button>
          </div>
        </div>
      );
    }else {
      //Render Loading
      return (
        <div className="row">
          <div className="col-xs-12 ">
            <div className="ctn-default-layout-log-table-loading">
              <h1> Loading </h1>
              <br />
              <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
          </div>
        </div>
      );
    }

  }

  toggleModal() {
    if(this.props.disabled) {
      return;
    }
    const { modalOpen, contact } = this.state;
    const propContact = this.props.contact;

    if(!modalOpen && propContact) {
      if(!contact || contact.id != propContact.id) {
        this.getContact();
      }
    }

    this.setState({
      modalOpen: !modalOpen
    });

  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, sessions } = this.props;
    return (
      <>
        <div className={`cnt-modal-openable-button cnt-modal-openable-button-contact ${outerClassNames}`} onClick={this.toggleModal} >
          {children}
        </div>
        {modalOpen ? (
          <Modal
            className="white-background cnt-user-admin-modal disable-scrollbars"
            overlayClassName="cnt-default-layout-modal-overlay"
            isOpen={modalOpen}
            onRequestClose={this.toggleModal}
            contentLabel=""
          >
            {this.renderModal()}
          </Modal>
        ):""}
        <Modal
          className="white-background cnt-user-admin-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default ContactProfileModal;
