import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import { dateText } from "@utility/TimeUtils"
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime, listTimeZones } from 'date-fns-timezone';
import LogItemChange from "./LogItemChange"
import { ignoredFields } from "./LogUtils"
class LogTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        logs: null,
        users: null,
    };
    this.getLogs = this.getLogs.bind(this);
    this.getUsers = this.getUsers.bind(this);
  }

  componentDidMount() {
    this.getLogs();
    this.getUsers();
  }

  getLogEndpoint() {
    const { logType, logObject } = this.props;
    return `/${logType}/${logObject.id}/get_changes`;
  }

  getLogs() {
    const { logs } = this.state;
    if(logs == null) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      console.log("fetching");
      fetch(this.getLogEndpoint(), {
        method: "GET",
        redirect: "manual",
        body: null,
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            logs: json.versions
          })
        }
        else {
          this.setState({
            error: json.error
          })
        }
      });
    }
  }

  getUsers() {
    const { users } = this.state;
    if(users == null) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");

      fetch('/logs/users', {
        method: "GET",
        redirect: "manual",
        body: null,
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            users: json.users
          })
        }
        else {
          this.setState({
            error: json.error
          })
        }
      });
    }
  }

  renderLogTable() {
    const { logs } = this.state;
    if(logs) {
      return logs.map((log, index) => this.renderLog(log, index)).reverse();
    }
    else {
      return this.renderLoading();
    }
  }

  renderLog(log, index) {
    return (
      <div className="cnt-default-layout-log">
        <div className="cnt-default-layout-log-date">
          {formatToTimeZone(log.created_at, "DD-MMM-YYYY", {timeZone: "America/Los_Angeles"})}
        </div>
        <div className="cnt-default-layout-log-description">
          {this.generateLogText(log,index)} {this.generateLogAttribution(log)}
        </div>
      </div>
    )
  }

  generateLogAttribution(log) {
    const { users } = this.state;
    let user = null;
    if(users) {
      user = users.find(x=> log.whodunnit == `${x.id}`)
    }
    if(user) {
      return <> by <a href="#">{user.first_name} {user.last_name}</a></>
    }else if(users){
      return <> by <a href="#">Automatic</a></>
    }
    return <> by <a href="#">{log.whodunnit}(Still Loading Users)</a></>
  }

  generateLogText(log, index) {
    if(index == 0) {
      return "created"
    } else {
      return Object.entries(
        log.changeset
      ).filter(x=> {
        return x[1][0] || x[1][1]
      }).filter(x=>
        ignoredFields().findIndex(y=>
           y==x[0]
         ) == -1).map(change =>
        (<LogItemChange fieldName={change[0]} startingState={change[1][0]} endingState={change[1][1]} />)
      )
    }
  }

  renderLoading() {
    return (
      <div className="ctn-default-layout-log-table-loading">
        <h1> Loading </h1>
        <br />
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
    )
  }

  render() {
    const { user } = this.props;
    return (
      <div className="cnt-default-layout-log-table">
        {this.renderLogTable()}
      </div>
    );
  }
}

export default LogTable;
