import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"

import CompanyForm from "./forms/CompanyForm"
class CompaniesNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { user } = this.props;
    return (
      <DefaultLayout
        {...this.props}
        sidebarId="companies"
      >
        <h1 className="cnt-page-header">
          New Company
        </h1>
        <CompanyForm {...this.props} />
      </DefaultLayout>
    );
  }
}

export default CompaniesNew;
