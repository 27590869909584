export function dataURItoBlob(dataURI, fileName) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
  else
      byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  let blob = new Blob([ia], {type:mimeString});
  blob.lastModifiedDate = new Date();
  blob.name = fileName;
  blob.path = fileName;
  return blob;
}


export function printDivToPdf(divId, title) {
    let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');
    mywindow.document.write(`
        <html><head>
        <title>${title}</title>
        <meta content="" name="description"/>
        <meta charset="utf-8"/>
        <meta content="text/html; charset=utf-8" http-equiv="Content-Type"/>
        <meta content="IE=edge,chrome=1" http-equiv="X-UA-Compatible"/>
        <meta content="width=device-width, initial-scale=1" name="viewport"/>
        <meta content="none" name="robots"/>
        <style>
            * {
                -webkit-print-color-adjust:exact;
                print-color-adjust: exact;
            }
        </style>
    `);
    for (const sheet of document.styleSheets) {
        if (sheet.href) {
            console.log(sheet.href);
            mywindow.document.write(`<link rel="stylesheet" href="${sheet.href}"/>`);
        }
    }

    mywindow.document.write('</head><body >');
    mywindow.document.write(document.getElementById(divId).innerHTML);
    mywindow.document.write('</body></html>');


    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    setTimeout(()=>{
        mywindow.print();
        mywindow.close();
        return true;
    }, 1000);


  }