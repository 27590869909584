import React from "react";
import axios from "axios";
import {isJaguar} from "@utility/PermissionUtility"
import {getImportantDataFields, getStaticRequiredFields} from "@utility/ImportUtility"
import { Formik, Form, Field } from "formik";
import SelectField from './SelectField';
import Select  from "react-select";
import Checkbox from "@layout/forms/Checkbox";
import { checkboxImages } from "@utility/checkboxUtility";

class ImportsMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formInitialValues: {
        mapping: {}
      },
      mapRow1: props.sheet.mapping_preview[0],
      mapRow2: props.sheet.mapping_preview[1],
      mapRow3: props.sheet.mapping_preview[2],
      mapRow4: props.sheet.mapping_preview[3],
      mapRow5: props.sheet.mapping_preview[4],
      mapError: [],
      bypassValidation: false,
      selectedEvent: "",
      defaultFields: props.fields,
      isSubmitting: false,
      showMoreErrors: false
    };
    this.mappingChange = this.mappingChange.bind(this);
    this.toggleCheckBox = this.toggleCheckBox.bind(this);
    this.changeEvent = this.changeEvent.bind(this);
    this.toggleShowMoreErrors = this.toggleShowMoreErrors.bind(this);
  }

  componentDidMount() {
    const { fields } = this.props;
    let newFields = getImportantDataFields(fields);
    this.setState({defaultFields: newFields});
  }

  fieldOptions() {
    const { selectedEvent, defaultFields } = this.state;
    let filteredFields = defaultFields.map(a => ({...a}));
    if (selectedEvent !== "") {
      let filteredNomFields = filteredFields[3].options.filter(option =>
        !option.value.includes("award_criteria_answers") || (option.value.includes("award_criteria_answers") && option.label.includes(selectedEvent))
      );
      filteredFields[3].options = filteredNomFields;
    }
    return filteredFields;
  }

  toggleShowMoreErrors() {
    const { showMoreErrors } = this.state;
    this.setState({ showMoreErrors: !showMoreErrors });
  }

  changeEvent(e) {
    const { setEvent, blueprint_events } = this.props;
    let eventName = "";
    let eventId = "";
    if (e !== null) {
      eventName = e.value;
    }
    blueprint_events.forEach(event => {
      if (event.name === eventName) {
        eventId = event.id;
      }
    });
    setEvent(eventId);
    this.setState({selectedEvent: eventName});
  }

  mappingChange(e) {
    const {defaultFields} = this.state;
    try {
      setTimeout(() => {
        let newDefaultFields = JSON.parse(JSON.stringify(defaultFields))
        let form = document.getElementById('sg-mgmt-form-list-mapping');
        let formData = new FormData(form);
        for (const pair of formData.entries()) {
          newDefaultFields.forEach(model => {
            let newModelOptions = []
            model.options.forEach(option => {
              if (option.value !== pair[1]) {
                newModelOptions.push(option);
              }
            });
            model.options = newModelOptions;
          });
        }
        this.forceUpdate();
        // this.setState({ defaultFields: newDefaultFields });
      }, 500);
    } catch {
      console.log('Form doesnt exist yet');
    }
  }


  renderFieldSelect(idx, listLength, formatClasses = []) {
    const { sheet } = this.props;
    return (
      <div
        className="sg-mgmt-form-input-container"
      >
        <SelectField
          fieldName={`import[mapping][${idx + 1}]`}
          options={this.fieldOptions()}
          onChange={() => {this.mappingChange()}}
          headerValues={sheet.mapping_header}
          defaultValue={null}
          index={idx}
          customStyle={this.customStyles(idx, listLength)}
        />
      </div>
    );
  }

  renderSelectFields(formikProps) {
    const { mapRow1 } = this.state;
    return (
      <div className="sg-mgmt-field-column">
        <div className="sg-mgmt-field-column-header">
          <b>Nom Tool Fields</b>
        </div>
        {Array.from(Array(mapRow1.length)).map((_, i) => (
          <div key={`mapping-field-${i}`}>{this.renderFieldSelect(i, mapRow1.length)}</div>
        ))}
      </div>
    );
  }

  generateKey(prefix) {
    return `${prefix}-${Math.random()}`
  }

  renderPreview(val, index) {
    const { mappedRows } = this.state;
    let mappedRow = false;
    try {
      let form = document.getElementById('sg-mgmt-form-list-mapping');
      let formData = new FormData(form);
      for (const pair of formData.entries()) {
        if (pair[0] === `import[mapping][${index+1}]` && pair[1]) {
          mappedRow = true;
        }
      }
    } catch {
      // console.log('Form not ready yet');
    }
    let value = val;
    if (value === 't') value = 'true';
    if (value === 'f') value = 'false';
    return (
      <div key={this.generateKey(val)} className={mappedRow ? 'preview-column-mapped' : 'preview-column-unmapped'}>{value}</div>
    );
  }

  renderPreviewColumn(preview, columnNumber) {
    return (
      <div className="sg-mgmt-preview-column">
        <div className="sg-mgmt-field-column-header">
          {`Sample Data ${columnNumber}`}
        </div>
        {preview.map((val, index) => (
          this.renderPreview(val, index)
        ))}
      </div>
    )
  }

  renderHeaderValues(val, index) {
    const { mappedRows } = this.state;
    let mappedRow = false;
    try {
      let form = document.getElementById('sg-mgmt-form-list-mapping');
      let formData = new FormData(form);
      for (const pair of formData.entries()) {
        if (pair[0] === `import[mapping][${index+1}]` && pair[1]) {
          mappedRow = true;
        }
      }
    } catch {
      // console.log('Form not ready yet');
    }
    return (
      <div key={this.generateKey(val)} className={mappedRow ? 'header-column-mapped' : 'header-column-unmapped'}>{val}</div>
    );
  }

  renderHeaderFields() {
    const { sheet } = this.props;
    return (
      <div className="sg-mgmt-preview-column sg-mgmt-preview-column-header sg-mgmt-preview-column-header-titles">
        <div className="sg-mgmt-field-column-header">
          <b>Spreadsheet Column Headings</b>
        </div>
        {sheet.mapping_header.map((val, index) => (
          this.renderHeaderValues(val, index)
        ))}
      </div>
    );
  }

  toggleCheckBox() {
    const { bypassValidation } = this.state;
    this.setState({ bypassValidation: !bypassValidation });
  }

  renderValidationBypass() {
    const { user } = this.props;
    const { bypassValidation } = this.state;
    // <label>Bypass Validation (Jaguars Only)</label>
    // <input type="checkbox" onClick={this.toggleCheckBox} value={bypassValidation} />

    if (isJaguar(user)) {
      return (
        <div style={{marginLeft: "-10px", fontSize: "13px", marginBottom: "10px"}}>
          <Checkbox
            disabled={false}
            value={bypassValidation}
            onClick={()=>this.toggleCheckBox()}
            images={checkboxImages()}
            label={"Bypass Validation (Jaguars Only)"}
          />
        </div>
      );
    }
  }

  isMenuTop(idx, listLength) {
    if (listLength < 8) return false;
    return (idx > listLength - 4);
  }

  customStyles(idx, listLength) {
    return ({
      singleValue: () => ({
        fontWeight: "bold",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden"
      }),
      valueContainer: () => ({
        flexWrap: "nowrap",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flex: "1",
        flexWrap: "nowrap",
        padding: "2px 8px",
        position: "relative",
        overflow: "hidden",
        boxSizing: "border-box",
        alignItems: "center"
      }),
      indicatorSeparator: () => ({
        display: 'none'
      }),
      control: () => ({
        minHeight: "38px",
        backgroundColor: "#EDF8FB",
        borderColor: "#EDF8FB",
        borderRadius: "6px",
        color: "#0D274D",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        outline: "0 !important",
        position: "relative",
        transition: "all 100ms",
        boxSizing: "border-box"
      }),
      menu: () => ({
        position: 'absolute',
        top: `${this.isMenuTop(idx, listLength) ? 'auto' : 'calc(100% + 10px)'}`,
        bottom: `${this.isMenuTop(idx, listLength) ? 'calc(100% + 10px)' : 'auto'}`,
        backgroundColor: 'white',
        border: '1px solid hsl(0,0%,80%)',
        borderRadius: '5px',
        width: "100%",
        zIndex: '10',
        overflow: "hidden",
      }),
    });
  }

  renderEventPicker() {
    const { events } = this.props;
    const eventNames = events.map(e => {return {label: e, value: e}});
    return (
      <div style={{fontSize: "13px"}}>
        <label>Filter Nomination Questions by Event (optional)</label>
        <div
          className="sg-mgmt-form-input-container"
        >
          <Select
            name={"import[event_name]"}
            options={eventNames}
            onChange={(e) => this.changeEvent(e)}
            styles={this.customStyles()}
            placeholder=""
          />
        </div>
      </div>
    );
  }

  formatError(error) {
    try {
      let fieldErrors = [];
      error.forEach(err => {
        fieldErrors.push(err[0]);
      });
      let uniq = [...new Set(fieldErrors)];
      return uniq.join(', ');
    } catch (e) {
      return error
    }
    // try {
    //   let fieldErrors = [];
    //   let errorMatch = error.match(/Validation failed:(.*)/)[1]
    //   let splitErrors = errorMatch.split(',');
    //   splitErrors.forEach(splitError => {
    //     let subErrorMatch = splitError.match(/(.*)can't be blank/)[1].trim();
    //     fieldErrors.push(subErrorMatch);
    //   })
    //   return fieldErrors.join(', ');
    // } catch (e) {
    //   return error;
    // }
  }

  setPage(page) {
    const { setStage } = this.props;
    setStage(page);
  }

  renderErrors() {
    const { mapError, showMoreErrors } = this.state;

    let renderedErrors = [];
    if (mapError !== [] && mapError.length > 0) {
      try {
        let form = document.getElementById('sg-mgmt-form-list-mapping');
        let staticRequiredFields = getStaticRequiredFields();
        let formData = new FormData(form);
        staticRequiredFields.forEach(requiredField => {
          let missingField = true;
          for (const pair of formData.entries()) {
            if (requiredField === pair[1]) {
              missingField = false;
            }
          }
          if (missingField) {
            renderedErrors.push(requiredField.split(/[\.\_]+/).join(' ').replace('User', 'Account Manager'));
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
    if (renderedErrors.length > 0) {
      return (
        <div style={{marginTop: "10px"}}>
          <div className="sg-mgmt-form-input-import-error">
            {`Import Error: ${renderedErrors.join(', ')}`}
          </div>
          <div className="sg-mgmt-form-input-import-error" style={{color: "#0D274D"}} >
            Please fill in the missing fields in your spreadsheet and <a style={{cursor: "pointer"}} onClick={() => this.setPage(1)}>reupload</a>
          </div>
          <div className="sg-mgmt-form-input-import-error" onClick={this.toggleShowMoreErrors} style={{color: "#0d98be", cursor: "pointer"}}>{showMoreErrors ? "Show Less" : "Show More"}</div>
          {
            showMoreErrors ?
            <div className="sg-mgmt-form-input-import-error">
              {
                mapError.map(err => <>{err}<br /></>)
              }
            </div>
            :
            <></>
          }
        </div>
      );
    }
  }

  // Prevent form submit on enter key
  onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  render() {
    const { user, callbackSuccess, sheet } = this.props;
    const { formInitialValues, isSubmitting, mapRow1, mapRow2, mapRow3, bypassValidation } = this.state;

    const formConfig = {
      alert: "added",
      formId: "sg-mgmt-form-list-mapping",
      formUrl: "/imports/import_execute",
      method: "POST",
      title: "Import Data"
    };
    return (
      <Formik
        initialValues={{
          import: formInitialValues
        }}
        onSubmit={(values, { setSubmitting }) => {

          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);
          formData.set("import[data_type]", sheet.data_type);
          formData.set("import[import_id]", sheet.id);
          formData.set("import[bypass_validation]", bypassValidation);
          const token = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          })
            .then(response => {
              console.log(response);
              console.log(response.data.errors.length);
              if (response.data.errors.length === 0) {
                callbackSuccess();
              } else {
                this.setState({ mapError: response.data.errors });
                setSubmitting(false);
              }
            })
            .catch(error => {
              alert(error);
            });
        }}
      >
        {(formikProps) => (
          <Form className="sg-mgmt-form" id={formConfig.formId} onKeyDown={this.onKeyDown}>
            <div className="sg-mgmt-form-container">
              <div className="sg-mgmt-form-section">
                <div>
                  <div>{this.renderValidationBypass()}</div>
                  <div style={{maxWidth: "700px"}}>{this.renderEventPicker()}</div>
                </div>
                <div style={{color: "#0D274D", marginTop: "20px"}}>
                  <b>Please map the nom tool fields to the spreadsheet column headings.</b>
                  <br />
                  Green means it is already mapped, gray means it still needs to be mapped.
                </div>
                <div className="sg-mgmt-form-row" style={{maxWidth: "100%", display: "block"}}>
                  <div className="sg-mgmt-form-row-column">
                    <div className="sg-mgmt-table-import-container">
                      <div className="sg-mgmt-table sg-mgmt-table-import">
                        {this.renderSelectFields(formikProps)}
                        {this.renderHeaderFields()}
                        <div className="sg-mgmt-table-import-rows">
                          {this.renderPreviewColumn(mapRow1, 1)}
                          {this.renderPreviewColumn(mapRow2, 2)}
                          {this.renderPreviewColumn(mapRow3, 3)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sg-mgmt-form-actions import-action-button">
              <div style={{display: "flex", alignItems: "center"}}>
                <button
                  className="sg-mgmt-modal-btn sg-mgmt-modal-btn-enabled"
                  type="submit"
                  disabled={formikProps.isSubmitting}
                >
                  {
                    formikProps.isSubmitting ?
                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    :
                    formConfig.title
                  }
                </button>
                {
                  formikProps.isSubmitting ?
                  <div style={{marginLeft: "20px"}}>Please be patient. This may take a few minutes.</div>
                  :
                  <></>
                }
              </div>
              {this.renderErrors()}
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default ImportsMapping;
