import React from "react";
import PropTypes from "prop-types";
import { isAccountManager, canApproveNomination } from "@utility/PermissionUtility";
import Select from 'react-select-virtualized';
import Cookies from "universal-cookie";

class SourceStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.changeValue = this.changeValue.bind(this);
  }

  getStatus(status) {
    return status;
  }

  renderSelectField() {
    const { nomination } = this.props;
    return (
      <div className="cnt-default-attendee-status-ticker-select-container">
        <Select
          options={this.getSelectOptions()}
          classNamePrefix='cnt-status-select'
          isClearable={false}
          components={{
            SingleValue: (children, props)=> this.renderTicker(children.children),
          }}
          value={{value: nomination.source, label: nomination.source}}
          onChange={this.changeValue}
        />
      </div>
    )
  }

  changeValue(value) {
    const { updateNomination, nomination } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    if(updateNomination){
      nomination.source = value.value || "";
      updateNomination(nomination);
    }
    fetch(`/nominations/${nomination.id}/update_status`, {
      method: 'PATCH',
      redirect: "manual",
      body: JSON.stringify(
        {
          nomination: {
            source: value.value
          }
        }
      ),
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.nomination != null) {
        if(updateNomination){
          updateNomination(json.nomination);
        }
      } else {

      }
    });
  }

  getSelectOptions() {
    return [
      'AE Nom',
      'AM Nom',
      'MM Nom',
      'Exec Nom',
      'List Import',
      'AE Import',
    ].map(x=> {return {
      value: x,
      label: x
    }})
  }

  renderTicker(status) {
    if(status) {
      return (
        <div className={"cnt-default-attendee-status-ticker "} style={{maxWidth: "200px"}}>
          {this.getStatus(status)}
        </div>
      );
    }
    return "--"
  }

  render() {
    const { nomination, user, event } = this.props;
    if(user && canApproveNomination(user)) {
      return this.renderSelectField();
    }
    return this.renderTicker(nomination.source)
  }
}

export default SourceStatus;
