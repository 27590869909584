import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import CompanyForm from "./forms/CompanyForm"
import UniversalImageCircle from "@images/UniversalImageCircle"
import  TabbedPageContainer from "@layout/tabs/TabbedPageContainer"
import  LogTable from "@logs/LogTable"
import ContactsTable from "@contacts/ContactsTable"
import {canEditCompanyAccountManagers, canViewLog} from "@utility/PermissionUtility"
import VerifyUpdateBlock from "@updates/VerifyUpdateBlock"
import CompanyAccountManagerDashboard from "./account_managers/CompanyAccountManagerDashboard"
import { fetchAPI } from "@utility/NetworkUtils"

class CompaniesShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: props.users
    };
    this.renderLogs = this.renderLogs.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.addUser = this.addUser.bind(this);
  }

  removeUser(user) {
    const { company } = this.props;
    const { users } = this.state;
    fetchAPI(
      `/companies/${company.id}/remove_account_manager`,
      (json) => {
        if(!json.error) {
          this.setState({
            users: users.filter(x=>x.id != user.id)
          })
        }
      },
      {
        method: "POST",
        body: {
          user_id: user.id,
          company_id: company.id
        }
      }
    )
  }

  addUser(user_id) {
    const { company } = this.props;
    const { users } = this.state;
    fetchAPI(
      `/companies/${company.id}/add_account_manager`,
      (json) => {
        if(json.user) {
          this.setState({
            users: users.filter(x=>x.id != json.user.id).concat([json.user])
          })
        }
      },
      {
        method: "POST",
        body: {
          user_id: user_id,
          company_id: company.id
        }
      }
    )
  }

  renderHeader() {
    const { company } = this.props;
    return (
      <div className="row cnt-event-show-header-container">
        <div className="cnt-event-show-header-container-image col-xs-12 col-sm-4 col-lg-3">
          <UniversalImageCircle size={'large'} image={null} name={company.name} id={company.id}/>
        </div>
        <div className="cnt-event-show-header-container-description col-xs-12 col-sm-8 col-lg-9">
          <div className="cnt-event-show-header-container-name">
            {company.name}
          </div>
        </div>
      </div>
    )
  }

  renderLogs() {
    const { company } = this.props;
    return (
      <LogTable
        logType="companies"
        logObject={company}
       />
    )
  }

  renderUpdates() {
    const { verifiable_versions, user } = this.props;
    if(canViewLog(user) && verifiable_versions) {
      return verifiable_versions.map(x=>(
        <VerifyUpdateBlock user={user} update={x}/>
      ))
    }
  }
  getPages(){
    const { users } = this.state;
    const { user, contacts } = this.props;
    let pages = [
      {id: "profile", name: "Profile", content:(<CompanyForm {...this.props} />)},
      {id: "account_managers", name: `Account Managers (${users.length})`, content:(
        <CompanyAccountManagerDashboard
        {...this.props}
        users={this.state.users}
        addUser={this.addUser}
        removeUser={this.removeUser}
      />)},
      {id: `contacts`, name: `Contacts (${contacts.length})`, content:(<ContactsTable forceLocal={true} {...this.props} />)},
      {id: "log", name: "Log", content: this.renderLogs}
    ]
    if(!canEditCompanyAccountManagers(user)) {
      pages = pages.filter(x=>x.id != "account_managers")
    }
    return pages;
  }

  render() {
    const { users } = this.state;
    const { user, contacts } = this.props;
    return (
      <DefaultLayout
        {...this.props}
        breadcrumbs={
          ['dashboard', 'companies']
        }
        sidebarId="companies"
      >
        <h1 className="cnt-page-header">
          My Companies
        </h1>
        <div class="cnt-page-subheader-link">
          <a href="/companies">
            {"< Return to my companies"}
          </a>
        </div>
        {this.renderUpdates()}
        {this.renderHeader()}
        <TabbedPageContainer
          pages={this.getPages()}
          default_tab_id="profile"
        />
      </DefaultLayout>
    );
  }
}

export default CompaniesShow;
