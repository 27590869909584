import React from "react";
import PropTypes from "prop-types";
import { isAccountManager, canApproveNomination } from "@utility/PermissionUtility";
import { getAllInviteStatuses } from "@utility/NominationUtility";
import Select from 'react-select-virtualized';
import Cookies from "universal-cookie";

class AttendeeStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.changeValue = this.changeValue.bind(this);
  }

  getStatus(status) {
    return status;
  }

  getReadableStatus(status) {
    //TODO replace this with IE compat
    const foundStatus = getAllInviteStatuses().find(x=>x.id == status)
    if(foundStatus) {
      return foundStatus.name
    }
    //Force capitalize
    if(status) {
      status.charAt(0).toUpperCase() + status.slice(1).replaceAll("_"," ").replaceAll("-"," ");
    }
    return ""
  }

  renderSelectField() {
    const { nomination } = this.props;
    return (
      <div className="cnt-default-attendee-status-ticker-select-container">
        <Select
          options={this.getSelectOptions()}
          classNamePrefix='cnt-status-select'
          isClearable={false}
          components={{
            SingleValue: (children, props)=> this.renderTicker(children.children),
            Option: (children, props)=> this.renderTicker(children.children)
          }}
          value={{value: nomination.invite_status, label: this.getReadableStatus(this.getStatus(nomination.invite_status))}}
          onChange={this.changeValue}
        />
      </div>
    )
  }

  changeValue(value) {
    const { updateNomination, nomination } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    if(updateNomination){
      nomination.invite_status = value.value || "";
      updateNomination(nomination);
    }
    fetch(`/nominations/${nomination.id}/update_status`, {
      method: 'PATCH',
      redirect: "manual",
      body: JSON.stringify(
        {
          nomination: {
            invite_status: value.value
          }
        }
      ),
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.nomination != null) {
        if(updateNomination){
          updateNomination(json.nomination);
        }
      } else {

      }
    });
  }

  getSelectOptions() {
    return [
      {value:null, label:""}
    ].concat(getAllInviteStatuses().map(x=>{
      return {
        value: x.id,
        label:x.name
      }
    }))
  }

  renderTicker(status) {
    status = (status||"").toLowerCase().replaceAll(" ", "_")
    if(status) {
      return (
        <div className={`cnt-default-attendee-status-ticker cnt-default-attendee-status-ticker-${this.getStatus(status)}`}>
          {this.getReadableStatus(this.getStatus(status))}
        </div>
      );
    }
    return "--"
  }

  render() {
    const { nomination, user } = this.props;
    if(user && canApproveNomination(user)) {
      return this.renderSelectField();
    }
    return this.renderTicker(nomination.invite_status)
  }
}

export default AttendeeStatus;
