import React from "react";
import PropTypes from "prop-types";

import Cookies from "universal-cookie";
import CompanyForm from "./CompanyForm";

class CompanyFormSlowLoad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: null,
      downloaded: false,
    };
    this.downloadCompanyFormInfo = this.downloadCompanyFormInfo.bind(this);
  }

  componentDidMount() {
    //Download Company/Company.
    const { newForm } = this.props;
    const { downloaded } = this.state;
    if(!downloaded && !newForm) {
      this.downloadCompanyFormInfo();
    }
  }

  downloadCompanyFormInfo() {
    const { companyId } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(
      `/api/v1/companies/${companyId}/form`,
      {
        method: "GET",
        redirect: "manual",
        headers: {
          "X-CSRF-Token": token,
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            company: json.company,
            downloaded: true
          })
        }
      });
  }

  render() {
    const { company, downloaded } = this.state;
    const { newForm, user, onRedirect, disableDelete, cancelCallback } = this.props;
    console.log({
      downloaded
    })

    if(!downloaded && !newForm) {
      return (
        <div className="ctn-default-layout-log-table-loading">
          <h1> Loading Full Company Details</h1>
          <br />
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
      )
    }
    return (
      <CompanyForm
        ref={this.props.companyRef || null}
        user={user}
        company={company||{}}
        disableDelete={disableDelete}
        cancelCallback={cancelCallback}
        onRedirect={onRedirect ? onRedirect : ()=>{location.reload()}}
      />
    )
  }
}

export default CompanyFormSlowLoad;
