import React from "react";
import PropTypes from "prop-types";
import MaterialTable from 'material-table';
import Cookies from "universal-cookie";

class ReportTableItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const {name, disableSearch, columns, data}  = this.props;

    return (
      <MaterialTable
        key={`material-table-selected-table-${name}`}
        options={{
          pageSizeOptions: [
          5,10,20,50,100
          ],
          showFirstLastPageButtons:true,
          pageSize: 10,
          exportAllData: true,
          exportButton: true,
          thirdSortClick: false,
          emptyRowsWhenPaging: false
        }}
        localization={{
          body: {
            emptyDataSourceMessage: "No records to display"
          }
        }}
        components={{
        }}
        columns={columns}
        data={data}
        autoHeight={true}
        title=""
      />
    );
  }
}

export default ReportTableItems;
