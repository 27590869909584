import React from "react";
import PropTypes from "prop-types";

import Cookies from "universal-cookie";
import ContactForm from "./ContactForm";

class ContactFormSlowLoad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: null,
      companies: null,
      users: null,
      downloaded: false,

    };
    this.downloadContactFormInfo = this.downloadContactFormInfo.bind(this);
  }

  componentDidMount() {
    //Download Contact/Company.
    const { downloaded } = this.state;
    if(!downloaded) {
      this.downloadContactFormInfo();
    }
  }

  downloadContactFormInfo() {
    const { contactId } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(
      `/api/v1/contacts/${contactId}/form`,
      {
        method: "GET",
        redirect: "manual",
        headers: {
          "X-CSRF-Token": token,
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            contact: json.contact,
            companies: json.companies,
            users: json.users,
            downloaded: true
          })
        }
      });
  }

  render() {
    const { contact, companies, users, downloaded } = this.state;
    const { user, onRedirect, disableDelete, cancelCallback, inlinePhoto} = this.props;
    console.log({
      downloaded
    })

    if(!downloaded) {
      return (
        <div className="ctn-default-layout-log-table-loading">
          <h1> Loading Full Contact Details</h1>
          <br />
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
      )
    }
    return (
      <ContactForm
        ref={this.props.contactRef || null}
        user={user}
        contact={contact}
        users={users}
        companies={companies}
        disableDelete={disableDelete}
        cancelCallback={cancelCallback}
        inlinePhoto={inlinePhoto}
        onRedirect={onRedirect ? onRedirect : (json)=>{location.reload()}}
      />
    )
  }
}

export default ContactFormSlowLoad;
