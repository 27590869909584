
export function jobLevelOptions() {
  return [
    "C-Level",
    "SVP / VP",
    "Director",
    "Sr. Manager / Manager"
  ].map(x=> {return {value: x, label: x}})
}
export function cabMemberOptions() {
  return [
    "Yes",
    "No",
    "Unsure",
  ].map(x=> {return {value: x, label: x}})
}
export function inviteeTypeOptions() {
  return [
    "Potential Nominee",
    "Guest",
  ].map(x=> {return {value: x, label: x}})
}
export function relationshipWithCiscoOptions() {
  return [
    "New Customer",
    "Existing Customer",
    "Partner"
  ].map(x=> {return {value: x, label: x}})
}
