import React from "react";
import PropTypes from "prop-types";
import GeneralChit from "@layout/tables/GeneralChit"
import UniversalImageCircle from "@images/UniversalImageCircle"

class UserTableChit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let { user } = this.props;
    user = user || {}
    return (
      <GeneralChit
        circle={
          <UniversalImageCircle size={'tiny'} image={null} name={user.first_name} id={user.id}/>
        }
        name={user.name}
        link={`/user/${user.id}`}
      />
    );
  }
}

export default UserTableChit;
