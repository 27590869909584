import React from "react";
import GeneralTable from "@layout/tables/GeneralTable"

class DirectoryContactTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: props.contacts ||[],
    };
  }

  mapFilteredContacts(contacts) {
    contacts = contacts.map(x=> {
      x.lower_first_name = x && x.first_name ? x.first_name.toLowerCase() : ""
      x.lower_last_name = x && x.last_name ? x.last_name.toLowerCase() : ""
      x.lower_full_name= x && x.last_name && x.first_name ? `${x.first_name.toLowerCase()} ${x.last_name.toLowerCase()}` : ""
      x.company_name = x && x.company ?  x.company.name : "";
      x.account_manager_name = x && x.users ? x.users.map(y=>`${y.first_name} ${y.last_name}`).join("|") : ""
      x.account_manager_email = x && x.users ? x.users.map(y=>y.email).join("|") : ""
      return x;
    })
    return contacts;
  }


  getColumns() {
    const { user } = this.props;
    const disabledColumns = this.props.disabledColumns || []
    let allColumns = [
      { title: 'First', field: 'lower_first_name', render: (row) => row.first_name },
      { title: 'Last', defaultSort: 'asc', field: 'lower_last_name', render: (row) => row.last_name },
      { title: 'Ful Name',hidden: true, searchable: true, defaultSort: 'asc', field: 'lower_last_name', render: (row) => row.last_name },
      { title: 'Title', field: 'job_title' },
      { title: 'Company', field: 'company_name' },
      { title: 'Account Manager(s)', field: 'account_manager_name', render: (row) =>{
        return (row.users||[]).map(x=>(
              <div>
                <a href={`mailto:${row.email}`}>{x.first_name} {x.last_name}</a>
              </div>
        ))
      }},
    ]
    return allColumns;
  }

  render() {
    return (
        <div className="cnt-default-layout-contacts-table">
          <GeneralTable
            columns={this.getColumns()}
            dataUrl='/api/v1/contacts/directory_table'
            dataUrlMapCallback={this.mapFilteredContacts}
            showTotals={true}
          />
        </div>
    )
  }
}

export default DirectoryContactTable;
