import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import { isAccountManager, canDeleteContact} from "@utility/PermissionUtility"
import GeneralTable from "@layout/tables/GeneralTable"
import CompanyTableChit from "@companies/tables/CompanyTableChit"
import GeneralTableDropdown from "@layout/tables/GeneralTableDropdown"
import ContactProfileModal from "@contacts/modals/ContactProfileModal"
import ContactDeleteModal from "@contacts/modals/ContactDeleteModal"
import DefaultTooltip from "@layout/tooltips/DefaultTooltip";
import { downloadPages } from "@utility/TableUtils";
import { getContactTableMultiSelectOptions } from "@contacts/tables/ContactTablesUtils"
import MultiselectActionBar from "@layout/tables/MultiselectActionBar"
import Checkbox from "@layout/forms/Checkbox"
import { fetchAPI } from "@utility/NetworkUtils"
import { multiSelectCheckboxImages } from "@utility/checkboxUtility"
import ContactMergeModal from "@contacts/modals/ContactMergeModal"
class ContactsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: props.contacts ||[],
      startedLoading: false,
      selectedContacts: [],
      accountManagers: null,
      companies: null,
      mergingContactIds: [],
    };
    this.onDelete = this.onDelete.bind(this);
    this.applyMultiSelectAttributes = this.applyMultiSelectAttributes.bind(this);
    this.renderContactCheckbox = this.renderContactCheckbox.bind(this);
    this.onCheckboxClick = this.onCheckboxClick.bind(this);
    this.getSelectedItems = this.getSelectedItems.bind(this);
    this.unselectAll = this.unselectAll.bind(this);
    this.onMergeCallback = this.onMergeCallback.bind(this);
    this.closeContactsMergeModal = this.closeContactsMergeModal.bind(this);
    this.openContactsMergeModal = this.openContactsMergeModal.bind(this);
  }

  componentDidMount() {
    const { forceLocal } = this.props;
    if(!forceLocal) {
      downloadPages(
        '/api/v1/contacts/table',
        (x) => {
          const { contacts } = this.state;
          const contact_ids = contacts.map(c => c.id);
          this.setState({
            contacts: contacts.concat(x.filter(y=> contact_ids.indexOf(y) == -1)),
            startedLoading: true,
          })
        }
      );
    }

    fetchAPI('/api/v1/companies/dropdown', (json) => {
      if(json.companies) {
        this.setState({
          companies: json.companies.sort((a,b)=>{return a.name< b.name?-1:1})
        })
      }
    })

    fetchAPI('/api/v1/users/account_manager_dropdown', (json) => {
      if(json.users) {
        this.setState({
          accountManagers: json.users.sort((a,b)=>{return `${a.first_name} ${a.last_name}`< `${b.first_name} ${b.last_name}` ? -1 : 1})
        })
      }
    })
  }

  getFilteredContacts() {
    let  { contacts } = this.state;
    contacts = contacts.map(x=> {
      x.lower_last_name = x && x.last_name ? x.last_name.toLowerCase() : ""
      x.lower_full_name = x && x.last_name && x.first_name ? `${x.first_name.toLowerCase()} ${x.last_name.toLowerCase()}` : ""
      x.company_name = x && x.company ?  x.company.name : "";
      x.account_manager_name = x && x.users ? x.users.map(y=>`${y.first_name} ${y.last_name}`).join("|") : ""
      return x;
    }).sort((a,b) => `${a.last_name} ${a.first_name}`.toLowerCase() > `${b.last_name} ${b.first_name}`.toLowerCase() ? 1 : -1)
    return contacts;
  }

  getFilters() {
    return [
      {id: null, name: "All", filterFunction:()=>{return true}},
      {id: 'domestic', name: "Domestic", filterFunction:(x,i,t)=>{ return x.address_country=="United States"}},
      {id: 'international', name: "International", filterFunction:(x,i,t)=>{ return x.address_country && x.address_country!="United States"}},
      {id: 'incomplete', name: "Incomplete", filterFunction:(x,i,t)=>{ return (
        !x.first_name ||
        !x.company_id ||
        !x.email
      )}},
    ]
  }

  renderViewAll(row) {
    const { user } = this.props;
    const { contacts } = this.state;
    return (
      <div className="cnt-default-layout-nomination-table-icons">
        <ContactProfileModal
          user={user}
          contact={row}
        >
          <DefaultTooltip
            tooltipId={`sg-cnt-default-layout-contact-table-contact-profile-${row.id}`}
          >
            Quick View
          </DefaultTooltip>
          <a
            href="#"
            data-for={`sg-cnt-default-layout-contact-table-contact-profile-${row.id}`}
            data-tip
          >
            <img src="/layout/view-icon.svg" />
          </a>
        </ContactProfileModal>
        <DefaultTooltip
          tooltipId={`sg-cnt-default-layout-contact-table-contact-edit-${row.id}`}
        >
          Full View
        </DefaultTooltip>
        <DefaultTooltip
          tooltipId={`sg-cnt-default-layout-contact-table-contact-nominate-${row.id}`}
        >
          Nominate Contact
        </DefaultTooltip>
        <a

          data-for={`sg-cnt-default-layout-contact-table-contact-edit-${row.id}`}
          data-tip
          href={`/contacts/${row.id}`}
        ><img style={{width: "17px"}} src="/layout/review-icon.png" /></a>
        <a
          data-for={`sg-cnt-default-layout-contact-table-contact-nominate-${row.id}`}
          data-tip
          href={`/nominations/new?contact_id=${row.id}`}
        ><img src="/layout/nominate-icon-2.svg" /></a>
        {canDeleteContact(user) ? (
          <>
          <DefaultTooltip
            tooltipId={`sg-cnt-default-layout-contact-table-contact-delete-${row.id}`}
          >
            Delete Contact
          </DefaultTooltip>
          <ContactDeleteModal contacts={contacts} contact={row} onDelete={this.onDelete}>
            <img
              className="sg-cnt-default-layout-table-delete-button"
              data-for={`sg-cnt-default-layout-contact-table-contact-delete-${row.id}`}
              data-tip
               src="/layout/trash-icon.svg"
            />
          </ContactDeleteModal>
          </>
        ):""}
      </div>
    );
  }

  onDelete(id) {
    let { contacts } = this.state;
    contacts = contacts.filter(x=> x.id != id)
    this.setState({
      contacts
    })
  }

  renderContactCheckbox(row) {
    const { selectedContacts } = this.state;
    const isChecked = selectedContacts.indexOf(row.id) != -1;
    return (
      <div className="cnt-nomination-table-checkbox">
        <Checkbox
          disabled={false}
          value={isChecked}
          onClick={()=>this.onCheckboxClick(row)}
          images={multiSelectCheckboxImages()}
        />
      </div>
    )
  }

  getColumns() {
    const { user } = this.props;
    const disabledColumns = this.props.disabledColumns || []
    let allColumns = [
      { maxWidth: '35px',sorting: false, title: "", field: 'select', render: this.renderContactCheckbox},
      { title: 'First', field: 'first_name' },
      { title: 'Last', defaultSort: 'asc', field: 'last_name', render: (row) => row.last_name },
      { title: 'Full Name',hidden: true, searchable: true, field: 'lower_full_name' },
      { title: 'Email', field: 'email'},
      { title: 'Title', field: 'job_title' },
      { title: 'Contact Type', searchable: true, field: 'contact_type' },
      { title: 'Company', field: 'company_name', render: (row)=>(row && row.company ? (<CompanyTableChit contact={row} user={user} company={row.company} />):"") },
      { title: 'Account Manager(s)', field: 'account_manager_name', render: (row) => row && row.users ? (
        (row.users||[]).map(x=> (
          <div>
            {x.first_name} {x.last_name}
          </div>
        ))
      ):""
      },
      { title: 'Actions', export: false, sortable: false, field: 'id', render: (row)  => this.renderViewAll(row)}
    ]
    if(isAccountManager(user)) {
      allColumns = allColumns.filter(x=>x.title != "Account Manager");
      allColumns = allColumns.filter(x=>x.field != "select");
    }
    allColumns = allColumns.filter(column=>
        disabledColumns.indexOf(column.field) == -1
    )
    return allColumns;
  }

  getSelectedItems() {
    const { contacts, selectedContacts } = this.state;
    return contacts.filter(x=>selectedContacts.indexOf(x.id) != -1);
  }

  applyMultiSelectAttributes(ids, performActionCallback, values) {
    let body = {
      ids: ids,
      contact: values
    }
    this.setState({
      selectedContacts: []
    })
    fetchAPI('/api/v1/contacts/update_multiple', (json) => {
      if(json.contacts) {
        let {contacts} = this.state;
        contacts = contacts.filter(x=>
          ids.indexOf(x.id) == -1
        );
        this.setState({
          contacts: contacts.concat(json.contacts),
          error: json.error,
        })
      }
      if(performActionCallback) {
        performActionCallback(json)
      }
    }, {
      body: body,
      method: 'PATCH'
    });
  }

  unselectAll() {
    this.setState({
      selectedContacts: []
    })
  }

  onCheckboxClick(row) {
    let { selectedContacts } = this.state;
    const isChecked = selectedContacts.indexOf(row.id) != -1;
    if(isChecked) {
      selectedContacts = selectedContacts.filter(x=> x != row.id)
    }else {
      selectedContacts = selectedContacts.concat([
        row.id
      ])
    }
    this.setState({
      selectedContacts
    })
  }

  getContactTableMultiSelectOptions() {
    const { companies, accountManagers } = this.state;
    let otherOptions = {
      name: "More options",
      id: "other",
      options: [{label: "Delete", value: 'delete'}, {label: "Merge", value: 'merge', onClick: this.openContactsMergeModal}]
    };
    let companyOptions = null;
    if(companies) {
      companyOptions= {
        name: 'Assign Company',
        id: "company_id",
        options: companies.map(x=> {return{
          value: x.id,
          label:x.name
        }})
      }
    }
    // let accountManagerOptions =  null;
    // if(accountManagers) {
    //   accountManagerOptions= {
    //     name: 'Assign Account Manager',
    //     id: "user_id",
    //     options: accountManagers.map(x=> {return{
    //       value: x.id,
    //       label:`${x.first_name} ${x.last_name}`
    //     }})
    //   }
    // }
    return [companyOptions, otherOptions].filter(x=>x);
  }

  getEmptyMessage() {
      const { startedLoading, contacts } = this.state;
      if(contacts.length == 0) {
        if(startedLoading) {
          return (
            <div className="cnt-dashboard-blank-notifications-message">
              You do not have any contacts.
              <br/>
              To add a new contact, please click on the green ‘Add Contact’ button in the top right corner.
            </div>
          )
        } else {
          return (
            <div className="cnt-dashboard-blank-notifications-message">
              Loading...
            </div>
          )

        }
      }
      return (
        <div className="cnt-dashboard-blank-notifications-message">
          No results found. Try refining your search and try again.
        </div>
      )
  }

  openContactsMergeModal() {
    const { selectedContacts } = this.state;
    this.setState({
      isMergingContactOpen: true,
      mergingContactIds:selectedContacts
    })
  }

  closeContactsMergeModal() {
    this.setState({
      isMergingContactOpen: false,
      mergingContactIds: [],
    })
  }

  onMergeCallback(selectedId, rejectedIds) {
    let { contacts } = this.state;
    console.log("Table On Merge")
    console.log({selectedId, rejectedIds})
    //Remove all ids returned
    contacts = contacts.filter(x=> rejectedIds.indexOf(x.id) == -1)
    this.setState({
      contacts,
      isMergingContactOpen: false,
      mergingContactIds: [],
    })
  }

  render() {
    const { contacts } = this.state;
    const { mergingContactIds, isMergingContactOpen} = this.state;
    const filteredContacts = this.getFilteredContacts();
    return (
        <div className="cnt-default-layout-contacts-table">
          <MultiselectActionBar
            selectedItems={this.getSelectedItems()}
            options={this.getContactTableMultiSelectOptions()}
            submitCallback={this.applyMultiSelectAttributes}
            closeCallback={this.unselectAll}
          />
          <GeneralTable
            filters={this.getFilters()}
            columns={this.getColumns()}
            data={filteredContacts}
            emptyDataSourceMessage={this.getEmptyMessage()}
            showTotals={true}
          />

          <ContactMergeModal
            contacts={contacts.filter(x=> mergingContactIds.indexOf(x.id) != -1)}
            openModal={this.openContactsMergeModal}
            closeModal={this.closeContactsMergeModal}
            isOpen={isMergingContactOpen}
            onMerge={this.onMergeCallback}
          />
        </div>
    )
  }
}

export default ContactsTable;
