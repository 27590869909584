export function multiSelectCheckboxImages() {
  return {
    standard: {
      checked: "/images/dashboard/dashboard-clicked.svg",
      unchecked: "/images/dashboard/dashboard-unclicked.svg",
    }
  }
}

export function radioImages() {
  return {
    standard: {
      checked: "/layout/form/radiobutton-clicked.svg",
      unchecked: "/layout/form/radiobutton-unclicked.svg"
    }
  }
}

export function checkboxImages() {
  return {
    standard: {
      checked: "/layout/form/form-checkbox-checked.svg",
      unchecked: "/layout/form/form-checkbox-unchecked.svg"
    }
  }
}
