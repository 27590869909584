import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import {isJaguar, isAccountManager} from "@utility/PermissionUtility"
import { formatId } from "@utility/ProgramUtils"
import GeneralTable from "@layout/tables/GeneralTable"
import CompanyTableChit from "@companies/tables/CompanyTableChit"
import Cookies from "universal-cookie";

class ReportCompanyTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: props.companies || null,
      error: null,
    };
    this.getColumns = this.getColumns.bind(this);
    this.getCompanies = this.getCompanies.bind(this);
  }

  componentDidMount() {
    const { companies } = this.state;
    if(!companies) {
      this.getCompanies();
    }
  }

  getCompanies() {
    const { logs } = this.state;
    const { setCompanies } = this.props;
    if(logs == null) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch("/reports/get_companies", {
        method: "GET",
        redirect: "manual",
        body: null,
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          if(setCompanies) {
            setCompanies(json.companies)
          }
          this.setState({
            companies: json.companies
          })
        }
        else {
          this.setState({
            error: "json.error"
          })
        }
      }).catch((error)=>{
        this.setState({
        error: "Could not load contacts at this time. Try again later."
      })});
    }
  }

  getFilteredCompanies() {
    const  { companies } = this.state;
    const filteredCompanies = companies.map(x=> {
      return x;
    })
    return filteredCompanies;
  }

  renderEmail(row) {
    return (
      <a href={`mailto:${row.email}`} target="_blank">
        {row.email}
      </a>
    )
  }

  renderActions(company) {
    return (
      <>
        <a href={`/companies/${company.id}/full_report`}>
        Full Report
        </a>
      </>
    )
  }

  renderChit(company) {
    return (
      <CompanyTableChit company={company}/>
    )
  }

  renderId(company) {
    return <a href={`/companies/${company.id}`}>{formatId(company.id)}</a>
  }

  getColumns() {
    const { user } = this.props;
    let allColumns = [
      { title: 'ID', field: 'id', render: (row) => this.renderId(row)},
      { title: 'Company', field: 'name', render: (row) => this.renderChit(row) },
      { title: 'Actions', field: 'id', render: (row) => this.renderActions(row) },
    ]
    // if(isAccountManager(user)) {
    //   allColumns = allColumns.filter(x=>x.title != "Account Manager");
    // }
    return allColumns;
  }

  render() {
    const { companies, error } = this.state;
    if(error) {
      return (
        <div className="ctn-default-layout-log-table-loading">
          <h1>{error}</h1>
        </div>
      )
    }
    if(companies) {
      return (
          <GeneralTable
            columns={this.getColumns()}
            data={this.getFilteredCompanies()}
          />
      )
    }
    return (
      <div className="ctn-default-layout-log-table-loading">
        <h1> Loading </h1>
        <br />
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
    )

  }
}

export default ReportCompanyTable;
