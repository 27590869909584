import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Select from 'react-select-virtualized'

class CompanyAccountManagerAddModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      selectedUser: null,
      allUsers: null
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.addUserModal = this.addUserModal.bind(this);
  }

  getUserOptions() {
    const { account_managers, users } = this.props;
    if(account_managers) {
      return account_managers.filter(x=> users.map(y=>y.id).indexOf(x.id) == -1).map(x=> {return {value: x.id, label: `${x.first_name} ${x.last_name}`}})
    }else {

    }
    return [];
  }


  renderModal() {
    const {editing_user, user, contacts } = this.props;
    const { selectedUser, selectErroring } = this.state;
    return (
      <div className="row">
        <div className="col-xs-12 ">
          <div className="cnt-admin-user-management-delete-modal-title">
              Add an Account Manager
          </div>
          <div className="cnt-admin-user-management-delete-modal-description">
            Please select an account manager to add to this company
          </div>
          <div>
            Cisco Account Manager
          </div>
          <div>
            <Select
              className={selectErroring && selectedUser == null ? "cnt-form-select-erroring" : ""}
              options={this.getUserOptions()}
              classNamePrefix='cnt-form-select'
              value={selectedUser}
              onChange={(option) => {
                this.setState({
                  selectedUser: option
                })
              }}
            />
            <br />
          </div>
          <div className="">
            <button disabled={selectedUser == null} className="cnt-submit-button" onClick={this.addUserModal}>Add Account Manager</button>
            <button className="cnt-cancel-button"onClick={this.toggleModal}> Cancel</button>
          </div>
        </div>
      </div>
    )
  }

  addUserModal() {
    const { addUser } = this.props;
    const { selectedUser }= this.state;
    this.toggleModal();
    addUser(selectedUser.value)
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen
    });
  }

  render() {
    const { modalOpen } = this.state;
    const { outerStyle, outerClassNames, children, sessions } = this.props;
    return (
      <>
        <div className={outerClassNames} style={{outerStyle}} onClick={() => this.toggleModal()} >
          {children}
        </div>
        <Modal
          className="white-background cnt-user-admin-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default CompanyAccountManagerAddModal;
