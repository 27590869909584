import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import { addMy} from "@utility/PermissionUtility"
import CompaniesTable from "@companies/tables/CompaniesTable"
class CompaniesIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  renderCreateCompaniesButton() {
    const { user } = this.props;

    return (
      <a href="/companies/new">
        <button className="cnt-page-header-button">
         Add Company
        </button>
      </a>
    )
  }

  render() {
    const { user } = this.props;
    return (
      <DefaultLayout
        {...this.props}
        breadcrumbs={
          ['dashboard']
        }
        sidebarId="companies"
      >
        <h1 className="cnt-page-header">
          {addMy(user)}Companies
          {this.renderCreateCompaniesButton()}
        </h1>
        <CompaniesTable {...this.props}/>
      </DefaultLayout>
    );
  }
}

export default CompaniesIndex;
