export function prefixOptions() {
  return [
    "Mr.",
    "Ms.",
    "Mrs.",
    "Miss",
    "Dr.",
    "Prof.",
    "Assoc. Prof.",
    "Sir",
    "Amb.",
    "Maj. Gen",
    "Lt. Gen.",
    "Col.",
    "The Hon.",
    "Comm.",
    "Pres.",
    "Ing.",
    "Lic.",
    "Dhr.",
    "Herr.",
    "H.E.",
    "H.M."
  ].map(x=>{return{
    label: x, value: x
  }})
}

export function getCompanyName(contact) {
  return contact.company ? contact.company.name : contact.am_provided_company_name||"Unknown"
}

export function getAmProvidedInformation(name, contact, options={}){
  let company = contact.company||{};

  if(options['company']){
    company = options['company']
  }

  console.log({company})

  const am_name = `am_provided_${name}`;
  const company_name = name.replace("company_","")
  if(options['prefer_contact']){
    if(contact[am_name]) {
      return contact[am_name]
    }
    return company[company_name];
  }else {
    if(company[company_name]) {
      return company[company_name];
    }
    return contact[am_name];
  }
}