import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"

import ContactForm from "./forms/ContactForm"
class ContactsNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { user } = this.props;
    return (
      <DefaultLayout
        {...this.props}
        sidebarId="contacts"
      >
        <h1 className="cnt-page-header">
          New Contact
        </h1>
        <div className="cnt-page-subheader-link"><a href="/contacts">
          {"< Return to my contacts"}
        </a>
        <br/>
        <br/>
        </div>
        <ContactForm {...this.props} />
      </DefaultLayout>
    );
  }
}

export default ContactsNew;
