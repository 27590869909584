import React from "react";
import PropTypes from "prop-types";
import {isAccountManager, isMarketingManager} from "@utility/PermissionUtility"
class Footer extends React.Component {
  constructor(props) {
    super(props);
  }



  render() {
    const { user } = this.props;
    return (
      <div className="cnt-default-layout-footer">
        <div className="cnt-default-layout-footer-links">
          <a href="mailto:nominationtoolsupport@cisco.com" target="_blank">
          Contact us
          </a>
          <span>{"|"}</span>
          <a href={
              isAccountManager(user) ?
              "https://app.smartsheet.com/sheets/7w984Q8GPJwhF4v9gWJV6pR4cpVrmX7rwjRPx7Q1"
              :
              "https://app.smartsheet.com/sheets/8P9Q4XvCv8ppjXfJQWC9pPGR37Grw3256w2Q3Pg1"
            } target="_blank">
            Leave feedback
          </a>
        </div>
        <img src="/layout/footer-logo.svg" className="cnt-default-layout-footer-logo"/>
      </div>
    )
  }
}

export default Footer;
