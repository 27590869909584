import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import {getRoleName} from "@utility/PermissionUtility"
import UniversalImageCircle from "@images/UniversalImageCircle"
import UserCondensedView from "./UserCondensedView"
class UserProfileModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      user: null,
    }
    this.toggleModal = this.toggleModal.bind(this);
  }

  getUser() {
    const { user } = this.props;
    if(user != null) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch(`/admin/users/${user.user_id ? user.user_id : user.id}/get_user_modal_info`, {
        method: "GET",
        redirect: "manual",
        body: null,
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            user: json.user
          })
        }
        else {
          this.setState({
            error: json.error
          })
        }
      }).catch((error)=>{
        this.setState({
        error: "Could not load users at this time. Try again later."
      })});
    }
  }

  renderModal() {
    const { user } = this.state;
    const currentUser = this.props.user;
    if(user) {
      return (
        <div className="row">
          <div className="col-xs-12 cnt-default-layout-nomination-contact-area">
            <div className="cnt-default-layout-nomination-contact-description-container">
              <div className="cnt-default-layout-nomination-contact-picture">
                <UniversalImageCircle image={user.profile_image} name={user.first_name} id={user.id}/>
              </div>
              <div className="cnt-default-layout-nomination-contact-description">
                <span className="cnt-default-layout-nomination-contact-description-name">
                  {user.first_name} {user.last_name}
                </span>
              </div>
            </div>
          </div>
          <div className="col-xs-12">
            <UserCondensedView user={user}/>
          </div>
          <div className="col-xs-12">
            <button className="cnt-submit-button" onClick={() => {window.location = `/admin/users/${user.id}`}}>Edit User</button>
            <button className="cnt-cancel-button" onClick={this.toggleModal}> Cancel</button>
          </div>
        </div>
      );
    }else {
      //Render Loading
      return (
        <div className="row">
          <div className="col-xs-12 ">
            <div className="ctn-default-layout-log-table-loading">
              <h1> Loading </h1>
              <br />
              <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
          </div>
        </div>
      );
    }
  }

  toggleModal() {
    const { modalOpen, user } = this.state;
    const propUser = this.props.user;

    if(!modalOpen && propUser) {
      if(!user || user.id != propUser.id) {
        this.getUser();
      }
    }

    this.setState({
      modalOpen: !modalOpen
    });

  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, sessions } = this.props;
    return (
      <>
        <div className={`cnt-modal-openable-button ${outerClassNames}`} onClick={this.toggleModal} >
          {children}
        </div>
        <Modal
          className="white-background cnt-user-admin-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default UserProfileModal;
