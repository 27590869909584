import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import Select from 'react-select-virtualized';
import { Formik, Field, Form } from "formik";

class ReportScheduler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  formURL() {
    return "/report_emails";
  }

  // Need update method too at some point
  formMethod() {
    return "POST";
  }

  renderField(name,formikProps, required=false, customValidation = false, properties = {}) {
    const { checkError } = this.state;
    const { report_email } = formikProps.values;
    const erroring = checkError || customValidation;

    return (
      <Field
        onChange={(e) => {
            report_email[name] = e.target.value
            formikProps.setFieldValue('report_email', report_email);
          }
        }
        value={report_email[name]}
        disabled={properties['disabled']}
        component={properties['component']}
        type={properties['type']}
        className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`editing_user[${name}]`}
      />
    )
  }

  renderSelectField(name, formikProps, options, customValidation = false, renderOptions = {}) {
    const { checkError } = this.state;
    const erroring = checkError || customValidation;
    let value = null;
    try {
       value = options.filter(option => option.value === formikProps.values['report_email'][name])[0]
    }catch (e) {

    }
    return (
      <Select
        className={erroring ? "cnt-form-select-erroring" : ""}
        options={options}
        isClearable={false}
        classNamePrefix='cnt-form-select'
        value={options ? value : ""}
        onChange={(option) => {
          const { report_email } = formikProps.values;
          report_email[name] = option.value
          formikProps.setFieldValue('report_email', report_email)
        }}
        disabled={renderOptions['disabled']}
      />
    )
  }

  getSecondsOffset(value) {
    let minute = 60;
    let hour = minute * 60;
    let day = hour * 24;
    let week = day * 7;
    switch (value) {
      case 'hour':
        return hour;
        break;
      case 'day':
        return day;
        break;
      case 'week':
        return week;
        break;
      case 'weekx2':
        return week * 2;
        break;
      default:
        return 0;
    }
  }

  getIntervals() {
    const { user } = this.props;
    return [
      { label: 'Hourly', value: this.getSecondsOffset('hour') },
      { label: 'Daily', value: this.getSecondsOffset('day') },
      { label: 'Weekly', value: this.getSecondsOffset('week') },
      { label: 'Every two weeks', value: this.getSecondsOffset('weekx2') }
    ];
  }

  renderForm(formikProps) {
    return (
      <Form className="cnt-default-layout-editing_users-form" >
        <div className="row">
          <div className="col-xs-12">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Schedule a time
              </label>
              {this.renderField("time_scheduled", formikProps, true, false, {type: 'datetime-local'})}
            </div>
          </div>
          <div className="col-xs-12">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Pick a time interval
              </label>
              {this.renderSelectField("time_interval", formikProps, this.getIntervals())}
            </div>
          </div>
          <div className="col-xs-12">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Number of repeats
              </label>
              {this.renderField("total_sends", formikProps, true, false, {type: 'number'})}
            </div>
          </div>
          <div className="col-xs-12">
            <br />
            <button type="button" onClick={() => formikProps.handleSubmit()} className="cnt-submit-button"> Submit </button>
          </div>
        </div>
      </Form>
    );
  }

  render() {
    const { report_id, current_user, report_email } = this.props;
    return (
      <Formik
        initialValues={{
          report_email: {
            report_id: report_id,
            user_id: current_user.id,
            time_scheduled: report_email ? report_email.time_scheduled : "",
            time_interval: report_email ? report_email.time_interval_seconds : "",
            total_sends: report_email ? report_email.total_sends : ""
          }
        }}
        onSubmit={(values, actions) => {
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          let date = new Date(values['report_email']['time_scheduled']);
          values['report_email']['time_scheduled'] = date.toUTCString();

          fetch(this.formURL(), {
            method: this.formMethod(),
            redirect: "manual",
            body: JSON.stringify(values),
            headers: {
              "X-CSRF-Token": token,
              "Content-Type": 'application/json'
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                console.log('success!');
                window.location.reload();
              } else {
                console.log('failure!');
              }
            });
        }}
        render={(formikProps) => (
          <>
            {this.renderForm(formikProps)}
          </>
        )}
      />
    );
  }
}

export default ReportScheduler;
