import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import NominationStatus from "@nominations/tables/NominationStatus"
import NominationThreeStepForm from "./forms/NominationThreeStepForm"
import NominationStatusBlock from "@nominations/NominationStatusBlock"
import { canApproveNomination } from "@utility/PermissionUtility"
import NominationApproveModal from "./forms/NominationApproveModal"
import NominationReviewModal from "./forms/NominationReviewModal"
import NominationRejectModal from "./forms/NominationRejectModal"
import NominationNotifyModal from "./forms/NominationNotifyModal"
import { getNominationQuestionsAndAnswers, setNominationReview } from "@utility/NominationUtility"
class NominationNewThreeStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
      this.reviewNomination = this.reviewNomination.bind(this);
  }



  reviewNomination() {
    const { reviewCallback, nomination } = this.props;
    const { currentComment } = this.state;
    setNominationReview(nomination, reviewCallback ? reviewCallback : null);
    location.reload();
  }

  renderApproveDeny() {
    const { user, nomination } = this.props;
    const state = nomination.status;
    const state_changable =  (state == "submit" || state=="in_review")
    if (canApproveNomination(user) && state != "draft") {
      return (
        <div className="cnt-nomination-review-buttons-container">
        <div className="cnt-default-layout-nominations-form-approve-deny-container row">
          <div className="cnt-default-layout-nominations-form-approve-deny-buttons">
            <div className="col-xs-12 text-right" style={{paddingTop: "20px"}}>
              {state_changable ? (
                <>
                {nomination.status == "submit" ? (
                  <NominationReviewModal {...this.props} outerClassNames="cnt-display-block">
                    <button type="button" className="cnt-review-button full-length-button"> In Review </button>
                  </NominationReviewModal>
                ):""}
                <NominationApproveModal {...this.props} outerClassNames="cnt-display-block"><button type="button" className="cnt-approve-button full-length-button"> Approve </button></NominationApproveModal>
                <NominationRejectModal {...this.props} outerClassNames="cnt-display-block"><button type="button" className="cnt-reject-button full-length-button"> Deny </button></NominationRejectModal>
                </>
              ):""}

              <NominationNotifyModal {...this.props} outerClassNames="cnt-display-block"><button type="button" className="cnt-notify-button full-length-button"> Notify AMs </button></NominationNotifyModal>
            </div>
          </div>
        </div>
        {state_changable ? (
          <div className="">
            <i>
              You will have the option to leave a note with your approval or denial.
            </i>
          </div>
        ):""}

        </div>
      )
    }
  }


  render() {
    const { user, nomination, versions } = this.props;
    return (
      <DefaultLayout
        {...this.props}
        sidebarId="nominations"
        classNames={{
          wrapper: 'cnt-nomination-form-wrapper'
        }}
      >
        <div className="row" style={{maxWidth:"1200px"}}>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 cnt-nomination-form-main-container">
            <NominationThreeStepForm {...this.props} />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
            {this.renderApproveDeny()}
            <br/>
            {nomination.status == 'draft' ? "":(
              <NominationStatusBlock versions={versions} showSubmission={true} nomination={nomination}/>
            )}

            <br/>

          </div>
        </div>

      </DefaultLayout>
    );
  }
}

export default NominationNewThreeStep;
