import React from "react";
import PropTypes from "prop-types";
import { getReadableNominationStatus } from "@utility/NominationUtility"
class NominationsStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  getRawStatus() {
    const { nomination } = this.props;
    if(nomination && nomination.status) {
      return nomination.status;
    }
    return "undefined"
  }


  capatalizeString(updatable_string) {
    return updatable_string.charAt(0).toUpperCase() + updatable_string.slice(1);
  }

  render() {
    return (
      <div className={`cnt-default-nomination-status-ticker cnt-default-nomination-status-ticker-${this.getRawStatus()}`}>
        {getReadableNominationStatus(this.getRawStatus())}
      </div>
    );
  }
}

export default NominationsStatus;
