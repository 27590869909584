import React from "react";
import GeneralTable from "@layout/tables/GeneralTable"
import DefaultTooltip from "@layout/tooltips/DefaultTooltip";
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime, listTimeZones } from 'date-fns-timezone';
import SuperFilter from "@layout/superfilter/SuperFilter"
import { multiSelectCheckboxImages } from "@utility/checkboxUtility"
import Checkbox from "@layout/forms/Checkbox"
import MultiselectActionBar from "@layout/tables/MultiselectActionBar"
import {fetchAPI} from "@utility/NetworkUtils"

class UpdatesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: props.contacts ||[],
      companies: props.contacts ||[],
      superFilters: [],
      selectedVersions: [],
    };
    this.renderActions = this.renderActions.bind(this);
    this.setSuperFilters = this.setSuperFilters.bind(this);
    this.renderVerificationStatus = this.renderVerificationStatus.bind(this);
    this.mapFilteredUpdates = this.mapFilteredUpdates.bind(this);
    this.renderNameRow = this.renderNameRow.bind(this);
    this.getVerifiedFilterValue = this.getVerifiedFilterValue.bind(this);
    this.tableRef = React.createRef();
    this.renderVersionCheckbox = this.renderVersionCheckbox.bind(this);
    this.applyMultiSelectAttributes = this.applyMultiSelectAttributes.bind(this);
  }

  mapFilteredUpdates(data) {
    return data.updates.map(x=>{
      x.item = this.getItem(x, data.contacts,data.companies);
      x.select = true;
      if(x.item_type == "Contact") {
        x.item_name = x.item ? `${x.item.first_name} ${x.item.last_name}` : "";
      } else if(x.item_type=="Company") {
        x.item_name = x.item ? x.item.name : "";
      }

      x.item_link = x.item ? `/${x.item_type == "Contact" ? 'contacts' : 'companies'}/${x.item_id}`: "";
      x.account_manager_name = x.user ? `${x.user.first_name} ${x.user.last_name}` : "";
      x.verified_by_name = x.verified_by ? `${x.verified_by.first_name} ${x.verified_by.last_name}`: "--";
      x.verification_status = x.verified_at ? "verified" : "new";
      return x;
    })
  }

  getColumns() {
    const { user } = this.props;
    let allColumns = [
      { maxWidth: '35px',sorting: false, title: "", field: 'select', render: this.renderVersionCheckbox},
      { sorting: false, title: 'Updates made to', field: 'item_name', render: this.renderNameRow},
      { sortable: false, title: 'Updates made by', field: 'account_manager_name' },
      { sortable: false, title: 'Type Updated', field: 'item_type' },
      { sortable: true, defaultSort: "desc", title: 'Updates Time and Date', field: 'created_at', render: (row) => (formatToTimeZone(row.created_at,"hh:mm aa MMM/DD/YYYY", { timeZone: 'America/Los_Angeles'})) },
      { sortable: false, title: 'Verified by', field: 'verified_by_name' },
      { sortable: false, title: 'Verfication Time & Date', field: 'verified_at',  render: (row) => (row.verified_at ? formatToTimeZone(row.verified_at,"hh:mm aa MMM/DD/YYYY", { timeZone: 'America/Los_Angeles'}) : "--")},
      {  maxWidth: '100px',sorting: false, title: 'Update Status', field: 'verification_status',  render: this.renderVerificationStatus},
      { maxWidth: '50px', exportable: false, sorting: false, title: 'Actions', field: 'verification_status',  render: this.renderActions}
    ]
    return allColumns;
  }

  renderNameRow(row) {
    if(row && row.item_name && row.item_link) {
      return (
        <a href={row.item_link} target="_blank">
          {row.item_name}
        </a>
      )
    }
  }

  getItem(version, contacts = null, companies = null) {
    switch(version.item_type) {
      case 'Contact':
        return contacts.find(x=> x.id == version.item_id)
      case 'Company':
        return companies.find(x=> x.id == version.item_id)
      default:
        return null;
    }
  }

  renderActions(row) {
    return (
      <div className="cnt-default-layout-nomination-table-icons">
        <DefaultTooltip
          tooltipId={`sg-cnt-default-layout-version-table-version-edit-${row.id}`}
        >
          Review Update
        </DefaultTooltip>
        <a

          data-for={`sg-cnt-default-layout-version-table-version-edit-${row.id}`}
          data-tip
          href={row.item_link}
          target="_blank"
        >
        <img style={{width: "17px"}} src="/layout/review-icon.png" /></a>
      </div>
    );
  }

  renderVerificationStatus(row) {
    return (
      <div className={`cnt-default-version-status-ticker cnt-default-version-status-ticker-${row.verification_status}`}>
        {this.capatalizeString(row.verification_status)}
      </div>
    )
  }

  capatalizeString(updatable_string) {
    if(updatable_string) {
      return updatable_string.charAt(0).toUpperCase() + updatable_string.slice(1).replaceAll("_"," ").replaceAll("-"," ");
    }
    return null
  }

  renderSuperFilter() {
    const verifiedFilterOptions = [
      {label: 'All', id: 'all'},
      {label: 'New Updates', id: 'new-updates'},
      {label: 'Verified Updates', id: 'verified-updates'},
    ]

    return(
      <>
        <SuperFilter
          onChange={this.setSuperFilters}
          defaultValues={{
            verified_filter:["new-updates"]
          }}
          filters={[
            {
              id: "verified_filter",
              type:"select",
              options: verifiedFilterOptions,
              outerClassName: "col-xs-12 col-md-6 col-lg-3",
              placeholder: "Sort by verified"
            },
          ]}
        />
      </>
    )
  }

  setSuperFilters(filters) {
    this.setState({
      superFilters: filters
    })
    console.log(this.tableRef)
    if(this.tableRef && this.tableRef.current) {
      this.tableRef.current.onQueryChange();
    }
  }

  getVerifiedFilterValue() {
    const { superFilters } = this.state;
    const verifiedFilterFilter = superFilters.find(x=>x.filterID == 'verified_filter');
    return verifiedFilterFilter ? verifiedFilterFilter.filterOptionID : "all"
  }

  getSelectedItems() {
    const { versions, selectedVersions } = this.state;
    return selectedVersions.map(x=>{return{id: x}})
  }

  unselectAll() {
    this.setState({
      selectedVersions: []
    })
  }

  applyMultiSelectAttributes(ids, performActionCallback, values) {
    let body = {
      ids: ids,
      version: values
    }
    this.setState({
      selectedVersions: []
    })
    fetchAPI('/api/v1/updates/update_multiple', (json) => {
      if(this.tableRef && this.tableRef.current) {
        this.tableRef.current.onQueryChange();
      }
      if(performActionCallback) {
        performActionCallback(json)
      }
    }, {
      body: body,
      method: 'PATCH'
    });
  }

  getVersionTableMultiSelectOptions() {
    let otherOptions = {
      name: "Set Verification",
      id: "set_verfication",
      options: [
        {label: "Verified", value: 'verified'},
        {label: "Unverified", value: 'unverified'}
      ]
    };
    return [otherOptions].filter(x=>x);
  }

  renderVersionCheckbox(row) {
    const { selectedVersions } = this.state;
    const isChecked = selectedVersions.indexOf(row.id) != -1;
    return (
      <div className="cnt-nomination-table-checkbox">
        <Checkbox
          disabled={false}
          value={isChecked}
          onClick={()=>this.onCheckboxClick(row)}
          images={multiSelectCheckboxImages()}
        />
      </div>
    )
  }

  onCheckboxClick(row) {
    let { selectedVersions } = this.state;
    const isChecked = selectedVersions.indexOf(row.id) != -1;
    if(isChecked) {
      selectedVersions = selectedVersions.filter(x=> x != row.id)
    }else {
      selectedVersions = selectedVersions.concat([
        row.id
      ])
    }
    this.setState({
      selectedVersions
    })
  }



  render() {
    const { superFilters } = this.state;
    return (
        <div className="cnt-default-layout-updates-table">
          <MultiselectActionBar
            selectedItems={this.getSelectedItems()}
            options={this.getVersionTableMultiSelectOptions()}
            submitCallback={this.applyMultiSelectAttributes}
            closeCallback={this.unselectAll}
          />
          {this.renderSuperFilter()}
          <GeneralTable
            tableRef={this.tableRef}
            columns={this.getColumns()}
            dataUrl='/api/v1/updates/table'
            dataUrlAdditionalParams={{
              verified_filter: this.getVerifiedFilterValue()
            }}
            dataUrlMapCallback={this.mapFilteredUpdates}
            showTotals={true}
          />
        </div>
    )
  }
}

export default UpdatesTable;
