import React from "react";
import PropTypes from "prop-types";
import { isEventLocked } from "@utility/EventUtils";
import { fetchAPI } from "@utility/NetworkUtils";
import { Formik, Field, Form } from "formik";
import Select from "react-select-virtualized";
import MultiSelect from "react-select";
import NominationVerifyPanel from "./NominationVerifyPanel";
import UniversalImageCircle from "@images/UniversalImageCircle";
import { dateText } from "@utility/TimeUtils";
import { isNominationEditable } from "@utility/PermissionUtility";
import NominationStatus from "@nominations/tables/NominationStatus";
import AttendeeStatus from "@nominations/tables/AttendeeStatus";
import AttendenceStatus from "@nominations/tables/AttendenceStatus";
import Cookies from "universal-cookie";
import NominationHistoryModal from "@nominations/modals/NominationHistoryModal";
import NominationQuestionModal from "./NominationQuestionModal"
import { convertMarkdownToHtml } from "@utility/MarkdownUtility";
import { convertCssToObject } from "@utility/CssUtility";
import { canEditSubmittedNomination, isExecutiveRelationsLead, isMarketingManager,isAccountManager, isSolutionsLead, isJaguar } from "../../utility/PermissionUtility";
import ContactAccountManagerFormModal from "@contacts/modals/ContactAccountManagerFormModal";

class NominationThreeStepForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      error: null,
      submitting: false,
      nominationExists: false,
      saving: false,
      contacts: props.contacts,
      forceEditing: false
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.isEventLockedByID = this.isEventLockedByID.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.saveAsDraft = this.saveAsDraft.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.checkNominationNew = this.checkNominationNew.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.maxStep = 2;
    this.minStep = 0;
    this.deleteNomination = this.deleteNomination.bind(this);
  }

  saveAsDraft(formikProps) {
    const { submitting } = this.state;
    formikProps.values.nomination.status = "draft";
    if (!submitting) {
      formikProps.handleSubmit();
    }
  }

  isFormEditingEnabled() {
    const { nomination, user, event } = this.props;
    const { forceEditing } = this.state;
    return isNominationEditable(nomination) || canEditSubmittedNomination(user) && forceEditing;
  }

  getStepImageSrc() {
    const { step } = this.state;
    let step_image = "";
    switch (step) {
      case 0:
        return "/images/nominations/nom-step-1.svg";
      case 1:
        return "/images/nominations/nom-step-2.svg";
      case 2:
        return "/images/nominations/nom-step-3.svg";
    }
  }

  renderReviewPage(formikProps) {
    const { nominationExists, contacts } = this.state;
    const { user, events } = this.props;
    const { values } = formikProps;
    const { nomination } = values;
    const contact = nomination.contact_id
      ? contacts.find(x => x.id == nomination.contact_id)
      : {};

    const event = events.find(x => x.id == nomination.event_id);
    return (
      <>
        <div className="row" id="cnt-review-top" />
        <div className="row">
          <div className="col-xs-12">
            <div className="cnt-condensed-model-section">
              <div className="cnt-condensed-model-section-header">
                Contact
                <NominationHistoryModal
                  contact={contact}
                  user={user}
                  outerClassNames="cnt-nomination-history-modal-link"
                >
                  Nomination History
                </NominationHistoryModal>
              </div>
            </div>
            {this.renderContact(formikProps)}
            <div className="cnt-condensed-model-section">
              <div className="cnt-condensed-model-section-header">Event</div>
            </div>
            {this.renderEvent(formikProps)}
          </div>
          <div className="col-xs-12">
            <div className="cnt-condensed-model-section">
              <div className="cnt-condensed-model-section-header">
                {event && event.options&& event.options.criteria_header_override ? event.options.criteria_header_override : "Event Criteria"}
              </div>
            </div>
          </div>
          {((event || {}).description||"").replace(/<[^>]*>/g, '').trim().length > 0 ? (
            <div className="cnt-nomination-form-event-description col-xs-12">
              {((event || {}).description || "").split("\n").map(x => (
                <>
                  {x}
                  <br />
                </>
              ))}
            </div>
          ):""}

          <div className="col-xs-12">
            <div className="cnt-condensed-model-section">
              <div className="cnt-condensed-model-section-header">
                Nomination Info
              </div>
            </div>
          </div>

          {this.renderEventCriteria(formikProps)}
          <NominationVerifyPanel
            user={user}
            contactId={nomination.contact_id}
          />
          <div className="cnt-condensed-model-section col-xs-12">
            <div className="cnt-condensed-model-section-header">Notes</div>
          </div>
          <div className="col-xs-12">{formikProps.values.nomination.notes}</div>
        </div>
      </>
    );
  }

  renderForm(formikProps) {
    const { step } = this.state;
    const { nomination } = this.props;
    if (nomination.status != "draft") {
      //TODO only on show
      return this.renderReviewPage(formikProps);
    }
    switch (step) {
      case 0:
        return this.renderNominatePage(formikProps);
      case 1:
        return this.renderVerifyPage(formikProps);
      case 2:
        return this.renderSubmitPage(formikProps);
      default:
        return this.renderErrorPage(formikProps);
    }
  }

  nominationEventQuestionsValid(formikProps) {
    const { values } = formikProps;
    const { nomination } = values;
    const { events } = this.props;
    const event = events.find(x => x.id == nomination.event_id);

    const questions =
      event && event.award_criteria && event.award_criteria.questions
        ? event.award_criteria.questions
        : [];

    const answers = nomination["award_criteria_answers"].answers || [];

    const invalidQuestions = questions
      .map(question => {
        if (question.optional || question.hidden || question.type == "label") {
          return true;
        }
        const id = question.id;
        const answer = answers.find(x => x.id == id);
        return !!(answer && answer.answer);
      })
      .filter(x => !x);
    return invalidQuestions.length <= 0;
  }

  createNewContact(contact, formikProps) {
    const { contacts } = this.state
    this.setState({
      contacts: contacts.filter(x=>x.id != contact.id).concat([contact])
    })
    const values = formikProps.values;
    let { nomination } = values;
    nomination.contact_id = contact.id
    formikProps.setFieldValue("nomination", nomination);
    this.setState({
      dirty: true
    });
  }

  renderNominatePage(formikProps) {
    const { nominationExists } = this.state;
    const { events, user} = this.props;
    const { values } = formikProps;
    const { nomination } = values;

    return (
      <>
        <div className="row">
          <div className="col-xs-12 text-center cnt-nomination-form-subheader">
            Make a nomination in three simple steps.
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className="cnt-default-layout-event-form-input-container">
              <b className="cnt-default-layout-nominations-form-section-header cnt-default-layout-nominations-form-section-header-green">
                Select your contact
              </b>
              {this.renderSelectField(
                "contact_id",
                formikProps,
                this.getContactOptions(),
                false,
                {
                  checkNominationExists: true,
                  clearable: true
                }
              )}
              {values.nomination.contact_id ? "" : (
                <div className="cnt-form-field-under-message">
                  <ContactAccountManagerFormModal 
                    user={user}
                    onSaveCallback={(contact)=>this.createNewContact(contact,formikProps)}
                  >
                    <button
                      className="cnt-nomination-create-contact-button"
                    >
                      Create a Contact
                    </button>
                  </ContactAccountManagerFormModal>
                </div>
              )}

            </div>
            {this.renderContact(formikProps)}
            {nomination.event_id && events.length <= 1 ? (
              ""
            ): (
              <div className="cnt-default-layout-event-form-input-container">
                <b className="cnt-default-layout-nominations-form-section-header cnt-default-layout-nominations-form-section-header-green">
                  Select the event
                </b>
                {this.renderSelectField(
                  "event_id",
                  formikProps,
                  this.getEventOptions(),
                  false,
                  {
                    optionFilter: this.isEventLockedByID,
                    checkNominationExists: true
                  }
                )}
            </div> 
            )}

            {this.renderEvent(formikProps)}
            {nominationExists ? (
              <div className="cnt-default-layout-event-form-error">
                This contact has already been nominated for this event. Please
                try nominating someone else or choosing another event to
                nominate to.
              </div>
            ) : (
              ""
            )}
          </div>
          {this.renderEventCriteria(formikProps)}
        </div>
      </>
    );
  }

  renderEventCriteria(formikProps) {
    const { events, user } = this.props;
    const { forceEditing, submitting } = this.state;
    const { nomination } = formikProps.values;
    if (nomination && nomination.event_id) {
      const event = events.find(x => x.id == nomination.event_id);
      return (
        <div className="col-xs-12">
          {nomination.status == "draft" ? (
            <>
              {((event || {}).description||"").replace(/<[^>]*>/g, '').trim().length > 0 ? (
                <>
                <b className="cnt-default-layout-nominations-form-section-header cnt-default-layout-nominations-form-section-header-green">
                  {event && event.options&& event.options.criteria_header_override ? event.options.criteria_header_override : "Event Criteria"}
                </b>
          
                <div className="cnt-nomination-form-event-description" dangerouslySetInnerHTML={{ __html: convertMarkdownToHtml(event.description) }} />
                </>
              ):""}
             
            </>
          ) : ""}
          {event &&
          event.award_criteria &&
          event.award_criteria.questions &&
          event.award_criteria.questions.length > 0 ? (
            <b className="cnt-default-layout-nominations-form-section-header cnt-default-layout-nominations-form-section-header-green">
              {event && event.options&& event.options.nomination_info_header_override ? event.options.nomination_info_header_override : "Nomination Info"}
            </b>
          ) : (
            <br />
          )}

          {this.renderEventCriteriaQuestions(formikProps)}
          <br/>
          {!this.isFormEditingEnabled() && canEditSubmittedNomination(user) ? (
            <button
              type="button"
              className="cnt-approve-button"
              onClick={()=>
                this.setState({
                  forceEditing: true
                })
              }
            >
              Edit
            </button>
          ):""}
          {this.isFormEditingEnabled() && forceEditing && canEditSubmittedNomination(user) ? (
            <button
              type="submit"
              className="cnt-approve-button"
              onClick={()=>
                this.saveForm(formikProps)
              }
            >
              {!submitting ? "Save Changes" : "Saving..."}
            </button>
          ):""}
        </div>
      );
    }
  }

  renderEventCriteriaQuestions(formikProps) {
    const { nomination } = formikProps.values;
    const { events } = this.props;
    if (nomination && nomination.event_id) {
      const event = events.find(x => x.id == nomination.event_id);
      const questions =
        event && event.award_criteria && event.award_criteria.questions
          ? event.award_criteria.questions
          : [];
      return questions.map(question =>
        this.renderEventCriteriaQuestion(question, formikProps)
      );
    }
    return "";
  }

  async nextPage(formikProps) {
    const { step } = this.state;
    if (
      step < this.maxStep &&
      (this.isPageValid(step, formikProps) || !this.isFormEditingEnabled())
    ) {
      this.setState({
        step: step + 1,
        error: "",
        nominationExists: false
      });
    } else {
      this.setState({
        error: "Please fill out all mandatory fields."
      });
    }
  }

  prevPage() {
    const { step } = this.state;
    if (step > this.minStep) {
      this.setState({
        step: step - 1,
        nominationExists: false,
        error: ""
      });
      
    }
  }

  async checkContactValid(formikProps) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const response = await fetch(
      `/api/v1/nominations/check_nomination_validity?contact_id=${formikProps.values.nomination.contact_id}`,
      {
        method: "GET",
        redirect: "manual",
        body: null,
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": "application/json"
        }
      }
    );
    const json = await response.json();

    if (json.contact_valid) {
      this.setState({
        error: null
      });
      return true;
    } else {
      this.setState({
        error: (
          <>
            {!json.contact_valid
              ? `Please check your contact, some required information is missing.`
              : ""}
            <br />
            <br/>
            Please click on “Edit contact information” to add required information.
          </>
        )
      });
      return false;
    }
  }

 isPageValid(page, formikProps) {
    const { nominationExists } = this.state;
    const nomination = formikProps.values.nomination;
    switch (page) {
    case 0:
        return (
        this.nominationEventQuestionsValid(formikProps) &&
        nomination.event_id &&
        nomination.contact_id &&
        !nominationExists
      );
    case 1:
      return true;
      case 2:
        this.isPageValid(0, formikProps) && this.isPageValid(1, formikProps);
      default:
        return false;
    }
  }

  async nextPage(formikProps) {
    const { step } = this.state;
    if (step == 1 && !(await this.checkContactValid(formikProps))) {
      return;
    }
    if (
      step < this.maxStep &&
      (this.isPageValid(step, formikProps) || !this.isFormEditingEnabled())
    ) {
      this.setState({
        step: step + 1,
        error: "",
        nominationExists: false
      });
    } else {
      this.setState({
        error: "Please fill out all mandatory fields."
      });
    }
  }


  renderQuestionModal(question) {
    if(question && question.custom_modal && question.custom_modal_content) {
      return (
        <NominationQuestionModal
          question={question}
        />
      )
    }
  }

  renderEventCriteriaQuestion(question, formikProps) {
    const { nomination } = formikProps.values;
    const { events } = this.props;
    const optional = question.optional;
    if (question.hidden) {
      return "";
    }
    switch (question.type) {
      case "dropdown":
        return (
          <div className="cnt-default-layout-nom-question">
            <b
              className={`cnt-default-layout-nominations-form-section-criteria-question-label cnt-default-layout-nominations-form-section-header ${
                optional ? "" : "required"
              }`}
            >
              {question.question}
              {this.renderQuestionModal(question)}
            </b>
            {this.renderSelectFieldAnswer(
              question,
              formikProps,
              question.answers.split("\n").map(x => {
                return { label: x, value: x };
              })
            )}
          </div>
        );
      case "checkbox":
        return (
          <div className="cnt-default-layout-nom-question">
            <b
              className={`cnt-default-layout-nominations-form-section-criteria-question-label cnt-default-layout-nominations-form-section-header ${
                optional ? "" : "required"
              }`}
            >
              {question.question}
              {this.renderQuestionModal(question)}
            </b>
            {this.renderCheckboxQuestion(
              question,
              formikProps,
              question.answers.split("\n").map(x => {
                return { label: x, value: x };
              })
            )}
          </div>
        );
      case "radiobutton":
          return (
            <div className="cnt-default-layout-nom-question">
              <b
                className={`cnt-default-layout-nominations-form-section-criteria-question-label cnt-default-layout-nominations-form-section-header ${
                  optional ? "" : "required"
                }`}
              >
                {question.question}
                {this.renderQuestionModal(question)}
              </b>
              {this.renderRadioQuestion(
                question,
                formikProps,
                question.answers.split("\n").map(x => {
                  return { label: x, value: x };
                })
              )}
            </div>
          );
      case "label":
        return (
          <div className="cnt-default-layout-nom-question">
            <b
              className={`cnt-default-layout-nominations-form-section-criteria-question-label cnt-default-layout-nominations-form-section-header ${
                optional ? "" : "required"
              }`}
              style={question.use_custom_styles ? convertCssToObject(question.custom_styles_css) : {}}
            >
              {question.question}
              {this.renderQuestionModal(question)}
            </b>
          </div>
        );
      case "money":
        return (
          <div className="cnt-default-layout-nom-question">
            <b
              className={`cnt-default-layout-nominations-form-section-criteria-question-label cnt-default-layout-nominations-form-section-header ${
                optional ? "" : "required"
              }`}
            >
              {question.question}
              {this.renderQuestionModal(question)}
            </b>
            <div style={{ position: "relative" }}>
              <span className="cnt-default-layoput-money-text-field-icon">
                $
              </span>
              {this.renderFieldAnswer(question, formikProps, {
                type: "number",
                paddern: "\d*",
                step: "any",
                classes: "cnt-default-layoput-money-text-field"
              })}
            </div>
          </div>
        );
      case "multiselect":
        return (
          <div className="cnt-default-layout-nom-question">
            <b
              className={`cnt-default-layout-nominations-form-section-criteria-question-label cnt-default-layout-nominations-form-section-header ${
                optional ? "" : "required"
              }`}
            >
              {question.question}
              {this.renderQuestionModal(question)}
            </b>
            {this.renderMultiSelectFieldAnswer(
              question,
              formikProps,
              question.answers.split("\n").map(x => {
                return { label: x, value: x };
              })
            )}
          </div>
        );
      default:
        return (
          <div className="cnt-default-layout-nom-question">
            <b
              className={`cnt-default-layout-nominations-form-section-criteria-question-label cnt-default-layout-nominations-form-section-header ${
                optional ? "" : "required"
              }`}
            >
              {question.question}
              {this.renderQuestionModal(question)}
            </b>
            {this.renderFieldAnswer(question, formikProps, {
              component: "textarea"
            })}
          </div>
        );
    }
  }

  renderMultiSelectFieldAnswer(
    question,
    formikProps,
    options,
    renderOptions = {}
  ) {
    const { checkError } = this.state;
    const { values } = formikProps;
    let { nomination } = values;
    const answers = nomination["award_criteria_answers"].answers || [];
    const id = question.id;
    let value = [];
    const answer = answers.find(x => x.id == id);
    const answersSplit = ((answer || {}).answer || "").split("\n");
    try {
      value = options.filter(
        option => answersSplit.indexOf(option.value) != -1
      );
    } catch (e) {}

    const erroring = checkError && !value;
    return (
      <MultiSelect
        isMulti={true}
        className={erroring ? "cnt-form-select-erroring" : ""}
        options={options}
        classNamePrefix="cnt-form-select"
        value={options ? value : ""}
        isClearable={false}
        onChange={option => {
          let { nomination } = values;
          let answerOption = answer;
          answerOption = option.map(x => x.value).join("\n");

          nomination.award_criteria_answers.answers = (
            nomination["award_criteria_answers"].answers || []
          )
            .filter(x => x.id != question.id)
            .concat([{ id: question.id, answer: answerOption }]);

          formikProps.setFieldValue("nomination", nomination);
          this.setState({
            dirty: true
          });
        }}
        isDisabled={renderOptions["disabled"] || !this.isFormEditingEnabled()}
        filterOption={this.customFilter}
      />
    );
  }

  renderSelectFieldAnswer(question, formikProps, options, renderOptions = {}) {
    const { checkError } = this.state;
    const { values } = formikProps;
    let { nomination } = values;
    const answers = nomination["award_criteria_answers"].answers || [];
    const id = question.id;
    let value = null;
    const answer = answers.find(x => x.id == id);
    try {
      value = options.filter(option => option.value === answer.answer);
    } catch (e) {}

    const erroring = checkError && !value;
    return (
      <Select
        className={erroring ? "cnt-form-select-erroring" : ""}
        options={options}
        classNamePrefix="cnt-form-select"
        value={options ? value : ""}
        isClearable={false}
        onChange={option => {
          let { nomination } = values;
          nomination.award_criteria_answers.answers = (
            nomination["award_criteria_answers"].answers || []
          )
            .filter(x => x.id != question.id)
            .concat([{ id: question.id, answer: option.value }]);
          formikProps.setFieldValue("nomination", nomination);
          this.setState({
            dirty: true
          });
        }}
        isDisabled={renderOptions["disabled"] || !this.isFormEditingEnabled()}
        filterOption={this.customFilter}
      />
    );
  }

  renderFieldAnswer(question, formikProps, properties = {}) {
    const { user } = this.props;
    const { checkError } = this.state;
    const erroring =
      checkError &&
      (!formikProps.values.nomination[name] ||
        formikProps.values.nomination[name].length <= 0);
    const { values } = formikProps;
    let { nomination } = values;
    const answers = nomination["award_criteria_answers"].answers || [];
    const id = question.id;
    const answer = answers.find(x => x.id == id);

    return (
      <Field
        onChange={e => {
          let { nomination } = values;
          nomination.award_criteria_answers.answers = (
            nomination["award_criteria_answers"].answers || []
          )
            .filter(x => x.id != question.id)
            .concat([{ id: question.id, answer: e.target.value }]);
          formikProps.setFieldValue("nomination", nomination);
          this.setState({
            dirty: true
          });
        }}
        style={question.use_custom_styles ? convertCssToObject(question.custom_styles_css) : {}}
        disabled={properties["disabled"] || !this.isFormEditingEnabled()}
        component={properties["component"]}
        className={`form-control ${properties["classes"]} ${
          erroring ? "field-erroring" : ""
        }`}
        pattern={properties["pattern"]}
        type={properties["type"]}
        value={answer ? answer.answer : ""}
        step={properties["step"]}
      />
    );
  }

  renderField(
    name,
    formikProps,
    required = false,
    customValidation = false,
    properties = {}
  ) {
    const { user } = this.props;
    const { checkError } = this.state;
    const erroring =
      required &&
      checkError &&
      (!formikProps.values.nomination[name] ||
        formikProps.values.nomination[name].length <= 0 ||
        customValidation);

    return (
      <Field
        onChange={e => {
          const { nomination } = formikProps.values;
          nomination[name] = e.target.value;
          formikProps.setFieldValue("nomination", nomination);
          this.setState({
            dirty: true
          });
        }}
        disabled={properties["disabled"] || !this.isFormEditingEnabled()}
        component={properties["component"]}
        className={`form-control ${erroring ? "field-erroring" : ""}`}
        type={properties["type"]}
        name={`nomination[${name}]`}
      />
    );
  }

  renderRadioQuestion(question, formikProps, options, renderOptions = {}) {
    const { checkError } = this.state;
    const { values } = formikProps;
    let { nomination } = values;
    const answers = (nomination['award_criteria_answers'].answers||[])
    const id = question.id

    let value = null;
    const answer = answers.find(x => x.id == id);

    const erroring = checkError && (!value);
    const checkboxes = options.map(option=>{
      const checkboxValue = option.value == (answer||{}).answer
      return (
        <>
          <img
            src={ checkboxValue  ? "/layout/form/radiobutton-clicked.svg" : "/layout/form/radiobutton-unclicked.svg" }
            style={{cursor:"pointer", margin: "0 10px"}}
            onClick={(e) => {
              let { nomination } = values;
              nomination.award_criteria_answers.answers = (
                nomination["award_criteria_answers"].answers || []
              )
                .filter(x => x.id != question.id)
                .concat([{ id: question.id, answer: option.value }]);
              formikProps.setFieldValue("nomination", nomination);
              this.setState({
                dirty: true
              });
            }}
          /><label className="checkbox-label" for="topic_ids">{option.label}</label>
          <br/>
        </>
      )
    })

    return (
      <div>
        {checkboxes}
      </div>
    );
  }

  renderCheckboxQuestion(question, formikProps, options, renderOptions = {}) {
    const { checkError } = this.state;
    const { values } = formikProps;
    let { nomination } = values;
    const answers = nomination["award_criteria_answers"].answers || [];
    const id = question.id;
    let value = [];
    const answer = answers.find(x => x.id == id);
    try {
      value = answer ? answer.answer.split("\n") : [];
    } catch (e) {}

    const erroring = checkError && !value;
    const checkboxes = options.map(option => {
      const checkboxValue = value.findIndex(x => x == option.value) != -1;
      return (
        <>
          <img
            src={
              checkboxValue
                ? "/images/checkbox-checked.svg"
                : "/images/checkbox-unchecked.svg"
            }
            style={{ cursor: "pointer", margin: "0 10px" }}
            onClick={e => {
              if (!renderOptions["disabled"] || !this.isFormEditingEnabled()) {
                let { nomination } = values;
                let answerOption = answer;
                if (checkboxValue) {
                  answerOption = value
                    .filter(x => x != option.value)
                    .join("\n");
                } else {
                  answerOption = value.concat([option.value]).join("\n");
                }

                nomination.award_criteria_answers.answers = (
                  nomination["award_criteria_answers"].answers || []
                )
                  .filter(x => x.id != question.id)
                  .concat([{ id: question.id, answer: answerOption }]);

                formikProps.setFieldValue("nomination", nomination);
                this.setState({
                  dirty: true
                });
              }
            }}
          />
          <label className="checkbox-label" for="topic_ids">
            {option.label}
          </label>
          <br />
        </>
      );
    });

    return <div>{checkboxes}</div>;
  }

  getEventOptions() {
    const { events } = this.props;
    return events.map(event => {
      return {
        label: [event.name].filter(x => x && x.trim().length > 0).join(" | "),
        value: event.id
      };
    });
  }

  getContactOptions() {
    const { contacts } = this.state;
    return contacts.map(contact => {
      return {
        label: `${contact.first_name} ${contact.last_name} | ${
          contact.job_title||"No Job Title"
        } | ${contact.company ? contact.company.name : contact.am_provided_company_name || "No Company"}`,
        value: contact.id
      };
    });
  }

  isEventLockedByID(option) {
    const { events } = this.props;
    const event = events.find(x => x.id == option.value);
    return isEventLocked(event);
  }

  renderSelectField(
    name,
    formikProps,
    options,
    customValidation = false,
    renderOptions = {}
  ) {
    const { checkError } = this.state;
    const erroring =
      checkError &&
      (formikProps.values.nomination[name] == null ||
        formikProps.values.nomination[name].length <= 0 ||
        customValidation);
    let value = null;
    try {
      value = options.filter(
        option => option.value === formikProps.values["nomination"][name]
      )[0];
    } catch (e) {}
    if (renderOptions["optionFilter"]) {
      options = options.filter(renderOptions.optionFilter);
    }

    return (
      <Select
        className={erroring ? "cnt-form-select-erroring" : ""}
        options={options}
        isClearable={!!renderOptions["clearable"]}
        classNamePrefix="cnt-form-select"
        value={options ? value : ""}
        onChange={option => {
          const { nomination } = formikProps.values;
          nomination[name] = option ? option.value : null;
          if (renderOptions.checkNominationExists) {
            this.checkNominationNew(formikProps);
          }
          formikProps.setFieldValue("nomination", nomination);
          this.setState({
            dirty: true,
            nominationExists: false
          });
        }}
        isDisabled={renderOptions["disabled"] || !this.isFormEditingEnabled()}
        filterOption={this.customFilter}
      />
    );
  }

  renderVerifyPage(formikProps) {
    const { nomination } = formikProps.values;
    const { user } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-xs-12 cnt-nomination-form-subheader text-center">
            Please verify that the contact and company info are correct and
            complete.
          </div>
          <NominationVerifyPanel
            closeModalCallback={() => {
              this.setState({ error: null });
            }}
            user={user}
            contactId={nomination.contact_id}
          />
        </div>
      </>
    );
  }

  renderSubmitPage(formikProps) {
    return (
      <>
        <div className="row">
          <div className="col-xs-12 cnt-nomination-form-subheader text-center">
            Is there anything else you would like to share?
            <br />
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className="cnt-default-layout-event-form-input-container cnt-default-layout-nomination-notes-container">
              <b className="cnt-default-layout-nominations-form-section-header-blue">
                Additional Notes{" "}
                <span style={{ fontWeight: "normal" }}>(optional)</span>
              </b>
              {this.renderField("notes", formikProps, false, false, {
                component: "textarea"
              })}
            </div>
          </div>
        </div>
        {this.isFormEditingEnabled() ? (
          <div className="row">
            <div className="col-xs-12">
              <br />
              <b className="cnt-default-layout-nominations-form-section-header-blue">
                Please click Submit button below if you are all set.
                <br />
              </b>
              Once submitted, your nomination will be reviewed by one of our
              audience engagement leads. You can check your nomination status at
              any time on your dashboard. You can save your nomination as a
              draft, if you would like to come back to it later. Drafts will
              also be accessible on your dashboard.
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }

  checkNominationNew(formikProps) {
    const { nomination } = formikProps.values;
    if (nomination && nomination.contact_id && nomination.event_id) {
      const url = `/api/v1/nominations/validate_nomination_new?contact_id=${nomination.contact_id}&event_id=${nomination.event_id}`;
      fetchAPI(url, json => {
        if (json.nomination_id) {
          this.setState({
            nominationExists: true
          });
        } else {
          this.setState({
            nominationExists: false
          });
        }
      });
    }
  }
  
  renderError() {
    const { error } = this.state;
    if (error) {
      return (
        <div className="col-xs-12 cnt-default-layout-event-form-error ">
          {error}
          <br />
          <br />
        </div>
      );
    }
  }

  renderButtons(formikProps) {
    const { nomination, preview } = this.props;
    const { step, submitting } = this.state;
    const isSubmitButtonDisabled =
      !this.isPageValid(step, formikProps) && this.isFormEditingEnabled();
    if (preview) {
      return "";
    }

    if (nomination.status != "draft") {
      //TODO only on show
      return (
        <div className="row cnt-nomination-form-button-section">
          <div className="col-xs-12 ">
            <div className="cnt-nomination-form-button-section-divider" />
          </div>
          <div className="col-xs-12">
            <button
              style={{ marginRight: "20px" }}
              onClick={() => {
                window.location = "/";
              }}
              className="cnt-cancel-button"
            >
              Close
            </button>
            <a href="#">Return to Top</a>
          </div>
        </div>
      );
    }
    return (
      <div className="row cnt-nomination-form-button-section">
        <div className="col-xs-12 ">
          <div className="cnt-nomination-form-button-section-divider" />
        </div>
        {this.renderError()}

        <div className="col-xs-12">
          {this.isFormEditingEnabled() ? (
            <button
              style={{marginLeft:"0"}}
              className="cnt-draft-button"
              onClick={() => this.saveAsDraft(formikProps)}
              type="button"
            >
              {submitting && formikProps.values.nomination.status != "submit" ? "Saving..." : "Save as draft"}
            </button>
          ) : (
            ""
          )}
          {step > this.minStep ? (
            <button onClick={this.prevPage} className="cnt-cancel-button">
              Go back
            </button>
          ) : (
            ""
          )}
          {step < this.maxStep ? (
            <button
              onClick={() => this.nextPage(formikProps)}
              className={
                !isSubmitButtonDisabled
                  ? "cnt-approve-button"
                  : "cnt-disabled-button "
              }
            >
              Continue
            </button>
          ) : (
            ""
          )}
          {step == this.maxStep && this.isFormEditingEnabled() ? (
            <button
              onClick={() => this.submitForm(formikProps)}
              className="cnt-submit-nom-button"
            >
              {submitting && formikProps.values.nomination.status == "submit" ? this.renderLoadingCircleButton() : "Submit"}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  renderContact(formikProps) {
    const { contacts } = this.state;
    const { nomination } = formikProps.values;
    const contact = nomination.contact_id
      ? contacts.find(x => x.id == nomination.contact_id)
      : null;
    if (contact) {
      return (
        <div className="cnt-default-layout-nomination-contact-area">
          <div className="cnt-default-layout-nomination-contact-description-container">
            <div className="cnt-default-layout-nomination-contact-picture">
              <UniversalImageCircle
                image={contact.contact_image}
                name={contact.first_name}
                id={contact.id}
              />
            </div>
            <div className="cnt-default-layout-nomination-contact-description">
              <span className="cnt-default-layout-nomination-contact-description-name">
                {contact.first_name} {contact.last_name}
              </span>
              <br />
              {contact.job_title}
              <br />
              {contact.company ? contact.company.name : contact.am_provided_company_name || "Unknown"}
            </div>
          </div>
        </div>
      );
    }
  }
  renderEvent(formikProps) {
    const { events } = this.props;
    const { nomination } = formikProps.values;
    const event = nomination.event_id
      ? events.find(x => x.id == nomination.event_id)
      : null;
    if (event) {
      return (
        <div className="cnt-default-layout-nomination-contact-area">
          <div className="cnt-default-layout-nomination-contact-description-container">
            <div className="cnt-default-layout-nomination-contact-picture">
              <UniversalImageCircle
                image={event.icon}
                name={event.name}
                id={event.id}
              />
            </div>
            <div className="cnt-default-layout-nomination-contact-description">
              <span className="cnt-default-layout-nomination-contact-description-name">
                {event.name}
              </span>
              <br />
              {dateText(event)}
              <br />
              {event.location}
              <br />
            </div>
          </div>
        </div>
      );
    }
  }

  submitForm(formikProps) {
    const { submitting } = this.state;
    if (!submitting) {
      formikProps.values.nomination.status = "submit";
      formikProps.handleSubmit();
    }
  }

  saveForm(formikProps) {
    const { submitting } = this.state;
    if (!submitting) {
      formikProps.handleSubmit();
    }
  }

  renderErrorPage(formikProps) {
    return (
      <div className="cnt-nomination-form-error-page">
        An error occured. Please reload or try again later.
      </div>
    );
  }

  formMethod() {
    return this.isEditing() ? "PATCH" : "POST";
  }

  isEditing() {
    const { nomination } = this.props;
    return !!nomination.id;
  }

  formURL() {
    const { nomination } = this.props;
    return this.isEditing() ? `/nominations/${nomination.id}` : `/nominations`;
  }

  renderLoadingCircleButton() {
    return (
      <div className="lds-button-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }

  onFormSubmit(values, actions) {
    const { contacts, forceEditing} = this.state
    this.setState({
      submitting: true,
      error: false,
    });
    values.nomination.submitted_contact_backup = contacts.find(x => x.id == values.nomination.contact_id);

    fetchAPI(
      this.formURL(),
      json => {
        if(forceEditing) {
          location.reload();
        } else if (json.error == null) {
          if (json.redirect_url) {
            window.location = "/";
          }
        } else {
          this.setState({
            error: json.error,
            submitting: false,
            forceEditing: false
          });
        }
      },
      {
        method: this.formMethod(),
        body: values
      }
    );
  }

  renderDelete() {
    const { nomination } = this.props;
    if (nomination.id && nomination.status == "draft") {
      return (
        <a
          className="cnt-nomination-form-delete-link"
          href="#"
          onClick={this.deleteNomination}
        >
          Delete Nomination
        </a>
      );
    }
  }

  deleteNomination() {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    if (!confirm("Are you sure you want to delete this nomination?")) {
      return;
    }
    fetch(this.formURL(), {
      method: "DELETE",
      redirect: "manual",
      body: null,
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          window.location = "/";
        } else {
          this.setState({
            error: json.error
          });
        }
      });
  }

  renderNominationStatuses() {
    const { nomination } = this.props;
    if (nomination) {
      return (
        <div className="row text-center">
          <div className="col-xs-4 cnt-status-alignment-column ">
            <div className="cnt-default-layout-nomination-modal-status-header">
              Nomination Status:
            </div>
            <div className="cnt-default-layout-nomination-modal-status-ticker">
              <NominationStatus nomination={nomination} />
            </div>
          </div>
          <div className="col-xs-4 cnt-status-alignment-column">
            <div className="cnt-default-layout-nomination-modal-status-header">
              Invite Status:
            </div>
            <div className="cnt-default-layout-nomination-modal-status-ticker">
              <AttendeeStatus nomination={nomination} />
            </div>
          </div>
          <div className="col-xs-4 cnt-status-alignment-column">
            <div className="cnt-default-layout-nomination-modal-status-header">
              Attendance:
            </div>
            <div className="cnt-default-layout-nomination-modal-status-ticker">
              <AttendenceStatus nomination={nomination} />
            </div>
          </div>
        </div>
      );
    }
  }

  getNominationSource() {
    const { user } = this.props;
    if(isAccountManager(user)){
      return "AM Nom"
    }
    if(isMarketingManager(user)){
      return "MM Nom"
    }
    if(isSolutionsLead(user) || isExecutiveRelationsLead(user)){
      return "AE Nom"
    } 
    if(isJaguar(user)){
      return "List Import"
    }
  }

  render() {
    const { nomination } = this.props;
    return (
      <Formik
        initialValues={{
          nomination: {
            event_id:
              nomination && nomination.event_id
                ? nomination.event_id
                : this.props.event_id
                ? parseInt(this.props.event_id)
                : null,
            contact_id:
              nomination && nomination.contact_id
                ? nomination.contact_id
                : this.props.contact_id
                ? parseInt(this.props.contact_id)
                : null,
            status: nomination ? nomination.status : "draft",
            award_criteria_answers:
              nomination &&
              !(typeof nomination.award_criteria_answers == "string")
                ? nomination.award_criteria_answers
                : { answers: [] },
            notes: nomination ? nomination.notes : "",
            source: nomination.status == "draft" ? this.getNominationSource() : nomination.source
          }
        }}
        onSubmit={this.onFormSubmit}
        render={formikProps => (
          <div className="cnt-default-layout-nomination-form">
            <div className="row text-center">
              <div className="col-xs-12">
                <h1 className="cnt-page-header cnt-nomination-header">
                  Nomination Form
                  {this.renderDelete()}
                </h1>
                <br />
                {nomination.status == "draft" ? (
                  <img
                    src={this.getStepImageSrc()}
                    className="cnt-nomination-form-step-image"
                  />
                ) : (
                  this.renderNominationStatuses()
                )}
              </div>
            </div>

            {this.renderForm(formikProps)}
            {this.renderButtons(formikProps)}
          </div>
        )}
      />
    );
  }
}

export default NominationThreeStepForm;
