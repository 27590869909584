import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import { tutorialStates } from "@tutorial/TutorialUtil"
import eventBus from "@tutorial/EventBus"
import { fetchAPI } from "@utility/NetworkUtils"
class TutorialController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tutorialStateIndex: null,
    };
    this.startTutorial = this.startTutorial.bind(this);
    this.changeTutorialState = this.changeTutorialState.bind(this);
    this.nextTutorialPage = this.nextTutorialPage.bind(this);
  }

  //Events
  //IN: tutorialNext
  //OUT: tutorialSetState(state)

  componentDidMount() {
     eventBus.on("tutorialNext", (data)=>this.nextTutorialPage());
     eventBus.on("tutorialStart", (data) => this.startTutorial());
  }

  startTutorial() {
    console.log("start tutorial");
    this.changeTutorialState(0);
    fetchAPI(
      '/api/v1/tutorials/view',
      () => {},
      {method: "POST"}
    )
  }

  nextTutorialPage() {
    const { tutorialStateIndex } = this.state;
    this.changeTutorialState((tutorialStateIndex)+1)
  }

  changeTutorialState(stateIndex) {
    const { tutorialType } = this.props;
    this.setState({
      tutorialStateIndex: stateIndex,
    })
    const tState = tutorialStates(tutorialType).find((x,i) => i == stateIndex);
    eventBus.dispatch("tutorialSetState", { stateID: (tState||{}).id, tutorialId: tutorialType});
  }

  render() {
    const { icon, title } = this.props;
    return (
      <div className='tutorial-creator'>
      </div>
    );
  }
}

export default TutorialController;
