import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import TabbedPageContainer from "@layout/tabs/TabbedPageContainer"
import GeneralTable from "@layout/tables/GeneralTable"
import { isMarketingManager} from "@utility/PermissionUtility"
import ReportEventTable from "./ReportEventTable"
import ReportCompanyTable from "./ReportCompanyTable"
import ReportContactTable from "./ReportContactTable"
import ReportTable from "@report-tool/ReportTable"

class ReportsIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: null,
      contacts: null
    };
    this.setContacts = this.setContacts.bind(this);
    this.setCompanies = this.setCompanies.bind(this);
  }

  renderComingSoon() {
    return (
      <div><br />Coming soon</div>
    );
  }

  renderEventsReports()
  {
    const { events, user } = this.props;
    return <ReportEventTable events={events} user={user}/>
  }

  renderCompaniesReports()
  {
    const { user } = this.props;
    const { companies } = this.state;
    return <ReportCompanyTable setCompanies={this.setCompanies} companies={companies} user={user}/>
  }

  setCompanies(companies) {
    this.setState({
      companies: companies
    })
  }

  setContacts(contacts) {
    this.setState({
      contacts: contacts
    })
  }

  renderContactsReports()
  {
    const { user } = this.props;
    const { contacts } = this.state;
    return <ReportContactTable setContacts={this.setContacts} contacts={contacts} user={user}/>
  }


  render() {
    const { user, events, reports, users, starred_reports, report_emails } = this.props;
    // Old Reports
    // breadcrumbs={
    //   ['dashboard']
    // }

    // <TabbedPageContainer
    //   pages={[
    //     {id: "events", name: "Events", content: this.renderEventsReports()},
    //     {id: "companies", name: "Companies", content: this.renderCompaniesReports()},
    //     {id: "contacts", name: "Contacts", content: this.renderContactsReports()},
    //     {id: "exec_briefing", name: "Executive Briefing", content: this.renderComingSoon()},
    //   ]}
    //   default_tab_id="details"
    // />
    return (
      <DefaultLayout
        {...this.props}
        sidebarId="reports"
      >
        <h1 className="cnt-page-header">
          {isMarketingManager(user) ? "My Dashboard" : "Reports"}
        </h1>
        {
          user.email.includes("@jaguardesignstudio.com") ?
          <a href="/imports/index">
            <button className="cnt-page-header-button">
             Import Data
            </button>
          </a>
          :
          ''
        }
        {
          user.email.includes("@jaguardesignstudio.com") ?
          <a href={"/reports/admin_panel"}>
            <div className="report-tool-button report-tool-button-blue" style={{ float: "right", marginLeft: "10px"}}>
              Report Settings
            </div>
          </a>
          :
          ''
        }
        <a href={"/reports/new"}>
          <div className="report-tool-button report-tool-button-green" style={{ float: "right"}}>
            New Report
          </div>
        </a>
        <ReportTable reports={reports} current_user={user} users={users} starred_reports={starred_reports} report_emails={report_emails}/>
      </DefaultLayout>
    );
  }
}

export default ReportsIndex;
