import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import ContactCondensedView from "@contacts/modals/ContactCondensedView"
import Cookies from "universal-cookie";

class ContactMergeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedId: null,
    }
    this.submitMerge = this.submitMerge.bind(this);
    this.selectContact = this.selectContact.bind(this);
  }

  renderOptions() {
    const { contacts } = this.props;
    const options = contacts.map((contact,index)=> this.renderOption(contact, index))
    return (
      <div className="cnt-contacts-merge-options-container row">
        {options}
      </div>
    )
  }

  selectContact(id) {
    this.setState({
      selectedId: id
    })
  }

  renderOption(contact,index) {
    const { selectedId } = this.state;
    return (
      <div className="cnt-contacts-merge-option" onClick={()=>this.selectContact(contact.id)}>
        <div className={`cnt-contacts-merge-option-inner ${contact.id == selectedId ? "active" : "inactive"}`}>
          <div className="cnt-contacts-merge-option-inner-header">
            Entry #{index+1}
          </div>
          <ContactCondensedView
            disableHeaders={true}
            contactId={contact.id}
          />
        </div>
      </div>
    )
  }

  submitMerge() {
    const { onMerge,contacts } = this.props;
    const { selectedId } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch('/api/v1/contacts/merge_multiple', {
      method: "PATCH",
      redirect: "manual",
      body: JSON.stringify({
        ids: contacts.map(x=> x.id).filter(x=> x != selectedId),
        replacement_id: selectedId
      }),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.errors.length <= 0) {
        onMerge(selectedId, contacts.map(x=> x.id).filter(x=> x != selectedId))
      }
      else {
        console.log({
          error: json
        })
      }
    });
  }

  renderButtons() {
    const { selectedId } = this.state;
    const { onCancel } = this.props;
    let buttons = [];
    //Add Submit
    buttons=buttons.concat([
      <button disabled={selectedId == null} className="cnt-submit-button" onClick={this.submitMerge} >Merge Entries</button>
    ])
    //Add Cancel
    if(onCancel) {
      buttons=buttons.concat([
        <button className="cnt-cancel-button" onClick={onCancel} >Cancel</button>
      ])
    }
    return (
      <div className="cnt-contacts-merge-form-buttons">
        <br/>
        <br/>
        {buttons}
      </div>
    )
  }

  render() {
    return (
      <div className="cnt-contacts-merge-form">
        <div className="cnt-contacts-merge-header">
          Please select the master entry you want all other entries merged with:
        </div>
        {this.renderOptions()}
        {this.renderButtons()}
      </div>
    );
  }
}

export default ContactMergeForm;
