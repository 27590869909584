import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import UserForm from "./forms/UserForm"
import UniversalImageCircle from "@images/UniversalImageCircle"
import  TabbedPageContainer from "@layout/tabs/TabbedPageContainer"
import  LogTable from "@logs/LogTable"
import ContactsTable from "@contacts/ContactsTable"
import {getRoleName, canDeleteUser} from "@utility/PermissionUtility"
import UserDeleteModal from "@users/UserDeleteModal"
import DropzoneComponent from "@images/DropzoneComponent"
import Cookies from "universal-cookie";

class UsersShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileImage: props.editing_user.profile_image
    };
    this.renderLogs = this.renderLogs.bind(this);
    this.setProfileImage = this.setProfileImage.bind(this);
    this.destroyUser = this.destroyUser.bind(this);
  }

  renderHeader() {
    const { editing_user, user } = this.props;
    const { profileImage } = this.state;
    return (
      <div className="row cnt-event-show-header-container">
        <div className="cnt-event-show-header-container-image col-xs-12 col-sm-4 col-lg-3">
          <UniversalImageCircle size={'large'} image={profileImage} name={editing_user.first_name} id={editing_user.id}/>
        </div>
        <div className="cnt-event-show-header-container-description col-xs-12 col-sm-8 col-lg-9">
          <div className="cnt-event-show-header-container-name">
            {editing_user.first_name} {editing_user.last_name}
          </div>
          <div className="cnt-admin-users-tabbed-header-role">
            {getRoleName(editing_user.role)}
          </div>
          <div className="cnt-admin-users-tabbed-header-options">
            <DropzoneComponent outerStyle={{display: "inline-block", paddingRight:"5px"}} setFiles={this.setProfileImage}>
              <a href="#">Upload Photo</a>
            </DropzoneComponent>
            { canDeleteUser(user) ? (
                <UserDeleteModal {...this.props} destroyUser={this.destroyUser}>
                  <a className="cnt-admin-users-tabbed-header-options-red" href="#"> Delete User </a>
                </UserDeleteModal>
              ) : ""
            }

          </div>
        </div>
      </div>
    )
  }

  destroyUser(replacement_id) {
    const { editing_user } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/admin/users/${editing_user.id}`, {
      method: "DELETE",
      redirect: "manual",
      body: JSON.stringify({replacement_id: replacement_id}),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          if(json.redirect_url) {
            window.location = json.redirect_url
          }else {
            console.log("Successful no redirect")
          }
        }
        else {
          this.setState({
            error: json.error
          })
        }
      });
  }


  setProfileImage(name, file, preview, tagList) {
    const { editing_user } = this.props;
    var form_data = new FormData();
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    form_data.append('editing_user[profile_image]', file);

    this.setState({
      profileImage: preview
    });

    fetch(`/admin/users/${editing_user.id}/update_profile_picture`, {
      method: 'POST',
      redirect: "manual",
      body: form_data,
      headers: {
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {

      } else {
        console.log(json.error);
      }
    });
  }

  renderLogs() {
    const { editing_user } = this.props;
    return (
      <LogTable
        logType="admin/users"
        logObject={editing_user}

       />
    )
  }

  render() {
    const { user, contacts } = this.props;
    return (
      <DefaultLayout
        {...this.props}
        sidebarId="users"
      >
        <h1 className="cnt-page-header">
          Users
        </h1>
        <div className="cnt-page-subheader-link">
          <a href="/admin/users">
            {"< Return to users"}
          </a>
        </div>
        {this.renderHeader()}
        <TabbedPageContainer
          pages={[
            {id: "profile", name: "Profile", content:(<UserForm {...this.props} />)},
            {id: `contacts`, name: `Contacts (${contacts.length})`, content:(<ContactsTable forceLocal={true} {...this.props} disabledColumns={['account_manager_name']}/>)},
            {id: "log", name: "Log", content: () => this.renderLogs()}
          ]}
          default_tab_id="profile"
        />
      </DefaultLayout>
    );
  }
}

export default UsersShow;
