import React from "react";
import PropTypes from "prop-types";
import GeneralChit from "@layout/tables/GeneralChit"
import UniversalImageCircle from "@images/UniversalImageCircle"
import CompanyProfileModal from "@companies/modals/CompanyProfileModal"
import { isAccountManager } from "../../utility/PermissionUtility";
class CompanyTableChit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.company != this.props.company;
  }

  render() {
    const { company, contact, user } = this.props;
    if(user && !isAccountManager(user)){
      if(company) {
        return (
          <CompanyProfileModal company={company}>
            <div  className="cnt-company-table-chit">
              {company.name}
            </div>
          </CompanyProfileModal>
        );
      }
    }
    if(contact) {
      return (
        <div  className="cnt-company-table-chit">
          {contact.am_provided_company_name}
        </div>
      );
    }
    return "";
  }
}

export default CompanyTableChit;
