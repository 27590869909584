import React from "react";
import PropTypes from "prop-types";
import GeneralTable from "@layout/tables/GeneralTable"
import {canSeeDrafts, isJaguar, isSolutionsLead, canUserShift, canDeleteNomination,  isAccountManager, canApproveNomination } from "@utility/PermissionUtility"
import NominationStatus from "@nominations/tables/NominationStatus"
import AttendeeStatus from "@nominations/tables/AttendeeStatus"
import AttendenceStatus from "@nominations/tables/AttendenceStatus"
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime, listTimeZones } from 'date-fns-timezone';
import ContactTableChit from "@contacts/tables/ContactTableChit"
import EventTableChit from "@events/tables/EventTableChit"
import CompanyTableChit from "@companies/tables/CompanyTableChit"
import GeneralTableDropdown from "@layout/tables/GeneralTableDropdown"
import NominationApproveModal from "@nominations/forms/NominationApproveModal"
import NominationRejectModal from "@nominations/forms/NominationRejectModal"
import NominationNotifyModal from "@nominations/forms/NominationNotifyModal"
import UserProfileModal from "../../users/modals/UserProfileModal"
import NominationProfileModal from "../modals/NominationProfileModal"
import DefaultTooltip from "@layout/tooltips/DefaultTooltip";
import Select from 'react-select-virtualized'
import ToggleOnOff from "@layout/superfilter/ToggleOnOff"
import SuperFilter from "@layout/superfilter/SuperFilter"
import NominationDeleteModal from "../modals/NominationDeleteModal"
import {getAllNominationStatuses, getReadableNominationStatus, getNominationTableMultiSelectOptions} from "@utility/NominationUtility"
import { fetchAPI } from "@utility/NetworkUtils"
import Checkbox from "@layout/forms/Checkbox"
import MultiselectActionBar from "@layout/tables/MultiselectActionBar"
import { multiSelectCheckboxImages } from "@utility/checkboxUtility"
import NominationReviewModal from "../forms/NominationReviewModal"
import SourceStatus from "@nominations/tables/SourceStatus"


class NominationsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      approveNomination: null,
      rejectNomination: null,
      filteredEvent: null,
      superFilters: null,
      currentComment: "",
      droppedDownContacts: {},
      loadedArchivedContacts:{},
      selectedNominations: [],
      allSelected: false,
      nominations: [],
    };
    this.unselectAll = this.unselectAll.bind(this);
    this.reviewCallback = this.reviewCallback.bind(this);
    this.rejectionCallback = this.rejectionCallback.bind(this);
    this.reviewCallback = this.reviewCallback.bind(this);
    this.approvalCallback = this.approvalCallback.bind(this);
    this.closeModalCallback = this.closeModalCallback.bind(this);
    this.openApproveNomination = this.openApproveNomination.bind(this);
    this.openRejectNomination = this.openRejectNomination.bind(this);
    this.openReviewNomination = this.openReviewNomination.bind(this);

    this.renderNominationCheckbox = this.renderNominationCheckbox.bind(this);
    this.updateNomination = this.updateNomination.bind(this);
    this.setSuperFilters = this.setSuperFilters.bind(this);

    this.renderUserProfileModal = this.renderUserProfileModal.bind(this);
    this.renderEventTableChit = this.renderEventTableChit.bind(this);
    this.renderNomStatusChit = this.renderNomStatusChit.bind(this);
    this.renderSource = this.renderSource.bind(this);
    this.renderInviteStatusChit = this.renderInviteStatusChit.bind(this);
    this.renderAttendenceStatusChit = this.renderAttendenceStatusChit.bind(this);
    this.renderUserDropdown = this.renderUserDropdown.bind(this);
    this.mapNominations = this.mapNominations.bind(this);
    this.applyMultiSelectAttributes = this.applyMultiSelectAttributes.bind(this);
    this.openNotifyNomination = this.openNotifyNomination.bind(this);
    this.tableRef = React.createRef();
  }

  setSuperFilters(filters) {
    this.setState({
      superFilters: filters
    }, ()=> {
    })
    if(this.tableRef && this.tableRef.current) {
      this.tableRef.current.onQueryChange();
    }

  }

  mapNominations(nominations,events=null) {
    if(!events) {
      events = this.props.events;
    }
    return nominations.map(x=>{
      if(!x.mapped) {
        x.contact_name = x.contact ? `${x.contact.first_name} ${x.contact.last_name}` :"";
        x.event = (events||[]).find(e=> e.id == x.event_id)
        x.email = (x.contact.email||"")
        x.company_name = x.contact && x.contact.company ? x.contact.company.name :"";
        x.company_savm = x.contact && x.contact.company ? x.contact.company.savmid :"";
        x.event_name = x.event ? x.event.name :"";
        x.user_name = x.contact && x.contact.users ? x.contact.users.map(y=>`${y.first_name} ${y.last_name}`).join("|") : ""
        x.user_email = x.contact && x.contact.users ? x.contact.users.map(y=>`${y.e_email}`).join("|") : ""
        x.contact_id = x.contact ? x.contact.id : "";
        x.mapped = true;
      }
      return x;
    })
  }

  updateNomination(newNomination) {
    this.tableRef.current.onQueryChange();
  }

  renderViewAll(row) {
    const { user } = this.props;
    return (
      <div className="cnt-default-layout-nomination-table-icons">
        <NominationProfileModal
          user={user}
          openRejectNomination={this.openRejectNomination}
          openReviewNomination={this.openReviewNomination}
          openApproveNomination={this.openApproveNomination}
          openNotifyNomination={this.openNotifyNomination}
          reviewCallback={this.reviewCallback}
          nomination={row}
        >
          <a href="#">
            <img
              data-for="sg-cnt-default-layout-table-view-tooltip"
              data-tip
              className="sg-cnt-default-layout-table-notify-button"
              src="/layout/view-icon.svg"
             />
          </a>
        </NominationProfileModal>
        {this.renderEditIcon(row)}
        {canDeleteNomination(user) || row.status == 'draft' ? (
          <NominationDeleteModal nomination={row} onDelete={this.updateNomination}>
            <img
              className="sg-cnt-default-layout-table-delete-button"
              data-for="sg-cnt-default-layout-contact-table-nomination-delete"
              data-tip
              src="/layout/trash-icon.svg"
            />
          </NominationDeleteModal>
        ):""}

      </div>
    );
  }



  renderEditIcon(row) {
    const { user } = this.props;
    if(isAccountManager(user) && row.status == 'draft') {
      return (
        <a href={`/nominations/${row.id}`}>
          <img
            style={{width:"13px"}}
            data-for={`sg-cnt-default-layout-edit-tooltip`}
            data-tip
            src="/layout/edit-icon.svg"
          />
        </a>
      )
    } else {
      return (
        <a href={`/nominations/${row.id}`}>
          <img
            style={{width: "17px"}}
            data-for={`sg-cnt-default-layout-review-tooltip`}
            data-tip
            src="/layout/review-icon.png"
          />
        </a>
      )
    }

  }

  openNotifyNomination(row, currentComment) {
    this.setState({notifyNomination: row, currentComment: currentComment || ""});
  }
  openApproveNomination(row, currentComment) {
    this.setState({approveNomination: row, currentComment: currentComment || ""});
  }

  openRejectNomination(row, currentComment) {
    this.setState({rejectNomination: row, currentComment: currentComment || ""});
  }

  openReviewNomination(row, currentComment) {
    this.setState({reviewNomination: row, currentComment: currentComment || ""});
  }

  renderNominationRow(column) {
    const { droppedDownContacts, nominations } = this.state;
    column.render = (row) => {
      const isDroppedDown = droppedDownContacts[row.id] == true;
      let currentNominations = row.nominations;
      if(isDroppedDown) {
        currentNominations = currentNominations.concat(
          nominations.filter(x=>
            x.contact_id == row.id &&
            currentNominations.map(n=>n.id).indexOf(x.id) == -1
          )
        )
      }
      return (
        <div className="cnt-nomination-table-multiple-render-container">
          {currentNominations.map((x,i)=> {
            return (
            <div
              key={`cnt-nomination-table-${x.id}-${row.id}-${column.field}`}
              className={`cnt-nomination-table-multiple-render-inner-container
                 ${this.isNominationSelected(x.id) ? "cnt-nomination-table-selected-cell" : ""}`}
              style={column.cellStyle||{}}
            >
              {column.singular && i == 0 ? column.renderSingular(row) : ""}
              {!column.singular ? column.renderSingular(x) : ""}
            </div>
          )})}
          
        </div>
    )}
    return column
  }

  isNominationSelected(id)  {
    const { selectedNominations } = this.state;
    return selectedNominations.map(x=>x.id).indexOf(id) != -1;
  }


  renderUserProfileModal(row) {
    if(row && row.users) {
      return (row.users||[]).filter((x,i,t)=> t.map(y=>y.id).indexOf(x.id) == i).map(x=>
        <UserProfileModal user={x}>
          <a href="#">{x.first_name} {x.last_name}</a>
        </UserProfileModal>
      )
    }
  }

  renderEventTableChit(row) {
    const { user } = this.props;
    return (row.event ? (<EventTableChit user={user} event={row.event} />): "");
  }

  renderNomStatusChit(row) {
    return (<NominationStatus user={this.props.user} updateNomination={this.updateNomination} nomination={row} />)
  }

  renderSource(row) {
    return (<SourceStatus user={this.props.user} updateNomination={this.updateNomination} nomination={row}/>)
  }

  renderInviteStatusChit(row) {
    return (<AttendeeStatus user={this.props.user} updateNomination={this.updateNomination} nomination={row} />)
  }

  renderAttendenceStatusChit(row) {
    return (<AttendenceStatus user={this.props.user} updateNomination={this.updateNomination} nomination={row} />)
  }

  renderUserDropdown(row) {
    const { loadedArchivedContacts, droppedDownContacts } = this.state;
    //States: Closed, Open, loading
    let state = 'closed'
    const id = row.id
    if(droppedDownContacts[id] && droppedDownContacts[id]==true) {
      state = loadedArchivedContacts[id] && droppedDownContacts[id]==true ? 'open' : 'loading';
    }
    if(state == 'loading') {
      return (
        <div className="cnt-table-loading">
          <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
      );
    }
    return (
      <img
        onClick={()=>this.toggleUserDropdown(row)}
        src="/layout/dropdown-arrow.svg"
        className={`cnt-nomination-table-archive-dropdown ${state=='open' ? "open" : "closed"}`}
      />
    )
  }

  toggleUserDropdown(row) {
    let { loadedArchivedContacts, droppedDownContacts, nominations } = this.state;
    const id = row.id
    droppedDownContacts[id] = !droppedDownContacts[id]
    if(!loadedArchivedContacts[id]) {
      fetchAPI(
        `/api/v1/contacts/${id}/archived_nominations`,
        (json)=> {
          loadedArchivedContacts[id] = true;
          nominations = nominations.concat(this.mapNominations(json.nominations));
          this.setState({
            loadedArchivedContacts,
            nominations
          })
        }
      )
    }
    this.setState({
      droppedDownContacts
    })
  }

  renderNominationCheckbox(row) {
    const { selectedNominations } = this.state;
    const isChecked = selectedNominations.map(x=>x.id).indexOf(row.id) != -1;
    return (
      <div className="cnt-nomination-table-checkbox">
        <Checkbox
          disabled={false}
          value={isChecked}
          onClick={()=>this.onCheckboxClick(row)}
          images={multiSelectCheckboxImages()}
        />
      </div>
    )
  }

  onCheckboxClick(row) {
    let { selectedNominations } = this.state;
    const isChecked = selectedNominations.indexOf(row.id) != -1;
    if(isChecked) {
      selectedNominations = selectedNominations.filter(x=> x.id != row.id)
    }else {
      selectedNominations = selectedNominations.concat([
        row
      ])
    }
    this.setState({
      selectedNominations
    })
  }


  getColumns() {
      const {user} = this.props;
      let columns = [
        this.renderNominationRow({ maxWidth: '30px',sorting: false, title: "", field: 'select', renderSingular: this.renderNominationCheckbox}),
        this.renderNominationRow({ singular: true,title: 'Name',defaultSort: 'asc', field: 'contact_name', renderSingular: (row) => (row ? (<ContactTableChit user={user} forceCircle={false} contact={row} />) : "") }),
        this.renderNominationRow({ hidden: true, searchable: true, defaultSort: 'asc', field: 'email'}),
        this.renderNominationRow({singular: true, title: 'Company', field: 'company_name', renderSingular: (row)=>(row && row.company ? (<CompanyTableChit user={user} company={row.company} />): ((row.contact||{}).am_provided_company_name||""))}),
        this.renderNominationRow({cellStyle: {height: "auto"},singular: true, title: 'Account Manager(s)', field: 'user_name', renderSingular: this.renderUserProfileModal}),
        this.renderNominationRow({ sorting: false, title: 'Event', field: 'event_name', renderSingular: this.renderEventTableChit}),
        this.renderNominationRow({singular: true, sorting: false,export: false,width: "20px",cellStyle: {
           maxWidth: 50,
           width: 50
         },
         maxWidth: '50px',
         title: '', field: 'dropdown', renderSingular: this.renderUserDropdown}
        ),
        this.renderNominationRow({ sorting: false, title: 'Source', field: 'source', renderSingular: this.renderSource }),
        this.renderNominationRow({ sorting: false, title: 'Nom Status', field: 'status', renderSingular: this.renderNomStatusChit }),
        this.renderNominationRow({ sorting: false, title: 'Invite Status', field: 'invite_status', renderSingular: this.renderInviteStatusChit}),
        this.renderNominationRow(
          { sorting: false,  title: 'Attendance Status', field: 'attendence_status', renderSingular: this.renderAttendenceStatusChit}
        ),
        this.renderNominationRow(
          { export: false, sorting: false,  title: 'Actions', field: 'id', renderSingular: (row)  => this.renderViewAll(row)}
        )
      ]
      if(isAccountManager(user)) {
        columns = columns.filter(x=> x.field!="company_name")
        columns = columns.filter(x=> x.field!="select")
        columns = columns.filter(x=> x.field!="user_name")
        columns = columns.filter(x=> x.field!="source")
      }else {
        columns= columns.filter(x=> x.field!="date")
      }

      return columns;
  }


  reviewCallback(nomination) {
    this.tableRef.current.onQueryChange();
    this.setState({
      approveNomination: null,
      rejectNomination: null,
      reviewNomination: null,
      currentComment: null
    })
  }

  rejectionCallback(nomination) {
    this.tableRef.current.onQueryChange();
    this.setState({
      approveNomination: null,
      rejectNomination: null,
      reviewNomination: null,
      currentComment: null
    })
  }

  reviewCallback(nomination) {
    this.tableRef.current.onQueryChange();
    this.setState({
      approveNomination: null,
      rejectNomination: null,
      reviewNomination: null,
      currentComment: null
    })
  }

  approvalCallback(nomination) {
    this.tableRef.current.onQueryChange();
    this.setState({
      approveNomination: null,
      rejectNomination: null,
      reviewNomination: null,
      currentComment: null
    })
  }

  closeModalCallback() {
    this.setState({
      approveNomination: null,
      rejectNomination: null,
      reviewNomination: null,
      currentComment: null,
      notifyNomination: null,
    })
  }

  renderModals() {
    const { notifyNomination, approveNomination,rejectNomination,reviewNomination, currentComment } = this.state;
    if(rejectNomination) {
      return (
        <NominationRejectModal
          nomination={rejectNomination}
          closeModalCallback={this.closeModalCallback}
          rejectionCallback={this.rejectionCallback}
          forceOpen={true}
          currentComment={currentComment}
        />
      )
    }
    if(notifyNomination) {
      return (
        <NominationNotifyModal
          nomination={notifyNomination}
          closeModalCallback={this.closeModalCallback}
          forceOpen={true}
          currentComment={currentComment}
        />
      )
    }
    if(reviewNomination) {
      return (
        <NominationReviewModal
          nomination={reviewNomination}
          closeModalCallback={this.closeModalCallback}
          rejectionCallback={this.reviewCallback}
          forceOpen={true}
          currentComment={currentComment}
        />
      )
    }
    if(approveNomination) {
      return (
        <NominationApproveModal
          nomination={approveNomination}
          closeModalCallback={this.closeModalCallback}
          approvalCallback={this.approvalCallback}
          forceOpen={true}
          currentComment={currentComment}
        />
      )
    }
  }

  getFilters() {
    return [
      {id: null, name: "All", filterFunction:()=>{return true}},
      {id: 'draft', name: "Draft", filterFunction:(x,i,t)=>{ return x.status=="draft"}},
      {id: 'approved', name: "Approved", filterFunction:(x,i,t)=>{ return x.status=="approved"}},
      {id: 'submit', name: "Submit", filterFunction:(x,i,t)=>{ return x.status=="submit"}},
      {id: 'rejected', name: "Declined", filterFunction:(x,i,t)=>{ return x.status=="rejected"}}
    ]
  }

  renderSuperFilter() {
    const { includeEventFilter, events } = this.props;
    const { filteredEvent, nominations } = this.state;
    const eventsOptions = [
      {id: null, label:"All Nominations",solo: true, filterFunction: ()=>{return true}},
      {id: 'active_events',solo:true, label:"Active Nominations", filterFunction: (x,i,t)=>{return !x.event.archived_at}},
    ].concat(
      events.sort((a,b)=> (a.name||"").toLowerCase() > (b.name||"").toLowerCase() ? 1 : -1 ).filter(x=>x.archived_at == null).map(event=>{
        return {label: event.name, id: event.id}
      })
    )

    const nom_status_options = [
      {label: 'All', id: null, filterFunction: (x,i,t)=>{return true}},
    ].concat(getAllNominationStatuses().map(x=> {
      return {label: x.name, id: x.id, filterFunction: (y,i,t)=>{ return y.status==x.id}}
    }))
    //TODO Fix this, make this auto update
    const invite_status_options = [
      {label: 'All', id: null, filterFunction: (x,i,t)=>{return true}},
      {label: 'Waitlisted', id: 'waitlisted', filterFunction: (x,i,t)=>{return x.invite_status == 'waitlisted'}},
      {label: 'Pending Invite', id: 'pending_invite', filterFunction: (x,i,t)=>{return x.invite_status == 'pending_invite'}},
      {label: 'Invited', id: 'invited', filterFunction: (x,i,t)=>{return x.invite_status == 'invited'}},
      {label: 'Registered', id: 'registered', filterFunction: (x,i,t)=>{return x.invite_status == 'registered'}},
      {label: 'Declined', id: 'declined', filterFunction: (x,i,t)=>{return x.invite_status == 'declined'}},
      {label: 'Deadline Passed', id: 'deadline_passed', filterFunction: (x,i,t)=>{return x.invite_status == 'deadline_passed'}},
      {label: 'Opt Out', id: 'opt_out', filterFunction: (x,i,t)=>{return x.invite_status == 'opt_out'}},
      {label: 'Bounced', id: 'bounced', filterFunction: (x,i,t)=>{return x.invite_status == 'bounced'}},
      {label: 'VIP', id: 'vip', filterFunction: (x,i,t)=>{return x.invite_status == 'vip'}},
      {label: 'Unmarked', id: 'unmarked', filterFunction: (x,i,t)=>{return !x.invite_status}},
    ]
    const attendence_status_options = [
      {label: 'All', id: null, filterFunction: (x,i,t)=>{return true}},
      {label: 'Not attended', id: 'did-not-attend', filterFunction: (x,i,t)=>{return x.attendence_status == 'did_not_attend'}},
      {label: 'Attended', id: 'attended', filterFunction: (x,i,t)=>{return x.attendence_status == 'attended'}},
      {label: 'No Show', id: 'no-show', filterFunction: (x,i,t)=>{return x.attendence_status == 'no_show'}},
      {label: 'Cancellation', id: 'cancellation', filterFunction: (x,i,t)=>{return x.attendence_status == 'cancellation'}},
      {label: 'Unmarked', id: 'unmarked', filterFunction: (x,i,t)=>{return !x.attendence_status}},
    ]
    const value = events.find(x=> x.value==filteredEvent);
    if(includeEventFilter) {
      return(
        <>
        <SuperFilter
          defaultValues={{
            events:["active_events"]
          }}
          urlLock={false}
          onChange={this.setSuperFilters}
          filters={[
            {
              id: "events",
              type:"multiselect",
              options: eventsOptions,
              outerClassName: "col-xs-12 col-md-6 col-lg-3",
              placeholder: "Filter by event"
            },
            {
              id: "nom_status",
              type:"select",
              options: nom_status_options,
              outerClassName: "col-xs-12  col-md-6 col-lg-3",
              placeholder: "Filter by nom status"
            },
            {
              id: "invite_status",
              type:"select",
              options: invite_status_options,
              outerClassName: "col-xs-12  col-md-6 col-lg-3",
              placeholder: "Filter by invite status"
            },
            {
              id: "attendece_status",
              type:"select",
              options: attendence_status_options,
              outerClassName: "col-xs-12 col-md-6 col-lg-3",

              placeholder: "Filter by attendance status"
            },
          ]}
        />
        </>
      )
    }
  }


  getSelectedItems() {
    const { nominations, selectedNominations } = this.state;
    return selectedNominations;
  }

  applyMultiSelectAttributes(ids, performActionCallback, values) {
    let body = {
      ids: ids,
      nomination: values
    }
    this.setState({
      selectedNominations: []
    })
    fetchAPI('/api/v1/nominations/update_multiple', (json) => {
      if(json.nominations) {
        let {nominations} = this.state;
        this.tableRef.current.onQueryChange();
      }
      if(performActionCallback) {
        performActionCallback(json)
      }
    }, {
      body: body,
      method: 'PATCH'
    });
  }

  unselectAll() {
    this.setState({
      selectedNominations: []
    })
  }

  renderTooltips() {
    return (
      <>
        <DefaultTooltip
          tooltipId={`sg-cnt-default-layout-table-notify-tooltip`}
        >
          Notify AM
        </DefaultTooltip>
        <DefaultTooltip
          tooltipId={`sg-cnt-default-layout-edit-tooltip`}
        >
          Edit
        </DefaultTooltip>
        <DefaultTooltip
          tooltipId={`sg-cnt-default-layout-review-tooltip`}
        >
          Full View
        </DefaultTooltip>
        <DefaultTooltip
          tooltipId={`sg-cnt-default-layout-table-view-tooltip`}
        >
          Quick View
        </DefaultTooltip>
        <DefaultTooltip
          tooltipId={`sg-cnt-default-layout-contact-table-nomination-delete`}
        >
          Delete Nomination
        </DefaultTooltip>
      </>
    )
  }

  render() {
    const { user } = this.props;
    const { superFilters } = this.state;
    return (
      <>
        {this.renderTooltips()}
        {this.renderModals()}
        <div className="cnt-default-layout-nomination-table">
          <MultiselectActionBar
            selectedItems={this.getSelectedItems()}
            options={getNominationTableMultiSelectOptions(user)}
            submitCallback={this.applyMultiSelectAttributes}
            closeCallback={this.unselectAll}
          />
          {this.renderSuperFilter()}
          <GeneralTable
            emptyDataSourceMessage={(
              <div className="cnt-dashboard-blank-notifications-message">
                No nominations found
                <br/>
                To make a new nomination, please click on the green ‘New Nomination’ button to the left.
              </div>
            )}
            dataUrlAdditionalParams={{super_filters: superFilters}}
            tableRef={this.tableRef}
            urlLock={false}
            columns={this.getColumns()}
            actions={[]}
            dataUrl='/api/v1/nominations/grouped_nomination_table'
          />
        </div>
      </>
    );
  }
}
export default NominationsTable;
