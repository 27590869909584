import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import ReportType from "./form/ReportType";
import ReportCustomize from "./form/ReportCustomize";
import ReportSave from "./form/ReportSave";

class ReportNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      baseObj: {},
      filterChains: [],
      objectKeys: [],
      phase: 1,
      reportEditable: false,
      reportLocation: { value: "", label: "" },
      reportName: "",
      reportType: null,
      selectedKeys: [],
      sortingCriteria: []
    };
    this.setBaseObj = this.setBaseObj.bind(this);
    this.setFilterChains = this.setFilterChains.bind(this);
    this.setReportLocation = this.setReportLocation.bind(this);
    this.setReportEditable = this.setReportEditable.bind(this);
    this.setReportName = this.setReportName.bind(this);
    this.setSelectedKeys = this.setSelectedKeys.bind(this);
    this.setSortingCriteria = this.setSortingCriteria.bind(this);
    this.submitPhase1 = this.submitPhase1.bind(this);
    this.submitPhase2 = this.submitPhase2.bind(this);
    this.submitPhase3 = this.submitPhase3.bind(this);
  }

  setFilterChains(filterChains) {
    this.setState({ filterChains: filterChains });
  }

  setReportEditable(e) {
    this.setState({ reportEditable: e.target.checked });
  }

  setReportLocation(e) {
    const editable = e.value === "shared" ? true : false;
    this.setState({
      reportLocation: e,
      reportEditable: editable
    });
  }

  setReportName(e) {
    this.setState({ reportName: e.target.value });
  }

  setSelectedKeys(keys) {
    this.setState({ selectedKeys: keys });
  }

  setSortingCriteria(sortingCriteria) {
    this.setState({ sortingCriteria: sortingCriteria });
  }

  async setBaseObj(e, resetValues = true) {
    this.setState({ reportType: e.value });
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    let objList = [];
    let objKeys = [];
    await fetch("/reports/object_skeleton_all", {
      method: "POST",
      redirect: "manual",
      body: JSON.stringify({
        object_type: e.value
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          objList = json.object_list;
          objKeys = objList[0];
          if (resetValues) {
            this.setState(
              {
                baseObj: e,
                objectKeys: objKeys
              },
              this.setDefaultFields
            );
          } else {
            this.setState({
              baseObj: e,
              objectKeys: objKeys
            });
          }
        } else {
          console.log(json.error);
        }
      });
  }

  submitPhase1() {
    console.log("phase 1 submitted");
    this.setState({ phase: 2 });
  }

  submitPhase2() {
    console.log("phase 2 submitted");
    this.setState({ phase: 3 });
  }

  submitPhase3() {
    console.log("phase 3 submitted");
    this.saveReport();
  }

  saveReport() {
    const {
      baseObj,
      reportName,
      reportLocation,
      reportEditable,
      selectedKeys,
      filterChains,
      sortingCriteria
    } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const keys = selectedKeys.map(key => key.value);
    fetch("/reports", {
      method: "POST",
      redirect: "manual",
      body: JSON.stringify({
        report_name: reportName,
        report_location: reportLocation.value,
        report_editable: reportEditable,
        keys: keys,
        object_type: baseObj.value,
        filterChains: filterChains,
        sortingCriteria: sortingCriteria
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          console.log("success!");
          if (reportLocation.value) {
            window.location.href = "/reports#my_reports";
          } else {
            window.location.href = "/reports";
          }
        } else {
          console.log(json.error);
        }
      });
  }

  render() {
    const {
      baseObj,
      filterChains,
      objectKeys,
      phase,
      reportEditable,
      reportLocation,
      reportName,
      reportType,
      selectedKeys,
      sortingCriteria
    } = this.state;
    const { baseObjects, events, user } = this.props;
    switch (phase) {
      case 1:
        return (
          <ReportType
            baseObj={baseObj}
            events={events}
            reportTypes={baseObjects}
            selectedKeys={selectedKeys}
            setBaseObj={this.setBaseObj}
            setSelectedKeys={this.setSelectedKeys}
            submit={this.submitPhase1}
            user={user}
          />
        );
      case 2:
        return (
          <ReportCustomize
            baseObj={baseObj}
            events={events}
            filterChains={filterChains}
            objectKeys={objectKeys}
            selectedKeys={selectedKeys}
            setFilterChains={this.setFilterChains}
            setSelectedKeys={this.setSelectedKeys}
            setSortingCriteria={this.setSortingCriteria}
            sortingCriteria={sortingCriteria}
            submit={this.submitPhase2}
            user={user}
          />
        );
      case 3:
        return (
          <ReportSave
            events={events}
            reportEditable={reportEditable}
            reportLocation={reportLocation}
            reportName={reportName}
            setReportEditable={this.setReportEditable}
            setReportLocation={this.setReportLocation}
            setReportName={this.setReportName}
            submit={this.submitPhase3}
            user={user}
          />
        );
      default:
        throw new Error("Invalid phase");
    }
  }
}

ReportNew.propTypes = {
  baseObjects: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired
};

export default ReportNew;
