import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import DefaultLayout from "@layout/DefaultLayout";

class ReportSave extends React.Component {
  constructor(props) {
    super(props);
  }

  renderButtons() {
    const { submit } = this.props;
    return (
      <div className="report-select-keys report-select-keys-divider">
        <div
          className="report-tool-button report-tool-button-blue"
          style={{ marginLeft: "10px" }}
          onClick={submit}
        >
          Save
        </div>
        <div
          className="report-tool-button report-tool-button-white"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            javascript: history.back();
          }}
        >
          Cancel
        </div>
      </div>
    );
  }

  renderSaveAs() {
    const {
      reportName,
      reportLocation,
      reportEditable,
      setReportName,
      setReportLocation,
      setReportEditable
    } = this.props;
    let locationObjects = [
      { value: false, label: "Reports (shared)" },
      { value: true, label: "My Reports (private)" }
    ];
    return (
      <>
        <label>Report Title:</label>
        <input
          type="text"
          value={reportName}
          onChange={e => setReportName(e)}
          className="report-tool-input"
        />
        <br />
        <div className="report-select-keys">
          <label>Report Location:</label>
          <Select
            value={reportLocation}
            options={locationObjects}
            onChange={location => {
              setReportLocation(location);
            }}
          />
        </div>
        {reportLocation.value === false ? (
          <>
            <div className="report-select-keys">
              <label>Editable by others:</label>
              <input
                type="checkbox"
                checked={reportEditable}
                onChange={e => {
                  setReportEditable(e);
                }}
                style={{ width: "auto" }}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </>
    );
  }

  render() {
    const { report } = this.props;
    return (
      <DefaultLayout {...this.props} sidebarId="reports">
        <div className="report-tool-form-wrapper" style={{ margin: "0px" }}>
          <div className="report-tool-page-header">
            <h1 className="cnt-page-header">
              {report === undefined ? "New Report" : report.name}
            </h1>
          </div>
          <div style={{ clear: "both" }} />
          {this.renderSaveAs()}
          {this.renderButtons()}
        </div>
      </DefaultLayout>
    );
  }
}

ReportSave.propTypes = {
  reportLocation: PropTypes.object.isRequired,
  reportName: PropTypes.string.isRequired,
  reportEditable: PropTypes.bool.isRequired,
  setReportLocation: PropTypes.func.isRequired,
  setReportName: PropTypes.func.isRequired,
  setReportEditable: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired
};

export default ReportSave;
