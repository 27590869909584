import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import UniversalImageCircle from "@images/UniversalImageCircle"
import { canEditNomination } from "@utility/PermissionUtility"
import Cookies from "universal-cookie";
import { dateText } from "@utility/TimeUtils"
import { isEventLocked } from "@utility/EventUtils"
import { convertMarkdownToHtml } from "@utility/MarkdownUtility"
class EventModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      event: null
    }
    this.toggleModal = this.toggleModal.bind(this);
  }

  getEvent() {
    const { event } = this.props;
    if(event != null) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch(`/events/${event.id}/get_event_modal_info`, {
        method: "GET",
        redirect: "manual",
        body: null,
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            event: json.event,
          })
        }
        else {
          this.setState({
            error: json.error
          })
        }
      }).catch((error)=>{
        this.setState({
        error: "Could not load events at this time. Try again later."
      })});
    }
  }

  renderModal() {
    const { event } = this.state;
    const { user } = this.props;
    const questions = event && event.award_criteria && event.award_criteria.questions ? event.award_criteria.questions : null;
    if(event) {
      return (
        <div className="row" style={{lineHeight: "1.48em"}}>
          <div className="col-xs-12 cnt-default-layout-nomination-contact-area">
            <div className="cnt-default-layout-nomination-contact-description-container">
              <div className="cnt-default-layout-nomination-contact-picture">
                <UniversalImageCircle image={event.icon } name={event.name} id={event.id}/>
              </div>
              <div className="cnt-default-layout-nomination-contact-description">
                <span className="cnt-default-layout-nomination-contact-description-name">
                  {event.name}
                </span>
              </div>
            </div>
          </div>
          <div className="col-xs-12">
            {dateText(event) ? (
              <div className="cnt-condensed-modal-section-field">
                <div className="cnt-condensed-modal-section-field-name">
                  Date:{" "}
                </div>
                <div className="cnt-condensed-modal-section-field-value">
                  {dateText(event)}
                </div>
              </div>
            ):""}
            {event.location ? (
              <div className="cnt-condensed-modal-section-field">
                <div className="cnt-condensed-modal-section-field-name">
                  Location:{" "}
                </div>
                <div className="cnt-condensed-modal-section-field-value">
                  {event.location}
                </div>
              </div>
            ):""}
          </div>
          <div className="col-xs-12">
            <div className="cnt-condensed-model-section-header" style={{paddingTop: "05px",marginBottom: "20px" }}>
            </div>
            <div className="cnt-condensed-modal-section-field">
              <div className="cnt-condensed-modal-section-field-name">
              {event && event.options && event.options.criteria_header_override ? event.options.criteria_header_override : "Event Criteria"}
              </div>
              <div className="cnt-condensed-modal-section-field-value" dangerouslySetInnerHTML={{ __html: convertMarkdownToHtml(event.description) }}/>
              <br/>
              <br/>
            </div>
          </div>
          {questions ? (
            <div className="col-xs-12">
              <div className="cnt-condensed-modal-section-field">
                <div className="cnt-condensed-modal-section-field-name">
                {event && event.options&& event.options.nomination_info_header_override ? event.options.nomination_info_header_override : "Nomination Questions"}
                </div>
                <div className="cnt-condensed-modal-section-field-value">
                  <br/>
                  {questions.map(question=> (
                    <div>
                      {question.question}
                    </div>
                  ))}
                </div>
                <br/>
                <br/>
              </div>
            </div>
          ): ""}


          <div className="col-xs-12 cnt-nomination-modal-button-section">
            {canEditNomination(user) && isEventLocked(event) && event.archived_at == null ? (
              <a href={`/nominations/new?event_id=${event.id}`}>
                <button className="cnt-approve-button" type="button">Nominate for this Event</button>
              </a>
            ):""}
            <button className="cnt-cancel-button" onClick={this.toggleModal}> Cancel</button>
          </div>
        </div>
      );
    }else {
      //Render Loading
      return (
        <div className="row">
          <div className="col-xs-12 ">
            <div className="ctn-default-layout-log-table-loading">
              <h1> Loading </h1>
              <br />
              <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
          </div>
        </div>
      );
    }

  }

  toggleModal() {
    const { modalOpen, event } = this.state;
    const propEvent = this.props.event;

    if(!modalOpen && propEvent) {
      if(!event || event.id != propEvent.id) {
        this.getEvent();
      }
    }

    this.setState({
      modalOpen: !modalOpen
    });

  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, sessions } = this.props;
    return (
      <>
        <div className={`cnt-modal-openable-button ${outerClassNames}`} onClick={this.toggleModal} >
          {children}
        </div>
        <Modal
          className="white-background cnt-user-admin-modal cnt-event-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default EventModal;
