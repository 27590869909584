import React from "react";
import PropTypes from "prop-types";
import { renderFullDate } from "@utility/TimeUtils"

class NominationComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const { comment } = this.props;
    return (
      <div className="cnt-default-layout-nomination-comment">
        <div className="cnt-default-layout-nomination-comment-message">
          {comment.message}
        </div>
        <div className="cnt-default-layout-nomination-comment-info">
          {comment.user.first_name} {comment.user.last_name} | {renderFullDate(comment.created_at)}
        </div>
      </div>
    );
  }
}

export default NominationComment;
