// Default fields will change per project
export function getDefaultFields() {
  return [
    {
      objType: 'Nomination',
      fields: [
        "contact.first_name",
        "contact.last_name",
        "company.name",
        "contact.job_title",
        "contact.email",
        "event.name",
        "status",
        "invite_status",
        "attendence_status"
      ]
    },
    {
      objType: 'Event',
      fields: [
        "name",
        "start_date",
        "location"
      ]
    },
    {
      objType: 'Contact',
      fields: [
        "first_name",
        "last_name",
        "company.name",
        "job_title",
        "email",
        // "Assigned Account Manager"
      ]
    },
    {
      objType: 'Company',
      fields: [
        "org_party_id",
        "savmid",
        "name",
        "company_type",
        // "Assigned Contacts"
      ]
    }
  ]
}

// Order of categories to display
export function getCategoryOrder() {
  return [
    "nomination",
    "contact",
    "company",
    "event",
    "user"
  ]
}
