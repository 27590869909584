import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import { isAccountManager, canUserShift } from "@utility/PermissionUtility"
import ToolGuideSection from "./ToolGuideSection"
class ToolGuideIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };

  }

  getSections() {
    const { user } = this.props;
    return [
      {
        bullets: [
          <>Click on <img className="cnt-default-layout-tool-guide-text-icons" style={{marginBottom: '2px'}}src="/layout/tool-guide/new-nomination.svg"/> to start a new nomination</>,
          "Once submitted, your nomination will show up on your dashboard and it will be reviewed"
        ],
        icon: "/layout/tool-guide/nomination.png",
        title: "Making a new nomination",
        account_manager: true,
      },
      {
        bullets: [
          "See the status of all your nominations, the status of communications (invites), and the status of your nominee’s attendance",
          <>Click the <img className="cnt-default-layout-tool-guide-text-icons" src="/layout/view-icon.svg"/> for a quick overview of your nomination status</>,
        <>Click on <img className="cnt-default-layout-tool-guide-text-icons" src="/layout/review-icon.png" style={{width: "17px"}}/> for a full view and to edit your nomination details </>,
        ],
        icon: "/layout/tool-guide/dashboard.svg",
        title: "My Dashboard",
        account_manager: true,
      },
      {
        bullets: [
          "See all your contacts and add new contacts",
          <>Click the <img className="cnt-default-layout-tool-guide-text-icons" src="/layout/view-icon.svg"/> for a quick overview of your contact</>,
          <>Click on <img className="cnt-default-layout-tool-guide-text-icons" src="/layout/review-icon.png" style={{width: "17px"}}/> to view full contact profile and to make edits</>,
        <><span className="cnt-default-layout-tool-guide-section-body-bullets-emphasis">Note:</span> Please contact <a href="mailto:nominationtoolsupport@cisco.com">nominationtoolsupport@cisco.com</a> if you would like to delete a contact.</>
        ],
        icon: "/layout/tool-guide/contacts.svg",
        title: "My Contacts",
        account_manager: false,
      },

      {
        bullets: [
          "See all your companies and manager their information",
          <>Click the <img className="cnt-default-layout-tool-guide-text-icons" src="/layout/view-icon.svg"/> for a quick overview of your contact</>,
          <>Click on <img className="cnt-default-layout-tool-guide-text-icons" src="/layout/review-icon.png" style={{width: "17px"}}/> to view full company profile and to make edits</>,
          <><span className="cnt-default-layout-tool-guide-section-body-bullets-emphasis">Note:</span> Please contact <a href="mailto:nominationtoolsupport@cisco.com">nominationtoolsupport@cisco.com</a> if you would like to delete a contact.</>
        ],
        icon: "/layout/tool-guide/companies.svg",
        title: "My Companies",
        account_manager: false,
      },
      {
        bullets: [
          "See all current events",
          "Click on a event for more details",
        ],
        icon: "/layout/tool-guide/dashboard.svg",
        title: "Event Info",
        account_manager: true,
      },

      //NON AM
      {
        bullets: [
          "See the status of all active nominations that require review",
          <>Click the <img className="cnt-default-layout-tool-guide-text-icons" src="/layout/view-icon.svg"/> for a quick overview of the nomination</>,
          <>Click the <img className="cnt-default-layout-tool-guide-text-icons" src="/layout/review-icon.png" style={{width: "17px"}}/> for a full view including nomination history and logs</>,
          <>
            Click on <img className="cnt-default-layout-tool-guide-text-icons" src="/layout/download-icon.png" style={{width: "21px", marginRight: "3px"}}/>
            to download a report of your customized search selections
          </>
        ],
        icon: "/layout/tool-guide/dashboard.svg",
        title: "My Dashboard",
        account_manager: false,
      },
      {

        bullets: [
          "See all contacts in the database",
          <>Click the <img className="cnt-default-layout-tool-guide-text-icons" src="/layout/view-icon.svg"/> for a quick overview of a contact</>,
          <>Click on <img className="cnt-default-layout-tool-guide-text-icons" src="/layout/review-icon.png" style={{width: "17px"}}/> to view the full contact profile and to make edits</>,
        ],
        icon: "/layout/tool-guide/contacts.svg",
        title: "Contacts",
        account_manager: false,
      },
      {
        bullets: [
          "See all active and archived events",
          "Click on a event for more details and to review the nomination form associated with the event",
          <><span className="cnt-default-layout-tool-guide-section-body-bullets-emphasis">Note: </span> Only Jaguars have ability to add and edit events. </>,
          <><span className="cnt-default-layout-tool-guide-section-body-bullets-emphasis">Important:</span> A nomination form needs to be locked down before it can be used</>
        ],
        icon: "/layout/tool-guide/dashboard.svg",
        title: "Event Info",
        account_manager: false,
      },
      {
        bullets: [
          "Enter new or edit existing company",
          <>Click the <img className="cnt-default-layout-tool-guide-text-icons" src="/layout/view-icon.svg"/> for a quick overview of an account</>,
          <>Click on <img className="cnt-default-layout-tool-guide-text-icons" src="/layout/review-icon.png" style={{width: "17px"}}/> to view the full account profile and to make edits</>,
        ],
        icon: "/layout/tool-guide/companies.svg",
        title: "Companies",
        account_manager: false,
      },
      {
        bullets: [
          "A list of updates made to contacts and companies by account managers",
          <>Click on <img className="cnt-default-layout-tool-guide-text-icons" src="/layout/review-icon.png" style={{width: "17px"}}/> to go to the contact or company form where the change was made and review. </>,
          "Verify if update is accepted or reach out to account manager for more information about the change",
        ],
        icon: "/layout/sidebar/updates.svg",
        title: "Updates",
        account_manager: false,
      },
      {
        bullets: [
          "Access event reports or create your own customized report with a an array of filtering and sorting features.",
          "Create custom reports that automatically generate.",
        ],
        icon: "/layout/tool-guide/reports.svg",
        title: "Reports",
        account_manager: false,
      },
      {
        bullets: [
          "Overview of all users and their roles",
          "Add new users and make edits to existing users",
          canUserShift(user) ? <>Review a user’s account by clicking on User Shift icon <img className="cnt-default-layout-tool-guide-text-icons" src="/layout/user-shift-icon.svg"/></> : null,
          canUserShift(user) ? <>
            <span className="cnt-default-layout-tool-guide-section-body-bullets-emphasis">Note: </span>
            Only Solution Leads and Jaguars have ability to add/edit Users and use User Shift functionality.
          </> : ""
        ].filter(x=>!!x),
        icon: "/layout/sidebar/users.svg",
        title: "Users",
        account_manager: false,
      },

    ]
  }

  renderSections() {
    const { user } = this.props;
    return this.getSections().filter(section =>
      isAccountManager(user) ? section.account_manager : !section.account_manager
    ).map(section => <ToolGuideSection {...section}/>)
  }

  render() {
    const { user, nomination } = this.props;
    return (
      <DefaultLayout
        {...this.props}
        sidebarId="tool-guide"
      >
        <h1 className="cnt-page-header">
          Tool Guide
        </h1>
        <a href="/pdfs/cisco_nomtool_quickstart_guide_AM.pdf" target="_blank">
          Download Quickstart Guide
        </a>
        <div className="cnt-default-layout-tool-guide-container">
          {this.renderSections()}
        </div>

      </DefaultLayout>
    );
  }
}

export default ToolGuideIndex;
