import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import { tutorialStates } from "@tutorial/TutorialUtil"
import eventBus from "@tutorial/EventBus"
class  TutorialBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      tutorialId: null,
    };
  }

  getTutorialState() {
    const { tutorialId } = this.state;
    const { stateID } = this.props;
    const tState = tutorialStates(tutorialId).find(x=>x.id == stateID);
    if(tState) {
      return tState;
    }else {
      console.log("ERROR: Could not find tutorial state");
    }
  }

  componentDidMount() {
   const { stateID } = this.props;
   eventBus.on("tutorialSetState", (data) =>{
     console.log({
       ds: data.stateID,
       ti:stateID,
     })
     this.setState({
       active: data.stateID == stateID,
       tutorialId: data.tutorialId
     })
   });
  }

  renderTutorialInner() {
    const tState = this.getTutorialState();
    return (
      <div className="cnt-default-layout-tutorial-box-inner">
        <div className="cnt-default-layout-tutorial-box-header">
          {tState.title}
        </div>
        <div className="cnt-default-layout-tutorial-box-description">
          {tState.content}
        </div>
        <div className="cnt-default-layout-tutorial-box-button-container">
          <button onClick={()=>this.nextTutorialPage()} className="cnt-default-layout-tutorial-box-button-container">
            {tState.button}
          </button>
        </div>
      </div>
    );
  }

  nextTutorialPage() {
    const tState = this.getTutorialState();
    if(tState.onButtonClick) {
      tState.onButtonClick();
    }
    eventBus.dispatch("tutorialNext", {});
  }

  renderTutorialBox() {
    const tState = this.getTutorialState();
    switch(tState.type) {
      case "toppointer":
        return (
          <div className="cnt-tutorial-pointer-container cnt-tutorial-top-pointer-container">
            <div className="cnt-tutorial-pointer-point"/>
            {this.renderTutorialInner()}
          </div>
        )
      case "pointer":
        return (
          <div className="cnt-tutorial-pointer-container">
            <div className="cnt-tutorial-pointer-point"/>
            {this.renderTutorialInner()}
          </div>
        )
      case "modal":
        return (
          <div className="cnt-tutorial-modal-outer">
            <div className="cnt-tutorial-modal-inner">
              {this.renderTutorialInner()}
            </div>
          </div>
        )
      default:
        console.log(`Missing Type on: ${tState}`)
        return (
          <div className="cnt-tutorial-modal-outer">
            <div className="cnt-tutorial-modal-inner">
              Missing Type
            </div>
          </div>
        )
    }
  }


  render() {
    const { active } = this.state;
    if(active) {
      return this.renderTutorialBox();
    }
    return (
      <div className="cnt-default-layout-tutorial-placeholder">
      </div>
    )
  }
}

export default TutorialBox;
