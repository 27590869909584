import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import {isJaguar, isAccountManager} from "@utility/PermissionUtility"
import { formatId } from "@utility/ProgramUtils"
import GeneralTable from "@layout/tables/GeneralTable"
import ContactTableChit from "@contacts/tables/ContactTableChit"
import Cookies from "universal-cookie";

class ReportContactTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: props.contacts || null,
      error: null,
    };
    this.getColumns = this.getColumns.bind(this);
    this.getContacts = this.getContacts.bind(this);
  }

  componentDidMount() {
    const { contacts } = this.state;
    if(!contacts) {
      this.getContacts();
    }


  }

  getContacts() {
    const { logs } = this.state;
    const { setContacts } = this.props;
    if(logs == null) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch("/reports/get_contacts", {
        method: "GET",
        redirect: "manual",
        body: null,
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          if(setContacts) {
            setContacts(json.contacts)
          }
          this.setState({
            contacts: json.contacts
          })
        }
        else {
          this.setState({
            error: "json.error"
          })
        }
      }).catch((error)=>{
        this.setState({
        error: "Could not load contacts at this time. Try again later."
      })});
    }
  }

  getFilteredContacts() {
    const  { contacts } = this.state;
    const filteredContacts = contacts.map(x=> {
      return x;
    })
    return filteredContacts;
  }

  renderEmail(row) {
    return (
      <a href={`mailto:${row.email}`} target="_blank">
        {row.email}
      </a>
    )
  }

  renderActions(contact) {
    return (
      <>
        <a href={`/contacts/${contact.id}/full_report`}>
        Full Report
        </a>
      </>
    )
  }

  renderChit(contact) {
    const { user } = this.props;
    return (
      <ContactTableChit user={user} contact={contact}/>
    )
  }

  renderId(contact) {
    return <a href={`/contacts/${contact.id}`}>{formatId(contact.id)}</a>
  }

  getColumns() {
    const { user } = this.props;
    let allColumns = [
      { title: 'ID', field: 'id', render: (row) => this.renderId(row)},
      { title: 'Contact', field: 'name', render: (row) => this.renderChit(row) },
      { title: 'Actions', field: 'id', render: (row) => this.renderActions(row) },
    ]
    // if(isAccountManager(user)) {
    //   allColumns = allColumns.filter(x=>x.title != "Account Manager");
    // }
    return allColumns;
  }

  render() {
    const { contacts, error } = this.state;
    if(error) {
      return (
        <div className="ctn-default-layout-log-table-loading">
          <h1>{error}</h1>
        </div>
      )
    }
    if(contacts) {
      return (
          <GeneralTable
            columns={this.getColumns()}
            data={this.getFilteredContacts()}
          />
      )
    }
    return (
      <div className="ctn-default-layout-log-table-loading">
        <h1> Loading </h1>
        <br />
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
    )

  }
}

export default ReportContactTable;
