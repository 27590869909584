import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Dropzone from "react-dropzone";
import { Formik, Form } from "formik";

class ImportsConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: ''
    };
    this.showError = this.showError.bind(this);
    this.continue = this.continue.bind(this);
  }

  showError() {
    this.setState({ error: "Please download the spreadsheet template and insert your data. Import will fail, if template is not used." });
  }

  continue() {
    const { callbackSuccess } = this.props;
    callbackSuccess();
  }

  renderErrors() {
    const { error } = this.state;
    if (error !== '') {
      return (
        <div className="sg-mgmt-form-input-import-errors">
          <div className="sg-mgmt-form-input-import-error">
            {error}
          </div>
        </div>
      )
    }
    return <></>;
  }

  render() {
    return (
      <div className="sg-mgmt-form">
        <div className="sg-mgmt-form-container">
          <div className="sg-mgmt-form-section">
            <div className="sg-mgmt-form-row">
              <div className="sg-mgmt-form-row-column">
                Are you using the template for your data import?
              </div>
            </div>
          </div>
        </div>
        <div className="sg-mgmt-form-actions import-action-button">
          <button
            className="sg-mgmt-modal-btn sg-mgmt-modal-btn-enabled"
            onClick={this.continue}
          >
            Yes
          </button>
          <button
            className="sg-mgmt-modal-btn sg-mgmt-modal-btn-cancel"
            onClick={this.showError}
            style={{marginLeft: "20px"}}
          >
            No
          </button>
        </div>
        {this.renderErrors()}
      </div>
    );
  }
}

export default ImportsConfirmation;
