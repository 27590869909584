import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import UserForm from "@admin/users/forms/UserForm"
import UniversalImageCircle from "@images/UniversalImageCircle"
import  TabbedPageContainer from "@layout/tabs/TabbedPageContainer"
import  LogTable from "@logs/LogTable"
import ContactsTable from "@contacts/ContactsTable"
import DropzoneComponent from "@images/DropzoneComponent"
import Cookies from "universal-cookie";
import { getRoleName } from "@utility/PermissionUtility"

class UsersProfileIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileImage: props.profile_user.profile_image
    };
    this.setProfileImage = this.setProfileImage.bind(this);
  }

  setProfileImage(name, file, preview, tagList) {
    const { profile_user } = this.props;
    var form_data = new FormData();
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    form_data.append('editing_user[profile_image]', file);

    this.setState({
      profileImage: preview
    });

    fetch(`/admin/users/${profile_user.id}/update_profile_picture`, {
      method: 'POST',
      redirect: "manual",
      body: form_data,
      headers: {
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {

      } else {
        console.log(json.error);
      }
    });
  }

  renderHeader() {
    const { user, profile_user } = this.props;
    const { profileImage } = this.state;
    return (
      <>
      <h1 className="cnt-page-header">
        Profile
      </h1>
      <div className="row cnt-event-show-header-container">

        <div className="cnt-user-profile-header-image-circle cnt-event-show-header-container-image col-xs-12 col-sm-4 col-lg-3">
          <UniversalImageCircle size={'large'} image={profileImage} name={profile_user.first_name} id={profile_user.id}/>
          <DropzoneComponent outerStyle={{display: "inline-block", paddingRight:"5px"}} setFiles={this.setProfileImage}>
            <a href="#">Upload Photo</a>
          </DropzoneComponent>
        </div>
      </div>
      </>
    )
  }

  renderAssociatedUsers() {
    const { associated_users } = this.props;
    if(associated_users && associated_users.length > 0) {
      const renderedAssociatedUsers = associated_users.map(x=>(
        <tr className="cnt-users-associated-user">
          <td>
            {x.email}
          </td>
          <td>
            {getRoleName(x.role)}
          </td>
          <td>
            <a href={`/admin/users/${x.id}/become_user`}>
              <button className="cnt-approve-button">
                User Shift
              </button>
            </a>

          </td>
        </tr>
      ))
      return (
        <div className="cnt-users-associated-users-section">
          <div className="cnt-users-associated-users-section-header cnt-condensed-model-section-header">
            Alternate Accounts
          </div>
          <table className="cnt-users-associated-users-section-table">
            <thead>
              <th>
                Email
              </th>
              <th>
                Role
              </th>
              <th>
                User Shift
              </th>
            </thead>
            <tbody>
              {renderedAssociatedUsers}
            </tbody>
          </table>
        </div>
      )
    }
  }

  render() {
    const { user, contacts, profile_user } = this.props;
    return (
      <DefaultLayout
        {...this.props}
      >
        {this.renderHeader()}
        <UserForm {...this.props} editing_user={profile_user} profile={true} />

        {this.renderAssociatedUsers()}
      </DefaultLayout>
    );
  }
}

export default UsersProfileIndex;
