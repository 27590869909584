import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import ContactForm from "./forms/ContactForm"
import UniversalImageCircle from "@images/UniversalImageCircle"
import  TabbedPageContainer from "@layout/tabs/TabbedPageContainer"
import  LogTable from "@logs/LogTable"
import CompanyForm from "@companies/forms/CompanyForm"
import UngroupedNominationsTable from "@nominations/tables/UngroupedNominationsTable"
import {canEditContact, canViewLog, canDeleteContact, isAccountManager} from "@utility/PermissionUtility"
import DropzoneComponent from "@images/DropzoneComponent"
import Cookies from "universal-cookie";
import VerifyUpdateBlock from "@updates/VerifyUpdateBlock"
import ContactDeleteModal from "@contacts/modals/ContactDeleteModal"
class ContactsShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactImage: props.contact ? props.contact.contact_image : null,
      forceDirty: false,
    };
    this.setImage = this.setImage.bind(this);
    this.deleteContact = this.deleteContact.bind(this);
    this.renderLogs = this.renderLogs.bind(this);
  }

  setImage(name, file, preview, tagList) {
    const { contact } = this.props;
    var form_data = new FormData();
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    form_data.append('contact[contact_image]', file);

    this.setState({
      contactImage: preview,
      forceDirty: true,
    });

    fetch(`/contacts/${contact.id}/update_image`, {
      method: 'POST',
      redirect: "manual",
      body: form_data,
      headers: {
        "X-CSRF-Token": token,
      }
    })
  }

  deleteContact() {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const { contact } = this.props;
    if(!confirm("Are you sure you want to delete this contact?")) {
      return;
    }
    fetch(`/contacts/${contact.id}`, {
      method: "DELETE",
      redirect: "manual",
      body: null,
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          if(json.redirect_url) {
            window.location = json.redirect_url
          }
        }
      });
  }

  renderHeader() {
    const { contact, user } = this.props;
    const { contactImage } = this.state;
    return (
      <div className="row cnt-event-show-header-container">
        <div className="cnt-event-show-header-container-image col-xs-12 col-sm-4 col-lg-3">
          <UniversalImageCircle image={contactImage} size={'large'} name={contact.first_name} id={contact.id}/>
        </div>
        <div className="cnt-event-show-header-container-description col-xs-12 col-sm-8 col-lg-9">
          <div className="cnt-event-show-header-container-name">
            {contact.first_name} {contact.last_name}
          </div>
          <div className="cnt-event-show-header-container-date">
            {canEditContact(user) ? (
              <>
                {canDeleteContact(user) ? (
                  <ContactDeleteModal  contact={contact} onDelete={(e)=>window.location='/contacts'}>
                    <a
                      href="#"
                      style={{display:'inline-block', color: "red"}}
                    >
                      Delete Contact
                    </a>
                  </ContactDeleteModal>
                ):""}
              </>
            ):""}
          </div>
        </div>
      </div>
    )
  }

  renderLogs() {
    const { contact } = this.props;
    return (
      <LogTable
        logType="contacts"
        logObject={contact}
       />
    )
  }

  getTabs() {
    const { user } = this.props;
    const { forceDirty } = this.state;
    let tabs =  [
      {id: "profile", name: "Profile", content:(<ContactForm {...this.props} disableDelete={true} forceDirty={forceDirty}/>)},
      {id: "nomination_history", name: "Nomination History", content: (<UngroupedNominationsTable {...this.props} />)},
      {id: "log", name: "Log", content: this.renderLogs}
    ]

    if(!canViewLog(user)) {
      tabs = tabs.filter(x=> x.id != 'log')
    }

    return tabs;
  }

  renderUpdates() {
    const { verifiable_versions, user } = this.props;
    if(!isAccountManager(user) && verifiable_versions) {
      return verifiable_versions.map(x=>(
        <VerifyUpdateBlock user={user} update={x}/>
      ))
    }
  }

  render() {
    const { user } = this.props;
    return (
      <DefaultLayout
        {...this.props}
        sidebarId="contacts"
      >
        <h1 className="cnt-page-header">
          My Contacts
        </h1>
        <div class="cnt-page-subheader-link"><a href="/contacts">
          {"< Return to my contacts"}
        </a>
        </div>
        {this.renderHeader()}
        <TabbedPageContainer
          pages={this.getTabs()}
          default_tab_id="profile"
        />
      </DefaultLayout>
    );
  }
}

export default ContactsShow;
