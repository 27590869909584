import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Select from 'react-select-virtualized'
import Cookies from "universal-cookie";

class CompanyDeleteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      selectedCompany: null,
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.destroyCompanyModal = this.destroyCompanyModal.bind(this);
  }

  getCompanyOptions() {
    const { companies, company } = this.props;
    if(companies) {
      return companies.filter(x=> x.id != company.id).map(x=> {return {value: x.id, label: `${x.name}`}})
    }
    return [];
  }

  renderModal() {
    const {editing_user, user } = this.props;
    const { selectedCompany, selectErroring } = this.state;
    return (
      <div className="row">
        <div className="col-xs-12 ">
          <div className="cnt-admin-user-management-delete-modal-title">
              Delete this company?
          </div>
          <div className="cnt-admin-user-management-delete-modal-description">
            This company has contacts mapped to their account. Which company should we re-assign them to?
          </div>
          <div>
            Company
          </div>
          <div>
            <Select
              className={selectErroring && selectedCompany == null ? "registration-select-erroring" : ""}
              options={this.getCompanyOptions()}
              classNamePrefix='registration-select'
              value={selectedCompany}
              onChange={(option) => {
                this.setState({
                  selectedCompany: option
                })
              }}
            />
            <br />
          </div>
          <div className="">
            <button disabled={selectedCompany == null} className="cnt-submit-button" onClick={this.destroyCompanyModal}> Confirm and Delete</button>
            <button className="cnt-cancel-button"onClick={this.toggleModal}> Cancel</button>
          </div>
        </div>
      </div>
    );

  }

  destroyCompanyModal() {
    const { company } = this.props;
    const { selectedCompany } = this.state;
    if(company && selectedCompany) {
      this.destroyCompany(selectedCompany.value)
    }
    else {
      alert("Please Select a company to replace this one.")
    }
  }


  formURL() {
    const { company } = this.props;
    return `/companies/${company.id}`;
  }

  destroyCompany(selectedCompanyID) {
    const {onDelete, company} = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(this.formURL(), {
      method: "DELETE",
      redirect: "manual",
      body: JSON.stringify({
        replacement_id: selectedCompanyID
      }),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          if(onDelete) {
            onDelete(company.id,{selectedCompanyID: selectedCompanyID})
          }
          this.toggleModal();
        }
        else {
          this.setState({
            error: json.error
          })
        }
      });
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen
    });
  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, sessions } = this.props;
    return (
      <>
        <div className={outerClassNames} style={{cursor:"pointer", display: "inline-block"}} onClick={() => this.toggleModal()} >
          {children}
        </div>
        <Modal
          className="white-background cnt-user-admin-modal disable-scrollbars cnt-overflow-none"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default CompanyDeleteModal;
