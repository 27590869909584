import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import Cookies from "universal-cookie";
import DefaultLayout from "@layout/DefaultLayout";
import AdminReportToolTable from "./AdminReportToolTable";

class AdminPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  renderTitle() {
    return (
      <>
        <div className="report-tool-page-header">
          <h1 className="cnt-page-header">
            Admin panel
          </h1>
        </div>
        <div style={{clear: "both"}} />
      </>
    );
  }

  render() {
    return (
      <DefaultLayout {...this.props} sidebarId="reports">
        <div className="report-tool-form-wrapper" style={{ margin: "0px" }}>
          {this.renderTitle()}
        </div>
        <AdminReportToolTable />
      </DefaultLayout>
    );
  }
}

export default AdminPanel;
