import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import Cookies from "universal-cookie";
import {canEditNomination, isAccountManager, canApproveNomination} from "@utility/PermissionUtility"
import Select from 'react-select-virtualized'
import { dateText } from "@utility/TimeUtils"
import UniversalImageCircle from "@images/UniversalImageCircle"
import NominationStatus from "@nominations/tables/NominationStatus"
import NominationApproveModal from "./NominationApproveModal"
import NominationRejectModal from "./NominationRejectModal"
import NominationSubmitModal from "./NominationSubmitModal"
import NominationQuestionModal from "./NominationQuestionModal"
import UniversalOpenableContainer from "@layout/openable/UniversalOpenableContainer"
import ContactProfileModal from "@contacts/modals/ContactProfileModal"
import NominationComment from "@nominations/comments/NominationComment"
import NominationDeleteModal from "@nominations/modals/NominationDeleteModal"
import { isEventLocked } from "@utility/EventUtils"
import NominationStatusBlock from "@nominations/NominationStatusBlock"
import { getNominationQuestionsAndAnswers } from "@utility/NominationUtility"

class NominationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      submitted: false,
      submitting: false,
      dirty: false || !!(props.event_id || props.contact_id),
      forceUpdate: false,
      comments: props.nomination.nomination_comments || [],
    };
    this.destroyNomination = this.destroyNomination.bind(this);
    this.submitForm  = this.submitForm.bind(this);
    this.saveAsDraft = this.saveAsDraft.bind(this);
    this.submitComment = this.submitComment.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.isEventLockedByID = this.isEventLockedByID.bind(this);
  }

  isEditing() {
    const { nomination } = this.props;
    return !!nomination.id;
  }

  renderField(name,formikProps, required=false,customValidation = false, properties = {}) {
    const { user } = this.props;
    const { checkError } = this.state;
    const erroring = required && checkError && (!formikProps.values.nomination[name] || formikProps.values.nomination[name].length <= 0 || customValidation);

    return (
      <Field
        onChange={(e) => {
            const { nomination } = formikProps.values;
            nomination[name] = e.target.value
            formikProps.setFieldValue('nomination', nomination);
            this.setState({
              dirty: true,
            });
          }
        }
        disabled={properties['disabled']}
        component={properties['component']}
        className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`nomination[${name}]`}
      />
    )
  }

  renderFieldAnswer(question,formikProps, properties = {}) {
    const { user } = this.props;
    const { checkError } = this.state;
    const erroring = checkError && (!formikProps.values.nomination[name] || formikProps.values.nomination[name].length <= 0);
    const { values } = formikProps;
    let { nomination } = values;
    const answers = (nomination['award_criteria_answers'].answers||[])
    const id = question.id
    const answer = answers.find(x=> x.id == id);

    return (
      <Field
        onChange={(e) => {
          let { nomination } = values;
          nomination.award_criteria_answers.answers = (nomination['award_criteria_answers'].answers||[]).filter(x=> x.id != question.id).concat([{id: question.id, answer: e.target.value}]);
          formikProps.setFieldValue('nomination', nomination);
          this.setState({
            dirty: true
          })
        }}
        disabled={properties['disabled']}
        component={properties['component']}
        className={`form-control ${erroring ? 'field-erroring': "" }`}
        type={properties['type']}
        value={
          answer ? answer.answer : ""
        }
      />
    )
  }

  renderDateField(name,formikProps, required = false) {
    const { checkError } = this.state;
    const erroring = required && checkError && (!formikProps.values.nomination[name] || formikProps.values.nomination[name].length <= 0 || customValidation);

    return (
      <Field
        onChange={(e) => {
            const { nomination } = formikProps.values;
            nomination[name] = e.target.value
            formikProps.setFieldValue('nomination', nomination);
            this.setState({
              dirty: true,
            });
          }
        }
        component={properties['component']}
        className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`nomination[${name}]`}
      />
    )
  }

  renderEventForm(formikProps) {
    const { events } = this.props;
    const { nomination } = formikProps.values;
    if(nomination && nomination.event_id) {
      const event = events.find(x=> x.id == nomination.event_id);
      return (
        <>
        <div className="col-xs-12 cnt-default-layout-nomination-contact-area">
          <div className="cnt-default-layout-nomination-contact-description-container">
            <div className="cnt-default-layout-nomination-contact-picture">
              <UniversalImageCircle image={event.icon } name={event.name} id={event.id}/>
            </div>
            <div className="cnt-default-layout-nomination-contact-description">
              <span className="cnt-default-layout-nomination-contact-description-name">
                {event.name}
              </span>
              <br/>
              {dateText(event)}
              <br/>
              {event.location}
              <br/>
            </div>
          </div>
        </div>
        <div className="col-xs-12 cnt-default-layout-nom-question" >
          <label>
            {event && event.options&& event.options.criteria_header_override ? event.options.criteria_header_override : "Event Criteria"}
          </label>
          <br/>
          {event.description.split("\n").map(x=>(<>{x}<br/></>))}
        </div>
        </>
      );
    }
  }



  areFieldsDisabled() {
    return this.getFormState() != "draft";
  }

  getNominationReasonsOptions() {
    return [
      {
        value: "tr1",
        label: "Reason 1 ",
      },
      {
        value: "tr2",
        label: "Reason 2 ",
      },
      {
        value: "tr3",
        label: "Reason 3",
      },
      {
        value: "tr4",
        label: "Reason 4",
      },
    ]
  }

  renderContactForm(formikProps) {
    const { contacts, user } = this.props;
    const { nomination } = formikProps.values;
    if(nomination && nomination.contact_id) {
      const contact = contacts.find(x=> x.id == nomination.contact_id);
      return (
        <div className="col-xs-12 cnt-default-layout-nomination-contact-area">
          <div className="cnt-default-layout-nomination-contact-description-container">
            <div className="cnt-default-layout-nomination-contact-picture">
              <UniversalImageCircle image={contact.contact_image} name={contact.first_name} id={contact.id}/>
            </div>
            <div className="cnt-default-layout-nomination-contact-description">
              <span className="cnt-default-layout-nomination-contact-description-name">
                {contact.first_name} {contact.last_name}
              </span>
              <br/>
              {contact.job_title}
              <br/>
              {contact.company ? contact.company.name : contact.am_provided_company_name||"Unknown"}
            </div>
          </div>
        </div>
      );
    }
  }

  getEventOptions() {
    const { events } = this.props;
    return events.map(event=> {return {label: [event.name,dateText(event),event.location].filter(x=>x && x.trim().length > 0).join(" | "), value: event.id}})
  }


  getContactOptions() {
    const { contacts } = this.props;
    return contacts.map(contact=> {return {
      label: `${contact.first_name} ${contact.last_name} | ${contact.job_title} | ${contact.company ? contact.company.name : contact.am_provided_company_name||"Unknown"}`,
      value: contact.id
    }});
  }

  renderNominationStatus() {
    const { nomination } = this.props;
    return (
      <div className="cnt-default-layout-nominations-form-status">
        <div className="cnt-default-layout-nominations-form-status-label">Status:</div>
        <div className="cnt-default-layout-nominations-form-status-spacing">
        <NominationStatus nomination={nomination} />
        </div>
      </div>
    )
  }

  renderQuestions(formikProps) {
    const { nomination } = formikProps.values;
    const { events } = this.props;
    if(nomination && nomination.event_id) {
      const event = events.find(x=> x.id == nomination.event_id);
      const questions = event && event.award_criteria && event.award_criteria.questions ? event.award_criteria.questions : []
      if(this.getFormState() == 'draft') {
        return questions.map(question=>
          this.renderEditableQuestion(question, formikProps)
        )
      }else {
        return questions.map(question=>
          this.renderUneditableQuestion(question, formikProps)
        );
      }
    }
    return ""
  }

  renderUneditableQuestion(question,formikProps) {
    const { nomination } = formikProps.values;
    const { events } = this.props;
    const answers = nomination.award_criteria_answers && nomination.award_criteria_answers.answers ? nomination.award_criteria_answers.answers : []
    const answer = answers.find(x=> x.id == question.id) || {}
    return (
      <div className="cnt-default-layout-nom-question">
        <br/>
        <label>
          {question.question}
        </label>
        <div className="cnt-default-layout-nom-submit-answer">
          {answer.answer}
        </div>
      </div>
    )
  }

  renderQuestionModal(question) {
    if(question && question.custom_modal && question.custom_modal_content) {
      return (
        <NominationQuestionModal
          question={question}
        />
      )
    }
  }

  renderEditableQuestion(question, formikProps) {
    const { nomination } = formikProps.values;
    const { events } = this.props;
    const optional = question.optional;
    const hidden = question.hidden;
    if(hidden) {
      return;
    }
    switch(question.type){
      case 'dropdown':
        return (
          <div className="cnt-default-layout-nom-question">
            <label className={`${optional ? "" : "required"}`}>
              {question.question} {this.renderQuestionModal(question)}
            </label>
            {this.renderSelectFieldAnswer(question, formikProps, question.answers.split("\n").map(x=>{return{label:x, value: x}}), {disabled: this.areFieldsDisabled()})}
          </div>
        )
      case 'checkbox':
        return (
          <div className="cnt-default-layout-nom-question">
            <label className={`${optional ? "" : "required"}`}>
              {question.question} {this.renderQuestionModal(question)}
            </label>
            {this.renderCheckboxQuestion(question, formikProps, question.answers.split("\n").map(x=>{return{label:x, value: x}}), {disabled: this.areFieldsDisabled()})}
          </div>
        )
      case 'radiobutton':
        return (
          <div className="cnt-default-layout-nom-question">
            <label className={`${optional ? "" : "required"}`}>
              {question.question} {this.renderQuestionModal(question)}
            </label>
            {this.renderRadioQuestion(question, formikProps, question.answers.split("\n").map(x=>{return{label:x, value: x}}), {disabled: this.areFieldsDisabled()})}
          </div>
        )
      default:
        return (
          <div className="cnt-default-layout-nom-question">
            <label className={`${optional ? "" : "required"}`}>
              {question.question} {this.renderQuestionModal(question)}
            </label>
            {this.renderFieldAnswer(question, formikProps, {component: "textarea", disabled: this.areFieldsDisabled()})}
          </div>
        )
    }
  }

  renderForm(formikProps) {
    return this.renderSubmitForm(formikProps);
  }


  renderApproveDeny(formikProps) {
    const { user } = this.props;
    const state = this.getFormState();
    if (canApproveNomination(user)) {
      return (
        <>
          { state == "submit" || state=="in_review"? (
            <>
            <div className="cnt-default-layout-nominations-form-approve-deny-container row">
              <div className="cnt-default-layout-nominations-form-approve-deny-buttons">
                {this.renderButtons()}
              </div>
            </div>
            <div className="">
              <i>
                You will have the option to leave a note with your approval or denial.
              </i>
            </div>
            </>
          ):""}
        </>
      )
    }
  }


  renderSubmitForm(formikProps) {
    const { error } = this.state;
    const { user } = this.props;

    return (
      <Form className="cnt-default-layout-nominations-form cnt-default-layout-nominations-form-submit" >
        <div className="row" style={{margin:0}}>
          <div className="col-xs-8">
            <div className="row cnt-default-layout-nominations-form-status-and-user-container">
              <div className="row">
                <div className="col-xs-12 col-sm-6 ">
                  {this.renderNominationStatus()}
                </div>
              </div>

            </div>
            <div className="row">
              {this.renderContact(formikProps)}
              {this.renderEvent(formikProps)}
              <div className="col-xs-12">
                <br/>
              </div>

              {this.renderNominationInfo(formikProps)}
            </div>
            <div className="row">
              <div className="col-xs-12 cnt-default-layout-event-form-error">
                {error}
              </div>
            </div>
          </div>
          <div className="col-xs-4">
            <br/>
            {this.renderStatusBlock()}
            {this.renderApproveDeny(formikProps)}
          </div>
        </div>
      </Form>
    )
  }

  renderNominationInfo(formikProps) {
    const { nomination } = formikProps.values;
    if(nomination) {
      return (
        <>
          <div className="cnt-condensed-model-section-header">
            Nomination Info
          </div>
          <div className="col-xs-12 ">
            <div className="cnt-condensed-model-section">
              {this.renderNominationQuestions(formikProps)}
              {nomination.notes ? (
                <div>
                  <div className="cnt-default-layout-nomination-modal-nomination-info-question">
                    Notes:
                  </div>
                  <div className="cnt-default-layout-nomination-modal-nomination-info-answer">
                    {nomination.notes}
                  </div>
                </div>
              ):""}
            </div>
          </div>
          <div className="col-xs-12">
            <br/><br/>
          </div>
        </>
      )
    }
  }


  renderStatusBlock() {
    const { user, nomination } = this.props;
    return (
      <NominationStatusBlock
        nomination={nomination}
        user={user}
        showSubmission={true}
      />
    )
  }

  submitComment() {
    const { nomination } = this.props;
    const { currentComment, comments } = this.state;
    if(currentComment) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch(`/nominations/${nomination.id}/comment`, {
        method: "POST",
        redirect: "manual",
        body: JSON.stringify({
          comment: {
            message: currentComment,
          }
        }),
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            comments: comments.concat([
              json.comment
            ]),
            currentComment: ""
          })
        }
      });
    }

  }

  renderComments() {
    const { nomination } = this.props;
    const { comments, currentComment } = this.state;
    return (
      <div className="cnt-default-layout-nominations-form-comments-container">
        <div className="cnt-default-layout-nominations-form-comments-header">
          Comments:
        </div>
        <div className="cnt-default-layout-nominations-form-comment-form">
          <textarea value={currentComment||""} onChange={(e) => this.setState({currentComment: e.target.value})}/>
          {currentComment ? (
            <>
            <button type="button" onClick={()=>this.submitComment()} className="cnt-approve-button cnt-submit-comment-button"> Save </button>
            <br/>
            </>
          ):""}
        </div>
        {comments.sort((a,b) => new Date(a) > new Date(b) ? 1 : -1).map(x=>
          <NominationComment comment={x}/>
        )}
        {!comments || comments.length == 0 ? (
          <div className="cnt-default-layout-nomination-comment">
            <div className="cnt-default-layout-nomination-comment-info">
              <i>There are no comments yet.</i>
            </div>
          </div>
        ):""}
      </div>
    );
  }


  renderNominationQuestions(formikProps) {
    const { events, user } = this.props;
    const { nomination } = formikProps.values;
    if(nomination && nomination.event_id) {
      const event = events.find(x=> x.id == nomination.event_id);
      return getNominationQuestionsAndAnswers(nomination, event).map(x=>
        <div>
          <div className="cnt-default-layout-nomination-modal-nomination-info-question">
            {x.question.question}
          </div>
          <div className="cnt-default-layout-nomination-modal-nomination-info-answer">
            {x.answer.answer}
          </div>
        </div>
      )
    }
  }

    renderContact(formikProps){
      const { contacts, user } = this.props;
      const { nomination } = formikProps.values;
      if(nomination && nomination.contact_id) {
        const contact = contacts.find(x=> x.id == nomination.contact_id);
        return (
          <div className="col-xs-12 col-md-6 cnt-default-layout-nomination-contact-area">
            <div className="cnt-condensed-model-section-header">
              Contact
            </div>
            <div className="cnt-default-layout-nomination-contact-description-container">
              <div className="cnt-default-layout-nomination-contact-picture">
                <UniversalImageCircle image={contact.contact_image} name={contact.first_name} id={contact.id}/>
              </div>
              <div className="cnt-default-layout-nomination-contact-description">
                <span className="cnt-default-layout-nomination-contact-description-name">
                  {contact.first_name} {contact.last_name}
                </span>
                <br/>
                {contact.job_title}
                <br/>
                {contact.company ? contact.company.name : contact.am_provided_company_name || "Unknown"}
              </div>
            </div>
          </div>
        );
      }
    }

    renderEvent(formikProps) {
      const { events, user } = this.props;
      const { nomination } = formikProps.values;
      if(nomination && nomination.event_id) {
        const event = events.find(x=> x.id == nomination.event_id);
        return (
          <div className="col-xs-12 col-md-6 cnt-default-layout-nomination-contact-area"  style={{paddingRight: 0}}>
            <div className="cnt-condensed-model-section-header">
              Event
            </div>
            <div className="cnt-default-layout-nomination-contact-description-container">
              <div className="cnt-default-layout-nomination-contact-picture">
                <UniversalImageCircle image={event.icon } name={event.name} id={event.id}/>
              </div>
              <div className="cnt-default-layout-nomination-contact-description">
                <span className="cnt-default-layout-nomination-contact-description-name">
                  {event.name}
                </span>
                <br/>
                {dateText(event)}
                <br/>
                {event.location}
                <br/>
              </div>
            </div>
          </div>
        );
      }
    }

  isEventLockedByID(option) {
    const { events } = this.props;
    const event = events.find(x=> x.id == option.value);
    return isEventLocked(event);
  }

  getFormState() {
    const { nomination } = this.props;
    if(this.state.forceUpdate) {
      return "draft";
    }
    switch(nomination.status) {
      case "draft":
        return "draft"
        break;
      case "submit":
        return "submit";
        break;
      case "in_review":
        return "submit"
      default:
        return "closed";
        break;
    }
    return "closed";
  }

  submitForm(formikProps) {
    const { submitting } = this.state;
    if(this.getFormState()=="draft" && !submitting) {
      if(!this.nominationEventQuestionsValid(formikProps)) {
        this.setState({
          error:"Please fill out all questions before submitting"
        })
        return;
      }
      formikProps.values.nomination.status = "submit";
      formikProps.handleSubmit();
    }
  }

  nominationEventQuestionsValid(formikProps) {

    const { values } = formikProps;
    const { nomination } = values;
    const { events } = this.props;
    const event = events.find(x=> x.id == nomination.event_id);

    const questions = event && event.award_criteria && event.award_criteria.questions ? event.award_criteria.questions : [];

    const answers = (nomination['award_criteria_answers'].answers||[])


    const invalidQuestions = questions.map(question=> {
      if(question.optional || question.hidden) {
        return true;
      }
      const id = question.id
      const answer = answers.find(x=> x.id == id);
      return !!(answer && answer.answer)
    }).filter(x=> !x)
    return invalidQuestions.length <= 0;
  }

  saveAsDraft(formikProps) {
    const { submitting } = this.state;
    formikProps.values.nomination.status = "draft";
    if(!submitting) {
      formikProps.handleSubmit();
    }
  }

  renderLoadingCircle() {
    return (<div class="lds-button-ellipsis"><div></div><div></div><div></div><div></div></div>)
  }

  renderButtons(formikProps) {
    const { user } = this.props;
    const { forceUpdate,submitting, dirty } = this.state;
    const state = this.getFormState();
    if(state == "draft") {
      return (
        <div className="col-xs-12" style={{paddingTop: "20px"}}>
          <button disabled={!dirty} type="button" onClick={()=>this.submitForm(formikProps)} className={dirty ? `cnt-submit-button` : `cnt-dirty-button`}> {submitting && formikProps.values.nomination.status != "draft" ? this.renderLoadingCircle() : "Submit"} </button>
          <button disabled={!dirty} type="button" onClick={()=>this.saveAsDraft(formikProps)} className={dirty ? `cnt-cancel-button` : `cnt-dirty-button`}>  {submitting && formikProps.values.nomination.status == "draft" ? this.renderLoadingCircle() : "Save as draft"}</button>
          {forceUpdate ? (
            <button onClick={()=>this.setState({forceUpdate: false})} type="button" className="cnt-cancel-button "> Cancel </button>
          ) : (<a href="/"><button type="button" className="cnt-cancel-button "> Cancel </button></a>)}
        </div>
      )
    }
    else if(state == "submit" && canApproveNomination(user)) {
      return (
        <div className="col-xs-12 text-right" style={{paddingTop: "20px"}}>
          <NominationApproveModal {...this.props} outerClassNames="cnt-display-block"><button type="button" className="cnt-approve-button full-length-button"> Approve </button></NominationApproveModal>
          <NominationRejectModal {...this.props} outerClassNames="cnt-display-block"><button type="button" className="cnt-reject-button full-length-button"> Deny </button></NominationRejectModal>
        </div>
      )
    }
    else if(state == "rejected") {
      return (
        <div className="col-xs-12 text-right" style={{paddingTop: "20px"}}>
        </div>
      )
    }
  }


  onDelete(json) {
    window.location = "/"
  }

  destroyNomination() {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    if(!confirm("Are you sure you want to delete this nomination?")) {
      return;
    }
    fetch(this.formURL(), {
      method: "DELETE",
      redirect: "manual",
      body: null,
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        if(json.redirect_url) {
          window.location = json.redirect_url
        }else {
          console.log("Successful no redirect")
        }
      }
      else {
        this.setState({
          error: json.error
        })
      }
    });
  }

  renderSelectField(name, formikProps, options, customValidation = false, renderOptions = {}) {
    const { checkError } = this.state;
    const erroring = checkError && ((formikProps.values.nomination[name] == null || formikProps.values.nomination[name].length <= 0) || customValidation);
    let value = null;
    try {
       value = options.filter(option => option.value === formikProps.values['nomination'][name])[0]
    }catch (e) {

    }
    if(renderOptions['optionFilter']) {
      options = options.filter(renderOptions.optionFilter)
    }
    return (
      <Select
        className={erroring ? "cnt-form-select-erroring" : ""}
        options={options}
        classNamePrefix='cnt-form-select'
        value={options ? value : ""}
        onChange={(option) => {
          const { nomination } = formikProps.values;
          nomination[name] = option.value
          formikProps.setFieldValue('nomination', nomination)
          this.setState({
            dirty: true
          })
        }}
        isDisabled={renderOptions['disabled']}
        filterOption={this.customFilter}
      />
    )
  }

  renderSelectFieldAnswer(question, formikProps, options, renderOptions = {}) {
    const { checkError } = this.state;
    const { values } = formikProps;
    let { nomination } = values;
    const answers = (nomination['award_criteria_answers'].answers||[])
    const id = question.id
    let value = null;
    const answer = answers.find(x=> x.id == id);
    try {
       value = options.filter(option => option.value === answer.answer)
    }catch (e) {

    }

    const erroring = checkError && (!value);
    return (
      <Select
        className={erroring ? "cnt-form-select-erroring" : ""}
        options={options}
        classNamePrefix='cnt-form-select'
        value={options ? value : ""}
        onChange={(option) => {
          let { nomination } = values;
          nomination.award_criteria_answers.answers = (nomination['award_criteria_answers'].answers||[]).filter(x=> x.id != question.id).concat([{id: question.id, answer: option.value}])
          formikProps.setFieldValue('nomination', nomination)
          this.setState({
            dirty: true
          })
        }}
        isDisabled={renderOptions['disabled']}
        filterOption={this.customFilter}
      />
    )
  }

  renderRadioQuestion(question, formikProps, options, renderOptions = {}) {
    const { checkError } = this.state;
    const { values } = formikProps;
    let { nomination } = values;
    const answers = (nomination['award_criteria_answers'].answers||[])
    const id = question.id
    const answer = answers.find(x=> x.id == id);


    const erroring = checkError && (!value);
    const checkboxes = options.map(option=>{
      const checkboxValue = value.findIndex(x=> x == option.value) != -1;
      return (
        <>
          <img
            src={ checkboxValue  ? "/layout/form/radiobutton-clicked.svg" : "/layout/form/radiobutton-unclicked.svg" }
            style={{cursor:"pointer", margin: "0 10px"}}
            onClick={(e) => {
              if(!renderOptions['disabled']){
                let { nomination } = values;
                let answerOption = answer;
                nomination.award_criteria_answers.answers = (
                  nomination['award_criteria_answers'].answers||[]
                ).filter(x=> x.id != question.id).concat([
                  {id: question.id, answer: option.value}
                ])
                formikProps.setFieldValue('nomination', nomination)
                this.setState({
                  dirty: true
                })
              }
            }}
          /><label className="checkbox-label" for="topic_ids">{option.label}</label>
        </>
      )
    })

    return (
      <div>
        {checkboxes}
      </div>
    );
  }


    renderCheckboxQuestion(question, formikProps, options, renderOptions = {}) {
      const { checkError } = this.state;
      const { values } = formikProps;
      let { nomination } = values;
      const answers = (nomination['award_criteria_answers'].answers||[])
      const id = question.id
      let value = [];
      const answer = answers.find(x=> x.id == id);
      try {
         value = answer ? answer.answer.split("\n") : []
      }catch (e) {

      }


      const erroring = checkError && (!value);
      const checkboxes = options.map(option=>{
        const checkboxValue = value.findIndex(x=> x == option.value) != -1;
        return (
          <>
            <img
              src={ checkboxValue  ? "/images/checkbox-checked.svg" : "/images/checkbox-unchecked.svg" }
              style={{cursor:"pointer", margin: "0 10px"}}
              onClick={(e) => {
                if(!renderOptions['disabled']){
                  console.log("attempting to set");
                  let { nomination } = values;
                  let answerOption = answer;
                  if(checkboxValue) {
                    console.log("unchecking");
                    answerOption = value.filter(x=>x!=option.value).join("\n");
                  }else {
                    console.log("checking");
                    answerOption = value.concat([option.value]).join("\n");
                  }
                  console.log({answerOption});

                  nomination.award_criteria_answers.answers = (
                    nomination['award_criteria_answers'].answers||[]
                  ).filter(x=> x.id != question.id).concat([
                    {id: question.id, answer: answerOption}
                  ])

                  formikProps.setFieldValue('nomination', nomination)
                  this.setState({
                    dirty: true
                  })
                }
              }}
            /><label className="checkbox-label" for="topic_ids">{option.label}</label>
          </>
        )
      })

      return (
        <div>
          {checkboxes}
        </div>
      );
    }




  formMethod() {
    return this.isEditing() ? "PATCH" : "POST"
  }

  formURL() {
    const { nomination } = this.props;
    return this.isEditing() ? `/nominations/${nomination.id}` : `/nominations`;
  }

  generateDefaultAccountManager() {
    const { user } = this.props
    if(isAccountManager(user)) {
      return user.id
    }
    return null
  }

  getNominationReasonText(nomination) {
    if(nomination && nomination.award_criteria_answers && nomination.award_criteria_answers.nomination_reason_text) {
      return nomination.award_criteria_answers.nomination_reason_text[0];
    }
    return "";
  }

  getNominationReasonSelect(nomination) {
    if(nomination && nomination.award_criteria_answers && nomination.award_criteria_answers.nomination_reason_select) {
      return nomination.award_criteria_answers.nomination_reason_select[0];
    }
    return "";
  }

  renderSubmitModal() {
    const { submitted } = this.state;
    if(submitted) {
      return (
        <NominationSubmitModal />
      )
    }
  }

  render() {
    const { user, nomination } = this.props;
    const { forceUpdate } = this.state;
    return (
      <>
      <Formik
        initialValues={{
          nomination: {
            event_id: nomination && nomination.event_id ? nomination.event_id : (this.props.event_id ? parseInt(this.props.event_id) : null),
            contact_id: nomination && nomination.contact_id ? nomination.contact_id : (this.props.contact_id ? parseInt(this.props.contact_id) : null),
            status: nomination ? nomination.status : "draft",
            award_criteria_answers: nomination && !(typeof nomination.award_criteria_answers == 'string') ? nomination.award_criteria_answers : {answers: []},
            notes: nomination.notes || ""
          }
        }}
        onSubmit={(values, actions) => {
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");


          this.setState({
            submitting: true,
            error: false
          })

          fetch(this.formURL(), {
            method: this.formMethod(),
            redirect: "manual",
            body: JSON.stringify(values),
            headers: {
              "X-CSRF-Token": token,
              "Content-Type": 'application/json'
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                if(json.redirect_url) {
                  window.location = json.redirect_url
                }else {
                  console.log("Successful no redirect")
                  this.setState({
                    submitting: false
                  })
                }
              }
              else {
                this.setState({
                  error: json.error,
                  submitting: false
                })
              }
            });
        }}
        render={(formikProps) => (
          <>
          {this.renderForm(formikProps)}
          {this.renderSubmitModal()}
          </>
        )}
      />
      </>
    );
  }
}

export default NominationForm;
