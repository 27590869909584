import React from "react";
import PropTypes from "prop-types";
import LogItemChange from "@logs/LogItemChange"
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-timezone';
import { fetchAPI } from "@utility/NetworkUtils";
import { canViewLog } from "@utility/PermissionUtility"
import { ignoredFields } from "@logs/LogUtils"
class VerifyUpdateBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verified: !!props.update.verified_at,
      submitting: false,
    };
    this.verifyUpdate = this.verifyUpdate.bind(this);
  }

  renderChanges() {
    const { update } =this.props;
    let entries = Object.entries(
      update.changeset
    )
    if(update && update.changeset && update.changeset.id && update.changeset.id[0]==null){
      return "New Record Created"
    }
    entries = entries.filter(x=>
      ignoredFields().findIndex(y=>
         y==x[0]
       ) == -1
    )

    return entries.filter(x=> {
      return (x[1][0] || x[1][1])
    }).map(change =>
      (
        <LogItemChange fieldName={change[0]} startingState={change[1][0]} endingState={change[1][1]} />
      )
    )
  }

  renderVerifyButton() {
    const { update } = this.props;
    if(update.verified_at == null) {
      return (
        <div className="cnt-nomination-tool-verify-button-container" style={{textAlign: "right"}}>
          <button onClick={this.verifyUpdate} className="cnt-approve-button cnt-nomination-tool-verify-button" >
            Verify
          </button>
        </div>
      )
    }
  }

  verifyUpdate() {
    const { update } = this.props;
    this.setState({
      verified: true
    })
    fetchAPI(`/api/v1/updates/${update.id}/verify`,()=>{},{
      method: 'PATCH',
      body: {}
    });
  }

  render() {
    const  { verified } = this.state;
    const { update, user } = this.props;
    if(!canViewLog(user)){
      return "";
    }
    if(verified) {
      return ""
    }
    const update_user = update.user || {}
    return (
      <div className="cnt-nomination-tool-verify-update-block">
        <div className="row">
          <div className="col-xs-12 cnt-nomination-tool-verify-update-block-field">
            <b>Update made by: </b> {update_user.first_name} {update_user.last_name}
          </div>
          <div className="col-xs-12 cnt-nomination-tool-verify-update-block-field">
            <b>Time/Date: </b> {formatToTimeZone(update.created_at, "DD-MMM-YYYY, HH:mm:ss aa", {timeZone: "America/Los_Angeles"})}
          </div>
          <div className="col-xs-12 cnt-nomination-tool-verify-update-block-field">
            <br/>
            <b>Update: </b>
          </div>
          <div className="col-xs-12 cnt-nomination-tool-verify-update-block-changes">
            {this.renderChanges()}
          </div>
        </div>
        {this.renderVerifyButton()}
      </div>
    );

  }
}

export default VerifyUpdateBlock;
