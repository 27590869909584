import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import {isJaguar, isExecutiveRelationsLead,  isSolutionsLead, isAccountManager, isAdmin, getRoleName, isMarketingManager} from "@utility/PermissionUtility"
import GeneralTable from "@layout/tables/GeneralTable"
import GeneralTableDropdown from "@layout/tables/GeneralTableDropdown"
import Cookies from "universal-cookie";
import UserProfileModal from "../../users/modals/UserProfileModal"
import ResendInvitationModal from "./forms/ResendInvitationModal"
import DefaultTooltip from "@layout/tooltips/DefaultTooltip"
class UsersIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  renderCreateContactsButton() {
    const { user } = this.props;

    return (
      <a href="/admin/users/new">
        <button className="cnt-page-header-button">
         Add User
        </button>
      </a>
    )
  }

  getFilteredUsers() {
    let  {users, contacts_count, companies_count} = this.props;
    users = users.map(x=>{
      x.full_name = `${x.last_name} ${x.first_name}`.toLowerCase()
      x.full_name_normal = `${x.first_name} ${x.last_name}`.toLowerCase()
      return x;
    }).sort((a,b) => b.full_name < a.full_name ? 1 : -1).map(x=>{
      x.contacts_count = contacts_count[x.id]
      x.companies_count = companies_count[x.id]
      return x
    })

    return users;
  }


  renderEmail(row) {
    return (
      <a href={`mailto:${row.email}`} target="_blank">
        {row.email}
      </a>
    )
  }


  renderViewAll(row) {
    const { user, user_management } = this.props;

    const canUserShift = row.id != (user.id) && (isJaguar(user) || isSolutionsLead(user) || isAdmin(user))

    return (
      <div className="cnt-default-layout-nomination-table-icons">
        <UserProfileModal
          user={row}
        >
          <DefaultTooltip
            tooltipId={`sg-cnt-default-layout-table-view-tooltip-${row.id}`}
          >
            View
          </DefaultTooltip>
          <a href="#"
            data-for={`sg-cnt-default-layout-table-view-tooltip-${row.id}`}
            data-tip
          >
            <img src="/layout/view-icon.svg" />
          </a>
        </UserProfileModal>
        <DefaultTooltip
          tooltipId={`sg-cnt-default-layout-table-edit-tooltip-${row.id}`}
        >
          Edit
        </DefaultTooltip>
        <a href={`/admin/users/${row.id}`}
          data-for={`sg-cnt-default-layout-table-edit-tooltip-${row.id}`}
          data-tip
        >
          <img src="/layout/edit-icon.svg" />
        </a>
        <ResendInvitationModal
          user={row}
        >
          <DefaultTooltip
            tooltipId={`sg-cnt-default-layout-table-resend-invite-tooltip-${row.id}`}
          >
            Resend Invite
          </DefaultTooltip>
          <a
            href="#"
            data-for={`sg-cnt-default-layout-table-resend-invite-tooltip-${row.id}`}
            data-tip
          >
            <img src="/layout/resend-icon.svg" />
          </a>
        </ResendInvitationModal>
        {canUserShift ? (
          <>
          <DefaultTooltip
            tooltipId={`sg-cnt-default-layout-table-user-shift-tooltip-${row.id}`}
          >
            User Shift
          </DefaultTooltip>
          <a
            href={`/admin/users/${row.id}/become_user`}
            data-for={`sg-cnt-default-layout-table-user-shift-tooltip-${row.id}`}
            data-tip
          >
            <img src="/layout/user-shift-icon.svg" />
          </a>
          </>
        ):""}
      </div>
    );
  }

  capatalizeString(updatable_string) {
    return updatable_string.charAt(0).toUpperCase() + updatable_string.slice(1);
  }

  renderContactsCount(row) {
    switch(row.role) {
      case "jaguar":
        return "All";
      case "admin":
        return "All";
      case "solutions_lead":
        return "All";
      case "marketing_manager":
        return "All"
      default:
        return row.contacts_count
    }
  }
  renderCompaniesRow(row) {
    switch(row.role) {
      case "jaguar":
        return "All";
      case "admin":
        return "All";
      case "solutions_lead":
        return "All";
      case "marketing_manager":
        return "All"
      default:
        return row.companies_count
    }
  }


  getFilters() {
    const { user} = this.props;
    let filters =  [
      {id: null, name: "All", filterFunction:()=>{return true}},
      {id: 'ams', name: "Account Managers", filterFunction:(x,i,t)=>{ return isAccountManager(x)}},
      {id: 'erls', name: getRoleName('executive_relations_lead'), filterFunction:(x,i,t)=>{ return isExecutiveRelationsLead(x)}},
      {id: 'solutions-leads', name: "Solutions Leads", filterFunction:(x,i,t)=>{ return isSolutionsLead(x)}},
      {id: 'marketing-managers', name: "Marketing Managers", filterFunction:(x,i,t)=>{ return isMarketingManager(x)}},
    ]

    if(isJaguar(user)) {
      filters = filters.concat([
          {id: 'jaguars', name: "Jaguars", filterFunction:(x,i,t)=>{ return isJaguar(x)}},
      ])
    }
    return filters;
  }


  renderUsersTable() {
    return (
        <GeneralTable
          filters={this.getFilters()}
          showTotals={true}
          columns={[
            { title: 'Name', field: 'full_name_normal', hidden: true, searchable: true},
            { title: 'Name', field: 'full_name', render: (row) => `${row.first_name} ${row.last_name}` },
            { title: 'Email', field: 'email', render: (row)  => this.renderEmail(row)},
            { title: 'Nom Tool Role', field: 'role', render: (row) => getRoleName(row.role) },
            { title: 'Sales Role', field: 'sales_role' },
            { title: 'Sales Role - Subategory', field: 'sub_sales_role' },
            { title: 'Contacts', field: 'contacts_count', render: (row) => this.renderContactsCount(row) },
            { title: 'Companies', field: 'companies_count', render: (row) => this.renderCompaniesRow(row) },
            { export: false, title: 'Actions', field: 'id', render: (row)  => this.renderViewAll(row)}
          ]}
          data={this.getFilteredUsers()}
        />
    )
  }

  render() {
    const { user, user_management } = this.props;
    return (
      <DefaultLayout
        {...this.props}
        sidebarId={user_management ? "" : "users"}
      >
        <h1 className="cnt-page-header">
          {user_management ? "User Management" : "Users"}
          {this.renderCreateContactsButton()}
        </h1>
        {this.renderUsersTable()}
      </DefaultLayout>
    );
  }
}

export default UsersIndex;
