import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"

class NominationsShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };

  }

  renderContents() {
    const { bullets } = this.props;
    return bullets.map(x=>
      <div className="cnt-default-layout-tool-guide-section-body-bullets">
        <div className="cnt-default-layout-tool-guide-section-body-bullet-square"/>
        <div className="cnt-default-layout-tool-guide-section-body-bullet-content">
          {x}
        </div>
      </div>
    )
  }


  render() {
    const { icon, title } = this.props;
    return (
      <div className="row cnt-default-layout-tool-guide-section-container">
        <div className="col-xs-12 col-md-8">
          <div className="cnt-default-layout-tool-guide-section-header">
            <img src={icon} className="cnt-default-layout-tool-guide-section-header-icon"/>
            {title}
          </div>
        </div>
        <div className="col-xs-12 col-md-8">
          <div className="cnt-default-layout-tool-guide-section-body">
            {this.renderContents()}
          </div>

        </div>
      </div>
    );
  }
}

export default NominationsShow;
