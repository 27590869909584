import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Select from 'react-select-virtualized'
import Cookies from "universal-cookie";
class ReportDeleteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      selectedContact: null,
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.destroyContact = this.destroyContact.bind(this);
  }

  getUserOptions() {
    const { users, editing_user } = this.props;
    if(users) {
      return users.filter(x=> x.id != editing_user.id).map(x=> {return {value: x.id, label: `${x.first_name} ${x.last_name}`}})
    }
    return [];
  }

  renderModal() {
    const {editing_user, user, report, deleteReport } = this.props;
    const { selectedContact, selectErroring } = this.state;
    console.log(this.props);
    return (
      <div className="row">
        <div className="col-xs-12 ">
          <div className="cnt-admin-user-management-delete-modal-title">
              Are you sure you want to delete this Report?
          </div>
          <div className="">
            <button className="cnt-submit-button" onClick={() => deleteReport(report)}> Confirm and Delete</button>
            <button className="cnt-cancel-button"onClick={this.toggleModal}> Cancel</button>
          </div>
        </div>
      </div>
    );
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen
    });
  }

  formURL() {
    const { contact } = this.props;
    return `/contacts/${contact.id}`;
  }

  destroyContact() {
    const {onDelete, contact} = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(this.formURL(), {
      method: "DELETE",
      redirect: "manual",
      body: "",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          if(onDelete) {
            onDelete(contact.id)
          }
          this.toggleModal();
        }
        else {
          this.setState({
            error: json.error
          })
        }
      });
  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, sessions } = this.props;
    return (
      <>
        <div className={outerClassNames} style={{cursor:"pointer", display: "inline-block" }} onClick={() => this.toggleModal()} >
          {children}
        </div>
        <Modal
          className="white-background cnt-user-admin-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default ReportDeleteModal;
