import React from "react";
import PropTypes from "prop-types";
import Select from 'react-select'
import MultiSelect from 'react-select'
import ToggleOnOff from "@layout/superfilter/ToggleOnOff"
import TutorialBox from "@tutorial/TutorialBox"
import DefaultTooltip from "@layout/tooltips/DefaultTooltip";
import { removeUrlParam, updateUrlParams, urlParamsToObject } from "@utility/UrlUtils"
class SuperFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterValues: props.defaultValues ? props.defaultValues : {}
    }
    this.getSelectedFilters = this.getSelectedFilters.bind(this);
    this.setFilterValue = this.setFilterValue.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.setMultiSelectFilterValue = this.setMultiSelectFilterValue.bind(this);
  }

  componentDidMount() {
    const { onChange } = this.props;
    this.manageLockOnMount();
    if(onChange) {
      onChange(this.getSelectedFilters());
    }
  }

  manageLockOnMount() {
    const { urlLock } = this.props;
    if(urlLock) {
      let values = this.getUrlLockedFilterValues()
      this.state.filterValues = values;
      this.setState({
        filterValue: this.getUrlLockedFilterValues()
      })
    }
  }

  updateUrlLock() {
    const { urlLock } = this.props;
    const { filterValues } = this.state;
    updateUrlParams(filterValues);
  }

  getUrlLockedFilterValues() {
    const { urlLock, defaultValues, filters} = this.props;
    if(urlLock) {
      try {
          let searchObject = urlParamsToObject()
          Object.keys(searchObject).map(key=> {
            const filter = filters.find(y=>y.id == key)
            if(key && filter) {
              if(filter['type']=='multiselect') {
                searchObject[key]=((searchObject[key]||"").split(","))
              }
            }
            if (searchObject[key] === null || searchObject[key]===undefined || searchObject[key]=='') {
              delete searchObject[key];
            }
          })
          return searchObject;
      } catch(e) {
        console.log("[Error][SuperFilter][UrlLock] Unable to fetch super filter lock")
        console.log(e);
      }
    }
    return defaultValues || {}
  }

  /******
  * Filter Schema (object)
  * id: Unique ID
  * type: onoff, select,
  * Options (array, object): {id, label, filterFunction, filterType(optional)}
  * outerClassName(optional)
  * innerClassName(optional)
  * placeholder(optional)
  *****/
  renderFilters() {
    const { filters } = this.props;
    return filters.map((f,index)=>
      <div
        key={`super-filter-${index}-${f.id}`}
        className={
          `${f.outerClassName} ${this.isFilterSelected(f.id) ? "cnt-super-filter-active" : "cnt-super-filter-inactive"}`
        }
      >
        {this.renderFilter(f,index)}
      </div>
    )
  }

  getSelectedFilters() {
    const {filters} = this.props;
    const {filterValues} = this.state;
    const selectedFilterKeys = Object.keys(filterValues).filter(x=>filterValues[x]);
    return filters.filter(x=> selectedFilterKeys.indexOf(x.id) != -1).map(f1=> {
      const option = f1.options.find(f2=>f2.id == filterValues[f1.id])
      return {
        filterType: option ? option.filterType : null,
        filterID: f1.id,
        filterOptionID: option ? option.id : null,
        filterFunction: option ? option.filterFunction : null,
        filterValue: filterValues[f1.id]
      }
    })
  }

  isFilterSelected(id) {
    const {filterValues} = this.state;
    const selectedFilterKeys = Object.keys(filterValues).filter(x=>filterValues[x]);
    return selectedFilterKeys.findIndex(x=> x == id) != -1;
  }

  setMultiSelectFilterValue(filterObject,value) {
    const  { defaultValues } = this.props;
    if((value||[]).length > 0){
      const recentlyAddedValue=value[value.length - 1]
      const recentlyAddedOption = filterObject.options.find(x=>x.id == recentlyAddedValue.value);
      if(recentlyAddedOption['solo']) {
        value = [recentlyAddedValue]
      }else {
        value = value.filter(x=> {
          const xobject = filterObject.options.find(y=>y.id == x.value);
          return !xobject.solo
        })
      }
    }else {
      value = [{value: null}]
    }

    this.setFilterValue(filterObject.id, (value||[]).map(x=>x.value));
  }

  setFilterValue(name,value) {
    let { filterValues } = this.state;
    const { onChange } = this.props;
    if(value == null) {
      if(this.isUrlLocked()){removeUrlParam(name)}
      delete filterValues[name];
    }else {
      filterValues[name] = value;
    }
    if(onChange) {
      onChange(this.getSelectedFilters());
    }
    if(this.isUrlLocked()){this.updateUrlLock()}
    this.setState({
      filterValues
    })

  }

  isUrlLocked() {
    const { urlLock } = this.props;
    return urlLock;
  }

  renderFilter(filterObject,index) {
    const {filterValues} = this.state;
    const value = filterValues[filterObject.id];
    switch(filterObject.type) {
      case "onoff":
        return (
          <div className={filterObject.innerClassName}>
            <div style={{color: "#7C7C7C", display:"flex", alignItems:"center"}}>
              {filterObject.label}
              <ToggleOnOff
                value={value=="true"}
                onClick={
                  ()=>this.setFilterValue(filterObject.id, value == "true" ? "false" : "true")
                }/>
            </div>
          </div>
        )
      case "select":
        return (
          <Select
            clearable={false}
            options={
              filterObject.options.map(x=> {
                return {
                  value: x.id,
                  label: x.label
                }
              })
            }
            classNamePrefix='cnt-default-layout-super-filter-select'
            placeholder={filterObject.placeholder}
            onChange={(e)=>this.setFilterValue(filterObject.id, e.value)}
            value={
              this.getSelectFilterValue(filterObject, value)
            }
          />
      );
      case "multiselect":
      return (
          <MultiSelect
            options={
              filterObject.options.map(x=> {
                return {
                  value: x.id,
                  label: x.label
                }
              })
            }
            onChange={(value) =>
              this.setMultiSelectFilterValue(filterObject, value)
            }
            value ={(value||[]).map(x=>this.getSelectFilterValue(filterObject,x))}
            closeMenuOnSelect={false}
            isMulti
            classNamePrefix='cnt-default-layout-super-filter-select'
          />
      );
      default:
        return (<div className="cnt-error"> Must Select a type for a filter.</div>)
    }
  }

  getSelectFilterValue(filter,value) {
    if(value) {
      const foundValue = filter.options.find(x=>x.id == value)
      if(foundValue) {
        return {
          label: foundValue.label,
          value: foundValue.id
        }
      }
    }
    return null
  }

  renderResetFilters() {
    const {filterValues} = this.state;
    const count = Object.keys(filterValues||{}).filter(x=> filterValues[x])
    return (
      <>
      <DefaultTooltip
        tooltipId={`sg-cnt-default-super-filter-reset-button`}
      >
        Reset Filters
      </DefaultTooltip>

      <img
        data-for={`sg-cnt-default-super-filter-reset-button`}
        data-tip
        className="cnt-superfilter-reset-button"
        src={count.length > 0 ? "/layout/superfilter/reset-filters.svg" : "/layout/superfilter/reset-filters-gray.svg"}
        onClick={this.resetFilters}
      />
      </>
    )
  }

  resetFilters() {
    const { onChange } = this.props;

    if(onChange) {
      onChange([]);
    }
    this.setState({
      filterValues: {}
    })
  }

  render() {
    const { value } = this.props;
    return (
      <div className="cnt-default-layout-super-filter row">
        <TutorialBox stateID={'superfilter'}/>
        {this.renderFilters()}
        {this.renderResetFilters()}
      </div>
    );
  }
}

export default SuperFilter;
