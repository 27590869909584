// Formik wrapper of react-datepicker
// Shamelessly copied wholesale with minor tweaks from: https://stackoverflow.com/a/58650742

import React, { useState } from "react";
import { useField, useFormikContext } from "formik";
import RichTextEditor, { createValueFromString } from "react-rte";

const RichTextField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const [formValue, setFormValue] = useState(
    createValueFromString(field.value, "markdown")
  );

  const onChange = val => {
    const mdValue = val.toString("markdown");
    const mdValueStripped = (mdValue||"").trim().replaceAll('\n','').replace(/[\u0000-\u001F\u007F-\u009F]/g, "").replace(/[^ -~]+/g, "");
    if(mdValueStripped.length == 0){
      setFieldValue(field.name, "");
      setFormValue(val);
    }else {
      setFieldValue(field.name, mdValue);
      setFormValue(val);
    }
  };

  const forceValue = val => {
    setFormValue(val);
  };

  const toolbarConfig = {
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "BLOCK_TYPE_DROPDOWN",
      "HISTORY_BUTTONS"
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" }
    ]
  };

  return (
    <RichTextEditor
      value={formValue}
      onChange={onChange}
      placeholder={props.placeholder||""}
      className="cms-form-rich-text-editor"
      toolbarConfig={toolbarConfig}
    />
  );
};

export default RichTextField;
