export function numberWithSuffix(number) {
  const digits = Math.floor(Math.log10(number)) + 1
  let workingExponent = 0;
  let suffix = ""
  if(digits >= 4) {
    workingExponent = 3;
    suffix = "Thousand"
  }
  if(digits >= 7) {
    workingExponent = 6
    suffix = "Million"
  }
  if(digits >= 10) {
    workingExponent = 9
    suffix = "Billion"
  }
  const sciNoteNumber = number / (Math.pow(10,workingExponent))
  return `${sciNoteNumber.toFixed(2)}${suffix ? " ":""}${suffix}`
}
