/**
 * Convert CSS styles to a JavaScript object.
 * @param {string} styles - The CSS styles to be converted.
 */
export function convertCssToObject(styles) {
  try {
    var regex = /([\w-]*)\s*:\s*([^;]*)/g;
    var match, properties={};
    while(match=regex.exec(styles)) properties[match[1]] = match[2].trim();
    console.log({
      properties,
      styles,
      match
    })
    return properties
  }catch(e){
    console.log(e);
    return {}
  }
}