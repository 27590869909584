import React from "react";
import PropTypes from "prop-types";
import Sidebar from "./Sidebar"
import {generateBreadCrumbObjectById} from "@utility/PathUtility"
import UniversalImageCircle from "@images/UniversalImageCircle"
import TutorialController from "@tutorial/TutorialController"
import TutorialBox from "@tutorial/TutorialBox"
import eventBus from "@tutorial/EventBus"
import {isAccountManager, isMarketingManager} from "@utility/PermissionUtility"
import Footer from "@layout/footer/Footer"
import MobileHeader from "@layout/header/MobileHeader"
import { checkUserLoggedIn } from "@utility/NetworkUtils"
class DefaultLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  renderBreadCrumbs() {
    let { breadcrumbs } = this.props;
    breadcrumbs = breadcrumbs || [];
    let breadcrumbsRendered = breadcrumbs.map(x=>this.renderBreadcrumb(x)).reduce((r, a) => r.concat(a, (<div className="cnt-default-layout-breadcrumb-seperator"> > </div>)), [null]);

    return (
      <div className="cnt-default-layout-breadcrumbs">
        {breadcrumbsRendered}
      </div>
    )
  }

  componentDidMount() {
    const { sidebarId, user } = this.props;
    if(sidebarId == 'dashboard' && !user.tutorial_completed_at && isAccountManager(user)) {
      eventBus.dispatch("tutorialStart", {})
    }
    setInterval(function() {
      checkUserLoggedIn()
    }, 2 * 60 * 1000);
  }

  renderBreadcrumb(breadcrumbObject) {
    if(typeof breadcrumbObject == "string") {
      breadcrumbObject = generateBreadCrumbObjectById(breadcrumbObject);
    }
    return (
      <a href={breadcrumbObject.url} className="ctn-default-layout-breadcrumb">
        {breadcrumbObject.name}
      </a>
    )
  }

  getTutorialType() {
    const { user } = this.props;
    if(isAccountManager(user)) {
      return "am"
    }else if(isMarketingManager(user)) {
      return "mm"
    }else {
      return "ae"
    }
  }

  renderTutorial() {
    const { user } = this.props;
    return (
      <>
        <TutorialController tutorialType={this.getTutorialType()}/>
        <TutorialBox stateID={'welcome'}/>
        <TutorialBox stateID={'finish'}/>
      </>
    )
  }


  render() {
    const { user, classNames } = this.props;
    return (
      <div id="cnt-default-layout-container">
        <Sidebar {...this.props} />
        <div id="page-content-wrapper" className={`${(classNames||{}).wrapper}`}>
          <MobileHeader {...this.props}/>
          <div className="container-fluid cnt-page-content-wrapper-inner">
            {this.renderTutorial()}
            {this.renderBreadCrumbs()}
            {this.props.children}
          </div>
          <Footer {...this.props}/>
        </div>

      </div>
    );
  }
}

export default DefaultLayout;
