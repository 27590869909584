import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import {DatePickerField} from './DatePickerField'
import Cookies from "universal-cookie";
import {isJaguar, canEditEvents, canDeleteEvents} from "@utility/PermissionUtility"
import DropzoneComponent from "@images/DropzoneComponent"
import UniversalImageCircle from "@images/UniversalImageCircle"
import Checkbox from "@layout/forms/Checkbox"
import RichTextField from "@layout/forms/RichTextField";
import { TagsInput } from "react-tag-input-component";

import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime, listTimeZones } from 'date-fns-timezone';
import { format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
class EventForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      icon: null,
      iconPreview: null,
    };
    this.destroyEvent = this.destroyEvent.bind(this);
    this.setImage = this.setImage.bind(this);
  }

  isEditing() {
    const { event } = this.props;
    return !!event.id;
  }

  renderField(name,formikProps, required=false,customValidation = false, properties = {}) {
    const { user } = this.props;
    const { checkError } = this.state;
    const erroring = required && checkError && (!formikProps.values.event[name] || formikProps.values.event[name].length <= 0 || customValidation);

    return (
      <Field
        onChange={(e) => {
            const { event } = formikProps.values;
            event[name] = e.target.value
            formikProps.setFieldValue('event', event);
            this.setState({
              dirty: true,
            });
          }
        }
        disabled={!canEditEvents(user)}
        component={properties['component']}
        className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`event[${name}]`}
      />
    )
  }

  renderDateField(name,formikProps, required = false) {
    const { checkError } = this.state;
    const erroring = required && checkError && (!formikProps.values.event[name] || formikProps.values.event[name].length <= 0 || customValidation);

    return (
      <Field
        onChange={(e) => {
            const { event } = formikProps.values;
            event[name] = e.target.value
            formikProps.setFieldValue('event', event);
            this.setState({
              dirty: true,
            });
          }
        }
        autoComplete="off"
        component={properties['component']}
        className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`event[${name}]`}
      />
    )
  }

  setImage(name, file, preview, tagList) {
    this.setState({
      icon: file,
      iconPreview:preview,
      dirty: true,
    });
  }

  renderForm(formikProps) {
    const { error } = this.state;
    const { user, event } = this.props;
    return (
      <Form className="cnt-default-layout-event-form" >
        <div className="row">
          <div className="col-xs-12">
            <label className="required">Mandatory Fields are marked with a </label>
          </div>
          <div className="col-xs-12">
            {this.renderEventPhotoUpload()}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Event Name
              </label>
              {this.renderField('name', formikProps, true)}
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-4">
                <div className="cnt-default-layout-event-form-input-container">
                  <label className="required">
                    Location
                  </label>
                  {this.renderField('location', formikProps, true)}
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <div className="cnt-default-layout-event-form-input-container">
                  <label className="required">
                    Venue Country
                  </label>
                  {this.renderField('venue_country', formikProps, true)}
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <div className="cnt-default-layout-event-form-input-container">
                  <label className="">
                    Theater
                  </label>
                  {this.renderField('theater', formikProps, false)}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-md-6 cnt-default-layout-event-form-input-container">
                <label className="required">
                  Start Date
                </label>
                <DatePickerField name="event[start_date]" disabled={!canEditEvents(user)}/>
              </div>
              <div className=" col-xs-12 col-md-6 cnt-default-layout-event-form-input-container">
                <label className="">
                  End Date
                </label>
                <DatePickerField name="event[end_date]" disabled={!canEditEvents(user)}/>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <label> Tags (press enter to add a new tag)</label>
                <TagsInput
                  value={formikProps.values.event.tag_list||[]}
                  onChange={(e)=>{
                    const { event } = formikProps.values;
                    event['tag_list'] = e;
                    formikProps.setFieldValue('event', event);
                    this.setState({
                      dirty: true,
                    });
                  }}
                  name="tag_list"
                  placeHolder="Enter Tags"
                />
              </div>
            </div>
            {isJaguar(user) ? (
              <div className="row">
                <div className="col-xs-12">
                  <Checkbox
                    value={!!formikProps.values['options']['show_source']}
                    onClick={()=>{
                      let nO = formikProps.values['options'];
                      let value = nO['show_source']
                      nO['show_source']= !value;
                      formikProps.setFieldTouched('options', nO);
                      this.setState({
                        dirty: true,
                      });
                    }}
                    label={"Show Source"}
                  />
                </div>
              </div>
            ):""}
            {isJaguar(user) ? (
              <div className="row">
                <div className="col-xs-12">
                  <Checkbox
                    value={!!formikProps.values['options']['show_segment_p24']}
                    onClick={()=>{
                      let nO = formikProps.values['options'];
                      let value = nO['show_segment_p24']
                      nO['show_segment_p24']= !value;
                      formikProps.setFieldTouched('options', nO);
                      this.setState({
                        dirty: true,
                      });
                    }}
                    label={"Show Segment (P24)"}
                  />
                </div>
              </div>
            ):""}
            {isJaguar(user) ? (
              <div className="row">
                <div className="col-xs-12">
                  <Checkbox
                    value={!!formikProps.values['options']['show_submitter']}
                    onClick={()=>{
                      let nO = formikProps.values['options'];
                      let value = nO['show_submitter']
                      nO['show_submitter']= !value;
                      formikProps.setFieldTouched('options', nO);
                      this.setState({
                        dirty: true,
                      });
                    }}
                    label={"Show Submitter"}
                  />
                </div>
              </div>
            ):""}
            {isJaguar(user) ? (
              <div className="row">
                <div className="col-xs-12">
                  <Checkbox
                    value={!!formikProps.values['options']['show_priority']}
                    onClick={()=>{
                      let nO = formikProps.values['options'];
                      let value = nO['show_priority']
                      nO['show_priority']= !value;
                      formikProps.setFieldTouched('options', nO);
                      this.setState({
                        dirty: true,
                      });
                    }}
                    label={"Show Priority"}
                  />
                </div>
              </div>
            ):""}

            {isJaguar(user) ? (
              <div className="row">
                <div className="col-xs-12">
                  <Checkbox
                    value={!!formikProps.values['event']['private']}
                    onClick={()=>{
                      let nO = formikProps.values['event'];
                      let value = nO['private']
                      nO['private']= !value;
                      formikProps.setFieldTouched('event', nO);
                      this.setState({
                        dirty: true,
                      });
                    }}
                    label={"Private Event"}
                  />
                </div>
              </div>
            ):""}

            {isJaguar(user) ? (
              <div className="row">
                <div className="col-xs-12">
                  <Checkbox
                    value={!!formikProps.values['options']['show_vip']}
                    onClick={()=>{
                      let nO = formikProps.values['options'];
                      let value = nO['show_vip']
                      nO['show_vip']= !value;
                      formikProps.setFieldTouched('options', nO);
                      this.setState({
                        dirty: true,
                      });
                    }}
                    label={"Show VIP"}
                  />
                </div>
              </div>
            ):""}

            {isJaguar(user) ? (
              <div className="row">
                <div className="col-xs-12">
                  <Checkbox
                    value={!!formikProps.values['options']['show_opt_out']}
                    onClick={()=>{
                      let nO = formikProps.values['options'];
                      let value = nO['show_opt_out']
                      nO['show_opt_out']= !value;
                      formikProps.setFieldTouched('options', nO);
                      this.setState({
                        dirty: true,
                      });
                    }}
                    label={"Show Opt Out"}
                  />
                </div>
              </div>
            ):""}



          </div>
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                {event && event.options&& event.options.criteria_header_override ? event.options.criteria_header_override : "Event Criteria"}
              </label>
              <RichTextField
                formikProps={formikProps}
                name={`event[description]`}
                style={{minHeight:"100px"}}
                disabled={!canEditEvents(user)}
              />
            </div>
          </div>
          <div className="col-xs-12 cnt-default-layout-event-form-error">
            {error}
          </div>
          {this.renderButtons(formikProps)}

        </div>
      </Form>
    )
  }

  renderEventPhotoUpload(formikProps) {
    const { iconPreview } = this.state;
    const { inlinePhoto } = this.props;
    if((!this.isEditing()) || inlinePhoto){
      return (
        <div className={inlinePhoto ?  "cnt-contact-form-upload-picture-inline" : "cnt-contact-form-upload-picture"}>
          <DropzoneComponent outerStyle={{display:"inline-block"}} setFiles={this.setImage}>
            <div className="col-xs-12">
              <UniversalImageCircle id={0} image={iconPreview}/>
              <a href="#" style={{marginTop:'5px', display: 'inline-block'}}>
                Upload Photo
              </a>
            </div>
          </DropzoneComponent>
        </div>
      )
    }
  }


  renderButtons(formikProps) {
    const { dirty } = this.state;
    const { user } = this.props;
    if(canEditEvents(user)) {
      return (
        <div className="col-xs-12" style={{paddingTop: "20px"}}>
          {!(formikProps.dirty || dirty) ?
            (
              <button disabled={true} type="button" class="cnt-dirty-button"> Save </button>
            )
          : (
            <button
              type="button"
              onClick={() => formikProps.handleSubmit()}
              className="cnt-submit-button"
            > Save </button>
          )}

          <a href="/events"><button type="button" className="cnt-cancel-button "> Cancel </button></a>
          {this.isEditing()  && canDeleteEvents(user) ? (<button onClick={this.destroyEvent} type="button" className="cnt-delete-button"> Delete </button>) : ""}
        </div>
      )
    }
  }

  destroyEvent() {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    if(!confirm("Are you sure you want to destroy this event?")) {
      return;
    }
    fetch(this.formURL(), {
      method: "DELETE",
      redirect: "manual",
      body: null,
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          if(json.redirect_url) {
            window.location = json.redirect_url
          }else {
            console.log("Successful no redirect")
          }
        }
        else {
          this.setState({
            error: json.error
          })
        }
      });
  }

  formMethod() {
    return this.isEditing() ? "PATCH" : "POST"
  }

  formURL() {
    const { event } = this.props;
    return this.isEditing() ? `/events/${event.id}` : `/events`;
  }

  getQualifyingQuestion(event) {
    if(event && event.award_criteria && event.award_criteria.questions) {
      return event.award_criteria.questions[0];
    }
    return "";
  }

  render() {
    const { user, event } = this.props;
    const { icon } = this.state;
    return (
      <>
      <Formik
        initialValues={{
          event: {
            name: event.name || "",
            location: event.location || "",
            start_date: event.start_date ?   new Date(formatToTimeZone(new Date(event.start_date), "MM/DD/YYYY",  {timeZone: "America/Los_Angeles"})) : "",
            end_date: event.end_date ?   new Date(formatToTimeZone(new Date(event.end_date), "MM/DD/YYYY",  {timeZone: "America/Los_Angeles"})) : "",
            description: event.description || "",
            venue_country: event.venue_country || "",
            theater: event.theater || "",
            private: event.private || false,
            tag_list: event.tag_list || []
          },
          options: event.options || {},
        }}
        onSubmit={(values, actions) => {
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          console.log("submitting")

          var form_data = new FormData();
          if(icon) {
            form_data.append('event[icon]', icon);
          }
          for ( var key in values['options'] ) {
            form_data.append(`event[options][${key}]`, values['options'][key]);
          }
          for ( var key in values['event'] ) {
            if(values['event'][key] && (key == 'start_date' || key=="end_date")){

              const value = format(
                values['event'][key],
                'MMM d yyyy'
              )+" 01:00:00 PST/PDT"
              form_data.append(
                `event[${key}]`,
                value
              );
            }else {
              form_data.append(`event[${key}]`, values['event'][key]);
            }

          }

          fetch(this.formURL(), {
            method: this.formMethod(),
            redirect: "manual",
            body: form_data,
            headers: {
              "X-CSRF-Token": token
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                if(json.redirect_url) {
                  window.location = json.redirect_url
                }else {
                  console.log("Successful no redirect")
                }
              }
              else {
                this.setState({
                  error: json.error
                })
              }
            });
        }}
        render={(formikProps) => (
          <>
          {this.renderForm(formikProps)}
          </>
        )}
      />
      </>
    );
  }
}

export default EventForm;
