import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime, listTimeZones } from 'date-fns-timezone';
import { isAccountManager } from "../../utility/PermissionUtility";
import {getAmProvidedInformation} from "@contacts/forms/ContactUtils"
class ContactCondensedView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: props.contact ? this.mapContact(props.contact) : null
    };
    this.renderField = this.renderField.bind(this);
    this.renderSections = this.renderSections.bind(this);
  }

  componentDidMount() {
    const { contact, contactId } = this.props;
    if(!contact && contactId) {
      const url = `api/v1/contacts/${contactId}/form`
      fetchAPI(url, (json)=>{
        if(json.contact) {
          this.setState({
            contact: this.mapContact(json.contact)
          })
        }
      },{})
    }
  }

  mapContact(contact) {
    contact.am_provided_company_name = getAmProvidedInformation('company_name', contact, {
      prefer_contact: true
    })
    contact.am_provided_area = getAmProvidedInformation('area', contact, {
      prefer_contact: true
    })
    contact.am_provided_theater = getAmProvidedInformation('theater', contact, {
      prefer_contact: true
    })
    contact.am_provided_segment = getAmProvidedInformation('segment', contact, {
      prefer_contact: true
    })
    return contact
  }

  isAmView() {
    const {amView, user} = this.props;
    return ((user && isAccountManager(user)) || amView) 
  }

  getFieldSections() {
    const {amView, user} = this.props;
    if(this.isAmView()) {
      return [
        {
          name: "Contact Information",
          fields: [
            {id: 'first_name', name: 'First Name'},
            {id: 'middle_name', name: 'Middle'},
            {id: 'last_name', name: 'Last Name'},
            {id: 'company_id', name: 'Company', render: (row)=>{return row.company ? row.company.name : row.company_id}},


            {id: 'job_title', name: 'Official Job Title'},
            {id: 'job_level', name: ' Job Level'},
            {id: 'email', name: 'Email'},

            {id: 'assistant_name', name: 'Assistant Name'},
            {id: 'assistant_email', name: 'Assistant Email'},
            {id: "preferred_am_first_name", name: 'Preferred AM First Name'},
            {id: "preferred_am_last_name", name: 'Preferred AM Last Name'},
            {id: "preferred_am_email", name: 'Preferred AM Email'},

            {id: 'executive_sponsor', name: 'Executive Sponsor'},
          ]
        },
        {
          name:"Company Information",
          fields:[
            {id: 'am_provided_company_name', name: 'Company Name'},
            {id: 'am_provided_area', name: 'Company Area'},
            {id: 'am_provided_theater', name: 'Theater'},
            {id: 'am_provided_segment', name: 'Tier / Segment'},
          ]
        }
      ]
    }
    return [
      {
        name: "Contact Information",
        fields: [
          {id: 'first_name', name: 'First Name'},
          {id: 'middle_name', name: 'Middle'},
          {id: 'last_name', name: 'Last Name'},
          {id: "contact_type", name:'Contact Type'},
          {id: 'company_id', name: 'Company', render: (row)=>{return row.company ? row.company.name : row.company_id}},
          {id: 'name_prefix', name: 'Name Prefix'},
          {id: 'name_suffix', name: 'Name Suffix'},
          {id: 'job_title', name: 'Official Job Title'},
          {id: 'job_level', name: ' Job Level'},
          {id: 'email', name: 'Email'},
          {id: 'mobile_phone', name: 'Mobile Phone'},
          {id: 'business_phone', name: 'Business Phone'},
          {id: 'assistant_name', name: 'Assistant Name'},
          {id: 'assistant_email', name: 'Assistant Email'},
          {id: 'assistant_phone', name: 'Assistant Phone'},
          {id: 'invitee_type', name: 'Invitee Type'},
          {id: 'relationship_with_cisco', name: 'Relationship with Cisco'},
          {id: 'beid', name: "BE ID"},
          {id: 'notes', name: 'Notes'},
        ]
      },
      {
        name: "Addresses",
        fields: [
          {id: 'address_country', name: 'Address Country'},
          {id: 'address_line_1', name: 'Address 1'},
          {id: 'address_line_2', name: 'Address 2'},
          {id: 'address_city', name: 'City'},
          {id: 'address_state', name: 'State'},
          {id: 'postal_code', name: 'Postal Code'},
          {id: 'home_address_country', name: 'Shipping Country'},
          {id: 'home_address_line_1', name: 'Shipping Address 1'},
          {id: 'home_address_line_2', name: 'Shipping Address 2'},
          {id: 'home_address_city', name: 'Shipping City'},
          {id: 'home_postal_code', name: 'Shipping Postal Code'},
          {id: 'home_address_state', name: 'Shipping State'},
          {id: 'invitee_type', name: 'Invitee Type'},
          {id: 'relationship_with_cisco', name: 'Relationship with Cisco'},
        ]
      },
      {
        name: "Contact Account Information",
        fields: [

          {id: 'created_at', name: 'Contact Created At', render:(row)=>formatToTimeZone(row.created_at, "MMM DD, YYYY HH:mm aa", {timeZone: "America/Los_Angeles"})},
          {id: 'created_by_id', name: 'Contact Created By', render: (row)=>{return row.created_by ? `${row.created_by.first_name} ${row.created_by.last_name}` : row.created_by_id}},
          {id: 'updated_at', name: 'Contact Updated At', render: (row)=>formatToTimeZone(row.updated_at, "MMM DD, YYYY HH:mm aa", {timeZone: "America/Los_Angeles"})},
          {id: 'updated_by_id', name: 'Contact Updated By', render: (row)=>{return row.updated_by ? `${row.updated_by.first_name} ${row.updated_by.last_name}` : row.updated_by_id}},
          {id: 'am_updated_at', name: 'Am Validation Date', render: (row)=>formatToTimeZone(row.am_updated_at, "MMM DD, YYYY HH:mm aa", {timeZone: "America/Los_Angeles"})},
          {id: 'am_updated_by_id', name: 'Am Validation By', render: (row)=>{return row.am_updated_by ? `${row.am_updated_by.first_name} ${row.am_updated_by.last_name}` : row.am_updated_by_id}},
        ]
      }
    ]
  }

  getValidFieldSections() {
    const { contact } = this.state;
    let fieldSections = this.getFieldSections();
    fieldSections = fieldSections.map(section => {
      section.fields = section.fields.filter(field=>
        contact[field.id]
      )
      return section;
    }).filter(section  => section.fields.length > 0);
    return fieldSections;
  }


  renderSections() {
    const { disableHeaders } = this.props;
    const sections = this.getValidFieldSections();
    return sections.map(section=> (
      <div className="cnt-condensed-model-section">
        {disableHeaders ? "" : (
          <div className="cnt-condensed-model-section-header">
            {section.name}
          </div>
        )}
        <div className="cnt-condensed-model-section-fields">
          {section.fields.map((field)=>this.renderField(field))}
        </div>
      </div>
    ))
  }

  renderField(field) {
    const { contact } = this.state;
    return (
      <div className="cnt-condensed-modal-section-field">
        <div className="cnt-condensed-modal-section-field-name">
          {field.name}:{" "}
        </div>
        <div className="cnt-condensed-modal-section-field-value">
          {field.render ? field.render(contact) : contact[field.id]}
        </div>
      </div>
    )
  }

  render() {
    const { contact } = this.state;
    return (
      <div className="cnt-condensed-model-container">
        {contact ? this.renderSections() : ""}
      </div>
    );
  }
}


export default ContactCondensedView;
