import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Select from 'react-select-virtualized'
import Cookies from "universal-cookie";
class NominationDeleteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.destroyNomination = this.destroyNomination.bind(this);
  }

  renderModal() {
    const { selectErroring } = this.state;
    return (
      <div className="row">
        <div className="col-xs-12 ">
          <div className="cnt-admin-user-management-delete-modal-title">
              Are you sure you want to delete this nomination?
          </div>
          <div className="">
            <button className="cnt-submit-button" onClick={() => this.destroyNomination()}> Confirm and Delete</button>
            <button className="cnt-cancel-button"onClick={this.toggleModal}> Cancel</button>
          </div>
        </div>
      </div>
    );

  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen
    });
  }

  formURL() {
    const { nomination } = this.props;
    return `/nominations/${nomination.id}`;
  }

  destroyNomination() {
    const {onDelete, nomination} = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(this.formURL(), {
      method: "DELETE",
      redirect: "manual",
      body: "",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          if(onDelete) {
            onDelete(nomination.id)
          }
          this.toggleModal();
        }
        else {
          this.setState({
            error: json.error
          })
        }
      });
  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, sessions } = this.props;
    return (
      <>
        <div className={`cnt-modal-openable-button ${outerClassNames}`} onClick={this.toggleModal} >
          {children}
        </div>
        <Modal
          className="white-background cnt-user-admin-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          {modalOpen ? (
            this.renderModal()
          ):""}

        </Modal>
      </>
    );
  }
}

export default NominationDeleteModal;
