import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Select from 'react-select-virtualized'
import Cookies from "universal-cookie";
import { downloadPages } from "@utility/TableUtils";

class ContactDeleteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      selectedContact: null,
      contacts: null,
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.destroyContact = this.destroyContact.bind(this);
    this.getContacts = this.getContacts.bind(this);
  }

  getContacts() {
    const { contacts } = this.props;
    if(!contacts){
      this.state.contacts = []
      downloadPages(
        '/api/v1/contacts/table',
        (x) => {
          const { contacts } = this.state;
          this.setState({
            contacts: this.state.contacts.concat(x),
          })
        }
      );
    }
  }

  getUserOptions() {
    const { users, editing_user } = this.props;
    if(users) {
      return users.filter(x=> x.id != editing_user.id).map(x=> {return {value: x.id, label: `${x.first_name} ${x.last_name}`}})
    }
    return [];
  }

  renderModal() {
    const {editing_user, user, contacts, contact } = this.props;
    const { selectedContact, selectErroring } = this.state;
    return (
      <div className="row">
        <div className="col-xs-12 ">
          <div className="cnt-admin-contact-delete-modal-title">
              Are you sure you want to delete this contact?
          </div>
          <div className="cnt-admin-contact-delete-modal-info">
            {contact.first_name || contact.last_name ? (
              <div>
                <b>
                  {contact.first_name} {contact.last_name}
                </b>
              </div>
            ):""}
            {contact.job_title ? (
              <div>
                {contact.job_title}
              </div>
            ):""}
            {contact.company_name || (contact.company && contact.company.name) ? (
              <div>
                {contact.company_name || (contact.company && contact.company.name)}
              </div>
            ):""}
            {contact.email ? (
              <div>
                {contact.email}
              </div>
            ):""}
          </div>
          <div className="cnt-admin-contact-delete-modal-note ">
            <span> Note: </span>
            Deleting the contact will delete the contact’s nominations and nomination history.
          </div>
          <div className="">
            <button style={{marginLeft: "0"}} className="cnt-delete-button" onClick={() => this.destroyContact()}> Confirm and Delete</button>
            <button className="cnt-cancel-button"onClick={this.toggleModal}> Cancel</button>
          </div>
        </div>
      </div>
    );
  }


  getContactOptions() {
    const { contacts } = this.state;
    const { contact } = this.props;
    if(contacts) {
      return contacts.filter(x=> x.id != contact.id).map(x=> {return {value: x.id, label: `${x.first_name} ${x.last_name}`}})
    }else {
      return (this.props.contacts||[]).filter(x=> x.id != contact.id).map(x=> {return {value: x.id, label: `${x.first_name} ${x.last_name}`}})
    }
    return [];
  }


  toggleModal() {
    const { modalOpen } = this.state;
    if(!modalOpen) {
      this.getContacts();
    }
    this.setState({
      modalOpen: !modalOpen
    });
  }

  formURL() {
    const { contact } = this.props;
    return `/contacts/${contact.id}`;
  }

  destroyContact() {
    const {onDelete, contact} = this.props;
    const { selectedContact } = this.state;
    const cookies = new Cookies();
    const selectedContactID = selectedContact ? selectedContact.value : null
    const token = cookies.get("X-CSRF-Token");
    fetch(this.formURL(), {
      method: "DELETE",
      redirect: "manual",
      body: JSON.stringify({
        replacement_id: selectedContactID
      }),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        if(onDelete) {
          onDelete(contact.id,{selectedContactID: selectedContactID})
        }
        this.toggleModal();
      }
      else {
        this.setState({
          error: json.error
        })
      }
    });
  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, sessions, outerStyle } = this.props;
    return (
      <>
        <div className={outerClassNames} style={{cursor:"pointer", display: "inline-block",...(outerStyle||{}) }} onClick={() => this.toggleModal()} >
          {children}
        </div>
        <Modal
          className="white-background cnt-user-admin-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          {modalOpen ? (
            this.renderModal()
          ):""}
        </Modal>
      </>
    );
  }
}

export default ContactDeleteModal;
