import React from "react";
import PropTypes from "prop-types";
import MaterialTable from 'material-table';
import {caseInsensitiveSort, customSearch} from "@utility/TableUtils"
import Cookies from "universal-cookie";
import TutorialBox from "@tutorial/TutorialBox"
import GeneralTablePagination from "./TableOverwrites/GeneralTablePagination"
import TablePagination from "@material-ui/core/TablePagination";
import { getUrlPage, updateUrlParam } from "@utility/UrlUtils"
class GeneralTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilter: null,
    };
    this.storeRowsPerPage = this.storeRowsPerPage.bind(this);
    this.tableRef = React.createRef();
        this.onChangePage = this.onChangePage.bind(this);
  }

  getColumns() {
    const { columns } = this.props;
    return columns.map(x=> {
      if(!x.customSort && x.field) {
        x.customSort = ((a,b) => {
          const fieldA = a[x.field]
          const fieldB = b[x.field]
          return caseInsensitiveSort(fieldA, fieldB)
        });
      }

      if(x.sorting != false && !x.customFilterAndSearch) {
        x.customFilterAndSearch = customSearch
      }
      return x;
    }) || [];
  }

  getActions() {
    const { actions } = this.props;
    return actions || null;
  }

  getData() {
    const { data, filters, dataUrl, dataUrlMapCallback, dataUrlAdditionalParams } = this.props;
    console.log(dataUrlAdditionalParams)
    const { selectedFilter } = this.state;
    if(Array.isArray(data)) {
      if(filters) {
        const filter = filters.find(x=> x.id == selectedFilter)
        if(filter && filter.filterFunction) {
          return data.filter((x,i,t)=>filter.filterFunction(x,i,t))
        }
      }
      return data || [];
    } else if(dataUrl) {
      return (query =>
        new Promise((resolve, reject) => {
            let url = dataUrl
            const cookies = new Cookies();
            const token = cookies.get("X-CSRF-Token");
            fetch(url, {
              method: "POST",
              redirect: "manual",
              body: JSON.stringify({
                per_page: query.pageSize,
                page: query.page,
                search: query.search,
                order_by_direction: query.orderDirection,
                order_by: query.orderBy ? query.orderBy.field : null,
                ...(dataUrlAdditionalParams || {})
              }),
              headers: {
                "X-CSRF-Token": token,
                "Content-Type": 'application/json'
              }
            })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                resolve({
                  data: dataUrlMapCallback ? dataUrlMapCallback(json.data) : json.data,
                  page: query.page,
                  totalCount: json.total_rows
                });
              }
            })
        })
      )
    }else {
      return data
    }
  }

  getTitle() {
    const { title } = this.props;
    return title;
  }

  getPageSize() {
    try {
      const pageSize =  parseInt(localStorage.getItem("tableRowsPerPage"))
      if(pageSize && pageSize > 0){
        return pageSize
      }
    } catch (error) {
      return 10
    }
    return 10
  }

  renderTable() {
    const {urlLock, selectedFilter, disableSearch, emptyDataSourceMessage}  = this.props;
    console.log(this.props);
    return (
      <MaterialTable
        key={`material-table-selected-table-${selectedFilter}`}

        options={{
          pageSizeOptions: [
            5,10,20,50,100
          ],
          initialPage: urlLock ? getUrlPage()||0:0,
          paginationType:"stepped",
          showFirstLastPageButtons:true,
          pageSize: this.getPageSize(),
          exportAllData: true,
          exportButton: true,
          search:!disableSearch,
          thirdSortClick: false,
          emptyRowsWhenPaging: false,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: emptyDataSourceMessage || "No records to display"
          }
        }}
        onChangePage={this.onChangePage}
        components={{
           Pagination: (props) => (
             <TablePagination
               {...props}
               ActionsComponent={(subprops) =>
                 <GeneralTablePagination
                   {...subprops}
                   showTotals={this.props.showTotals}
                   unfilteredTotal={this.props.unfilteredTotal}
                   filteredTotal={this.props.filteredTotal}
                   icons={props.icons}
                   showFirstLastPageButtons={
                     true
                   }
                 />
               }
             />
           )
       }}
        columns={this.getColumns()}
        data={this.getData()}
        autoHeight={true}
        tableRef={this.props.tableRef||this.tableRef}
        actions = {this.getActions()}
        title={this.getTitle() || ""}
        paginationType="stepped"
        onChangeRowsPerPage={this.storeRowsPerPage}
      />
    )
  }

  onChangePage(e) {
    const { urlLock } = this.props;
    if(urlLock) {
      updateUrlParam('page',e+1);
    }
  }

  storeRowsPerPage(e){
    localStorage.setItem("tableRowsPerPage", e);
  }

  countFilter(filter) {
    const { data } = this.props;
    if(data) {
      if(filter && filter.filterFunction)  {
        return data.filter((x,i,t)=> filter.filterFunction(x,i,t)).length;
      }
      return 0;
    }
    return null
  }


  renderFilters() {
    /*
    * Scheme
    * id: string
    * name: string
    * filterFunction: function for enumerable
    */
    const { selectedFilter } = this.state;
    const { filters } = this.props;

    if(filters) {
      const renderedFilters = filters.map(filter=>{
        let count = this.countFilter(filter);
        return (
          <div key={`cnt-default-layout-general-table-filter-${filter.id}`} className={`${selectedFilter == filter.id ? "active" : ""} cnt-default-layout-general-table-filter`} onClick={() => this.setState({selectedFilter: filter.id})}>
            {filter.name} {count == null ? "" : `(${count})`}
          </div>
        )
      })
      return (
        <div className="cnt-default-layout-general-table-filters-container">
          {renderedFilters}
        </div>
      )
    }
    return "";
  }

  renderTutorial() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="export-tutorial-block">
            <TutorialBox stateID={'export'}/>
          </div>
        </div>
      </div>
    )
  }
  render() {
    const { user } = this.props;
    return (
      <div className="cnt-default-layout-general-table-container">
        {this.renderFilters()}
        {this.renderTutorial()}
        {this.renderTable()}
      </div>
    );
  }
}

export default GeneralTable;
