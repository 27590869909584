import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import Cookies from "universal-cookie";
import {canEditUserRole, canEditUsers,isAccountManager, isAdmin, isJaguar, isSolutionsLead, getRoleName} from "@utility/PermissionUtility"
import Select from 'react-select-virtualized'
import UserDeleteModal from "@users/UserDeleteModal"
import UserCreationModal from "./UserCreationModal"
import Checkbox from "@layout/forms/Checkbox";
class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      openModal: false,
    };
    this.destroyUser = this.destroyUser.bind(this);
  }

  isEditing() {
    const { editing_user } = this.props;
    return !!editing_user.id;
  }

  renderField(name,formikProps, required=false,customValidation = false, properties = {}) {
    const { editing_user, user } = this.props;
    const { checkError } = this.state;
    const erroring = required && checkError && (!formikProps.values.editing_user[name] || formikProps.values.editing_user[name].length <= 0 || customValidation);

    return (
      <Field
        onChange={(e) => {
            const { editing_user } = formikProps.values;
            editing_user[name] = e.target.value
            formikProps.setFieldValue('editing_user', editing_user);
            this.setState({
              dirty: true,
            });
          }
        }
        disabled={!this.isFormEditable()}
        component={properties['component']}
        className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`editing_user[${name}]`}
      />
    )
  }


  renderForm(formikProps) {
    const { user, profile, events } = this.props;
    const { error } = this.state;
    const { editing_user } = formikProps.values;
    return (
      <Form className="cnt-default-layout-editing_users-form" >
        <div className="row">
          <div className="col-xs-12">
            <label className="required">Mandatory Fields are marked with a </label>
          </div>
          <div className="col-xs-12 ">
            <div className="cnt-form-subheader">
              User Information
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                First Name
              </label>
              {this.renderField('first_name', formikProps, true)}
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Middle
              </label>
              {this.renderField('middle_name', formikProps, false)}
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Last Name
              </label>
              {this.renderField('last_name', formikProps, true)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Job Title
              </label>
              {this.renderField('job_title', formikProps, false)}
            </div>
          </div>
          {this.renderSalesRole(formikProps)}
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Email
              </label>
              {this.renderField('email', formikProps, true)}
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Business Phone
              </label>
              {this.renderField('phone_number', formikProps, false)}
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Mobile Phone
              </label>
              {this.renderField('mobile_phone_number', formikProps, false)}
            </div>
          </div>
        </div>
        {canEditUserRole(user) && !profile && user.id != editing_user.id ? (
          <div className="row">
            <div className="col-xs-12 col-md-4">
              <div className="cnt-default-layout-event-form-input-container">
                <label className="required">
                  Nomination Tool Role
                </label>
                {this.renderSelectField("role", formikProps, this.getRoleOptions())}
              </div>
            </div>
          </div>
        ):""}
        {canEditUserRole(user) ? (
          <div className="row">
            <div className="col-xs-12">
              <br/>
              Advanced Options
            </div>
            <div className="col-xs-12 col-md-4">
              <div className="cnt-default-layout-event-form-input-container">
                <Checkbox
                  label="Limit Viewable Events"
                  inline={true}
                  disabled={false}
                  value={formikProps.values.editing_user.option_restricted_events}
                  onClick={(e) => {
                      const { editing_user } = formikProps.values;
                      editing_user['option_restricted_events'] = !editing_user['option_restricted_events']
                      formikProps.setFieldValue('editing_user', editing_user);
                    }
                  }
                />
              </div>
            </div>
          </div>
        ):""}
        {canEditUserRole(user) && formikProps.values.editing_user.option_restricted_events ? (
          <div className="row" style={{display:"flex", flexWrap: "wrap"}}>
            <div className="col-xs-12">
              <br/>
              Choose Events Options
            </div>
            {events.map((event, index) => (
              <div className="col-xs-12 col-md-4">
                <div className="cnt-default-layout-event-form-input-container">
                  <Checkbox
                    label={event.name}
                    inline={true}
                    disabled={false}
                    value={formikProps.values.editing_user.event_ids.indexOf(event.id) != -1}
                    onClick={(e) => {
                        const { editing_user } = formikProps.values;
                        const contains_event = formikProps.values.editing_user.event_ids.indexOf(event.id) != -1;
                        if(contains_event){
                          editing_user['event_ids'] = editing_user['event_ids'].filter(id => id != event.id);
                        }else {
                          editing_user['event_ids'] = editing_user['event_ids'].concat(event.id);
                        }
                        formikProps.setFieldValue('editing_user', editing_user);
                      }
                    }
                  />
                </div>
              </div>
          ))}
          </div>
        ):""}
        <div className="row">
          <div className="col-xs-12 cnt-default-layout-event-form-error">
            {error}
          </div>
          {this.renderButtons(formikProps)}
        </div>
      </Form>
    )
  }

  renderSalesRole(formikProps) {
    const { editing_user } = formikProps.values;
    return  (
      <>
        <div className="col-xs-12 col-md-4">
          <div className="cnt-default-layout-event-form-input-container">
            <label className="required">
              Sales Role
            </label>
            {this.renderSelectField("sales_role", formikProps, this.getSalesRoleOptions())}
          </div>
        </div>
        {editing_user.sales_role == 'Other' ? (
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Sales Role - Subcategory
              </label>
              {this.renderField("sales_role", formikProps, false)}
            </div>
          </div>
        ):""}
        {editing_user.sales_role  && editing_user.sales_role != 'Other' ? (
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Sales Role - Subcategory
              </label>
              {this.renderSelectField("sub_sales_role", formikProps, this.getSalesSubRoleOptions(editing_user.sales_role))}
            </div>
          </div>

        ):""}
      </>
    )
  }

  getSalesSubRoleOptions(role) {
    const roles = {
      "Account Lead":[
        'Client Executive',
        'Global Account Manager',
        'Account Manager',
        'Territory Account Manager',
        'Geo Territory Account Manager',
        'Small Business Territory Manager',
        'Client Director ',
        'Regional Manager'
      ],
      "Technical Lead": [
        'Systems Engineer',
        'Solutions Architect',
        'Technical Solutions Architect',
        'Client Services',
        'Business Solutions Architect'
      ],
      "Specialist": [
        'Product Solutions Specialists',
        'Services Sales Specialists',
        'Software Sales Specialists',
        'Business Development Manager',
        'Technical Solutions Specialists'
      ]
    }

    const currentRoleOptions = roles[role];
    return currentRoleOptions ? currentRoleOptions.map(x=>{return {
      value: x,
      label: x
    }}) : [];
  }

  getSalesRoleOptions() {
    return [
      "Account Lead",
      "Technical Lead",
      "Specialist",
      "Other"
    ].map(x=>{return {
      value: x,
      label: x
    }})
  }

  getRoleOptions() {
    const { user } = this.props;
    let accessable_roles = [
      'account_manager',
      'executive_relations_lead',
      'marketing_manager'
    ]
    if(isJaguar(user)) {
      accessable_roles = accessable_roles.concat(['jaguar'])
    }
    if(isAdmin(user)) {
      accessable_roles = accessable_roles.concat(['admin', 'solutions_lead'])
    }
    if(isSolutionsLead(user)){
      accessable_roles = accessable_roles.concat(['solutions_lead'])
    }
    return accessable_roles.map(x=>{return {
      value: x,
      label: getRoleName(x)
    }})
  }

  capatalizeString(updatable_string) {
    return updatable_string.charAt(0).toUpperCase() + updatable_string.slice(1);
  }

  isFormEditable() {
    const { user, editing_user } = this.props;
    return canEditUsers(user) || user.id == editing_user.id
  }

  renderButtons(formikProps) {
    const { user } = this.props;
    if(this.isFormEditable()) {
      return (
        <div className="col-xs-12" style={{paddingTop: "20px"}}>
          {this.isEditing() ? ( //Editing
            <button type="button" onClick={() => formikProps.handleSubmit()} className="cnt-submit-button"> Save </button>
          ):( //New
            <>
            <button
              type="button"
              onClick={() => {
                this.sendInvite=true;
                formikProps.handleSubmit()
              }}
              className="cnt-submit-button"
            > Save and Send Invite </button>
            <button
              type="button"
              onClick={() => {
                this.sendInvite=false;
                formikProps.handleSubmit()
              }}
              className="cnt-submit-button"
            > Save </button>
            </>
          )}
          <a href={this.isProfile() ? "/" :"/admin/users"}><button type="button" className="cnt-cancel-button "> Cancel </button></a>
        </div>
      )
    }
  }

  destroyUser(replacement_id) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(this.formURL(), {
      method: "DELETE",
      redirect: "manual",
      body: JSON.stringify({replacement_id: replacement_id}),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          if(json.redirect_url) {
            window.location = json.redirect_url
          }else {
            console.log("Successful no redirect")
          }
        }
        else {
          this.setState({
            error: json.error
          })
        }
      });
  }
  renderSelectField(name, formikProps, options, customValidation = false, renderOptions = {}) {
    const { checkError } = this.state;
    const erroring = checkError && ((formikProps.values.editing_user[name] == null || formikProps.values.editing_user[name].length <= 0) || customValidation);
    let value = null;
    try {
       value = options.filter(option => option.value === formikProps.values['editing_user'][name])[0]
    }catch (e) {

    }
      return (
        <Select
          className={erroring ? "cnt-form-select-erroring" : ""}
          options={options}
          isClearable={false}
          classNamePrefix='cnt-form-select'
          value={options ? value : ""}
          onChange={(option) => {
            const { editing_user } = formikProps.values;
            editing_user[name] = option.value
            formikProps.setFieldValue('editing_user', editing_user)
          }}
          disabled={renderOptions['disabled']}
          filterOption={this.customFilter}
        />
      )
    }


  formMethod() {
    return this.isEditing() ? "PATCH" : "POST"
  }

  formURL() {
    const { editing_user } = this.props;
    return this.isEditing() ? `/admin/users/${editing_user.id}` : `/admin/users`;
  }

  isProfile() {
    return this.props.profile;
  }

  render() {
    const { editing_user, user } = this.props;
    const { openModal, newUser } = this.state;
    return (
      <>
      <Formik
        initialValues={{
          editing_user: {
            id: editing_user ? editing_user.id : null,
            first_name: editing_user ? editing_user.first_name : "",
            middle_name: editing_user ? editing_user.middle_name : "",
            last_name: editing_user ? editing_user.last_name : "",
            role: editing_user ? editing_user.role || "account_manager": "account_manager",
            email: editing_user ? editing_user.email : "",
            phone_number: editing_user ? editing_user.phone_number : "",
            job_title: editing_user ? editing_user.job_title : "",
            sales_role:  editing_user ? editing_user.sales_role : "",
            sub_sales_role: editing_user ? editing_user.sub_sales_role : "",
            mobile_phone_number: editing_user ? editing_user.mobile_phone_number : "",
            option_restricted_events: editing_user ? editing_user.option_restricted_events : false,
            event_ids: editing_user ? editing_user.event_ids || [] : [],
          }
        }}
        onSubmit={(values, actions) => {
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          values.send_invite = this.sendInvite;
          delete values.editing_user.id;
          fetch(this.formURL(), {
            method: this.formMethod(),
            redirect: "manual",
            body: JSON.stringify(values),
            headers: {
              "X-CSRF-Token": token,
              "Content-Type": 'application/json'
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                if(json.redirect_url) {
                  if(this.props.temporary_contact_id) {
                    window.location=`/contacts/new?temporary_contact_id=${this.props.temporary_contact_id}&account_manager_id=${json.new_user.id}`
                    return;
                  }
                  if(!this.isEditing()) {
                    this.setState({openModal: true, newUser: json.new_user})
                  }else {
                    if(this.isProfile()){
                      location.reload();
                    }else {
                      window.location = json.redirect_url
                    }

                  }
                }else {
                  console.log("Successful no redirect")
                }
              }
              else {
                this.setState({
                  error: json.error
                })
              }
            });
        }}
        render={(formikProps) => (
          <>
          {this.renderForm(formikProps)}
          </>
        )}
      />
    {openModal ? (<UserCreationModal inviteSent={this.inviteSent} newUser={this.state.newUser} />) : ""}
      </>
    );
  }
}

export default UserForm;
