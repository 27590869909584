import React from "react";
import PropTypes from "prop-types";
import ReportKeys from "../ReportKeys";
import ReportSortingCriteria from "../ReportSortingCriteria";
import ReportFilters from "../ReportFilters";
import ReportPreview from "../ReportPreview";
import DefaultLayout from "@layout/DefaultLayout";
import Modal from "react-modal";

class ReportCustomize extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewModal: false
    }
    this.togglePreviewModal = this.togglePreviewModal.bind(this);
  }

  componentWillMount() {
    Modal.setAppElement("body");
  }

  togglePreviewModal() {
    const { previewModal } = this.state;
    this.setState({ previewModal: !previewModal });
  }

  // setDefaultFields() {
  //   const { baseObj, objectKeys } = this.state;
  //   // const defaultFields = getDefaultFields();
  //   const defaultFields = [];
  //   const addedFields = [];
  //   defaultFields.forEach(type => {
  //     if (type.objType === baseObj.value) {
  //       Object.entries(objectKeys).forEach(entry => {
  //         type.fields.forEach(field => {
  //           if (field === entry[0]) {
  //             let newField = entry[1];
  //             newField.value = entry[0];
  //             addedFields.push(newField);
  //           }
  //         });
  //       });
  //     }
  //   });
  //   this.setState({ selectedKeys: addedFields });
  // }

  renderReportFields() {
    const { baseObj, objectKeys, selectedKeys, setSelectedKeys } = this.props;
    const keys = Object.entries(objectKeys).map(key => {
      return {
        value: key[0],
        label: key[1]["label"],
        datatype: key[1]["datatype"]
      };
    });
    return (
      <>
        <div className="report-select-keys">
          <ReportKeys
            baseObj={baseObj}
            keys={keys}
            updateKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
          />
        </div>
      </>
    );
  }

  renderSortingCriteria() {
    const { setSortingCriteria, sortingCriteria, report, selectedKeys } = this.props;
    return (
      <div className="report-select-keys report-select-keys-divider">
        <label>Sorting Criteria:</label>
        <ReportSortingCriteria
          options={selectedKeys}
          updateSorting={setSortingCriteria}
          initialCriteria={sortingCriteria}
          report={report}
        />
      </div>
    );
  }

  renderFilters() {
    const { filterChains, report, selectedKeys, setFilterChains } = this.props;
    return (
      <div className="report-select-keys">
        <label>Filter(s):</label>
        <ReportFilters
          options={selectedKeys}
          updateFilterChains={setFilterChains}
          initialFilterChains={filterChains}
          report={report}
        />
      </div>
    );
  }

  renderButtons() {
    const { submit } = this.props;
    return (
      <div className="report-select-keys report-select-keys-divider">
        <div
          className="report-tool-button report-tool-button-blue"
          style={{ marginLeft: "10px", marginRight: "10px" }}
          onClick={submit}
        >
          Continue
        </div>
        <div className="report-tool-button report-tool-button-green" onClick={this.togglePreviewModal}>
          Preview
        </div>
        <div
          className="report-tool-button report-tool-button-white"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            javascript: history.back();
          }}
        >
          Cancel
        </div>
      </div>
    );
  }

  renderPreview() {
    const { baseObj, filterChains, objectKeys, selectedKeys, sortingCriteria } = this.props;
    return (
      <ReportPreview
        baseObj={baseObj}
        objectKeys={selectedKeys}
        keysWithDataType={objectKeys}
        filterChains={filterChains}
        sortingCriteria={sortingCriteria}
      />
    );
  }

  render() {
    const { report } = this.props;
    const { previewModal } = this.state;
    return (
      <DefaultLayout {...this.props} sidebarId="reports">
        <div className="report-tool-form-wrapper" style={{ margin: "0px" }}>
          <div className="report-tool-page-header">
            <h1 className="cnt-page-header">
              {report === undefined ? "New Report" : report.name}
            </h1>
          </div>
          <div style={{ clear: "both" }} />
          <div className="report-tool-page-sub-header">
            Customize your report
          </div>
          {this.renderReportFields()}
          <div className="report-select-keys-divider" />
          {this.renderFilters()}
          {this.renderSortingCriteria()}
          {this.renderButtons()}
        </div>
        <Modal
          className="white-background report-modal disable-scrollbars"
          overlayClassName="report-modal-overlay"
          isOpen={previewModal}
          onRequestClose={this.togglePreviewModal}
          contentLabel=""
        >
          {this.renderPreview()}
        </Modal>
      </DefaultLayout>
    );
  }
}

ReportCustomize.propTypes = {
  baseObj: PropTypes.object.isRequired,
  filterChains: PropTypes.array.isRequired,
  objectKeys: PropTypes.object.isRequired,
  selectedKeys: PropTypes.array.isRequired,
  setFilterChains: PropTypes.func.isRequired,
  setSelectedKeys: PropTypes.func.isRequired,
  setSortingCriteria: PropTypes.func.isRequired,
  sortingCriteria: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired
};

export default ReportCustomize;
