import React from "react";
import PropTypes from "prop-types";
import { format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import ReactTooltip from "react-tooltip";
import Cookies from "universal-cookie";
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-timezone';
import { canSessionBeAdded, isInTrack } from "@utility/SessionUtils"
import Table from "rc-table";

class UsersDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: ""
    }

    this.columns = [
      {
        title: "Session Name",
        dataIndex: "title",
        key: "title",
        width: 300,
      },
      {
        title: "Session Type",
        dataIndex: "session_type",
        key: "session_type",
      },
      {
        title: "Start Time",
        dataIndex: "session_type",
        key: "session_type",
      },
      {
        title: "Session Code",
        dataIndex: "code",
        key: "code",
        width: 100,
      },
      {
        title: 'Time',
        dataIndex: '',
        key: 'time',
        render: (data) => (<>{data.date_and_time}</> ),
      },
      {
        title: 'Operations',
        dataIndex: '',
        key: 'operations',
        render: (data) => (<a href={`/admin/zoom/${data.uuid}/report`}>Download Zoom Report</a>),
      },
    ];
  }

  getData() {
    let { sessions } = this.props;
    const { searchQuery } = this.state;
    sessions =  sessions.filter(x=>
      x.session_type!="Breakout" && x.session_type !="General Session" && x.session_type !="Broadcast Center"
    ).filter(x =>
      x.data && x.data.zoom && x.data.zoom.id
    )

    if(searchQuery && searchQuery.length > 0) {
      sessions = sessions.filter(x=>
        x.title.toLowerCase().includes(searchQuery.toLowerCase())
        ||
        x.code.toLowerCase().includes(searchQuery.toLowerCase())
        ||
        x.session_type.toLowerCase().includes(searchQuery.toLowerCase())
      )
    }

    return sessions.sort((a,b) =>
      compareAsc( new Date(a.date_and_time), new Date(b.date_and_time) )
    )
  }


  render() {
    const { searchQuery } = this.state;
    return (
      <>
        <div className="row">
          <div className="col-xs-12">
            Search:
            <br />
            <input className="form-control" value={searchQuery} onChange={(e)=>this.setState({searchQuery: e.target.value})}/>
            <br />
          </div>
        </div>
        <table className="sg-admin-user-panel-table">
          <Table
            columns={this.columns}
            data={this.getData()}
            tableLayout="auto"
          />
        </table>
      </>
    );
  }
}

export default UsersDashboard;
