// Keeping around for quick reference. Delete when current report components are solid.

import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import Cookies from "universal-cookie";
import ReportKeys from "./ReportKeys";
import ReportSortingCriteria from "./ReportSortingCriteria";
import ReportFilters from "./ReportFilters";
import ReportPreview from "./ReportPreview";
import { flattenObj } from "@utility/ObjectManipulation";
import DefaultLayout from "@layout/DefaultLayout";
import { getDefaultFields } from "./ReportToolDefaultFields"

class ReportOld extends React.Component {
  constructor(props) {
    super(props);

    let reportName = props.title ? props.title : "";
    let reportLocation = { value: "", label: "" };
    let reportEditable = false;
    let baseObj = {};
    let objectList = [];
    let objectKeys = [];
    let selectedKeys = [];
    let filterChains = props.filterChains ? props.filterChains : [];
    let sortingCriteria = props.sorting_criteria ? props.sorting_criteria : [];

    if (props.report) {
      if (props.report.private) {
        reportLocation = { value: true, label: "My Reports (private)" };
      } else {
        reportLocation = { value: false, label: "Reports (shared)" };
      }
      reportEditable = props.report.editable;
    }

    this.state = {
      reportName: reportName,
      reportLocation: reportLocation,
      reportEditable: reportEditable,
      baseObj: baseObj,
      objectList: [],
      objectKeys: [],
      selectedKeys: selectedKeys,
      filterChains: filterChains,
      sortingCriteria: sortingCriteria
    };
    this.editReportName = this.editReportName.bind(this);
    this.editReportLocation = this.editReportLocation.bind(this);
    this.setBaseObj = this.setBaseObj.bind(this);
    this.updateKeys = this.updateKeys.bind(this);
    this.updateFilterChains = this.updateFilterChains.bind(this);
    this.updateSorting = this.updateSorting.bind(this);
    this.saveReport = this.saveReport.bind(this);
    this.setDefaultFields = this.setDefaultFields.bind(this);
  }

  // componentDidMount() {
  //   const { keys, report } = this.props;
  //   // let count = 0;
  //   if (report) {
  //     this.setBaseObj(
  //       { value: report.object_type, label: report.object_type },
  //       false
  //     );
  //     let selectedKeys = report.keys.map(key => {
  //       const keyDetails = keys.find(ele => ele[0] === key);
  //       return { value: keyDetails[0], label: keyDetails[1] };
  //     });
  //     this.setState({
  //       selectedKeys: selectedKeys
  //       // sortingCriteria: sortingCriteria
  //     });
  //   }
  // }

  componentDidMount() {
    const { keys, report } = this.props;
    if (report) {
      this.setBaseObj(
        { value: report.object_type, label: report.object_type },
        false
      ).then(() => {
        const { objectKeys } = this.state;
        let selectedKeys = report.keys.map(key => {
          const keyDetails = objectKeys[key];
          return { value: key, label: keyDetails.label, datatype: keyDetails.datatype };
        });
        this.setState({
          selectedKeys: selectedKeys
          // sortingCriteria: sortingCriteria
        });
      });
    }
  }

  setDefaultFields() {
    const { baseObj, objectKeys } = this.state;
    // const defaultFields = getDefaultFields();
    const defaultFields = [];
    const addedFields = [];
    defaultFields.forEach(type => {
      if (type.objType === baseObj.value) {
        Object.entries(objectKeys).forEach(entry => {
          type.fields.forEach(field => {
            if (field === entry[0]) {
              let newField = entry[1];
              newField.value = entry[0];
              addedFields.push(newField);
            }
          });
        });
      }
    });
    this.setState({ selectedKeys: addedFields });
  }

  updateFilterChains(filterChains) {
    this.setState({ filterChains: filterChains });
  }
  updateSorting(sortingCriteria) {
    this.setState({ sortingCriteria: sortingCriteria });
  }

  updateKeys(keys) {
    this.setState({ selectedKeys: keys });
  }

  editReportName(e) {
    this.setState({ reportName: e.target.value });
  }

  editReportLocation(e) {
    let editableByOthers = false;
    if (e.value === "shared") {
      editableByOthers = true;
    }
    this.setState({
      reportLocation: e,
      reportEditable: editableByOthers
    });
  }

  editReportEditable(e) {
    this.setState({ reportEditable: e.target.checked });
  }

  renderSaveUrl() {
    const { report } = this.props;
    if (report) {
      return `/reports/${report.id}`;
    }
    return "/reports";
  }
  renderSaveType() {
    const { report } = this.props;
    if (report) {
      return "PATCH";
    }
    return "POST";
  }

  saveReport() {
    const {
      reportName,
      reportLocation,
      reportEditable,
      selectedKeys,
      baseObj,
      filterChains,
      sortingCriteria
    } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const keys = selectedKeys.map(key => key.value);
    fetch(this.renderSaveUrl(), {
      method: this.renderSaveType(),
      redirect: "manual",
      body: JSON.stringify({
        report_name: reportName,
        report_location: reportLocation.value,
        report_editable: reportEditable,
        keys: keys,
        object_type: baseObj.value,
        filterChains: filterChains,
        // filters: filters.filter(ele => !!ele.operationValue),
        sortingCriteria: sortingCriteria
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          console.log("success!");
          if (reportLocation.value) {
            window.location.href = "/reports#my_reports";
          } else {
            window.location.href = "/reports";
          }
        } else {
          console.log(json.error);
        }
      });
  }

  async setBaseObj(e, resetValues = true) {
    const { selectedKeys, filterChains, sortingCriteria } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    let objList = [];
    let objKeys = [];
    await fetch("/reports/object_skeleton_all", {
      method: "POST",
      redirect: "manual",
      body: JSON.stringify({
        object_type: e.value
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          objList = json.object_list;
          // objKeys = Object.keys(flattenObj(objList));
          objKeys = objList[0];
          // objKeys = objList;
          if (resetValues) {
            this.setState({
              baseObj: e,
              objectKeys: objKeys,
              selectedKeys: [],
              filterChains: [],
              sortingCriteria: []
            }, this.setDefaultFields);
          } else {
            this.setState({
              baseObj: e,
              objectKeys: objKeys
            });
          }
        } else {
          console.log(json.error);
        }
      });
  }

  renderSaveAs() {
    const { reportName, reportLocation, reportEditable } = this.state;
    let locationObjects = [
      { value: false, label: "Reports (shared)" },
      { value: true, label: "My Reports (private)" }
    ];
    return (
      <>
        <label>Report Title:</label>
        <inpue
          type="text"
          value={reportName}
          onChange={e => this.editReportName(e)}
          className="report-tool-input"
        />
        <br />
        <div className="report-select-keys">
          <label>Report Location:</label>
          <Select
            value={reportLocation}
            options={locationObjects}
            onChange={location => {
              this.editReportLocation(location);
            }}
          />
        </div>
        {reportLocation.value === false ? (
          <>
            <div className="report-select-keys">
              <label>Editable by others:</label>
              <input
                type="checkbox"
                checked={reportEditable}
                onChange={e => {
                  this.editReportEditable(e);
                }}
                style={{ width: "auto" }}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </>
    );
  }

  renderReportFields() {
    const { baseObjects, objectType } = this.props;
    const { baseObj, objectKeys, selectedKeys } = this.state;
    let objects = baseObjects.map(object => {
      const obj = { value: object, label: object };
      return obj;
    });
    // let keys = objectKeys.map(key => {
    //   const field = {value: key, label: key};
    //   return field;
    // });
    let keys = Object.entries(objectKeys).map(key => {
      return {
        value: key[0],
        label: key[1]["label"],
        datatype: key[1]["datatype"]
      };
    });
    return (
      <>
        <div className="report-select-keys report-select-keys-divider">
          <label>Report Type:</label>
          <Select
            value={baseObj}
            options={objects}
            onChange={obj => {
              this.setBaseObj(obj);
            }}
          />
        </div>
        <div className="report-select-keys">
          <ReportKeys
            baseObj={baseObj}
            keys={keys}
            updateKeys={this.updateKeys}
            selectedKeys={selectedKeys}
          />
        </div>
      </>
    );
  }

  renderSortingCriteria() {
    const { sorting_criteria, report } = this.props;
    const { selectedKeys } = this.state;
    return (
      <div className="report-select-keys report-select-keys-divider">
        <label>Sorting Criteria:</label>
        <ReportSortingCriteria
          options={selectedKeys}
          updateSorting={this.updateSorting}
          initialCriteria={sorting_criteria}
          report={report}
        />
      </div>
    );
  }

  renderFilters() {
    const { report } = this.props;
    const { selectedKeys, filterChains } = this.state;
    return (
      <div className="report-select-keys">
        <label>Filter(s):</label>
        <ReportFilters
          options={selectedKeys}
          updateFilterChains={this.updateFilterChains}
          initialFilterChains={filterChains}
          report={report}
        />
      </div>
    );
  }

  renderButtons() {
    const {
      objectList,
      selectedKeys,
      filterChains,
      sortingCriteria,
      baseObj,
      objectKeys
    } = this.state;
    return (
      <div className="report-select-keys report-select-keys-divider">
        {/* <ReportPreview */}
        {/*   baseObj={baseObj} */}
        {/*   objectList={objectList} */}
        {/*   objectKeys={selectedKeys} */}
        {/*   keysWithDataType={objectKeys} */}
        {/*   filterChains={filterChains} */}
        {/*   sortingCriteria={sortingCriteria} */}
        {/* /> */}
        <div
          className="report-tool-button report-tool-button-blue"
          style={{ marginLeft: "10px" }}
          onClick={this.saveReport}
        >
          Save
        </div>
        <div
          className="report-tool-button report-tool-button-white"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            javascript: history.back();
          }}
        >
          Cancel
        </div>
      </div>
    );
  }

  render() {
    const { report } = this.props;
    return (
      <DefaultLayout {...this.props} sidebarId="reports">
        <div className="report-tool-form-wrapper" style={{ margin: "0px" }}>
          <div className="report-tool-page-header">
            <h1 className="cnt-page-header">
              {report === undefined ? "New Report" : report.name}
            </h1>
          </div>
          <div style={{ clear: "both" }} />
          {this.renderSaveAs()}
          {this.renderReportFields()}
          {this.renderSortingCriteria()}
          {this.renderFilters()}
          {this.renderButtons()}
        </div>
      </DefaultLayout>
    );
  }
}

export default ReportOld;
