import React from "react";
import PropTypes from "prop-types";
import { format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import ReactTooltip from "react-tooltip";
import Cookies from "universal-cookie";
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-timezone';
import { canSessionBeAdded, isInTrack } from "@utility/SessionUtils"

class AdminAchievementsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userAchievements: props.user_achievements
    }
    this.addAward = this.addAward.bind(this);
    this.removeAward = this.removeAward.bind(this);
  }

  renderAchievements() {
    const { awards } = this.props;
    return awards.map(award => (
      <div className="col-xs-12 col-sm-6 col-md-4">
        <div className="sg-admin-achievement-form-achievement-card">
          <div className="sg-admin-achievement-form-card-name">
            {award.name}
          </div>
          <div className="sg-admin-achievement-icon">
            {this.renderAchievementIcon(award)}
          </div>
        </div>
      </div>
    ))
  }

  renderAchievementIcon(award) {
    const { userAchievements } = this.state;
    const hasAward = userAchievements.map(x=> x.slug).filter(x=> x == award.slug).length > 0;
    if(hasAward) {
      return (<img onClick={() => this.removeAward(award)} className="sg-admin-achievement-form-card-icon-image" src="/agenda/checkmark.svg" />);
    }
    return (
      <img style={{cursor: "pointer"}}  onClick={() => this.addAward(award)} className="sg-admin-achievement-form-card-icon-image" src="/images/dashboard/icons/dashboard-add-icon.svg" />
    );
  }

  addAward(award) {
    const { user } = this.props;
    const { userAchievements } = this.state;
    this.setState({
      userAchievements: userAchievements.concat([award])
    });

    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch('/admin/achievements', {
      method: 'POST',
      redirect: "manual",
      body: JSON.stringify(
        {
          email: user.email,
          slug: award.slug
        }
      ),
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {

      } else {

      }
    });
  }

  removeAward(award) {
    const { user } = this.props;
    const { userAchievements } = this.state;
    this.setState({
      userAchievements: userAchievements.filter(x=> x.slug != award.slug)
    });

    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch('/admin/achievements/id', {
      method: 'DELETE',
      redirect: "manual",
      body: JSON.stringify(
        {
          email: user.email,
          slug: award.slug
        }
      ),
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {

      } else {

      }
    });
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-xs-12 sg-admin-achievement-form-achievement-header">
            Achievements
          </div>
          {this.renderAchievements()}
          <div className="col-xs-12">
            <a href="/admin/achievements">
              <button className="btn btn-primary">
                Return to attendees
              </button>
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default AdminAchievementsForm;
