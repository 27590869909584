import { fetchAPI } from "@utility/NetworkUtils"
export function tutorialStates(tutorialType) {
  if(tutorialType == 'ae') {
    return aeTutorialStates();
  }
  const amTutorial = [
    {
      id:'welcome',
      title: 'Welcome to Cisco’s Nomination Tool',
      content:"Designed to make customer nominations a breeze, this tool allows you to nominate customers, review nomination statuses and manage your contacts. We know that learning a new tool can be overwhelming, but don’t worry. This brief tutorial will provide you with an overview of all the features available to you and have you nominating in no time.",
      button: 'Get started',
      type: 'modal',
    },
    {
      id:'new_nom',
      title: 'Making a new nomination',
      content: 'You can make a new nomination in three easy steps. It’s that simple. You can submit your nomination for review or you can save it as a draft, if you feel like you want to come back to it later.',
      button: 'Continue',
      type: 'pointer',
    },
    {
      id:'dashboard',
      title: 'Your Dashboard',
      content: 'Your nomination will appear on your dashboard, which provides you an overview of all your active nominations.',
      button: 'Continue',
      type: 'pointer',
    },
    {
      id:'events',
      title: 'What’s happening?',
      content: 'Get a quick overview of all ongoing and upcoming events at Cisco.',
      button: 'Continue',
      type: 'pointer',
    },
    {
      id: 'toolguide',
      title:'A good reference',
      content: 'The tool guide provides a great overview of all the features available to you.',
      button: 'Continue',
      type: 'pointer',
    },
    {
      id: 'superfilter',
      title:'At your fingertips',
      content: 'Easily find what you are looking for by using the filters or simply type into the search field. You can also sort the content by clicking on the headings in the table.',
      button: 'Continue',
      type: 'toppointer',
    },
    {
      id: 'export',
      title:'Download a report',
      content: 'You can download a report of everything you see on your dashboard or use the filters to create a customized report.',
      button: 'Continue',
      type: 'toppointer',
    },
    {
      id: 'questions',
      title:'Questions?',
      content: 'Please come here, if you still have question or would like to go through the tutorial again. And should you come across anything that is not working, please let us know and leave us feedback.',
      button: 'Continue',
      type: 'pointer',
    },
    {
      id: 'finish',
      title:'This concludes the tutorial.',
      content: 'You are all set to go. Happy nominating!',
      button: 'Finish',
      type: 'modal',
      onButtonClick: () => {
        fetchAPI(
          '/api/v1/tutorials/view',
          () => {},
          {method: "POST"}
        )
      }
    }
  ]

  if(tutorialType == "mm") {
    return amTutorial.filter(x=>x.id != 'directory')
  }
  return amTutorial
}

export function aeTutorialStates() {
  return [
    {
      id:'welcome',
      title: 'Welcome to Cisco’s Nomination Tool',
      content:"Designed to make the nomination review process a breeze, this tool allows you to review and approve (or deny) nominations, access customer attendance history and invite statuses, and download customized reports. This brief tutorial will provide you with a quick overview of all the features available to you.",
      button: 'Get started',
      type: 'modal',
    },
    {
      id:'new_nom',
      title: 'Making a new nomination',
      content: "Not only can you review customer nominations, you can also make new nominations in just three simple steps.",
      button: 'Continue',
      type: 'pointer',
    },
    {
      id:'dashboard',
      title: 'Your Dashboard',
      content: 'This is the main area from where you can review and approve nominations. You can sort and filter by event, nominations status or search by keyword.',
      button: 'Continue',
      type: 'pointer',
    },
    {
      id:'contacts',
      title: 'All your customers in one place',
      content: 'All customers in the database are listed here. You can add, review and edit customers and view a customer’s nomination and attendance history.',
      button: 'Continue',
      type: 'pointer',
    },
    {
      id:'events',
      title: 'What’s happening?',
      content: 'Get a quick overview of all Cisco events past, present and future. You can also review event details, add nomination criteria or download event specific reports.',
      button: 'Continue',
      type: 'pointer',
    },
    {
      id:'companies',
      title: 'All companies in one place',
      content: 'All companies in the database are listed here. You can add, review and edit companies. Changes made to a company will be reflected for all contacts associated with that company.',
      button: 'Continue',
      type: 'pointer',
    },
    {
      id: 'updates',
      title:'Reviewing changes',
      content: 'The update queue lists all the changes that have been made by account managers. You can review those changes and verify them here.',
      button: 'Continue',
      type: 'pointer',
    },
    {
      id: 'reports',
      title:'Reporting tool',
      content: 'Access event reports or create your own customized report with a an array of filtering and sorting features.',
      button: 'Continue',
      type: 'pointer',
    },
    {
      id: 'toolguide',
      title:'A good reference',
      content: 'The tool guide provides a great overview of all the features available to you.',
      button: 'Continue',
      type: 'pointer',
    },
    {
      id: 'superfilter',
      title:'At your fingertips',
      content: 'Easily find what you are looking for by using the filters or simply type into the search field. You can also sort the content by clicking on the headings in the table.',
      button: 'Continue',
      type: 'toppointer',
    },
    {
      id: 'export',
      title:'Download a report',
      content: 'You can download a report of everything you see on your dashboard or use the filters to create a customized report.',
      button: 'Continue',
      type: 'toppointer',
    },
    {
      id: 'questions',
      title:'Questions?',
      content: 'Please come here, if you still have question or would like to go through the tutorial again. And should you come across anything that is not working, please let us know and leave us feedback.',
      button: 'Continue',
      type: 'pointer',
    },
    {
      id: 'finish',
      title:'This concludes the tutorial.',
      content: 'You are all set to go.',
      button: 'Finish',
      type: 'modal',
      onButtonClick: () => {
        fetchAPI(
          '/api/v1/tutorials/view',
          () => {},
          {method: "POST"}
        )
      }
    }
  ]
}
