import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import NominationsHistoryTable from "@nominations/tables/NominationsHistoryTable"
class NominationHistoryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    }
    this.toggleModal = this.toggleModal.bind(this);

  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen
    })
  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, sessions,user } = this.props;
    const { nomination } = this.state;
    return (
      <>
        <div className={`cnt-modal-openable-button ${outerClassNames}`} onClick={this.toggleModal} >
          {children}
        </div>
        <Modal
          className="white-background cnt-user-admin-modal cnt-user-admin-wide-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          <NominationsHistoryTable contact={this.props.contact ? this.props.contact:null} user={this.props.user} nominations={null} />
        </Modal>
      </>
    );
  }
}

export default NominationHistoryModal
;
