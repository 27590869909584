import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import { canEditNomination } from "@utility/PermissionUtility"
import ContactCondensedView from "./ContactCondensedView"
import UniversalImageCircle from "@images/UniversalImageCircle"
import CompanyCondensedView from "@companies/modals/CompanyCondensedView"
import ContactAccountManagerForm from "@contacts/forms/ContactAccountManagerForm"
class ContactAccountManagerFormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      contact: null,
      loading: true,
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.loadContact = this.loadContact.bind(this);
    this.onSaveCallback = this.onSaveCallback.bind(this);
  }

  toggleModal() {
    if(this.state.modalOpen) { //Closing
      this.setState({
        modalOpen: false
      })
    }
    else {
      this.setState({
        modalOpen: true,
        loading: true,
        contact: null,
      })
      this.loadContact();
    }
  }

  loadContact() {
    if(this.props.contact && this.props.contact.id) {
      fetchApi(`/contacts/${this.props.contact.id}/get_contact_modal_info`, (json)=>{
        if (json.error == null) {
          this.setState({
            contact: json.contact,
            company: json.company,
            loading: false,
          })
        }
        else {
          this.setState({
            error: json.error,
            loading: false,
          })
        }
      })
    }else {
      this.setState({
        loading: false,
        contact: null,
      })
    }
  }

  onSaveCallback(contact) {
    const { onSaveCallback } = this.props;
    this.toggleModal();
    if(onSaveCallback) {
      onSaveCallback(contact);
    }
  }

  renderModal() {
    const  { loading } = this.state;
    const { user } = this.props;
    if(loading) {
      //Render Loading
      return (
        <div className="row">
          <div className="col-xs-12 ">
            <div className="ctn-default-layout-log-table-loading">
              <h1> Loading </h1>
              <br />
              <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <ContactAccountManagerForm
        user={user}
        contact={this.state.contact||{}}
        onFormSubmitCallback={this.onSaveCallback}
      />
    )
  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, sessions } = this.props;
    return (
      <>
        <div className={`cnt-modal-openable-button cnt-modal-openable-button-contact ${outerClassNames}`} onClick={this.toggleModal} >
          {children}
        </div>
        {modalOpen ? (
          <Modal
            className="white-background cnt-user-admin-modal disable-scrollbars"
            overlayClassName="cnt-default-layout-modal-overlay"
            isOpen={true}
            onRequestClose={this.toggleModal}
            contentLabel=""
          >
            {this.renderModal()}
          </Modal>
        ):""}
      </>
    );
  }
}

export default ContactAccountManagerFormModal;
