import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import CompanyMergeForm from "@companies/forms/CompanyMergeForm"
import CompanyCondensedView from "@companies/modals/CompanyCondensedView"
class CompanyMergeModal extends React.Component {
  constructor(props) {
    super(props);
    this.onMerge = this.onMerge.bind(this);
  }

  onMerge(selectedId, rejectedIds) {
    console.log("Modal On Merge")
    console.log({selectedId, rejectedIds})
    const { onMerge } = this.props;
    if(onMerge) {
      onMerge(selectedId, rejectedIds)
    }
  }

  render() {
    const {companies, user, isOpen, closeModal } = this.props;
    return (
      <>
        <Modal
          className="white-background cnt-user-admin-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={isOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          <CompanyMergeForm
            onMerge={this.onMerge}
            onCancel={closeModal}
            companies={companies}
          />
        </Modal>
      </>
    );
  }
}

export default CompanyMergeModal;
