import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import NominationThreeStepForm from "@nominations/forms/NominationThreeStepForm"
import { printDivToPdf } from "../../utility/FileUtils";
class NominationPreviewModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    }
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen
    })
  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, sessions,user } = this.props;
    const { nomination } = this.state;
    return (
      <>
        <div className={`cnt-modal-openable-button ${outerClassNames}`} onClick={this.toggleModal} >
          {children}
        </div>
        <Modal
          className="white-background cnt-user-admin-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        > 
          <div className="btn cnt-primary-button" onClick={(e)=>printDivToPdf("preview-nomination-modal", "Nomination Preview")}>
            Print to PDF
          </div>
          {modalOpen ? (
            <div id="preview-nomination-modal" className="col-xs-12 cnt-nomination-form-main-container">
              <NominationThreeStepForm {...this.props} />
            </div>
          ):""}
          <br/>
          <br/>
          <div class="col-xs-12" style={{marginTop: "15px", padding:"15px"}}>
            <button type="button" onClick={this.toggleModal} style={{marginLeft:0}} className="cnt-cancel-button" > Close </button>
          </div>
        </Modal>
      </>
    );
  }
}

export default NominationPreviewModal
;
