import Cookies from "universal-cookie";

export function caseInsensitiveSort(a, b) {
  if(a && b && typeof a == 'string' && typeof b == 'string') {
    return a.toLowerCase().localeCompare(b.toLowerCase(), 'en', { sensitivity: 'base' });
  }
  return a < b ? -1 : 1
}

export function customSearch(searchQuery,row,column) {
  const field = row[column.field]
  if(field && typeof field == 'string'){
    return field.toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) != -1
  }
  return false;
}


export async function downloadPages(url, callback, options = {}) {
  const pageSize = options.per_page ? options.per_page : 1000
  let count = 0;
  let listCount = 0;
  let page = 0;
  const cookies = new Cookies();
  const token = cookies.get("X-CSRF-Token");
  while(true) {
    const fetchJSON = await fetch(url, {
      method: "POST",
      redirect: "manual",
      body: JSON.stringify({
        per_page: pageSize,
        page: page,
      }),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    }).then(response => {
      return response.json();
    })
    count += fetchJSON.data.length

    if(callback) {
      callback(fetchJSON.data);
    }
    if(count >= fetchJSON.total_rows){
      break;
    }

    if(fetchJSON.data.length < pageSize) {
      break;
    }
    page++;
  }
  return;
}
