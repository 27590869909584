import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import {isJaguar, addMy, isAccountManager} from "@utility/PermissionUtility"
import UniversalImageCircle from "@images/UniversalImageCircle"
import { dateText } from "@utility/TimeUtils"
import ToggleOnOff from "@layout/superfilter/ToggleOnOff"
import UniversalOpenableContainer from "@layout/openable/UniversalOpenableContainer"
import EventModal from "@events/modals/EventModal"
import Select from 'react-select-virtualized'
class EventsIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      archivedSortBy: "Date",
      defaultSortBy:"Date",
      archivedOpen: true,
    };
  }

  renderCreateEventButton() {
    const { user } = this.props;
    if(isJaguar(user)) {
      return (
        <a href="/events/new">
          <button className="cnt-page-header-button">
           Add Event
          </button>
        </a>
      )
    }
  }

  renderEvent(event) {
    const { user } = this.props;
    if(isAccountManager(user)) {
      return (
        <EventModal
          user={user}
          event={event}
          outerClassNames="col-xs-6 col-sm-6 col-md-3 col-lg-2"
        >
          <div className="cnt-events-index-event-container">
            <UniversalImageCircle size={'large'} image={event.icon || null} name={event.name} id={event.id}/>
            <div className="cnt-events-index-event-description">
              <div className="cnt-events-index-event-description-name">
                {event.name}
              </div>
              <div className="cnt-events-index-event-description-location">
                {dateText(event)}
              </div>
            </div>
          </div>
        </EventModal>
      )
    }
    return (
      <a href={`/events/${event.id}`} key={`event-${event.id}`} className="col-xs-6 col-sm-6 col-md-3 col-lg-2">
        <div className="cnt-events-index-event-container">
          <UniversalImageCircle size={'large'} image={event.icon || null} name={event.name} id={event.id}/>
          <div className="cnt-events-index-event-description">
            <div className="cnt-events-index-event-description-name">
              {event.name}
            </div>
            <div className="cnt-events-index-event-description-location">
              {dateText(event)}
            </div>
          </div>
        </div>
      </a>
    )
  }

  getSortOptions() {
    return [
      "Date",
      "Alphabetical"
    ].map(x=>{return {label: x, value: x}})
  }

  sortEvents(events, sortName, archived){
    if(sortName == 'Date') {
      if(archived) {
        events = events.sort((a,b) => new Date(a.start_date||"12-12-1990") > new Date(b.start_date||"12-12-1990") ? -1 : 1)
      }else {
        events = events.sort((a,b) => new Date(a.start_date||"12-12-2100") < new Date(b.start_date||"12-12-2100") ? -1 : 1)
      }

    } else {
      events = events.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) ;
    }
    return events
  }

  render() {
    let { user, events } = this.props;
    const { archivedSortBy, defaultSortBy, archivedOpen } = this.state;

    return (
      <DefaultLayout
        {...this.props}
          sidebarId="events"
      >
        <h1 className="cnt-page-header">
          Events
          {this.renderCreateEventButton()}
        </h1>
        <div className="row">
          <div className="col-xs-12">
            <div className="cnt-condensed-model-section-header">Active</div>
          </div>
          <div className="col-xs-12">
            <div className="row cnt-default-layout-super-filter">
              <div className={`col-xs-12 col-sm-6 col-md-4 ${defaultSortBy ? "cnt-super-filter-active" : ""}`}>
                <Select
                  value={defaultSortBy ? {label: defaultSortBy, value: defaultSortBy} : null}
                  options={this.getSortOptions()}
                  isClearable={false}
                  classNamePrefix='cnt-default-layout-super-filter-select'
                  onChange={(e)=>
                    this.setState({
                      defaultSortBy: e.value
                    })
                  }
                />
                <br/>
              </div>
            </div>
          </div>

          {this.sortEvents(events.filter(x=> x.archived_at == null), defaultSortBy, false).map(x=> this.renderEvent(x))}
        </div>
        <div className="row">

          <div className="col-xs-12" style={{cursor: "pointer"}} onClick={(e)=>this.setState({archivedOpen: !archivedOpen})}>
            <div className="cnt-condensed-model-section-header" >
              Archived
              <div className={`cnt-default-layout-universal-openable-header-arrow ${archivedOpen ? "open" : "closed"}`}>
                <img src="/layout/openable-arrow.svg" />
              </div>
            </div>
          </div>
          {archivedOpen ? (
            <>
            <div className="col-xs-12">
              <div className="row cnt-default-layout-super-filter">
                <div className={`col-xs-12 col-sm-6 col-md-4 ${defaultSortBy ? "cnt-super-filter-active" : ""}`}>
                  <Select
                    value={archivedSortBy ? {label: archivedSortBy, value: archivedSortBy} : null}
                    options={this.getSortOptions()}
                    isClearable={false}
                    classNamePrefix='cnt-default-layout-super-filter-select'
                    onChange={(e)=>
                      this.setState({
                        archivedSortBy: e.value
                      })
                    }
                  />
                  <br/>
                </div>
              </div>
            </div>
            <br/>
            {this.sortEvents(events.filter(x=> x.archived_at != null), archivedSortBy, true).map(x=> this.renderEvent(x))}
            </>
          ):""}
          <br/>
        </div>
      </DefaultLayout>
    );
  }
}

export default EventsIndex;
