import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import Cookies from "universal-cookie";
import { flattenObj } from "@/utility/ObjectManipulation";
import DefaultLayout from "@layout/DefaultLayout";
import ReportPreviewValues from "@/report-tool/ReportPreviewValues";
import ReportPreview from "@/report-tool/ReportPreview";
import Modal from "react-modal";

import MaterialTable from 'material-table';
import GeneralTablePagination from "../layout/tables/TableOverwrites/GeneralTablePagination"
import TablePagination from "@material-ui/core/TablePagination";

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchedPreview: false,
      pageNumber: 0,
      itemsPerPage: 5,
      count: 0,
      previewList: [],
      objectKeys: [],
      selectedKeys: [],
      keysReady: false,
      downloadModalOpen: false,
      downloadReportFileId: null,
      downloadReportGenerated: false,
      downloadReportUrl: null
    }
    this.fetchPreview = this.fetchPreview.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.storeRowsPerPage = this.storeRowsPerPage.bind(this);
    this.saveReport = this.saveReport.bind(this);
    this.toggleDownloadModal = this.toggleDownloadModal.bind(this);
    this.generateReportCsv = this.generateReportCsv.bind(this);
    this.generateReportXlsx = this.generateReportXlsx.bind(this);
    this.clearReportDownload = this.clearReportDownload.bind(this);
    this.fetchReportFile = this.fetchReportFile.bind(this);
  }

  componentWillMount() {
    Modal.setAppElement("body");
  }

  componentDidMount() {
    // this.fetchPreview();
    // this.fetchObjectSkeleton();
    const { report } = this.props;
    if (report) {
      this.setBaseObj(
        { value: report.object_type, label: report.object_type },
        false
      ).then(() => {
        const { objectKeys } = this.state;
        let selectedKeys = report.keys.map(key => {
          const keyDetails = objectKeys[key];
          return { value: key, label: keyDetails.label, datatype: keyDetails.datatype };
        });
        this.setState({
          keysReady: true,
          selectedKeys: selectedKeys
        });
      });
    }
  }

  toggleDownloadModal() {
    const { downloadModalOpen } = this.state;
    this.setState({ downloadModalOpen: !downloadModalOpen });
  }

  clearReportDownload() {
    this.setState({
      downloadModalOpen: false,
      downloadReportFileId: null,
      downloadReportGenerated: false,
      downloadReportUrl: false
    });
  }

  editReportName(e) {
    this.setState({ reportName: e.target.value })
  }

  async setBaseObj(e, resetValues = true) {
    this.setState({ reportType: e.value });
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    let objList = [];
    let objKeys = [];
    await fetch("/reports/object_skeleton_all", {
      method: "POST",
      redirect: "manual",
      body: JSON.stringify({
        object_type: e.value
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          objList = json.object_list;
          objKeys = objList[0];
          if (resetValues) {
            this.setState(
              {
                baseObj: e,
                objectKeys: objKeys
              },
              this.setDefaultFields
            );
          } else {
            this.setState({
              baseObj: e,
              objectKeys: objKeys
            });
          }
        } else {
          console.log(json.error);
        }
      });
  }

  fetchObjectSkeleton() {
    const { report } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch("/reports/object_skeleton_all", {
      method: "POST",
      redirect: "manual",
      body: JSON.stringify({
        object_type: report.object_type
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          let objList = json.object_list;
          let objKeys = objList[0];
          this.setState({
            objectKeys: objKeys
          });
        } else {
          console.log(json.error);
        }
      });
  }

  generateReportXlsx() {
    const { report } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch(`/reports/${report.id}/generate_report`, {
      method: "GET",
      redirect: "manual",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          const reportFileId = json.report_file_id;
          this.setState({
            downloadReportFileId: reportFileId
          }, () => {
            // start polling in setState callback to ensure downloadReportFileId is set
            setTimeout(this.fetchReportFile, 3000)
          });
        } else {
          console.log(json.error);
        }
      });
  }

  generateReportCsv() {
    const { report } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch(`/reports/${report.id}/generate_report_csv`, {
      method: "GET",
      redirect: "manual",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          const reportFileId = json.report_file_id;
          this.setState({
            downloadReportFileId: reportFileId
          }, () => {
            // start polling in setState callback to ensure downloadReportFileId is set
            setTimeout(this.fetchReportFile, 3000)
          });
        } else {
          console.log(json.error);
        }
      });
  }

  fetchReportFile() {
    const { downloadReportFileId } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch(`/reports/fetch_report`, {
      method: "POST",
      redirect: "manual",
      body: JSON.stringify({
        report_file_id: downloadReportFileId
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error != null) {
          console.log(json.error);
        } else {
          switch(json.status) {
            case "completed":
              this.setState({
                downloadReportGenerated: true,
                downloadReportUrl: json.report_url
              });
              break;
            case "failed":
              console.log(json.error);
              alert("Report generation failed, try again.");
              break;
            default:
              // still generating, continue fetching
              setTimeout(this.fetchReportFile, 3000);
          }
        }
      });
  }

  fetchPreview() {
    const { report, objectType, filterChains, sorting_criteria, reportKeys } = this.props;
    const { pageNumber, itemsPerPage } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch('/reports/get_preview', {
      method: 'POST',
      redirect: "manual",
      body: JSON.stringify(
        {
          object_type: report.object_type,
          filterChains: filterChains,
          // sortingCriteria: sorting_criteria,
          sortingCriteria: sorting_criteria.map(sort => { return {"ascending": sort.ascending, "selectedOption": {"value": sort.selected_option}} }),
          keys: report.keys,
          page_number: pageNumber,
          items_per_page: itemsPerPage
        }
      ),
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        let newList = json.list[1].map(object => flattenObj(object))
        this.setState({
          fetchedPreview: true,
          previewList: newList,
          count: json.list[0]
        });
      } else {
        console.log(json.error);
      }
    });
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  onChangePage(event, page) {
    this.setState(
      {pageNumber: page},
      // () => this.fetchPreview()
    );
  }
  storeRowsPerPage(event) {
    this.setState(
      {itemsPerPage: event},
      // () => this.fetchPreview()
    );
  }

  saveReport() {
    const { objectType, report } = this.props;
    const { keys, reportName } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch('/reports', {
      method: 'POST',
      redirect: "manual",
      body: JSON.stringify(
        {
          report_name: reportName,
          object_type: objectType,
          keys: keys
        }
      ),
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        console.log('success');
        window.location.href = `/reports/${report.id}`;
      } else {
        console.log(json.error);
      }
    });
  }

  archiveReport(report) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/reports/${report.id}/archive`, {
      method: 'POST',
      redirect: "manual",
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        console.log('success');
        window.location.href = '/reports';
      } else {
        console.log(json.error);
      }
    });
  }

  renderResults() {
    const { objectList, reportKeys } = this.props;
    const { fetchedPreview, previewList, itemsPerPage, pageNumber, count, objectKeys } = this.state;
    let keys = reportKeys.map(key => { return {title: key[1], field: key[0], render: (row) => (<ReportPreviewValues row={row} value={key[0]} keysWithDataType={objectKeys} />)} });
    const options = {
      serverSide: true,
      pageSize: itemsPerPage,
      pageSizeOptions: [
        5,10,20,50,100
      ],
    };
    if (fetchedPreview) {
      return (
        <MaterialTable
          key={`material-table-selected-table-preview`}
          localization={{
            body: {
              emptyDataSourceMessage: "No records to display"
            }
          }}
          components={{
            Pagination: (props) => (
              <TablePagination
                {...props}
                ActionsComponent={(subprops) =>
                  <GeneralTablePagination
                    {...subprops}
                    icons={props.icons}
                    showFirstLastPageButtons={
                      true
                    }
                    page={pageNumber}
                    count={count}
                    onChangePage={this.onChangePage}
                  />
                }
              />
            )
          }}
          columns={keys}
          data={previewList}
          autoHeight={true}
          title=""
          options={options}
          onChangeRowsPerPage={this.storeRowsPerPage}
        />
      );
    }
    return (
      <div className="report-preview-placeholder">
        Generating Preview
      </div>
    );
  }

  renderSaveReport() {
    const { reportTitle } = this.props;
    const { reportName } = this.state;
    if (reportTitle !== undefined) return '';
    return (
      <div className="report-save-wrapper">
        <input type="text" placeholder="Report name here..." value={reportName} onChange={(e) => this.editReportName(e)} />
        <button onClick={this.saveReport}>Save Report</button>
      </div>
    )
  }

  renderReportTitle() {
    const { reportTitle } = this.props;
    if (reportTitle !== undefined) {
      return (
        <>
          <div className="report-tool-page-header">
            <h1 className="cnt-page-header">
              {reportTitle}
            </h1>
          </div>
          <div style={{clear: "both"}} />
        </>
      );
    }
    return '';
  }

  renderReportActions() {
    const { report, user, editable } = this.props;
    return (
      <div className="report-tool-actions-wrapper">
        <div className="report-tool-action-links">
          <a className="report-tool-link-blue" href="/reports">Return to Reports</a>
          <a className="report-tool-link-red" onClick={() => this.archiveReport(report)}>Archive Report</a>
        </div>
        <div className="report-tool-actions-buttons">
          {
            report.editable || user.id === report.created_by_id ?
            <a href={`/reports/${report.id}/edit`}>
              <div className="report-tool-button report-tool-button-blue">Edit Report</div>
            </a>
            :
            ''
          }
          <a onClick={this.toggleDownloadModal} style={{marginLeft: "10px"}}>
            <div className="report-tool-button report-tool-button-dark-blue">Download Report</div>
          </a>
          <a href={`/reports`} style={{marginLeft: "10px"}}>
            <div className="report-tool-button report-tool-button-white">Close</div>
          </a>
          {
            !report.editable && user.id !== report.created_by_id ?
            <div className="report-tool-actions-label-editable">Only the owner can make edits to this report.</div>
            :
            ''
          }
        </div>
      </div>
    );
  }

  redirectUrl(value) {
    let url = new URL(window.location.href);
    url.searchParams.set('base_obj', value);
    window.location.href = url.href;
  }

  renderModalDownload() {
    const { downloadReportUrl } = this.state;
    return (
      <>
        <div className="report-modal-download-text">
          <a href={downloadReportUrl} download>Click here to download report</a>
        </div>
        <div className="report-modal-download-options">
          <a className="report-modal-download-option" onClick={this.clearReportDownload}>
            Close
          </a>
        </div>
      </>
    );
  }

  renderModalPolling() {
    return (
      <>
        <div className="report-modal-download-text">
          <p>Your report is generating. It will be ready to download in a few moments.</p>
          <p>If you are generating a very large report, it may take a few minutes.</p>
        </div>
      </>
    );
  }

  renderModalGenerateButtons() {
    const { report } = this.props;
    return (
      <>
        <div className="report-modal-download-text">
          Export Report as
        </div>
        <div className="report-modal-download-options">
          <a className="report-modal-download-option" onClick={this.generateReportXlsx}>
            XLSX
          </a>
          <a className="report-modal-download-option" onClick={this.generateReportCsv}>
            CSV
          </a>
        </div>
      </>
    );
  }

  renderModalGenerateButtonsOld() {
    const { report } = this.props;
    return (
      <>
        <div className="report-modal-download-text">
          Export Report as
        </div>
        <div className="report-modal-download-options">
          <a className="report-modal-download-option" href={`/reports/${report.id}/download`} onClick={this.toggleDownloadModal}>
            XLSX
          </a>
          <a className="report-modal-download-option" href={`/reports/${report.id}/download_csv`} onClick={this.toggleDownloadModal}>
            CSV
          </a>
        </div>
      </>
    );
  }

  renderModalBody() {
    const { downloadReportFileId, downloadReportGenerated } = this.state;

    // no report job running, present buttons for starting job
    if (!downloadReportFileId) {
      return this.renderModalGenerateButtons();
    }

    // report generating job in progress, continue to poll for job completion
    if (downloadReportFileId && !downloadReportGenerated) {
      return this.renderModalPolling();
    }

    // report generated and ready to download
    if (downloadReportGenerated) {
      return this.renderModalDownload();
    }
  }

  renderModal() {
    return (
      <div className="report-modal-download-wrapper">
        {this.renderModalBody()}
      </div>
    );
  }

  render() {
    const { report, filterChains, sorting_criteria } = this.props;
    const { downloadModalOpen, selectedKeys, objectKeys, keysReady } = this.state;
    return (
      <DefaultLayout
        {...this.props}
        sidebarId="reports"
      >
        {this.renderReportTitle()}
        {this.renderReportActions()}
        <br />
        <div className="report-tool-results" style={{width: "100%", overflowX: "hidden"}}>
          <div className="cnt-default-layout-general-table-container">
            {
              keysReady ?
              <ReportPreview
                baseObj={{value: report.object_type, label: report.object_type}}
                objectKeys={selectedKeys}
                keysWithDataType={objectKeys}
                filterChains={filterChains}
                sortingCriteria={sorting_criteria}
              />
              :
              <></>
            }
          </div>
        </div>
        {this.renderSaveReport()}
        <Modal
          className="white-background report-modal-download disable-scrollbars"
          overlayClassName="report-modal-overlay"
          isOpen={downloadModalOpen}
          onRequestClose={this.toggleDownloadModal}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
      </DefaultLayout>
    );
  }
}

export default Report;
