import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import CompanyCondensedView from "./CompanyCondensedView"
class CompanyProfileModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      company: null,
    }
    this.toggleModal = this.toggleModal.bind(this);
  }

  getCompany() {
    const { company } = this.props;
    if(company != null) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch(`/companies/${company.id}/get_company_modal_info`, {
        method: "GET",
        redirect: "manual",
        body: null,
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            company: json.company
          })
        }
        else {
          this.setState({
            error: json.error
          })
        }
      }).catch((error)=>{
        this.setState({
        error: "Could not load company at this time. Try again later."
      })});
    }
  }

  renderModal() {
    const { company } = this.state;
    if(company) {
      return (
        <div className="row">
          <div className="col-xs-12">

          </div>
          <div className="col-xs-12">
            <CompanyCondensedView company={company}/>
          </div>
          <div className="col-xs-12">
            <br/>
            <br/>
          </div>
          <div className="col-xs-12">
            <button className="cnt-submit-button" onClick={() => {window.location = `/companies/${company.id}`}}>Edit Company</button>
            <button className="cnt-cancel-button" onClick={this.toggleModal}> Cancel</button>
          </div>
        </div>
      );
    }else {
      //Render Loading
      return (
        <div className="row">
          <div className="col-xs-12 ">
            <div className="ctn-default-layout-log-table-loading">
              <h1> Loading </h1>
              <br />
              <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
          </div>
        </div>
      );
    }

  }

  toggleModal() {
    const { modalOpen, company } = this.state;
    const propCompany = this.props.company;

    if(!modalOpen && propCompany) {
      if(!company || company.id != company.id) {
        this.getCompany();
      }
    }

    this.setState({
      modalOpen: !modalOpen
    });

  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, sessions } = this.props;
    return (
      <>
        <div className={`cnt-modal-openable-button ${outerClassNames}`} onClick={this.toggleModal} >
          {children}
        </div>
        <Modal
          className="white-background cnt-user-admin-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >

          <a href="#" onClick={this.toggleModal}>
          </a>
          <br/>
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default CompanyProfileModal;
