export function getTemplates(baseObj) {
  // switch (baseObj.value) {
  //   case "Nomination":
  //     return getNominationLayouts();
  //     break;
  //   case "Contact":
  //     return getContactLayouts();
  //     break;
  //   case "Company":
  //     return getCompanyLayouts();
  //     break;
  //   case "Event":
  //     return getEventLayouts();
  //     break;
  //   default:
  //     return [];
  // }
  return [];
}


// Specific templates for object types go here, these will need to change for each project
export function getNominationLayouts() {
  return [
    {
      name: "Template #1",
      keys: [
        {value: 'user.id', label: 'User ID', datatype: 'integer'},
        {value: 'user.email', label: 'Email', datatype: 'string'},
        {value: 'user.first_name', label: 'First Name', datatype: 'string'},
        {value: 'user.last_name', label: 'Last Name', datatype: 'string'},
        {value: 'user.phone_number', label: 'Phone Number', datatype: 'string'}
      ]
    }
  ]
}

export function getContactLayouts() {
  return [
    {
      name: "Template #1",
      keys: [
        {value: 'user.id', label: 'User ID', datatype: 'integer'},
        {value: 'user.email', label: 'Email', datatype: 'string'},
        {value: 'user.first_name', label: 'First Name', datatype: 'string'},
        {value: 'user.last_name', label: 'Last Name', datatype: 'string'},
        {value: 'user.phone_number', label: 'Phone Number', datatype: 'string'}
      ]
    }
  ]
}

export function getCompanyLayouts() {
  return [
    {
      name: "Template #1",
      keys: [
        {value: 'user.id', label: 'User ID', datatype: 'integer'},
        {value: 'user.email', label: 'Email', datatype: 'string'},
        {value: 'user.first_name', label: 'First Name', datatype: 'string'},
        {value: 'user.last_name', label: 'Last Name', datatype: 'string'},
        {value: 'user.phone_number', label: 'Phone Number', datatype: 'string'}
      ]
    }
  ]
}

export function getEventLayouts() {
  return [
    {
      name: "Template #1",
      keys: [
        {value: 'user.id', label: 'User ID', datatype: 'integer'},
        {value: 'user.email', label: 'Email', datatype: 'string'},
        {value: 'user.first_name', label: 'First Name', datatype: 'string'},
        {value: 'user.last_name', label: 'Last Name', datatype: 'string'},
        {value: 'user.phone_number', label: 'Phone Number', datatype: 'string'}
      ]
    }
  ]
}

export function getReportTypeImage(type) {
  let imgUrl;
  switch (type) {
    case "Nomination":
      imgUrl = "/layout/blue-star.svg";
      break;
    case "Contact":
      imgUrl = "/layout/sidebar/contacts.svg";
      break;
    case "Company":
      imgUrl = "/layout/sidebar/companies.svg";
      break;
    default:
      imgUrl = "/layout/sidebar/events.svg";
  }
  return imgUrl;
}
