import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Select from 'react-select-virtualized'

class UserCreationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: true,

    }
  }

  getUserOptions() {
    const { users, editing_user } = this.props;
    if(users) {
      return users.filter(x=> x.id != editing_user.id).map(x=> {return {value: x.id, label: `${x.first_name} ${x.last_name}`}})
    }
    return [];
  }

  renderModal() {
    const {newUser, user, contacts, inviteSent } = this.props;
    return (
      <div className="row">
        <div className="col-xs-12 ">
          <div className="cnt-admin-user-management-delete-modal-title">
            A New User has been Created
          </div>
          <div className="cnt-admin-user-management-delete-modal-description">
            {inviteSent ?
              `An email has been sent to ${newUser.email} inviting them to the tool.`
              :
              `${newUser.email} has been added to the tool.`
            }

          </div>
          <div className="">
            <button className="cnt-submit-button" onClick={() => {window.location = `/admin/users/${newUser.id}`}}>Finish</button>
            <button className="cnt-cancel-button" onClick={() => {window.location = "/"}}> Back to my Dashboard</button>
          </div>
        </div>
      </div>
    );
  }



  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, sessions } = this.props;
    return (
      <>
        <div className={outerClassNames} style={{cursor:"pointer", display: "inline-block", float: 'right'}} onClick={() => this.toggleModal()} >
          {children}
        </div>
        <Modal
          className="white-background cnt-user-admin-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={true}
          onRequestClose={()=>{}}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default UserCreationModal;
