import React from "react";
import PropTypes from "prop-types";
import { format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import ReactTooltip from "react-tooltip";
import Cookies from "universal-cookie";
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-timezone';
import { canSessionBeAdded, isInTrack } from "@utility/SessionUtils"
import Table from "rc-table";
import "chart.js";
import { AreaChart, PieChart } from "react-chartkick";
class UsersDashboard extends React.Component {
  constructor(props) {
    super(props);
  }
  getUserRegValues() {
    return this.props.values;
  }

  getRegistrantsByType() {
    let {attendees} = this.props;
    attendees = attendees.filter(x=>x.email.indexOf("@jaguardesignstudio") == -1 && x['registration_answers']);
    const attendee_types = attendees.map(x=> x['registration_answers'][22]).filter((x,i,t) => t.indexOf(x) == i);
    console.log(attendee_types);

    let attendee_object = attendee_types.filter(x=>x).map(x=>
      [x,attendees.filter(y=> y['registration_answers'][22] == x).length]
    )
    return attendee_object;
  }

  getTotalRegistrants() {
    const { attendees, users} = this.props;
    return attendees.filter(x=>x.email.indexOf("@jaguardesignstudio") == -1 && x['registration_answers']).filter(x=> users.findIndex(y=> x.email == y.email)!= -1).length;
  }

  render() {
    return (
      <>
        <div className="row sg-platform-report-section">
          <div className="col-xs-12">
            <div className="sg-platform-report-circle">
              <div className="sg-platform-report-circle-inner">
                <div className="sg-platform-report-circle-title">
                  Total Registrations
                </div>
                <div className="sg-platform-report-circle-count">
                  {this.getTotalRegistrants()}
                </div>
              </div>

            </div>
          </div>
          <div className="col-xs-12 col-md-7">
            <h1> Registrants over time</h1>
             <AreaChart data={this.getUserRegValues()} />
          </div>
          <div className="col-xs-12 col-md-5">
            <h1> Registrants breakdown</h1>
            <PieChart legend={false} data={this.getRegistrantsByType()} />
          </div>
        </div>
          <div className="row sg-platform-report-section">
            <h1>Download reports </h1>
              <table className="sg-admin-user-panel-table">
                <tr>
                  <th>
                    Report
                  </th>
                </tr>
                <tr>
                  <td>
                    <a href="/admin/registrants_report">Registrants Report</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="/admin/sessions_report">Session Report</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="/admin/sessions_attendee_registrant_report">Session Registrant Report</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="/admin/reports/image_report">Family Image .zip</a>
                  </td>
                </tr>
              </table>
          </div>
      </>
    );
  }
}

export default UsersDashboard;
