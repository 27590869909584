import React from "react";
import PropTypes from "prop-types";
import titleize from "titleize";

class ReportToolSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: { value: "", label: "", datatype: "" },
      selectOpen: false,
      openOptions: []
    };
    this.toggleSelectOpen = this.toggleSelectOpen.bind(this);
  }

  toggleSelectOpen() {
    const { selectOpen } = this.state;
    this.setState({ selectOpen: !selectOpen });
  }

  optionClicked(e, option) {
    const { onChange } = this.props;
    e.preventDefault();
    // onChange({value: option.value, label: option.label});
    onChange({
      value: option.value,
      label: option.label,
      datatype: option.datatype
    });
  }
  objectExpand(e, object) {
    const { openOptions } = this.state;
    e.stopPropagation();
    let newOptions = openOptions;
    if (newOptions.includes(object)) {
      newOptions = newOptions.filter(item => {
        return item !== object;
      });
    } else {
      newOptions.push(object);
    }
    this.setState({ openOptions: newOptions });
  }

  renderOptions() {
    const { baseObj, options } = this.props;
    const { openOptions } = this.state;
    const optionArray = [];
    const relationalOptions = [];
    // options.forEach(option => {
    //   if (option.value.includes(".")) {
    //     let splitOption = option.value.split(".");
    //     let relationalObject = relationalOptions.some(
    //       e => e.object === splitOption[0]
    //     );
    //     if (relationalObject) {
    //       let selectedRelationalObject = relationalOptions.filter(
    //         e => e.object === splitOption[0]
    //       )[0];
    //       selectedRelationalObject["options"].push(option);
    //     } else {
    //       let newObject = {};
    //       newObject["object"] = splitOption[0];
    //       newObject["options"] = [option];
    //       relationalOptions.push(newObject);
    //     }
    //   } else {
    //     optionArray.push(option);
    //   }
    // });

    options.forEach(option => {
      // values on the base object (eg. field_name only)
      if (!option.value.includes(".")) {
        optionArray.push(option);
      } else {
        // values on associated models (eg. model_name.field_name)
        const [model, field] = option.value.split(".");
        const modelObject = relationalOptions.find(e => e.object === model);

        // add field to model's options list in relationalOptions, creating object 
        // if one for this model is not yet present in array
        if (!modelObject) {
          relationalOptions.push({
            object: model,
            options: [field]
          });
        } else {
          modelObject["options"].push(option);
        }
      }
    });

    console.log("break here");

    const renderedOptions = optionArray.map(option => {
      return (
        <div
          key={option.value}
          className="report-tool-select-box-option"
          onClick={e => this.optionClicked(e, option)}
        >
          {option.label}
        </div>
      );
    });
    const renderedRelationalOptions = relationalOptions.map(object => {
      return (
        <div
          key={`relation-${object.object}`}
          className="report-tool-select-box-relation"
        >
          <div
            className="report-tool-select-box-relation-title"
            onClick={e => this.objectExpand(e, object.object)}
          >
            {titleize(object.object)}
          </div>
          {openOptions.includes(object.object)
            ? object.options.map(option => {
                return (
                  <div
                    key={option.value}
                    className="report-tool-select-box-option"
                    onClick={e => this.optionClicked(e, option)}
                  >
                    {option.label}
                  </div>
                );
              })
            : ""}
        </div>
      );
    });

    return (
      <>
        {renderedOptions}
        {renderedRelationalOptions}
      </>
    );
  }

  renderSelectBox() {
    const { selectOpen } = this.state;
    if (selectOpen) {
      return (
        <div className="report-tool-select-box">{this.renderOptions()}</div>
      );
    }
  }

  render() {
    return (
      <div
        className="report-tool-select-wrapper"
        onClick={this.toggleSelectOpen}
      >
        Select ...
        {this.renderSelectBox()}
      </div>
    );
  }
}

export default ReportToolSelect;
