import React from "react";
import PropTypes from "prop-types";

class GeneralChit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  renderCircle() {
    const { forceCircle, circle } = this.props;
    if(forceCircle) {
      return (
        <div className="cnt-default-layout-table-chit-image">
          {circle}
        </div>
      )
    }
  }
  render() {
    const { user, link, name, circle, style, outerClassNames} = this.props;
    return (
      <a
        style={{...(style||{})}}
        href={link || "#"}
        className={`cnt-default-layout-table-chit ${outerClassNames}`}
      >
        {this.renderCircle()}
        <div className="cnt-default-layout-table-chit-name ">
          {name}
        </div>
      </a>
    );
  }
}

export default GeneralChit;
