import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import UniversalImageCircle from "@images/UniversalImageCircle"
import { canApproveNomination } from "@utility/PermissionUtility"
import { dateText } from "@utility/TimeUtils"
import NominationStatus from "@nominations/tables/NominationStatus"
import AttendeeStatus from "@nominations/tables/AttendeeStatus"
import AttendenceStatus from "@nominations/tables/AttendenceStatus"
import ToggleableCommentContainer from "@nominations/comments/ToggleableCommentContainer"
import { getNominationQuestionsAndAnswers, setNominationReview } from "@utility/NominationUtility"
import NominationStatusBlock from "@nominations/NominationStatusBlock"
class NominationProfileModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      event: null,
      contact: null,
      currentComment: "",
      commentsOpen: true,
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.approveNomination = this.approveNomination.bind(this);
    this.rejectNomination = this.rejectNomination.bind(this);
    this.submitComment = this.submitComment.bind(this);
    this.reviewNomination = this.reviewNomination.bind(this);
    this.notifyNomination = this.notifyNomination.bind(this);
  }

  getNomination() {
    const { nomination } = this.props;
    if(nomination != null) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch(`/nominations/${nomination.id}/get_nomination_modal_info`, {
        method: "GET",
        redirect: "manual",
        body: null,
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            contact: json.contact,
            nomination: json.nomination,
            event: json.event,
            comments: json.nomination.nomination_comments || [],
            versions: json.versions
          })
        }
        else {
          this.setState({
            error: json.error
          })
        }
      }).catch((error)=>{
        this.setState({
        error: "Could not load nominations at this time. Try again later."
      })});
    }
  }

  renderContact(){
    const { contact } = this.state;
    if(contact) {
      return (
        <div className="col-xs-12 col-md-6 cnt-default-layout-nomination-contact-area">
          <div className="cnt-default-layout-nomination-contact-description-container">
            <div className="cnt-default-layout-nomination-contact-picture">
              <UniversalImageCircle image={contact.contact_image} name={contact.first_name} id={contact.id}/>
            </div>
            <div className="cnt-default-layout-nomination-contact-description">
              <span className="cnt-default-layout-nomination-contact-description-name">
                {contact.first_name} {contact.last_name}
              </span>
              <br/>
              {contact.job_title}
              <br/>
              {contact.company ? contact.company.name : contact.am_provided_company_name||"Unknown"}
              <div style={{color:"red"}}>{contact && contact.company && contact.company.compliance_issue ? "Compliance Issue" : ""}</div>
            </div>
          </div>
        </div>
      );
    }
  }

  renderEvent() {
    const { event } = this.state;
    if(event) {
      return (
        <div className="col-xs-12 col-md-6 cnt-default-layout-nomination-contact-area">
          <div className="cnt-default-layout-nomination-contact-description-container">
            <div className="cnt-default-layout-nomination-contact-picture">
              <UniversalImageCircle image={event.icon } name={event.name} id={event.id}/>
            </div>
            <div className="cnt-default-layout-nomination-contact-description">
              <span className="cnt-default-layout-nomination-contact-description-name">
                {event.name}
              </span>
              <br/>
              {dateText(event)}
              <br/>
              {event.location}
              <br/>
            </div>
          </div>
        </div>
      );
    }
  }

  renderNominationApprovalButtons() {
    const { user, nomination } = this.props;
    const { currentComment } = this.state;
    if(canApproveNomination(user) && nomination.status == "submit" || nomination.status == "in_review") {
      return (
        <>
          {nomination.status != "in_review" ? (
            <button onClick={this.reviewNomination} type="button" className="cnt-review-button full-length-button"> In Review </button>
          ):""}
          <button onClick={this.approveNomination} type="button" className="cnt-approve-button full-length-button"> {currentComment ?  "Approve with Comment" : "Approve" } </button>
          <button onClick={this.rejectNomination} type="button" className="cnt-reject-button full-length-button"> {currentComment ? "Deny with Comment" : "Deny" } </button>
          <button onClick={this.notifyNomination} type="button" className="cnt-notify-button full-length-button"> Notify AMs </button>
        </>
      )
    }else if(canApproveNomination(user)){
      return (
        <>
          <button onClick={this.notifyNomination} type="button" className="cnt-notify-button full-length-button"> Notify AMs </button>
        </>
      )
    }
  }

  approveNomination() {
    const { openApproveNomination, nomination } = this.props;
    const { currentComment } = this.state;
    if(openApproveNomination) {
      openApproveNomination(nomination, currentComment)
    }
    this.toggleModal();
  }

  notifyNomination() {
    const { openNotifyNomination, nomination } = this.props;
    const { currentComment } = this.state;
    if(openNotifyNomination) {
      openNotifyNomination(nomination, currentComment)
    }
    this.toggleModal();
  }

  reviewNomination() {
    const { openReviewNomination, nomination } = this.props;
    const { currentComment } = this.state;
    if(openReviewNomination) {
      openReviewNomination(nomination, currentComment)
      this.toggleModal();
    }else {
      setNominationReview(nomination, reviewCallback ? reviewCallback : null);
      this.toggleModal();
    }
  }

  rejectNomination() {
    const { openRejectNomination, nomination } = this.props;
    const { currentComment } = this.state;
    if(openRejectNomination) {
      openRejectNomination(nomination, currentComment)
    }
    this.toggleModal();
  }

  renderNominationInfo() {
    const { nomination, event } = this.state;
    if(nomination) {
      return (
        <>
          <div className="col-xs-12 ">
            <div className="cnt-condensed-model-section">
              <div className="cnt-condensed-model-section-header">
                Nomination Info
              </div>
              {this.renderNominationQuestions()}
              {nomination.notes ? (
                <div>
                  <div className="cnt-default-layout-nomination-modal-nomination-info-question">
                    Notes:
                  </div>
                  <div className="cnt-default-layout-nomination-modal-nomination-info-answer">
                    {nomination.notes}
                  </div>
                </div>
              ):""}
            </div>


          </div>

          <div className="col-xs-12">
            <br/><br/>
          </div>

        </>
      )
    }
  }

  renderNominationQuestions() {
    const { nomination, event } = this.state;
    if(nomination && event) {
      return getNominationQuestionsAndAnswers(nomination, event).map(x=>
        <div>
          <div className="cnt-default-layout-nomination-modal-nomination-info-question">
            {x.question.question}
          </div>
          <div className="cnt-default-layout-nomination-modal-nomination-info-answer">
            {x.answer.answer}
          </div>
        </div>
      )
    }
  }

  submitComment() {
    const { nomination } = this.props;
    const { currentComment, comments } = this.state;
    if(currentComment) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch(`/nominations/${nomination.id}/comment`, {
        method: "POST",
        redirect: "manual",
        body: JSON.stringify({
          comment: {
            message: currentComment,
          }
        }),
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            comments: comments.concat([
              json.comment
            ]),
            currentComment: ""
          })
        }
      });
    }
  }

  renderComments() {
    const { nomination, user} = this.props;
    const { comments, currentComment } = this.state;
    return (
      <div className="col-xs-12 cnt-default-layout-nominations-form-comments-container">
        <ToggleableCommentContainer comments={comments} />
        {nomination.status == 'submit' && canApproveNomination(user) ? (
          <div className="cnt-default-layout-nominations-form-comment-form">
            <textarea value={currentComment||""} onChange={(e) => this.setState({currentComment: e.target.value})}/>
          </div>
        ):""}
      </div>
    );
  }

  renderModal() {
    const { nomination, currentComment } = this.state;
    if(nomination) {
      return (
        <div className="row">
          {this.renderContact()}
          {this.renderEvent()}
          {this.renderNominationInfo()}
          <div className="col-xs-12 cnt-nomination-modal-button-section">
            {this.renderNominationApprovalButtons()}
            {currentComment ? <div style={{marginTop: "10px"}}/>:""}
            <button className="cnt-submit-button" onClick={() => {window.location = `/nominations/${nomination.id}`}}>{nomination.status == "draft" ? "Edit" : "Full View"}</button>
            <button className="cnt-cancel-button" onClick={this.toggleModal}> Cancel</button>
          </div>
        </div>
      );
    }else {
      //Render Loading
      return (
        <div className="row">
          <div className="col-xs-12 ">
            <div className="ctn-default-layout-log-table-loading">
              <h1 className=""> Loading </h1>
              <br />
              <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
          </div>
        </div>
      );
    }

  }

  toggleModal() {
    const { modalOpen, nomination } = this.state;
    const propNomination = this.props.nomination;

    if(!modalOpen && propNomination) {
      if(!nomination || nomination.id != propNomination.id) {
        this.getNomination();
      }
    }

    this.setState({
      modalOpen: !modalOpen,
      currentComment: null,
    });

  }

  renderStatuses() {
    const { nomination } = this.state;
    if(nomination) {
      return (
        <div className="row">
          <div className="col-xs-4 cnt-status-alignment-column ">
            <div className="cnt-default-layout-nomination-modal-status-header">
              Nomination Status:
            </div>
            <div className="cnt-default-layout-nomination-modal-status-ticker">
              <NominationStatus nomination={nomination}/>
            </div>
          </div>
          <div className="col-xs-4 cnt-status-alignment-column">
            <div className="cnt-default-layout-nomination-modal-status-header">
              Invite Status:
            </div>
            <div className="cnt-default-layout-nomination-modal-status-ticker">
              <AttendeeStatus nomination={nomination}/>
            </div>
          </div>
          <div className="col-xs-4 cnt-status-alignment-column">
            <div className="cnt-default-layout-nomination-modal-status-header">
              Attendance:
            </div>
            <div className="cnt-default-layout-nomination-modal-status-ticker">
              <AttendenceStatus nomination={nomination}/>
            </div>
          </div>
        </div>
      )
    }

  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, sessions,user } = this.props;
    const { nomination, versions } = this.state;
    return (
      <>
        <div className={`cnt-modal-openable-button ${outerClassNames}`} onClick={this.toggleModal} >
          {children}
        </div>
        <Modal
          className="white-background cnt-user-admin-modal disable-scrollbars"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          <div className="row">
            <div className="col-xs-12">
              <h1 className="cnt-default-layout-modal-header"> Overview </h1>
            </div>
          </div>
          {this.renderStatuses()}
          <br/>
          {nomination ? (
            <NominationStatusBlock
              user={user}
              nomination={nomination}
              versions={versions}
            />
          ):""}
          <br/>
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default NominationProfileModal;
