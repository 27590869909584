import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout";

class ReportLoading extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { report } = this.props;
    return (
      <DefaultLayout {...this.props} sidebarId="reports">
        <div className="report-tool-form-wrapper" style={{ margin: "0px" }}>
          <div className="report-tool-page-header">
            <h1 className="cnt-page-header">
              Loading
            </h1>
          </div>
          <div style={{ clear: "both" }} />
        </div>
      </DefaultLayout>
    );
  }
}

ReportLoading.propTypes = {
  events: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired
};

export default ReportLoading;
